import React, { useEffect, useState } from "react";
import BannerInputs from "../../../components/Banner/BannerInputs";
import HeaderBread from "../../../components/HeaderBread";
import Layout from "../../../Layout";
import { useParams } from "react-router-dom";
import {
  getDestinationDataById,
  updateBannerData,
} from "../../../utils/ApiCalls/Destination";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import DestinationInput from "../../../components/Destination/DestinationInput";

const EditDestination = () => {
  const params = useParams();
  const [destination, setDestination] = useState({});
  const [{ useDetails }, dispatch] = useDataLayerValue();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Destination List",
      route: "/cms/homepage/destinations",
    },
    {
      title: "Edit Destination",
      route: "/cms/homepage/destinations/edit",
    },
  ];
  useEffect(() => {
    console.log("in use effect");
    getData(params?.id);
  }, [params]);

  const getData = async (id) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getDestinationDataById(id);
    console.log(data);
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else setDestination(data);
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  console.log(params);
  return (
    <Layout type={"update_per"} module_data={"destinations"}>
      <CustomHeaderTitle title={"Edit Destination"} />
      <HeaderBread
        navigation={navigation}
        title={"Edit Destination"}
      ></HeaderBread>
      {destination && <DestinationInput destination={destination} />}
    </Layout>
  );
};

export default EditDestination;
