import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import ReactQuill from "react-quill"; // Import React Quill
import { MenuItem, Select } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { getLocations } from "../../utils/ApiCalls/Locations";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { createBanner, updateBannerData } from "../../utils/ApiCalls/Banner";
import { checkFields } from "../../utils/Validation/checkFields";
import { createOtherPages, updateOtherPagesData } from "../../utils/ApiCalls/OtherPage";
import { addlog } from "../../utils/ApiCalls/Logs";

const OtherPagesInputs = ({ pages }) => {
  const [datacontent, setdataContent] = useState(pages?.content || "");
  const [isActive, setIsActive] = useState(true);
  const [title, setTitle] = useState(pages?.title || "");
  const [url, setUrl] = useState(pages?.url || "");
  const [{userDetails}, dispatch] = useDataLayerValue();
  const [error, setError] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    console.log({pages})
    if (pages) {
      setTitle(pages?.title);
      setdataContent(pages?.content);
      setUrl(pages?.url);
      setIsActive(pages?.is_active === 1 ? true : false);
    }
  }, [pages]);
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleCheckboxChange = () => {
    setIsActive(!isActive);
  };

  const addPages = async (e) => {
    console.log("hi");
    e.preventDefault();
    let added = false;
    let dataValues = {
      title,
      url,
      content:datacontent,
      is_active: isActive ? 1 : 0,
    };
    let valid = checkFields(dataValues);
    if (valid) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: valid, type: "error" },
      });
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });   
setError(valid);
      console.log(valid);
    } else {
      setError();
      const values = {
        ...dataValues,
      };

      let data;
      if (pages?.id) {
        data = await updateOtherPagesData(pages.id, values);
      } else {
        data = await createOtherPages(values);
      }
      if (data?.error) {
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: data.error, type: "error" },
        });

        added = false;
      } else {
        added = true;
        
      }
      if (pages?.id) {
        addlog(
          "Other Pages Updated",
          "Other_Pages",
          {
            id: pages.id,
            title: values.title || pages.title,
            url: url || pages.url,
          },
          userDetails.id
        );
      } else {
        addlog(
          "Other Pages Created",
          "Other_Pages",
          {
            id: data.id,
            title: data.title,
            url: data.url,
          },
          userDetails.id
        );
      }
      if (added && !pages?.id) {
        navigate("/cms/other-pages");
      }else{
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: "Values updated", type: "success" },
        });
        navigate("/cms/other-pages");
      }
    }
  };
  return (
    <div>
      <section className="content">
        {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )}

        <div className="container-fluid">
          <div className="card">
            <form className="input_form" onSubmit={addPages}>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Title</label>
                      <input
                        required
                        className="f_input"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Url</label>
                      <input
                        className="f_input"
                        required
                        type="text"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="fieldrow">
                      <label className="f_label">Content</label>
                      <ReactQuill
                        value={datacontent}
                        onChange={setdataContent}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="iti_inner">
                      <div className="icheck-primary d-inline">
                        <input
                          type="checkbox"
                          id="customCheckbox4"
                          name="r10"
                          checked={isActive}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="customCheckbox4">
                        {isActive? <p><span style={{fontWeight:600}}>Active</span></p> : <p><span style={{fontWeight:200}}>Active</span></p>}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-right">
                <button className="btn main_submit">
                  {pages?.id ? "Update" : "Add"} Page
                </button>
              </div>
            </form>
          </div>
          <div className="pt-3"></div>
        </div>
      </section>
    </div>
  );
};


export default OtherPagesInputs;
