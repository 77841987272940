import React from "react";
import { Contact } from "../../../components/template/CustomTemplate/Pages/Contact";
import CustomTemplates from "../Deals/CustomTemplate";

const ContactUs= () => {

  return (
    <CustomTemplates slug={"contact-us"}  attributes={Contact} />
  );
};

export default ContactUs;
