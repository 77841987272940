export const modifyError = (e) => {
  if (e && e.response && e.response.data && e.response.data.message) {
    let message = e.response.data.message;
    const errorMessage = Array.isArray(message)
      ? message.join(", ") // Join array elements into a string
      : message;
    return { message: errorMessage };
  } else {
    return { message: "Unknown error occurred." };
  }
};
