import React, { useState } from 'react';
import FormPopup from '../FormPopup';
import { Box, MenuItem, Select } from '@mui/material';
import { CustomComponents } from '../template/CustomTemplate/Pages/templates/CustomComponents';

const AddComponent = ({ open, setOpen, saveComponent }) => {
    const [data, setData] = useState(); // Initialize with an empty string or suitable default value

    const handleChange = (event) => {
        setData(event.target.value); // Set the selected value
    };

    return (
        <FormPopup sx={{ width: "700px" }} open={open} setOpen={setOpen}>
            <div className="fieldrow" style={{ marginBottom: "10px" }}>
                <label className="f_label">Choose Component</label>
                <Select
                    required
                    style={{ width: '100%', height: '45px' }}
                    value={data}
                    onChange={handleChange}
                >
                    {CustomComponents.component.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.title}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <Box style={{ width: "100%", height: "400px", overflowY: "scroll" }}>
                {data && CustomComponents.component.find(item => item.id === data).component?.preview("english", CustomComponents.component.find(item => item.id === data).component?.sampleData)}
            </Box>
            {data && <div className="card-footer text-right">
                <button onClick={() => saveComponent(data)} className="btn main_submit">
                    Add Component
                </button>
            </div>}
        </FormPopup>
    );
};

export default AddComponent;
