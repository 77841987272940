import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Layout from "../../../Layout";
import HeaderBread from "../../../components/HeaderBread";
import Logs from "../../../components/Logs";
import SwitchBar from "../../../components/Pages/SwitchBar";
import SearchField from "../../../components/SearchField";
import ActiveInactive from "../../../components/UI/ActiveInactive";
import CustomizedDragNDrop from "../../../components/UI/CustomizedDragNDrop";
import { constants } from "../../../constants/constants";
import ValidDateUI from "../../../constants/validDateUI";
import {
  deleteDestinationData,
  getDestinationData,
  updateDestination,
  updatePosition,
} from "../../../utils/ApiCalls/Destination/index";
import { addlog } from "../../../utils/ApiCalls/Logs";

import { message } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DeletePop from "../../../constants/ErrorPermissionModal";
import { checkPermission } from "../../../utils/Validation/checkPermission";

const MySwal = withReactContent(Swal);

const Destinations = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Destinations",
      route: "/cms/homepage/destinations",
    },
  ];
  const [active, setActive] = useState("listing");
  const [regionalActive, setRegionalActive] = useState("SG");
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [positionVal, setPositionVal] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [{ userDetails, permission }, dispatch] = useDataLayerValue();
  const sortHandler = async (options) => {
    if (Object.keys(options).length > 0) {
      let sort, order;
      sort = options.columnKey;
      order =
        options.order === "ascend"
          ? "asc"
          : options.order === "descend"
          ? "desc"
          : undefined;
      await getData(1, sort, order);
    }
  };

  const setPositionalValue = (data) => {
    const mappedPositionVal = data.map((d) => ({
      id: d.id,
      pos: d.position,
    }));
    setPositionVal(mappedPositionVal);
  };
  const updateDestinationPosition = async (destinations) => {
    // e.preventDefault();
    console.log(destinations?.map(item=>item?.position))
    if (checkPermission(permission, "update_per", "Destinations")) {
      try {
        let args = { search: search, page: page, limit: pageSize };
        if (regionalActive) {
          args = { ...args, location: regionalActive };
        }
        if (status !== "") {
          args = { ...args, is_active: status };
        }
        const data = await updatePosition(destinations, args);
      
        addlog(
          "Destination Position Updated",
          "Destination",
          { location_id: destinations[0].location_id },
          userDetails.id
        );
        if (data.error) {
          // dispatch({
          //   type: "SET_ERROR_MESSAGE",
          //   errMess: { message: data.error, type: "error" },
          // });
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data.error,
          });
        } else if (data.data) {
          console.log(data.data?.map(item=>item?.position))
          setRows(data.data);
          setCount(data.count);
          setPositionalValue(data.data);
        }
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      } catch (error) {
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message || "Unknown error occurred",
        });
      }
    } else {
      message.error("Update permission missing");
    }
  };

  const columns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (text, record, index) => (page - 1) * pageSize + index + 1,
    },

    {
      title: "Image",
      width: 100,
      dataIndex: "image",
      key: "image",
      render: (image) => (
        <img
          src={`${constants.MEDIAURL}/${image}`}
          style={{ width: "100px", height: "80px" }}
        />
      ),
    },
    {
      title: "Valid date",
      dataIndex: "valid_date",
      key: "valid_till",
      width: 100,

      render: (text, record, index) => {
        return <ValidDateUI record={record} handleUpdate={handleUpdate} />;
      },
      // sorter: (a, b) => "",
    },
    {
      title: "Display Location",
      dataIndex: "location",
      key: "location",
      width: 50,
      sorter: (a, b) => "",
      render: (item) => <p>{item?.location_name}</p>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 100,
      render: (title) => <p>{title}</p>,
    },
  
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: 100,
      sorter: (a, b) => "",
      render: (item, record, index) => (
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            checked={!(new Date(record.valid_to) <
              new Date(new Date()))&&item ? true : false}
            onChange={(value) => {
     
              try {
                if (new Date(record.valid_from) > new Date()) {
                  throw new Error(`Destination is not yet started`);
                }

                if (new Date(record.valid_to) < new Date()) {
                  throw new Error(`Destination is expired`);
                }
                handleUpdate(
                  record.id,
                  {
                    is_active: value.target.checked ? 1 : 0,
                  },
                  record.title,
                  record.location_id
                );
              } catch (error) {
                dispatch({
                  type: "SET_LOADING",
                  loading: false,
                });
                // dispatch({
                //   type: "SET_ERROR_MESSAGE",
                //   errMess: {
                //     message: error.message || "Unknown error occurred",
                //     type: "error",
                //   },
                // });
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error.message || "Unknown error occurred",
                });
              }
            }}
            className="custom-control-input"
            id={"customSwitch1" + index}
          />
          <label
            className="custom-control-label"
            style={{}}
            for={"customSwitch1" + index}
          ></label>
        </div>
      ),
    },

    {
      title: "Action",
      key: "operation",

      width: 100,
      render: (text, record) => (
        <div className="table_action_btn">
          <div className="btn" onClick={() => handleEdit(record.id)}>
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <DeletePop
            onConfirm={() =>
              handleDelete(record.id, record.title, record.location_id)
            }
          >
            <div>
              {" "}
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getData(page);
  }, [page, pageSize, search]);

  useEffect(() => {
    getData(page);
  }, [regionalActive]);

  const handleUpdate = async (id, values, title, location_id) => {
    if (checkPermission(permission, "update_per", "Destinations")) {
      let data = await updateDestination(id, values);
      if (data.error) {
        // dispatch({
        //   type: "SET_ERROR_MESSAGE",
        //   errMess: { message: data.error, type: "error" },
        // });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.error,
        });
      } else {
        addlog(
          "Destination " + !values.valid_from
            ? values.is_active === 1
              ? "Actived"
              : "Deactivated"
            : "updated",
          "Destination",
          { ...values, id, title, location_id },
          userDetails.id
        );
      }
      await getData(page);
    } else {
      message.error("Update permission missing");
    }
  };

  const handleEdit = async (id) => {
    if (checkPermission(permission, "update_per", "Destinations")) {
      navigate("/cms/homepage/destinations/edit/" + id);
    } else {
      message.error("Update permission missing");
    }
  };

  const handleDelete = async (id, title, location_id) => {
    if (checkPermission(permission, "delete_per", "Destinations")) {
      await deleteDestinationData(id);
      addlog(
        "Destination deleted",
        "Destination",
        { id, title, location_id },
        userDetails.id
      );
      await getData();
    } else {
      message.error("Missing Permission for delete Destination");
    }
  };

  const getData = async (value, sort, order) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let args = { search: search, page: value, limit: pageSize };
    if (regionalActive) {
      args = { ...args, location: regionalActive };
    }
    if (status !== "") {
      args = { ...args, is_active: status };
    }
    if (order) {
      if (sort) {
        args = { ...args, sort };
      }
      args = { ...args, order };
    }
    let data = await getDestinationData(args);
    if (data.error) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: data.error, type: "error" },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.error,
      });
    } else if (data.data) {
      setRows(data.data);
      setCount(data.count);
      setPositionalValue(data.data);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  return (
    <Layout type={"read_per"} module_data={"destinations"}>
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"Destinations"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <SwitchBar
              tabs={[
                { title: "Destination List", value: "listing" },
                { value: "logs", title: " History Logs" },
              ]}
              activeTab={active}
              setActiveTab={setActive}
            />
            {active === "listing" && (
              <div className="card">
                <div className="card-header two_item">
                  <h5 className="card-title">Destinations List</h5>
                  <div className="two_item" style={{ width: "350px" }}>
                    <ActiveInactive
                      getData={getData}
                      setStatus={setStatus}
                      status={status}
                      setPage={setPage}
                    />
                    <Link
                      to="/cms/homepage/destinations/add"
                      className="btn add_v_btn"
                    >
                      Add Destination
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table_holder">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div style={{ maxWidth: 200 }}>
                        <SearchField
                          value={search}
                          SearchValue={() => {
                            setPage(1);
                          }}
                          setValue={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <SwitchBar
                      tabs={[
                        { title: "Singapore", value: "SG" },
                        { value: "MY", title: "Malaysia" },
                        { value: "ID", title: "Indonesia" },
                        { value: "IN", title: "India" },
                        { value: "CN", title: "China" },
                        { value: "TW", title: "Taiwan" },
                        { value: "HK", title: "Hong Kong" },
                        { value: "PH", title: "Philippines" },
                        { value: "AE", title: "UAE" },
                      
                        { value: "OTHER", title: "All Others" },
                      ]}
                      module={"country-tab"}
                      activeTab={regionalActive}
                      setPage={setPage}
                      setCount={setCount}
                      setRows={setRows}
                      setPageSize={setPageSize}
                      setActiveTab={setRegionalActive}
                      setSearch={setSearch}
                    />
                    {rows && (
                      <CustomizedDragNDrop
                        setRows={setRows}
                        setPageSize={setPageSize}
                        setPage={setPage}
                        pageSize={pageSize}
                        page={page}
                        count={count}
                        columns={
                          regionalActive !== "OTHER"
                            ? columns.filter((c) => c.key !== "location")
                            : columns
                        }
                        rows={rows}
                        title={"Destinations"}
                        sortHandler={sortHandler}
                        updatePosition={updateDestinationPosition}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        {active === "logs" && (
          <Logs
            type={"Destination"}
            slug={"/cms/homepage/destinations/edit/"}
            refreshlog={active}
          />
        )}
      </div>
    </Layout>
  );
};

export default Destinations;
