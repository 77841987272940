import Api from "../../Api";

export const createWebpage = async (data) => {
  try {
    let res = await Api.post("/webpages", data);
    return res?.data;
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const getWebpage = async (query) => {
  try {
    let res = await Api.get("/webpages", {
      params: query,
    });

    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const getShips = async (query) => {
  try {
    let res = await Api.get("/menu-list", {
      params: query,
    });
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};
export const getAllWebpage = async (query) => {
  try {
    let res = await Api.get("/webpages/all", {
      params: query,
    });

    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const exportWebpage = async (query) => {
  try {
    let res = await Api.get("/webpages/export", {
      responseType: "arraybuffer",
    });

    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const deleteWebpage = async (id) => {
  try {
    let res = await Api.delete("/webpages/" + id);

    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updateWebpage = async (id, data) => {
  try {
    let res = await Api.patch("/webpages/" + id, data);

    return res?.data || [];
  } catch (e) {
    return { error: "Failed to Update" };
  }
};
export const getWebpageByID = async (query) => {
  try {
    let res = await Api.get("/webpages/ids", {
      params: query,
    });
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to Get" };
  }
};
export const updatePageComponents = async (data) => {
  try {
    let res = await Api.post("/page-components", data);
    return res?.data;
  } catch (e) {
    return { error: "Something Wrong" };
  }
};
