import React, { useEffect, useState } from 'react'
import BannerInputs from '../../../components/Banner/BannerInputs'
import HeaderBread from '../../../components/HeaderBread';
import Layout from '../../../Layout';
import { useParams } from 'react-router-dom';
import { getDealerDataById, updateBannerData } from '../../../utils/ApiCalls/Dealer';
import { useDataLayerValue } from '../../../DataLayer/DataLayer';
import CustomHeaderTitle from '../../../components/constants/CustomHeaderTitle';
import DealInputs from '../../../components/Deal/DealInput';

const EditDeal = () => {
    const params=useParams();
    const [deal,setDeal]=useState({});
    const [{useDetails},dispatch]=useDataLayerValue();
   const navigation = [{
        title: "Home",
        route: "/"
    }, {
        title: "Deal List",
        route: "/cms/homepage/deals"
    }, {
        title: "Edit Deal",
        route: "/cms/homepage/deals/edit"
    }]
    useEffect(()=>{
        getData(params?.id)
    },[params])
    
    const getData = async (id) => {
        dispatch({
            type: "SET_LOADING",
            loading:true
          });
        let data = await getDealerDataById(id);

        if (data.error)
            dispatch({
                type: 'SET_ERROR_MESSAGE',
                errMess: { message: data.error, type: 'error' }
            });
        else
            setDeal(data);
            dispatch({
                type: "SET_LOADING",
                loading:false
              });
    }
    return (
        <Layout>
              <CustomHeaderTitle title={"Edit Deal"}/>
            <HeaderBread navigation={navigation} title={"Edit Deal"}></HeaderBread>{deal&&<DealInputs deal={deal} />}</Layout>
    )
}

export default EditDeal