import React from "react";
import "./app.css";
import { getImage } from "../../../../../../utils/getImages";
const PreviewDeal3 = ({ cardData, activetab }) => {
  return (
    <div style={{ marginLeft: "10px" }}>
      <h1 className="title-color mb-4">
        {" "}
        {activetab === "english"
          ? cardData?.title
          : activetab === "simplified_chinese"
          ? cardData?.simplified_chinese_title
          : cardData?.traditional_chinese_title}
      </h1>
      <div
        className="col-12 text-center mb-5"
        style={{ display: "flex", alignItems: "center", width: "100%" }}
      >
        <ul
          className="page-tab scroll-tab"
          style={{
            display: "flex",
            gap: "10px",

            listStyleType: "none",
            padding: 0,
            alignItems: "center",
            justifyContent: "center",
            margin: 0,
            width: "100%",
          }}
        >
          {cardData?.dataArray?.map((item, index) => (
            <li data-scroll-tab-seq={index + 1 + ""} className="">
              {activetab === "english"
                ? item?.title
                : activetab === "simplified_chinese"
                ? item?.simplified_chinese_title
                : item?.traditional_chinese_title}
            </li>
          ))}
        </ul>
      </div>
      <div
        style={{
          display: "flex",
          // alignItems: "center",
          flexWrap: "wrap",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <div className="row" style={{ width: "100%" }}>
          {cardData?.dataArray?.map((item) => (
            <div style={{ width: "100%", padding: "10px" }}>
              <div className="col-12 text-center scroll-content-1">
                <h2
                  className="mb-5 title-grey"
                  style={{ direction: activetab === "arabic" && "rtl" }}
                >
                  {activetab === "english"
                    ? item?.title
                    : activetab === "simplified_chinese"
                    ? item?.simplified_chinese_title
                    : activetab === "arabic"
                    ? item?.arabic_title
                    : item?.traditional_chinese_title}
                </h2>
              </div>
              <div className="row" style={{ width: "100%" }}>
                {item?.dataArray?.map((item) => (
                  <div className="col-md-4">
                    <div
                      className="content-info-holder"
                      style={{
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={getImage(item?.image)}
                        className="w-100 d-block rounded-top"
                      />
                      <div className="info border border-1">
                        <div className="title d-flex justify-content-between">
                          <p
                            data-lang='{"CN":"愛是永恆"}'
                            style={{
                              direction: activetab === "arabic" && "rtl",
                            }}
                          >
                            {activetab === "english"
                              ? item?.title
                              : activetab === "simplified_chinese"
                              ? item?.simplified_chinese_title
                              : activetab === "arabic"
                              ? item?.arabic_title
                              : item?.traditional_chinese_title}
                          </p>
                          <p data-lang='{"CN":"<span>從</span> 新幣116* / 新臺幣872*"}'>
                            <span>
                              {activetab === "english"
                                ? item?.from_text
                                : activetab === "simplified_chinese"
                                ? item?.simplified_chinese_from_text
                                : activetab === "arabic"
                                ? item?.arabic_from_text
                                : item?.traditional_chinese_from_text}
                            </span>{" "}
                            {activetab === "english"
                              ? item?.card_price
                              : activetab === "simplified_chinese"
                              ? item?.simplified_chinese_card_price
                              : activetab === "arabic"
                              ? item?.arabic_card_price
                              : item?.traditional_chinese_card_price}
                          </p>
                        </div>
                        <p
                          style={{
                            direction: activetab === "arabic" && "rtl",
                            padding: "40px",
                          }}
                          dangerouslySetInnerHTML={{
                            __html:
                              activetab === "english"
                                ? item?.description
                                : activetab === "simplified_chinese"
                                ? item?.simplified_chinese_description
                                : activetab === "arabic"
                                ? item?.arabic_description
                                : item?.traditional_chinese_description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
             
            </div>
          ))}
           <p
                style={{
                  direction: activetab === "arabic" && "rtl",
                 
                  marginLeft:"10px",
                  fontSize: "10px",
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    activetab === "english"
                      ? cardData?.note
                      : activetab === "simplified_chinese"
                      ? cardData?.simplified_chinese_note
                      : activetab === "arabic"
                      ? cardData?.arabic_note
                      : cardData?.traditional_chinese_note,
                }}
              />
        </div>
        
      </div>
    </div>
  );
};

export default PreviewDeal3;
