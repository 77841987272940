import React from "react";
import HeaderBread from "../../../components/HeaderBread";
import Layout from "../../../Layout";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import CabinTypeInput from "../../../components/CabinTypeInput";

const CreateCabinType = () => {
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Cabin Types",
      route: "/masters/cabin-types",
    },
    {
      title: "Add Cabin Types",
      route: "/masters/cabin-types/add",
    },
  ];

  return (
    <Layout type={"create_per"} module_data={"Cabin Types"}>
      <CustomHeaderTitle title={"Create Cabin Types"} />
      <HeaderBread
        navigation={navigation}
        title={"Add Cabin Types"}
      ></HeaderBread>
      <CabinTypeInput cabintypes={{}} />
    </Layout>
  );
};

export default CreateCabinType;
