import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Layout from "../../../Layout";
import HeaderBread from "../../../components/HeaderBread";
import OtherPagesInputs from "../../../components/Pages/OtherPagesInputs";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import { getOtherPagesData } from "../../../utils/ApiCalls/OtherPage";
import { getFareCodeData } from "../../../utils/ApiCalls/FareCode";
import FareCodeInputs from "../../../components/FareCode/FareCodeInputs";

const EditFareCode = () => {
  const params = useParams();
  const [farecode, setFareCode] = useState({});
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Market",
      route: "/masters/markets",
    },
    {
      title: "Edit Market",
      route: "/masters/markets/edit",
    },
  ];
  useEffect(() => {
    getData(params?.id);
  }, [params]);

  const getData = async (value) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getFareCodeData({ id: value });

    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.count === 1) {
      setFareCode(data.data[0]);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <Layout type={"update_per"} module_data={"Markets"}>
      <CustomHeaderTitle title={"Edit Fare Code"} />
      <HeaderBread
        navigation={navigation}
        title={"Edit Fare Code"}
      ></HeaderBread>
      {farecode && <FareCodeInputs codes={farecode} />}
    </Layout>
  );
};

export default EditFareCode;
