import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Layout from "../../../Layout";
import HeaderBread from "../../../components/HeaderBread";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import { getNewsLetter } from "../../../utils/ApiCalls/NewsLetter";
import NewsLetterInputs from "../../../components/NewsLetter/NewsLetterInput";

const EditCabinTypes = () => {
  const params = useParams();
  const [newsletter, setNewsLetter] = useState({});
  const [{ useDetails }, dispatch] = useDataLayerValue();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Newsletter",
      route: "/cms/news-letter",
    },
    {
      title: "Edit Newsletter",
      route: "/cms/news-letter/edit",
    },
  ];
  useEffect(() => {
    getData(params?.id);
  }, [params]);

  const getData = async (value) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getNewsLetter({ id: value });

    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.count === 1) {
      setNewsLetter(data.data[0]);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <Layout type={"update_per"} module_data={"Newsletter"}>
       <CustomHeaderTitle title={"Edit Experience"}/>
      <HeaderBread
        navigation={navigation}
        title={"Edit Newsletter"}
      ></HeaderBread>
      {newsletter && <NewsLetterInputs newsletter={newsletter} />}
    </Layout>
  );
};

export default EditCabinTypes;
