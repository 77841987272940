import React from "react";
import { ThePalaceData } from "../../../../components/template/CustomTemplate/Pages/ThePalace";
import CustomTemplates from "../../Deals/CustomTemplate";
import { BalconyClassPage } from "../../../../components/template/CustomTemplate/Pages/BalconyClass";

const BalconyClassMain = () => {
  return <CustomTemplates slug={"balcony-class"} attributes={BalconyClassPage} />;
};

export default BalconyClassMain;
