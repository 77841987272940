import { Button, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../Layout";
import HeaderBread from "../../components/HeaderBread";
import CustomizedTables from "../../components/Tables/BasicTable";
import {
  deleteOtherPagesData,
  getOtherPagesData,
  updateOtherPagesData,
} from "../../utils/ApiCalls/OtherPage";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import SearchField from "../../components/SearchField";
import CustomHeaderTitle from "../../components/constants/CustomHeaderTitle";
import CustomizedTable from "../../components/UI/CustomizedTable";
import SwitchBar from "../../components/Pages/SwitchBar";
import Logs from "../../components/Logs";
import { addlog } from "../../utils/ApiCalls/Logs";
import ActiveInactive from "../../components/UI/ActiveInactive";

const OtherPage = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Other Pages",
      route: "/cms/other-pages",
    },
  ];
  const [active, setActive] = useState("listing");
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState("");
  const [{ userDetails }, dispatch] = useDataLayerValue();
  
  const sortHandler = async (options) => {
    if (Object.keys(options).length > 0) {
      let sort, order;
      sort = options.columnKey;
      order =
        options.order === "ascend"
          ? "asc"
          : options.order === "descend"
          ? "desc"
          : undefined;
      await getData(1, sort, order);
    }
  };
  const columns = [
    {
      title: "Sl",

      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (text, record, index) => (page - 1) * 10 + index + 1,
    },

    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => "",
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",

      render: (item) => (
        <div
          style={{ height: "100px", overflow: "hidden", display:"flex", justifyContent:"left", alignItems:"center" }}
          dangerouslySetInnerHTML={{ __html: item }}
        />
      ),
    },

    {
      title: "URL",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      sorter: (a, b) => "",

      render: (item, record, index) => (
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            checked={item ? true : false}
            onChange={(value) => {
              handleUpdate(
                record.id,
                {
                  is_active: value.target.checked ? 1 : 0,
                },
                record.title
              );
            }}
            className="custom-control-input"
            id={"customSwitch1" + index}
          />
          <label
            className="custom-control-label"
            style={{}}
            for={"customSwitch1" + index}
          ></label>
        </div>
      ),
    },

    {
      title: "Action",
      key: "operation",

      render: (text, record) => (
        <div className="table_action_btn">
          <div className="btn" onClick={() => handleEdit(record.id)}>
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <div
            className="btn delete_btn"
            onClick={() => handleDelete(record.id, record.title)}
          >
            <i
              className="ri-delete-bin-line"
              data-toggle="tooltip"
              title="Delete"
            ></i>
          </div>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getData(page);
  }, [page, pageSize, search]);

  const getData = async (value, sort, order) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let args = { search: search, page: value, limit: pageSize };
    if(status!==""){
      args = { ...args, is_active: status };
    }
    if (order) {
      if (sort) {
        args = { ...args, sort };
      }
      args = { ...args, order };
    }

    let data = await getOtherPagesData(args);
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.data) {
      setRows(data.data);
      setCount(data.count);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const handleEdit = async (id) => {
    navigate("/cms/other-pages/edit/" + id);
  };
  const handleDelete = async (id, title) => {
    deleteOtherPagesData(id);
    addlog("Other Pages Deleted", "Other_Pages", { id, title },  userDetails.id);
    getData();
  };

  const handleUpdate = async (id, values, title) => {
    let data = await updateOtherPagesData(id, values);
    if (data.error) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    } else {
      addlog(
        "Other Pages "+ !values.valid_from? (values.is_active === 1 ? "Actived" : "Deactivated"):"updated",
        "Other_Pages",
        { ...values, id, title },
        userDetails.id
      );
    }
    getData(page);
  };

  return (
    <Layout>
      <CustomHeaderTitle title={"Other Pages"} />
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"Other Pages"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <SwitchBar
              tabs={[
                { title: "Other Pages List", value: "listing" },
                { value: "logs", title: " History Logs" },
              ]}
              activeTab={active}
              setActiveTab={setActive}
            />
            {active === "listing" && (
              <div className="card">
                <div className="card-header two_item">
                  <h5 className="card-title"> Other Pages List</h5>
                  <div className="two_item" style={{width:"350px"}}>
                    <ActiveInactive getData={getData} setStatus={setStatus} status={status} />
                  <Link to="/cms/other-pages/add" className="btn add_v_btn">
                    Add Pages
                  </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table_holder">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div style={{ maxWidth: 200 }}>
                        <SearchField
                          value={search}
                          SearchValue={() => {
                      
                            setPage(1);
                          }}
                          setValue={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {rows && (
                      <CustomizedTable
                        setRows={setRows}
                        setPageSize={setPageSize}
                        setPage={setPage}
                        pageSize={pageSize}
                        page={page}
                        count={count}
                        columns={columns}
                        rows={rows}
                        sortHandler={sortHandler}
                        title={"Other Pages"}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        {active === "logs" && (
          <Logs
            type={"Other_Pages"}
            slug={"/cms/other-pages/edit/"}
            refreshlog={active}
          />
        )}
      </div>
    </Layout>
  );
};

export default OtherPage;
