import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";

export const createBanner = async (data) => {
  try {
    let res = await Api.post("/banner", data);
    return res?.data;
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getBannerData = async (query) => {
  try {
    let res = await Api.get("/banner", {
      params: query,
      headers:{
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const getBannerDataLocation = async (query) => {
  try {
    let res = await Api.get("/banner/location", {
      params: query,
      headers:{
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const deleteBannerData = async (id) => {
  try {
    let res = await Api.delete("/banner/" + id);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updateBannerData = async (id, data) => {
  try {
    let res = await Api.patch("/banner/" + id, data);
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const updatePosition = async(banners, query)=>{
  try {
    let res = await Api.patch("/banner/relocate-position", banners, {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
}