import React, { useEffect, useState } from "react";
import HeaderBread from "../../../components/HeaderBread";
import Layout from "../../../Layout";
import { useParams } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import LeadsInput from "../../../components/Leads/LeadsInputs";

import { getLeadsData } from "../../../utils/ApiCalls/Leads";
import LeadsInputs2 from "../../../components/Leads/LeadsInputs2";

const EditLeads = () => {
  const params = useParams();
  const [leads, setLeads] = useState({});

  const [{ userDetails }, dispatch] = useDataLayerValue();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Failed Booking List",
      route: "/bookings/leads",
    },
    {
      title: "Failed Booking",
      route: "/bookings/leads/edit",
    },
  ];
  useEffect(() => {
    getData(params?.id);
  }, [params]);

  const getData = async (value) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getLeadsData({ id: value });

    console.log({ value });
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.count === 1) {
      setLeads(data.data[0]);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <Layout type={"read_per"} module_data={"Failed Bookings"}>
      <CustomHeaderTitle title={"View Failed Bookings"} />
      <HeaderBread
        navigation={navigation}
        title={"View Failed Booking"}
      ></HeaderBread>
      {leads &&leads?.leadDetails?.length>1?<LeadsInputs2 booking={leads} />: <LeadsInput booking={leads} />}
    </Layout>
  );
};

export default EditLeads;
