import { Button } from "@mui/material";
import React, { act, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Layout from "../../Layout";
import HeaderBread from "../../components/HeaderBread";
import Logs from "../../components/Logs";
import SwitchBar from "../../components/Pages/SwitchBar";
import SearchField from "../../components/SearchField";
import ActiveInactive from "../../components/UI/ActiveInactive";
import CustomizedTable from "../../components/UI/CustomizedTable";
import Itineary from "../../components/UI/FareCodeitineary";
import PromoIndex from "../../components/UI/PromoIndex";
import FareMarketCode from "../../components/UI/FareMarketCode";
import { addlog } from "../../utils/ApiCalls/Logs";
import {
  createCron,
  deletePackageData,
  getCron,
  getFare,
  getPackageData,
  getPackageDataAll,
  updatePackageStatus,
} from "../../utils/ApiCalls/Package/index";
import { getVessels } from "../../utils/ApiCalls/SpecialPage/index";
import LoadingButton from "@mui/lab/LoadingButton";
import { message } from "antd";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import MultiSelectData from "../../components/UI/MultiSelectData";
import Vessel from "../../components/UI/VesselFilter";
import DeletePop from "../../constants/ErrorPermissionModal";
import { checkPermission } from "../../utils/Validation/checkPermission";
import CircularProgressWithLabel from "../../components/UI/ProgressBar";
const MySwal = withReactContent(Swal);

const Packages = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Package List",
      route: "/cms/packages",
    },
  ];

  const [rows, setRows] = useState([]);
  const [fareRows, setFareRows] = useState([]);
  const [fareCount, setFareCount] = useState(0);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [fare, setFare] = useState(false);
  const [itineary_id, setItineary_Id] = useState([]);
  const [page, setPage] = useState(1);
  const [itineary_id_select, setItineary_Id_select] = useState([]);
  const [cruise_select, setCruiseSelect] = useState([]);
  const [active, setActive] = useState("listing");
  const [pageSize, setPageSize] = useState(10);
  const [searchCategory, setSearchCategory] = useState("");
  const [{ userDetails, permission }, dispatch] = useDataLayerValue();
  const [status, setStatus] = useState("");
  const [vessel, setVessel] = useState([]);
  const [vesselId, setVesselId] = useState("");
  const [iti, setIti] = useState([]);
  const [promoIndexs, setPromoIndexs] = useState([]);
  const [marketCodeList, setMarketCodeList] = useState([]);
  const [marketCode, setMarketCode] = useState("");
  const [itinearyId, setItinearyId] = useState("");
  const [promoIndex, setPromoIndex] = useState("");
  const [cruiseList, setCruiseList] = useState([]);
  const [cruiseId, setCruiseId] = useState([]);
  const [cronData, setCronData] = useState();

  const sortHandler = async (options) => {
    if (Object.keys(options).length > 0) {
      let sort, order;
      sort = options.columnKey;
      order =
        options.order === "ascend"
          ? "asc"
          : options.order === "descend"
            ? "desc"
            : undefined;

      if (active === "listing") await getData(1, sort, order);
      else if (active === "fares") await getFareData(1, sort, order);
    }
  };

  const startRefresh = async (updated_type, id) => {
    let values = {
      status: 0,
      created_by: userDetails?.id,
      message: id,
      updated_type: updated_type,
    };
    const res = await createCron(values);
    if (res?.success) {
      message.error("cron is already running");
    } else {
      message.success("cron is triggered");
    }
    await getData(page);
  };

  const pkgColumns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (text, record, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Itinerary Id ",
      dataIndex: "itinerary_id",
      key: "itinerary_id",
      width: 200,
      sorter: (a, b) => "",
      render: (itinerary_id, record) => (
        <div>
          {" "}
          <div className="row w-100">
            {Array.isArray(itinerary_id) ?
              itinerary_id.map((cruise) => (
                <div className="col-6 mb-1">
                  <span className="cruise_dates">{cruise}</span>
                </div>
              )) : <p>#{itinerary_id}</p>}
          </div>

          <p style={{ fontSize: "14px", marginTop: "10px", color: "grey" }}>
            {" "}
            Itinerary updated at{" "}
            {moment(record?.updated_at).tz("Asia/Kuala_Lumpur").format("DD/MM/YYYY hh:mm a")}
          </p>
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 200,
      sorter: (a, b) => "",
      render: (title) => <p>{title}</p>,
    },
    {
      title: "Cruise Id",
      dataIndex: "cruises",
      key: "cruises",
      width: 230,
      render: (cruises) => (
        <div className="row w-100">
          {cruises &&
            cruises.map((cruise) => (
              <div className="col-6 mb-1">
                <span className="cruise_dates">{cruise}</span>
              </div>
            ))}
        </div>
      ),
    },
    {
      title: "Departure Port",
      width: 80,
      dataIndex: "departure_port",
      key: "departure_port",
      sorter: (a, b) => "",
      render: (departure_port) => <p>{departure_port}</p>,
    },
    {
      title: "Vessels",
      dataIndex: "vessel_name",
      key: "vessel_name",
      sorter: (a, b) => "",
      width: 100,
      render: (vessel_name) => <p>{vessel_name}</p>,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: 100,
      sorter: (a, b) => "",
      render: (is_active, record, index) => (
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            checked={is_active ? true : false}
            onChange={(value) => {
              handleUpdate(
                record.itinerary_id,
                {
                  is_active: value.target.checked ? 1 : 0,
                },
                record.package_name
              );
            }}
            className="custom-control-input"
            id={"customSwitch1" + index}
          />
          <label
            className="custom-control-label"
            style={{}}
            for={"customSwitch1" + index}
          ></label>
        </div>
      ),
    },
    {
      title: "Update",
      key: "operation",

      width: 400,
      render: (text, record, index) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {cronData?.itineary?.includes(record?.itinerary_id) ? (
            <div>
              <LoadingButton
                size="small"
                loading={true}
                sx={{ textTransform: "none", width: "300px" }}
                loadingPosition="end"
                variant="contained"
              >
                <span>
                  Refresh in Progress By{" "}
                  {cronData?.fare?.filter(
                    (item) => item.message === record?.itinerary_id
                  )[0]?.first_name + " "}
                  <b style={{ color: "green" }}>{cronData?.fare?.filter((item) => item.message === record?.itinerary_id)[0]?.progress}{"/"}{record?.cruises.length}</b>
                </span>
              </LoadingButton>
              {/* <CircularProgressWithLabel value={cronData?.fare?.filter((item) => item.message === record?.itinerary_id)[0]?.progress}/> */}
            </div>
          ) : (
            <Button
              variant="contained"
              onClick={() => startRefresh("fare", record.itinerary_id)}
              sx={{
                marginRight: "10px",
                background: "rgb(215 24 42)",
                fontWeight: "bold",
              }}
            >
              Refresh Fare
            </Button>
          )}
          <p style={{ fontSize: "14px", marginTop: "10px", color: "grey" }}>
            {" "}
            <p style={{ fontSize: "14px", marginTop: "10px", color: "grey" }}>
              {" "}
              Fare updated at{" "}
              {moment(record?.first_updated_at).tz("Asia/Kuala_Lumpur").format("DD/MM/YYYY hh:mm a")}
            </p>
          </p>
        </div>
      ),
    },
    {
      title: "Action",
      key: "operation",

      width: 100,
      render: (text, record, index) => (
        <div className="table_action_btn">
          <div className="btn" onClick={() => handleEdit(record.itinerary_id)}>
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <DeletePop
            onConfirm={() =>
              handleDelete(record.itinerary_id, record.package_name)
            }
          >
            <div>
              {" "}
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>
        </div>
      ),
    },
  ];
  const pkgGroupColumns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (text, record, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Itinerary Id ",
      dataIndex: "itinerary_id",
      key: "itinerary_id",
      width: 200,
      sorter: (a, b) => "",
      render: (itinerary_id, record) => (
        <div>
          {" "}
          <div className="row w-100">
            {Array.isArray(itinerary_id) ?
              itinerary_id.map((cruise) => (
                <div className="col-6 mb-1">
                  <span className="cruise_dates">{cruise}</span>
                </div>
              )) : <p>#{itinerary_id}</p>}
          </div>

          <p style={{ fontSize: "14px", marginTop: "10px", color: "grey" }}>
            {" "}
            Itinerary updated at{" "}
            {moment(record?.updated_at).tz("Asia/Kuala_Lumpur").format("DD/MM/YYYY hh:mm a")}
          </p>
        </div>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 200,
      sorter: (a, b) => "",
      render: (title) => <p>{title}</p>,
    },
    {
      title: "Cruise Id",
      dataIndex: "cruises",
      key: "cruises",
      width: 230,
      render: (cruises) => (
        <div className="row w-100">
          {cruises &&
            cruises.map((cruise) => (
              <div className="col-6 mb-1">
                <span className="cruise_dates">{cruise}</span>
              </div>
            ))}
        </div>
      ),
    },
    {
      title: "Departure Port",
      width: 80,
      dataIndex: "departure_port",
      key: "departure_port",
      sorter: (a, b) => "",
      render: (departure_port) => <p>{departure_port}</p>,
    },
    {
      title: "Vessels",
      dataIndex: "vessel_name",
      key: "vessel_name",
      sorter: (a, b) => "",
      width: 100,
      render: (vessel_name) => <p>{vessel_name}</p>,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: 100,
      sorter: (a, b) => "",
      render: (is_active, record, index) => (
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            checked={is_active ? true : false}
            onChange={(value) => {
              handleUpdate(
                record.itinerary_id,
                {
                  is_active: value.target.checked ? 1 : 0,
                },
                record.package_name
              );
            }}
            className="custom-control-input"
            id={"customSwitch1" + index}
          />
          <label
            className="custom-control-label"
            style={{}}
            for={"customSwitch1" + index}
          ></label>
        </div>
      ),
    },
    {
      title: "Update",
      key: "operation",

      width: 400,
      render: (text, record, index) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {cronData?.itineary?.includes(record?.itinerary_id) ? (
            <div>
              <LoadingButton
                size="small"
                loading={true}
                sx={{ textTransform: "none", width: "300px" }}
                loadingPosition="end"
                variant="contained"
              >
                <span>
                  Refresh in Progress By{" "}
                  {cronData?.fare?.filter(
                    (item) => item.message === record?.itinerary_id
                  )[0]?.first_name + " "}
                  <b style={{ color: "green" }}>{cronData?.fare?.filter((item) => item.message === record?.itinerary_id)[0]?.progress}{"/"}{record?.cruises.length}</b>
                </span>
              </LoadingButton>
              {/* <CircularProgressWithLabel value={cronData?.fare?.filter((item) => item.message === record?.itinerary_id)[0]?.progress}/> */}
            </div>
          ) : (
            <Button
              variant="contained"
              onClick={() => startRefresh("fare", record.itinerary_id)}
              sx={{
                marginRight: "10px",
                background: "rgb(215 24 42)",
                fontWeight: "bold",
              }}
            >
              Refresh Fare
            </Button>
          )}
          <p style={{ fontSize: "14px", marginTop: "10px", color: "grey" }}>
            {" "}
            <p style={{ fontSize: "14px", marginTop: "10px", color: "grey" }}>
              {" "}
              Fare updated at{" "}
              {moment(record?.first_updated_at).tz("Asia/Kuala_Lumpur").format("DD/MM/YYYY hh:mm a")}
            </p>
          </p>
        </div>
      ),
    },
    {
      title: "Action",
      key: "operation",

      width: 100,
      render: (text, record, index) => (
        <div className="table_action_btn">
          <div className="btn" onClick={() => handleEdit2(record.itinerary_key)}>
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <DeletePop
            onConfirm={() =>
              handleDelete(record.itinerary_id, record.package_name)
            }
          >
            <div>
              {" "}
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>
        </div>
      ),
    },
  ];

  const fareColumns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (text, record, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Itinerary Id ",
      dataIndex: "itinerary_id",
      key: "itinerary_id",
      width: 80,
      sorter: (a, b) => "",
      render: (itinerary_id) => <p>#{itinerary_id}</p>,
    },
    {
      title: "Cruise Id ",
      dataIndex: "package_id",
      key: "package_id",
      width: 80,
      sorter: (a, b) => "",
      render: (package_id) => <p>{package_id}</p>,
    },
    {
      title: "Cabin Type",
      dataIndex: "display_name",
      key: "display_name",
      width: 80,
      // sorter: (a, b) => "",
      render: (display_name) => <p>{display_name}</p>,
    },
    {
      title: "Market Code",
      dataIndex: "market_code",
      key: "market_code",
      width: 80,
      // sorter: (a, b) => "",
      render: (market_code) => <p>{market_code}</p>,
    },
    {
      title: "Currency",
      dataIndex: "currency_code",
      key: "currency_code",
      width: 80,
      // sorter: (a, b) => "",
      render: (currency_code) => <p>{currency_code}</p>,
    },
    {
      title: "Fare Code",
      dataIndex: "fare_code",
      key: "fare_code",
      width: 80,
      sorter: (a, b) => "",
      render: (fare_code) => <p>{fare_code}</p>,
    },
    {
      title: "Fare Code Description",
      dataIndex: "fare_code_name",
      key: "fare_code_name",
      width: 80,
      sorter: (a, b) => "",
      render: (fare_code_name) => <p>{fare_code_name}</p>,
    },
    {
      title: "Fare",
      dataIndex: "price",
      key: "price",
      width: 80,
      sorter: (a, b) => "",
      render: (price) => <p>{price}</p>,
    },
    {
      title: "Promo Index",
      dataIndex: "promo_index",
      key: "promo_index",
      width: 80,
      sorter: (a, b) => "",
      render: (promo_index) => <p>{promo_index}</p>,
    },
    {
      title: "Promo Title",
      dataIndex: "promo_title",
      key: "promo_title",
      width: 80,
      sorter: (a, b) => "",
      render: (promo_title) => <p>{promo_title}</p>,
    },
    {
      title: "Promo Description",
      dataIndex: "promo_description",
      key: "promo_description",
      width: 80,
      sorter: (a, b) => "",
      render: (promo_description) => <p>{promo_description}</p>,
    },
  ];

  useEffect(() => {
    console.log(active)
    active === "listing"
      ? getData(page) : active == "group-lisiting" ? getDataGrouped(page)
        : active === "fares" && getFareData(page);
  }, [page, pageSize, active, search, itineary_id, cruiseId]);

  useEffect(() => {
    if (active === "listing" || active == "group-listing") {
      getVesselData();
    }
  }, [active]);

  useEffect(() => {
    setSearch("");
    setStatus("");
    setVesselId("");
    setPage(1);
    setCount(0);
    setFareCount(0);
    setRows([]);
    setFareRows([]);
    setItinearyId("");
    setPromoIndex("");
    setMarketCode("");
    setVessel([]);
  }, [active]);

  const getVesselData = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });

    let vessels = await getVessels();

    if (vessels.error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: vessels.error,
      });
    } else {
      vessels.push({ id: null, name: "All" });
      setVessel(vessels);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  const handleUpdate = async (id, values, title) => {
    if (checkPermission(permission, "update_per", "Packages")) {
      let data = await updatePackageStatus(id, values);
      if (data.error) {
        // dispatch({
        //   type: "SET_ERROR_MESSAGE",
        //   errMess: { message: data.error, type: "error" },
        // });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.error,
        });
      } else {
        addlog(
          "Package  " + !values.valid_from
            ? values.is_active === 1
              ? "Actived"
              : "Deactivated"
            : "updated",
          "Package",
          { ...values, id, title },
          userDetails.id
        );
      }
      await getData(page);
    } else {
      message.error("Update permission missing");
    }
  };

  const handleEdit = async (id) => {
    if (checkPermission(permission, "update_per", "Packages")) {
      navigate("/cms/packages/" + id);
    } else {
      message.error("Update permission missing");
    }
  };

  const handleEdit2 = async (id) => {
    if (checkPermission(permission, "update_per", "Packages")) {
      navigate("/cms/grouppackages/" + id);
    } else {
      message.error("Update permission missing");
    }
  };

  const handleDelete = async (id, title) => {
    if (checkPermission(permission, "delete_per", "Packages")) {
      await deletePackageData(id);
      addlog("Package deleted", "Package", { id, title }, userDetails.id);
      await getData(page);
    } else {
      message.error("Missing Permission for delete");
    }
  };

  const getData = async (value, sort, order) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });

    let args = {
      search: search,
      page: value,
      limit: pageSize,
      itinearyIds: itineary_id,
      cruiseIds: cruiseId,
    };

    if (searchCategory !== active) {
      args.search = "";
    }
    if (status !== "") {
      args = { ...args, is_active: status };
    }
    if (vesselId) {
      args = { ...args, vessel_id: vesselId };
    }
    if (order) {
      if (sort) {
        args = { ...args, sort };
      }
      args = { ...args, order };
    }


    let data = await getPackageData(args);
    if (data.error) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: data.error, type: "error" },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.error,
      });
    } else if (data.data) {
      setRows(data.data);
      setCount(data.count);
      setItineary_Id_select(data?.itinerary_id);
      setCruiseSelect(data?.cruises);
    }
    const cron = await getCron();

    setCronData({
      ...cron,
      cron: cron?.itinery,
      itineary: cron?.fare.map((item) => item?.message),
      fare: cron?.fare,
    });

    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const getDataGrouped = async (value, sort, order) => {
    console.log("hii")
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });

    let args = {
      search: search,
      page: value,
      limit: pageSize,
      itinearyIds: itineary_id,
      cruiseIds: cruiseId,
    };

    if (searchCategory !== active) {
      args.search = "";
    }
    if (status !== "") {
      args = { ...args, is_active: status };
    }
    if (vesselId) {
      args = { ...args, vessel_id: vesselId };
    }
    if (order) {
      if (sort) {
        args = { ...args, sort };
      }
      args = { ...args, order };
    }

    let data = await getPackageDataAll(args);
    if (data.error) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: data.error, type: "error" },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.error,
      });
    } else if (data.data) {
      setRows(data.data);
      setCount(data.count);
      setItineary_Id_select(data?.itinerary_id);
      setCruiseSelect(data?.cruises);
    }
    const cron = await getCron();

    setCronData({
      ...cron,
      cron: cron?.itinery,
      itineary: cron?.fare.map((item) => item?.message),
      fare: cron?.fare,
    });

    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const onChangeItinery = () => {
    setCruiseId([]);
  };
  const getFareData = async (value, sort, order) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let args = { search: search, page: value, limit: pageSize };

    if (searchCategory !== active) {
      args.search = "";
    }
    if (itinearyId) {
      args = { ...args, itinearyId };
    }
    if (promoIndex) {
      args = { ...args, promoIndex };
    }
    if (marketCode) {
      args = { ...args, marketCode };
    }
    if (order) {
      if (sort) {
        args = { ...args, sort };
      }
      args = { ...args, order };
    }

    let data = await getFare(args);
    if (data.error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.error,
      });
    } else if (data.data) {

      if (data?.itinerary) {
        if (iti.length === 0) {
          data.itinerary.unshift({ itinerary_id: "All" });
          setIti(data.itinerary);
        }
      }
      if (data?.marketCode) {
        // if(marketCode.length === 0) {
        data.marketCode.unshift({ market_code: "All" });
        setMarketCodeList(data.marketCode);
        // }
      }
      if (data?.promoIndex) {
        // if(marketCode.length === 0) {
        data.promoIndex.unshift({ promo_index: "All" });
        setPromoIndexs(data.promoIndex);
        // }
      }
      setFareRows(data.data);
      setFareCount(data.count);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  useEffect(() => {
    var previous_itineary_refresh = 1;
    var previous_fare_refresh = 1;
    const interval = setInterval(async () => {
      const cron = await getCron();
      var itineary_refresh = cron?.itinery?.length;
      var fare_refresh = cron?.fare?.length;
      if (itineary_refresh == 0 && previous_itineary_refresh == 0) {
        await getData(page);
      }
      if (fare_refresh == 0 && previous_fare_refresh == 0) {
        await getData(page);
      }
      if (itineary_refresh > 0) {
        previous_itineary_refresh = 0;
      } else {
        previous_itineary_refresh = 1;
      }
      if (fare_refresh > 0) {
        previous_fare_refresh = 0;
      } else {
        previous_fare_refresh = 1;
      }
      setCronData({
        ...cron,
        cron: cron?.itinery,
        itineary: cron?.fare.map((item) => item?.message),
        fare: cron?.fare,
      });
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Layout type={"read_per"} module_data={"Packages"}>
      <div>
        <div>
          <HeaderBread navigation={navigation} title={"Package"}></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <SwitchBar
              tabs={[
                { title: "Package Information", value: "listing" },
                { title: "Grouped Package Information", value: "group-lisiting" },
                { title: "Fares", value: "fares" },
                { value: "logs", title: " History Logs" },
              ]}
              activeTab={active}
              setActiveTab={setActive}
            />

            {(active === "group-lisiting" || active === "fares" || active === "listing") && (
              <div className="card">
                <div className="card-header two_item">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h5 className="card-title">
                      {active === "listing"
                        ? "Package Information"
                        : active === "fares" && "Fares"}
                    </h5>
                    {/* {cronData && (
                      <p style={{ fontSize: "14px", color: "grey" }}>
                        Itineraries updated on{" "}
                        {moment(cronData?.completed?.[0]?.updated_at).tz("Asia/Kuala_Lumpur").format(
                          "DD/MM/YYYY hh:mm a"
                        )}
                      </p>
                    )} */}
                  </div>{" "}
                  {active === "listing" || active === "group-lisiting" && (
                    <div
                      style={{
                        maxWidth: "900px",
                        display: "flex",
                        justifyContent: "end",
                        background: "white"
                      }}
                    >
                      <MultiSelectData
                        getData={getData}
                        data={itineary_id_select}
                        onChange={onChangeItinery}
                        value={itineary_id}
                        label={"Select Itinerary Id"}
                        setValue={setItineary_Id}
                      />
                      <MultiSelectData
                        data={cruise_select}
                        value={cruiseId}
                        getData={getData}
                        label={"Select Cruise Id"}
                        setValue={setCruiseId}
                      />
                      <ActiveInactive
                        getData={getData}
                        setStatus={setStatus}
                        setPage={setPage}
                        status={status}
                      />
                      <Vessel
                        setVesselId={setVesselId}
                        vesselData={vessel}
                        vesselId={vesselId}
                        getData={getData}
                        setPage={setPage}
                        width={200}
                      />
                    </div>
                  )}
                  {active === "fares" && (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Itineary
                        width={"150px"}
                        data={iti}
                        getData={getFareData}
                        itinearyId={itinearyId}
                        setItinearyId={setItinearyId}
                        setPage={setPage}
                      />
                      <PromoIndex
                        width={"150px"}
                        data={promoIndexs}
                        getData={getFareData}
                        promoIndex={promoIndex}
                        setPromoIndex={setPromoIndex}
                        setPage={setPage}
                      />
                      <FareMarketCode
                        width={"200px"}
                        data={marketCodeList}
                        getData={getFareData}
                        marketCode={marketCode}
                        setMarketCode={setMarketCode}
                        setPage={setPage}
                      />
                    </div>
                  )}
                </div>
                <div className="card-body">
                  <div className="table_holder">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      {active === "listing" || active === "group-lisiting" && (
                        <div
                          style={{ marginRight: "auto", marginBottom: "10px" }}
                        >
                          {" "}
                          {cronData?.itinery?.length > 0 ? (
                            <span>
                              <LoadingButton
                                size="small"
                                loading={true}
                                loadingPosition="end"
                                variant="contained"
                                sx={{ width: "350px", textTransform: "none" }}
                              >
                                <span>
                                  Refresh Itineraries in Progress By{" "}
                                  {cronData.cron[0].first_name}
                                </span>
                              </LoadingButton>
                              <CircularProgressWithLabel value={cronData.itinery[0].progress} />
                            </span>
                          ) : (
                            <Button
                              variant="contained"
                              onClick={() => startRefresh("itinery")}
                              style={{
                                marginRight: "10px",
                                background: "rgb(215 24 42)",
                                fontWeight: "500",
                              }}
                            >
                              Refresh Itineraries
                            </Button>
                          )}
                          <Button
                            variant="contained"
                            style={{
                              marginRight: "10px",
                              background: "Black",
                              fontWeight: "500",
                            }}
                          >
                            Total Itineraries: {itineary_id_select.length}
                          </Button>
                          <Button
                            variant="contained"
                            style={{
                              marginRight: "10px",
                              background: "Black",
                              fontWeight: "500",
                            }}
                          >
                            Total Cruise IDs: {cruise_select.length}
                          </Button>
                        </div>
                      )}
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <SearchField
                          value={search}
                          SearchValue={() => {
                            setPage(1);
                          }}
                          setValue={(e) => {
                            setSearchCategory(active);
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {rows && (
                      <CustomizedTable
                        setRows={active === "listing" || active === "group-lisiting" ? setRows : setFareRows}
                        setPageSize={setPageSize}
                        setPage={setPage}
                        pageSize={pageSize}
                        page={page}
                        count={active === "listing" || active === "group-lisiting" ? count : fareCount}
                        columns={
                          active === "listing" ? pkgColumns : active === "group-lisiting" ? pkgGroupColumns :

                            active === "fares" && fareColumns
                        }
                        rows={active === "listing" || active === "group-lisiting" ? rows : fareRows}
                        title={"Deals"}
                        sortHandler={sortHandler}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        {active === "logs" && (
          <Logs
            type={"Package"}
            slug={"/cms/packages/edit/"}
            refreshlog={active}
          />
        )}
      </div>
    </Layout>
  );
};

export default Packages;
