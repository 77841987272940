import React, { useEffect, useState } from "react";
import BannerInputs from "../../../components/Banner/BannerInputs";
import HeaderBread from "../../../components/HeaderBread";
import Layout from "../../../Layout";
import { useParams } from "react-router-dom";
import {
  getBannerData,
  updateBannerData,
} from "../../../utils/ApiCalls/Banner";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import { getExperienceData } from "../../../utils/ApiCalls/Experience";
import ExperienceInputs from "../../../components/Experience/ExperienceInputs";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import { getDealDetailsData } from "../../../utils/ApiCalls/DealDetails";
import DealDetailsInputs from "../../../components/DealDetails/DealDetailsInputs";

const EditDealDetails = () => {
  const params = useParams();
  const [dealDetails, setDealDetails] = useState({});
  const [{ useDetails }, dispatch] = useDataLayerValue();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Deal Details",
      route: "/cms/homepage/deal-details",
    },
    {
      title: "Edit Deal Details",
      route: "/cms/homepage/deal-details/edit",
    },
  ];
  useEffect(() => {
    getData(params?.id);
  }, [params]);

  const getData = async (value) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getDealDetailsData({ id: value });

    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.count === 1) {
      setDealDetails(data.data[0]);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <Layout>
      <CustomHeaderTitle title={"Edit Deal Details"} />
      <HeaderBread
        navigation={navigation}
        title={"Edit Deal Details"}
      ></HeaderBread>
      {dealDetails && <DealDetailsInputs details={dealDetails} />}
    </Layout>
  );
};

export default EditDealDetails;
