import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { constants } from "../../../../constants/constants";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import UploadFileImage from "../../../imageUpload/uploadFileImage";
import DyanmicFrom from "../../../DyanmicForm/DyanmicFrom";
import { checkFormFields } from "../../../../utils/Validation/checkFormFields";
import { message } from "antd";
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ direction: "rtl" }], // this is rtl support
  ],
};

const FromData = ({
  data,
  rootData,
  index,
  updateData,
  add,
  attributes,
  active,
}) => {
  const [error, setError] = useState([]);

  const [cardData, setCardData] = useState(
    data || {
      image: null,
      title: "",
      simplified_chinese_title: "",
      traditional_chinese_title: "",
    }
  );
  useEffect(() => {
    if (cardData?.dataArray?.length < data?.dataArray?.length) {
      setCardData(item => ({ ...item, dataArray: data.dataArray }))
    }
  }, [data])
  const addData = () => {
    if (index) {
      let error = checkFormFields(
        cardData,
        attributes.filter((item) => item.required).map((item) => item.key)
      );

      if (error.length > 0) {
        setError(error);
        message.error("Some fields are missing");
      } else {
        setError([]);
        updateData(index - 1, { ...cardData }, "new");
      }
    } else {
      updateData(0, { ...cardData, title: "Card Title" });
    }
  };

  return (
    <div
      style={{ borderRadius: "5px", overflow: "hidden" }}
      className="card-body"
    >
      <div style={{ width: "100%" }}>
        <div style={{ width: "100%" }}>
          {!add && (
            <div className="row" style={{ width: "100%" }}>
              <DyanmicFrom
                error={error}
                active={active}
                rootData={rootData}
                attributes={attributes || []}
                data={cardData}
                setData={(e) => {
                  setCardData(e);
                }}
              />
            </div>
          )}
          {!add ? (
            <div
              className="col-lg-12"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                onClick={addData}
                style={{
                  marginLeft: "auto",
                  marginTop: "10px",
                  marginRight: "10px",
                }}
                className={"btn main_submit2"}
              >
                {add && <AddCircleOutlineIcon />} {index ? "Save" : "Add"} Card
              </button>
            </div>
          ) : (
            <div
              className="col-lg-12"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                onClick={addData}
                style={{
                  marginLeft: "auto",
                  marginTop: "10px",
                  marginRight: "-20px",
                }}
                className={!add ? "btn main_submit2" : "btn main_submit3"}
              >
                {add && <AddCircleOutlineIcon />} {index ? "Save" : "Add"} Card
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FromData;
