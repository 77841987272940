import React, { useEffect } from "react";
import "./app.css";
import { getImage } from "../../../../../../utils/getImages";
import { getLang } from "../../lang/getlang";
const OnboardPromtion4 = ({ cardData, activetab }) => {
  useEffect(() => {
    console.log({ cardData });
  }, [cardData]);
  return (
    <div style={{ marginLeft: "10px" }}>
      <div
        className="col-12 text-center mb-5"
        style={{ display: "flex", alignItems: "center", width: "100%" }}
      >
        <ul
          className="page-tab scroll-tab"
          style={{
            display: "flex",
            gap: "10px",

            listStyleType: "none",
            padding: 0,
            alignItems: "center",
            justifyContent: "center",
            margin: 0,
            width: "100%",
          }}
        >
          {cardData?.dataArray?.map((item, index) => (
            <li data-scroll-tab-seq={index + 1 + ""} className="">
              {getLang(item?.ship, activetab)}
            </li>
          ))}
        </ul>
      </div>
      <div
        style={{
          display: "flex",
          // alignItems: "center",
          flexWrap: "wrap",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <div className="row" style={{ width: "100%" }}>
          {cardData?.dataArray?.map((item) => (
            <div style={{ width: "100%" }}>
          
              <div className="col-12 text-center scroll-content-1">
           
                <h2
                  className="mb-5 title-grey"
                  style={{ direction: activetab === "arabic" && "rtl" }}
                >
                  {activetab === "english"
                    ? item?.title
                    : activetab === "simplified_chinese"
                    ? item?.simplified_chinese_title
                    : activetab === "arabic"
                    ? item?.arabic_title
                    : item?.traditional_chinese_title}
                  <div
                    style={{
                      direction: activetab === "arabic" && "rtl",
                      fontSize: "15px",
                      fontWeight: "400",
                      marginTop: 10,
                    }}
                    dangerouslySetInnerHTML={{
                      __html:
                        activetab === "english"
                          ? item?.description
                          : activetab === "simplified_chinese"
                          ? item?.simplified_chinese_description
                          : activetab === "arabic"
                          ? item?.arabic_description
                          : item?.traditional_chinese_description,
                    }}
                  />
                </h2>
              </div>

              <div className="row" style={{ width: "100%" }}>
                {item?.dataArray?.map((item) => (
                  <div className="col-md-4">
                       <div  className="rounded gallery-item" style={{padding:"10px"}} data-src="https://webpages.rwcruises.com/assets/img/deals/onboard-promotion/GDR/fnb/popup/1.1.jpg" >
                    <div className="destination-card">
                        <p className="price"> {activetab === "english"
                            ? item?.sub_title
                            : activetab === "simplified_chinese"
                            ? item?.simplified_chinese_sub_title
                            : activetab === "arabic"
                            ? item?.arabic_sub_title
                            : item?.traditional_chinese_sub_title}</p>
                        <img src={getImage(item?.image)} className="w-100 d-block" />       </div>
                    <div className="info2">
                        <p className="sub"> {activetab === "english"
                            ? item?.type
                            : activetab === "simplified_chinese"
                            ? item?.simplified_chinese_type
                            : activetab === "arabic"
                            ? item?.arabic_type
                            : item?.traditional_chinese_type}</p>
                        <p><strong > {activetab === "english"
                            ? item?.title
                            : activetab === "simplified_chinese"
                            ? item?.simplified_chinese_title
                            : activetab === "arabic"
                            ? item?.arabic_title
                            : item?.traditional_chinese_title}</strong></p>
                       </div>
                  </div>
                   
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OnboardPromtion4;
