import React, { useEffect, useState } from "react";
import Layout from "../../../Layout";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import HeaderBread from "../../../components/HeaderBread";
import { Link, useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import CustomizedTable from "../../../components/UI/CustomizedTable";
import Logs from "../../../components/Logs";
import { deleteWebpage, getAllWebpage, updateWebpage } from "../../../utils/ApiCalls/WebPages";
import DeletePop from "../../../constants/ErrorPermissionModal";
import { checkPermission } from "../../../utils/Validation/checkPermission";
import { deleteMenuList, updateManyMenuList } from "../../../utils/ApiCalls/MenuList";
import { addlog } from "../../../utils/ApiCalls/Logs";
import { message } from "antd";
import Swal from "sweetalert2";
import ValidDateUI from "../../../constants/validDateUI";
import { constants } from "../../../constants/constants";

const DestinationPage = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState();
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [active, setActive] = useState("listing");
  const [{ userDetails, permission }, dispatch] = useDataLayerValue();

  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Destinations List",
      route: "/cms/webpages/our-ship",
    },
  ];

  const handleUpdate = async (id, values, title, location_id) => {
    if (checkPermission(permission, "update_per", "webpages")) {
      let data = await updateWebpage(id, values);
      if (values.slug) {
        let menu = await updateManyMenuList({ url: `destinations/${values.slug}`, is_active: values.is_active })
      }
      if (data.error) {

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.error,
        });
      } else {
        addlog(
          "Webpage " + !values.valid_from
            ? values.is_active === 1
              ? "Actived"
              : "Deactivated"
            : "updated",
          "Webpage",
          { ...values, id, title, location_id },
          userDetails.id
        );
      }
      await getData(page);
    } else {
      message.error("Update permission missing");
    }
  };
  const columns = [
    {
      title: "No",
      width: 2,
      dataIndex: "id",
      key: "position",
      fixed: "left",
      render: (position, record, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Title",
      width: 400,
      dataIndex: "page_name",
      key: "page_name",
      fixed: "left",
    },
    {
      title: "Valid date",
      dataIndex: "valid_date",
      key: "valid_till",

      render: (text, record, index) => {
        return <ValidDateUI record={record} handleUpdate={handleUpdate} />;
      },

    }, {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      sorter: (a, b) => "",

      render: (item, record, index) => {
        if(item){
          console.log({val:!(new Date(record.valid_to) <
            new Date(new Date())),slug:record})
            if((new Date(record.valid_to) <
            new Date(new Date())))
            {
              handleUpdate(
                record.id,
                {
                  is_active:0,
                  slug:record.slug
                },
                record.title
              );
            }
        }
        
        return (
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              checked={!(new Date(record.valid_to) <
                new Date(new Date()))&&item ? true : false}
              onChange={(value) => {
                try {
                  if (
                    new Date(record.valid_from) >
                    new Date(new Date())
                  ) {
                    throw new Error(`Webpage is not yet started`);
                  }

                  if (
                    new Date(record.valid_to) <
                    new Date(new Date())
                  ) {
                    throw new Error(`Webpage is expired`);
                  }
                  handleUpdate(
                    record.id,
                    {
                      is_active: value.target.checked ? 1 : 0,
                      slug:record.slug
                    },
                    record.title
                  );
                } catch (error) {
                  dispatch({
                    type: "SET_LOADING",
                    loading: false,
                  });

                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.message,
                  });
                }
              }}
              className="custom-control-input"
              id={"customSwitch1" + index}
            />
            <label
              className="custom-control-label"
              style={{}}
              for={"customSwitch1" + index}
            ></label>
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "operation",
      width: 30,
      render: (text, record) => (
        <div className="table_action_btn">
          <div
            onClick={() =>
              navigate("/webpages/destinations/edit/" + record.slug)
            }
            className="btn"
          >
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <DeletePop
            onConfirm={() =>
              handleDelete(record.id, record.title, record.location_id)
            }
          >
            <div>
              {" "}
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>
        </div>
      ),
    },
  ];
  const handleDelete = async (id, url) => {
    if (checkPermission(permission, "delete_per", "destinations")) {
      deleteWebpage(id);
      deleteMenuList({ url })
      addlog("Webpage Deleted", "Webpage", { id }, userDetails.id);
      getData(page, sort, order);
    } else {
      message.error("Missing Permission for delete ");
    }
  };
  const getData = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getAllWebpage({ page_type: "destinations" });
    console.log(data);
    setRows(data.data);
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Layout type={"read_per"} module_data={"Destinations"}>
      <CustomHeaderTitle title={"Destinations"} />
      <div>
        <div>
          <HeaderBread navigation={navigation} title={"Destinations"}></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            {active === "listing" && (
              <div className="card">
                <div className="card-header two_item">
                  <h5 className="card-title">Destinations List</h5>
                  <Link to="/webpages/destinations/add" className="btn add_v_btn">
                    Add Destinations
                  </Link>
                </div>

                <div className="card-body">
                  <div className="table_holder">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    ></div>
                    {rows && (
                      <CustomizedTable
                        setRows={setRows}
                        setPageSize={setPageSize}
                        setPage={setPage}
                        pageSize={pageSize}
                        page={page}
                        count={count}
                        columns={columns}
                        rows={rows}
                        title={"Cabin Category"}
                        sortHandler={() => { }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>{" "}
        {active === "logs" && (
          <Logs
            type={"Page"}
            slug={"/webpages/our-ship/edit/"}
            refreshlog={active}
          />
        )}
      </div>
    </Layout>
  );
};

export default DestinationPage;