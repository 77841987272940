import React from "react";
import DealInputs from "../../../components/Deal/DealInput";
import HeaderBread from "../../../components/HeaderBread";
import Layout from "../../../Layout";
import { useNavigate } from "react-router-dom";
import { createBanner } from "../../../utils/ApiCalls/Banner";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";

const CreateDeal = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Deals List",
      route: "/cms/homepage/deals",
    },
    {
      title: "Add Deal",
      route: "/cms/homepage/deals/add",
    },
  ];

  return (
    <Layout>
        <CustomHeaderTitle title={"Add Deal"}/>
      <HeaderBread navigation={navigation} title={"Add Deal"}></HeaderBread>
      <DealInputs deal={{}} />
    </Layout>
  );
};

export default CreateDeal;
