import React from "react";
import { useParams } from "react-router-dom";
import { ships } from "../../../components/template/CustomTemplate/Pages/Ships";
import CustomTemplates from "../Deals/CustomTemplate";
import { Mediaandresource } from "../../../components/template/CustomTemplate/Pages/MediaandResource";
import { PrepareForYourCruisePage } from "../../../components/template/CustomTemplate/Pages/PrepareForYourCruise";

const PrepareForCruise = () => {
  const params = useParams();

  return (
    <CustomTemplates slug={"prepare-for-your-cruise"} attributes={PrepareForYourCruisePage} />
  );
};

export default PrepareForCruise;
