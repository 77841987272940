import React, { useState } from 'react'
import CustomizedTable from '../UI/CustomizedTable';

import Modal from '@mui/material/Modal';
import { Box, Button } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 4,
};

const Ancillary = ({ record }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const columns = [
        {
            title: "Sl",
            width: 30,
            dataIndex: "id",
            key: "id",
            fixed: "left",
            render: (text, record, index) => index + 1,
        },
        {
            title: "Ancillary name",
            dataIndex: "ancillary_name",
            key: "ancillary_name",
            sorter: (a, b) => "",
            width: 50,
            render: (item, record) => <div>{record.ancillary_name}</div>,
        },
        {
            title: "Ancillary ID",
            dataIndex: "ancillary_id",
            key: "ancillary_id",
            sorter: (a, b) => "",
            width: 50,
            render: (item, record) => <div>{record.ancillary_id}</div>,
        },
        {
            title: "Ancillary Price",
            dataIndex: "price",
            key: "price",
            sorter: (a, b) => "",
            width: 50,
            render: (item, record) => <div>{record.price}</div>,
        },
        {
            title: "Ancillary currency",
            dataIndex: "currency",
            key: "currency",
            sorter: (a, b) => "",
            width: 50,
            render: (item, record) => <div>{record.currency}</div>,
        },];
    return (
        <div>

            <Button onClick={handleOpen} sx={{ color: "blue" }}>{record?.ancillary?.length}</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h4>Ancillary Details</h4>
                    <CustomizedTable
                        setPageSize={() => { }}
                        page={1}
                        count={0}
                        columns={columns}
                        rows={record?.ancillary || []}
                        sortHandler={() => { }}
                    /></Box>


            </Modal>

        </div>
    )
}

export default Ancillary