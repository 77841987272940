import { FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import DyanmicFrom from "../../../DyanmicForm/DyanmicFrom";
import CustomSubmit from "./CustomSubmit";

const CardForm = ({
  cardData,
  setCardData,
  preview,
  oldData,
  attributes,
  error,
  page_id,
  children,
  updateComponentData
}) => {

  const [active, setActive] = useState(false);

  const handleChange = (event) => {
    setActive(event.target.checked);
    console.log("Switch changed:", event.target.checked);
  };

  useEffect(() => {
    setActive(preview);
  }, [preview]);

  useEffect(() => {
    if (oldData) setCardData(oldData);
  }, [oldData]);
  return (
    <div className="card">
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        {" "}
        <FormControlLabel
          control={<Switch checked={active} onChange={handleChange} />}
          label="Preview"
        />
      </div>
      {active && children}
      {!active && (
        <div className="card-body">
          <div className="row">
            <DyanmicFrom
              level={1}
              error={error}
              attributes={attributes || []}
              data={cardData}
              setData={(e) => {
                setCardData(e);
              }}
            />
          </div>
          {page_id && <CustomSubmit data1={cardData} data2={oldData} handleSubmit={() => updateComponentData(cardData)} />
          }

        </div>
      )
      }
    </div >
  );
};

export default CardForm;
