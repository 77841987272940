import Api from "../../Api";

export const createNewsLetter = async (data) => {

  try {
    let res = await Api.post("/newsletter", data);
    return res?.data;
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const getNewsLetter = async (query) => {
  try {
    let res = await Api.get("/newsletter", {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const exportNewsletter = async (query) => {
  try {
    let res = await Api.get("/newsletter/export", {
      responseType:"arraybuffer"
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const deleteNewsLetter = async (id) => {
  try {
    let res = await Api.delete("/newsletter/" + id);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updateNewsLetter = async (id, data) => {
  try {
    let res = await Api.patch("/newsletter/" + id, data);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to Update" };
  }
};
