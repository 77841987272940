import { FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataCard from "../DataCard";
import FormCard1 from "./FormCard1";
import Level2SubCard from "./SubCard";
import { message } from "antd";
import DyanmicFrom from "../../../DyanmicForm/DyanmicFrom";
import { gettitle } from "../../../../utils/getImages";
import CustomSubmit from "../Custom2StageForm/CustomSubmit";

const Level3Card = ({ error, fields,page_id,updateComponentData ,preview, dataCard, setDataCard, children, oldData, level1, level2, hidepreview }) => {
  const [active, setActive] = useState(false);

  const [activeSection, setActiveSection] = useState()

  const [cardData, setCardData] = useState({
    image: null,
    title: "",
    simplified_chinese_title: "",
    traditional_chinese_title: "",
  });
  useEffect(() => {
    if (oldData) setDataCard(oldData);
  }, [oldData]);
  useEffect(() => {
    setActive(preview);
  }, [preview]);

  const addData = (index, cardData, type) => {
    console.log("hi")
    let array = [];
    if (type) {
      array = [...dataCard.dataArray];
      array[index] = cardData;
    } else if (dataCard.dataArray) array = [...dataCard.dataArray, cardData];
    else array = [cardData];
    setDataCard({ ...dataCard, dataArray: array });
    if (type) {
      message.success("data updated click on publish to publish changes");
    } else {
      message.success("Created");
    }
  };




  const handleChange = (event) => {
    setActive(event.target.checked);
  };
  return (
    <div className={!hidepreview&&"card"}>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        {" "}
        {!hidepreview && <FormControlLabel
          control={<Switch checked={active} onChange={handleChange} />}
          label="Preview"
        />}
      </div>
      {active && children}

      {!active && (
        <div className="card-body">
          <div className="row">
            <DyanmicFrom
              error={error}
              attributes={fields || []}
              data={dataCard}
              level={1}
              setData={(e) => {
                setDataCard(e);
              }}
            />
          </div>
          {dataCard?.dataArray?.map((item, index) => (
            <DataCard
              title={gettitle(item)}
              index={index}
              activeDrop={activeSection}
              setActiveDrop={setActiveSection}
              array={dataCard?.dataArray}
              setArray={(e) => {
                setDataCard({ ...dataCard, dataArray: e })
              }}
            >
              <FormCard1 active={activeSection === index} attributes={level1} data={item} updateData={addData} index={index + 1} />

              <Level2SubCard
                item={item}
                index={index}
                attributes={level2}
                key={index}


                dataCard={dataCard?.dataArray?.[index] || { dataArray: [] }}
                setDataCard={(item) => {
                  console.log({ item, data: dataCard?.dataArray })
                  let arr = dataCard?.dataArray || [];
                  arr[index] = item;
                  setDataCard({
                    ...dataCard,
                    dataArray: arr
                  });
                }}

              />
            </DataCard>
          ))}
          <FormCard1 add updateData={addData} />
          {page_id&&  <CustomSubmit data1={dataCard} data2={oldData} handleSubmit={() => updateComponentData(dataCard)} />
        }
        </div>
      )}
    </div>
  );
};

export default Level3Card;
