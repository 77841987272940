import React from "react";
import { Button, message, Popconfirm } from "antd";

const DeletePop = ({ children, onConfirm }) => {
  const confirm = (e) => {

   
    onConfirm();
  };
  const cancel = (e) => {
    console.log(e);
  };
  return (
    <Popconfirm
      title="Delete the task"
      description="Are you sure to delete this?"
      onConfirm={confirm}
      onCancel={cancel}
      okText="Yes"
      cancelText="No"
    >
      {children}
    </Popconfirm>
  );
};
export default DeletePop;
