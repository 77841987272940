import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";


export const uploadData = async (file, imageType) => {
    try {
      console.log("api=>", file)
      const formData = new FormData();
      formData.append('file', file)
      // formData.append("file", file);
      formData.append('imageType', imageType?.type);
      formData.append('imageSubType', imageType?.subType)
      console.log("formData=>", formData);
      let res = await Api.post("/upload", formData ,{
        headers: {
            'Content-Type': 'multipart/form-data',
          },
      });
    
      return res?.data || [];
    } catch (e) {
      let error = modifyError(e);
      return { error: error.message };
    }
};