function validateField(values, fields){
  const errors = [];
  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      // if (!values[key]&&key!=="is_active") {
      //   errors.push(key);
      // }
      if (!values[key]&&fields.includes(key)) {
        errors.push(key);
      }
    }
  }
  return errors;
}

export const checkFields =(values, module, mandatoryFields) => {
  let errors;
  let requiredFields = [
    "image",
    // "itineary_image",
    // "thumbnail_image",
    "main_image",
    // "itineary_traditional_chinese_image",
    // "itineary_simplified_chinese_image",
    // "thumbnail_traditional_chinese_image",
    // "thumbnail_simplified_chinese_image",
    // "main_traditional_chinese_image",
    // "main_simplified_chinese_image",
    "location_id"
  ];
  let newValue;


  switch (module) {
    case "deals":
      newValue = {...values};
      newValue["english_image"] = values["image"];
      delete newValue["image"];
      errors = validateField(newValue, mandatoryFields);    
      break;
    case "banners":
      newValue = {...values};
      let key='';
      if(newValue.content_type==="image") key='image'
      if(newValue.content_type==="video") key='video';
      if(newValue.content_type==="link") key='link'; 
      newValue[`english_${key}`] = values["image"];
      newValue[`traditional_chinese_${key}`] = values['traditional_chinese_image'];
      newValue[`simplified_chinese_${key}`] = values['simplified_chinese_image'];
      delete newValue["image"];
      errors = validateField(newValue, mandatoryFields);
      break;
  
    default:
      errors = validateField(values, requiredFields);
      break;
  }

  if (errors.length > 0) {
    return `Error: The following fields are empty - ${errors.map(item=>item.replaceAll("_"," ")).join(", ")}`;
  } else {
    return false;
  }
};
