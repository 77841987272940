import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";

export const createOtherPages = async (data) => {
  try {
    let res = await Api.post("/other-pages", data);
    return res?.data;
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getOtherPagesData = async (query) => {
  try {
    let res = await Api.get("/other-pages", {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const deleteOtherPagesData = async (id) => {
  try {
    let res = await Api.delete("/other-pages/" + id);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updateOtherPagesData = async (id, data) => {
  try {
    let res = await Api.patch("/other-pages/" + id, data);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to Update" };
  }
};
