import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import {
  createNewsLetter,
  updateNewsLetter,
} from "../../utils/ApiCalls/NewsLetter";
import { checkFields } from "../../utils/Validation/checkFields";
import AddComponent from "./AddComponent";
import { CustomComponents } from '../template/CustomTemplate/Pages/templates/CustomComponents';

const TemplatesInputInputs = ({ newsletter }) => {
  const [title, setTitle] = useState(newsletter?.title || "");
  const [displayLocation, setDisplayLocation] = useState([]);
  const [, dispatch] = useDataLayerValue();
  const [error, setError] = useState();
  const [open, setOpen] = useState(false)
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (newsletter) {
      setTitle(newsletter?.title);
      setDisplayLocation(newsletter.location_name);
    }
  }, [newsletter]);

  const addNewsLetter = async (e) => {
    e.preventDefault();
    let added = false;
    let dataValues = {
      title,
    };

    let valid = checkFields(dataValues);
    if (valid) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: valid, type: "error" },
      });
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
      setError(valid);
    } else {
      setError();

      const values = {
        location_name: displayLocation,
        ...dataValues,
      };

      let data;
      if (newsletter?.id) {
        data = await updateNewsLetter(newsletter.id, values);
      } else {
        data = await createNewsLetter(values);
      }
      if (data?.error) {
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: data.error, type: "error" },
        });
        added = false;
      } else {
        added = true;
      }

      console.log({ added, newsletter });
      if (added && !newsletter.id) {
        navigate("/cms/news-letter");
      } else {
        navigate("/cms/news-letter");
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: "Values updated", type: "success" },
        });
      }
    }
  };
  const openData = () => {
    setOpen(true)
  }
  const saveComponent = (id) => {
    setData(item => [...item, id])
    setOpen(false)
  }
  return (
    <div>
      <AddComponent open={open} setOpen={setOpen} saveComponent={saveComponent}></AddComponent>
      <section className="content">
        {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )}
        {data.map((data, index) => {
          return <div key={index}>     <div className="card">
          <div
            
          >{CustomComponents.component.find(item => item.id === data).component?.preview("english", CustomComponents.component.find(item => item.id === data).component?.sampleData)}</div></div> </div>
        })}
        <div className="container-fluid">
          <div className="input_form">
            <div className="card-footer text-left">
              <button className="btn main_submit" onClick={openData}>
                Add Componets
              </button>
            </div>
          </div>

          <div className="pt-3"></div>
        </div>
      </section>
    </div>
  );
};

export default TemplatesInputInputs;
