import React from "react";
import CustomTemplates from "../../Deals/CustomTemplate";
import { SafetyGuidsPage } from "../../../../components/template/CustomTemplate/Pages/SafetyGuids";

const SafetyGuidsPagesMain = () => {
  return (
    <CustomTemplates slug={"safety-guides"} attributes={SafetyGuidsPage} />
  );
};

export default SafetyGuidsPagesMain;
