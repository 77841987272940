import { Helmet } from "react-helmet-async";
import { constants } from "../../../constants/constants";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
const CustomHeaderTitle = ({ title }) => {
  
  let data = `default-src ${constants.MEDIAURL} ${constants.BASE_URL} https://cms.rwcruisesuat.com/ https://rwcruises.com/ https://cms.b2cdev.rwcruisesuat.com/ http://localhost:3000 https://www.google.com https://cdn.jsdelivr.net https://cdnjs.cloudflare.com 'unsafe-inline'; 
script-src *; 
style-src 'self' 'unsafe-inline' https://example.com https://code.ionicframework.com https://fonts.googleapis.com https://cdn.jsdelivr.net; 
img-src 'self' blob: data: https:; 
frame-src 'self'  ${constants.MEDIAURL} blob:; 
connect-src 'self' ${constants.MEDIAURL} ${constants.BASE_URL} 'unsafe-inline' https://cdn.jsdelivr.net https://cdnjs.cloudflare.com;`;

let location = useLocation();
  useEffect(()=>{
    console.log("hii")
  },[location])
 useEffect(()=>{},[])
  return (
    <Helmet>
      <title>Resort World Cruises | {title||""}</title>


      <meta http-equiv="Content-Security-Policy" content={data}/>

    </Helmet>
  );
};

export default CustomHeaderTitle;
