import { Button, FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import DyanmicFrom from "../../../DyanmicForm/DyanmicFrom";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import CustomSubmit from "./CustomSubmit";
const CustomComponent = ({
  cardData,
  setCardData,
  preview,
  oldData,
  index,
  addField,
  attributes,
  item,
  show,
  page_id,
  updateComponentData,
  error,
  children,
  handleDelete,
  handleUp,
  handleDown
}) => {
  const [active, setActive] = useState(false);

  const handleChange = (event) => {
    setActive(event.target.checked);
    console.log("Switch changed:", event.target.checked);
  };

  useEffect(() => {
    setActive(preview);
  }, [preview]);
  useEffect(() => {
    if (oldData) setCardData(oldData);
  }, [oldData]);
  return (
    <div className="card">
      <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
        <div style={{
          display: "flex",
          alignItems: "center",

          marginRight: "10px"
        }}>
          <div style={{ cursor: "pointer", color: "#9f9f9f", marginLeft: "20px" }} onClick={() => handleUp(index)}>
            <ArrowCircleUpIcon style={{ color: "red", fontSize: 28 }} />

          </div>
          <div style={{ cursor: "pointer", color: "#9f9f9f", marginRight: "20px" }} onClick={() => handleDown(index)}>
            <ArrowCircleDownIcon style={{ color: "green", fontSize: 28 }} />

          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "10px"
          }}
        >     <div style={{ cursor: "pointer", color: "#9f9f9f", marginRight: "20px" }} onClick={() => handleDelete(index)}>
            <DeleteIcon style={{ color: "#9f9f9f", fontSize: 28 }} />

          </div>
          <FormControlLabel
            control={<Switch checked={active} onChange={handleChange} />}
            label="Preview"
          /></div>



      </div>
      {active && children}
      {!active && (
        <div className="card-body">
          <div className="row">
            <DyanmicFrom
              level={1}
              error={error}
              attributes={attributes || []}
              data={cardData}
              setData={(e) => {
                setCardData(e);
              }}
            />
            {show && <button
              onClick={() => addField(item)}
              className="btn main_submit"
            >
              Add More
            </button>}
            <div style={{ width: "100%" }}>
              {page_id && <CustomSubmit data1={cardData} data2={oldData} handleSubmit={() => updateComponentData(cardData)} />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomComponent;
