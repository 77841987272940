import React from "react";
import HeaderBread from "../../components/HeaderBread";
import Layout from "../../Layout";
import { useNavigate } from "react-router-dom";
import CustomHeaderTitle from "../../components/constants/CustomHeaderTitle";
import OtherPagesInputs from "../../components/Pages/OtherPagesInputs";
import SpecialPagesInputs from "../../components/Pages/SpecialPagesInputs";

const CreateSpecialPages = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Special Pages",
      route: "/cms/special-pages",
    },
    {
      title: "Add Special Pages",
      route: "/cms/special-pages/add",
    },
  ];

  return (
    <Layout type={"create_per"} module_data={"Special Pages"}>
      <CustomHeaderTitle title={"Add Special Pages"} />
      <HeaderBread
        navigation={navigation}
        title={"Add Special Pages"}
      ></HeaderBread>
      <SpecialPagesInputs banner={{}} />
    </Layout>
  );
};

export default CreateSpecialPages;
