import React from "react";
import "./app.css";
import { getImage } from "../../../../../../utils/getImages";
const ShipBannerPreview = ({ cardData, activetab }) => {
  return (
    <div>
      <div className="card-body"></div>
      <div className="generic-banner">
        <div className="info">
          <img src={getImage(cardData?.image)} alt="Our Ship" className="w-100" />
          <div>
            <h3
              data-lang='{"CN":"您的郵輪⾸選"}'
              style={{ direction: activetab === "arabic" && "rtl" }}
            >
              {activetab === "english"
                ? cardData?.title
                : activetab === "simplified_chinese"
                ? cardData?.simplified_chinese_title
                : activetab === "arabic"
                ? cardData?.arabic_title
                : cardData?.traditional_chinese_title}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipBannerPreview;
