import React from "react";
import CustomTemplates from "../../Deals/CustomTemplate";
import { OurStoryPagesData } from "../../../../components/template/CustomTemplate/Pages/OurStory";

const OurStoryPage = () => {
 
  return (
    <CustomTemplates slug={"our-story"} attributes={OurStoryPagesData} />
  );
};

export default OurStoryPage;
