import React, { useEffect, useState } from "react";
import { Box, Chip, MenuItem, Select } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { getlanguages } from "../../utils/ApiCalls/Language/index";
import { getCountries } from "../../utils/ApiCalls/Country/index";
import { checkFields } from "../../utils/Validation/checkFields";
import {
  createLocationData,
  updateLocationData,
  deleteLocationData,
} from "../../utils/ApiCalls/Location/index";
import { getAgencies } from "../../utils/ApiCalls/Agency/index";
import { addlog } from "../../utils/ApiCalls/Logs";

const LocationInputs = ({ location }) => {
  const [isActive, setIsActive] = useState(true);
  const [displayLanguage, setDisplayLanguage] = useState(
    location?.language?.english_name || ""
  );
  const [displayLanguageId, setDisplayLanguageId] = useState(
    location?.language?.id || ""
  );
  const [displayCountry, setDisplayCountry] = useState(
    location?.country?.country_name || ""
  );
  const [displayCountryId, setDisplayCountryId] = useState(
    location?.country?.id || ""
  );
  const [displayCountryCode, setDisplayCountryCode] = useState();
  const [displayAgency, setDisplayAgency] = useState([]);
  const [displayAgencyId, setDisplayAgencyId] = useState(
    location?.agency?.id || ""
  );
  const [continent, setContinent] = useState();
  const [languageOption, setLanguageOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  const [agencyOption, setAgencyOption] = useState([]);
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [error, setError] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    console.log("location=====>", location);
    if (location) {
      setContinent(location?.continent);
      setDisplayLanguage(location?.language?.english_name || "");
      setDisplayLanguageId(location?.language?.id || "");
      setDisplayCountry(location?.country?.country_name || "");
      setDisplayCountryId(location?.country?.id || "");
      setDisplayAgency(location?.agency?.agencyCode || "");
      setDisplayAgencyId(location?.agency?.id || "");
      setIsActive(location?.is_active === 1 ? true : false);
    }
  }, [location]);
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleCheckboxChange = () => {
    setIsActive(!isActive);
  };

  const handleDisplayLanguageChange = (selectedOptions) => {
    setDisplayLanguage(selectedOptions.target.value.split("-")[0]);
    setDisplayLanguageId(selectedOptions.target.value.split("-")[1] || "");
  };
  const handleDisplayCountryChange = (selectedOptions) => {
    const value = selectedOptions.target.value;
    console.log(selectedOptions.target.value);
    setDisplayCountry(value.split("-")[0]);
    setDisplayCountryId(value.split("-")[1] || "");
  };
  const handleDisplayAgencyChange = (selectedOptions) => {
    setDisplayAgency(selectedOptions.target.value.split("-")[0]);
    setDisplayAgencyId(selectedOptions.target.value.split("-")[1] || "");
  };
  useEffect(() => {
    getLanguageOption();
    getCountriesOption();
    getAgenciesOption();
  }, []);

  const getLanguageOption = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getlanguages();

    if (data.error) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    } else {
      setLanguageOption(data);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const getCountriesOption = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getCountries();

    console.log("country ====>", data);
    if (data.error) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    } else {
      setCountryOption(data);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const getAgenciesOption = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getAgencies();
  
    if (data.error) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    } else {
      console.log({data})
      setAgencyOption(data);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const addLocation = async (e) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    e.preventDefault();
    const country = countryOption.find(
      (country) => country.id === displayCountryId
    );
    let dataValues = {
      language_id: parseInt(displayLanguageId),
      agency_id: parseInt(displayAgencyId),
      location_code: country ? country.country_code : "",
      location_name: country ? country.country_name : "",
      country_id: parseInt(displayCountryId),
      continent,
      is_active: isActive ? 1 : 0,
    };

    let valid = checkFields(dataValues);
    if (valid) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: valid, type: "error" },
      });
      setError(valid);
    } else {
      try {
        setError();

        let data;
        if (location?.id) {
          data = await updateLocationData(location.id, dataValues);
        } else {
          data = await createLocationData(dataValues);
        }
        if (data?.error) {
          throw new Error(data.error);
        }
        if (location?.id) {
          addlog(
            "Location Updated",
            "Location",
            {
              id: location.id,
              title: dataValues.title || location.title,
            },
            userDetails.id
          );
        } else {
          addlog(
            "Location Created",
            "Location",
            {
              id: data.id,
              title: dataValues.continent,
            },
            userDetails.id
          );
        }
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
        if (!location.id) {
          navigate("/cms/homepage/location");
        } else {
          navigate("/cms/homepage/location");
          dispatch({
            type: "SET_ERROR_MESSAGE",
            errMess: { message: "Values updated", type: "success" },
          });
        }
      } catch (error) {
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: {
            message: error.message || "Unknown error occurred",
            type: "error",
          },
        });
      }
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <div>
      <section className="content">
        {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )}

        <div className="container-fluid">
          <div className="card">
            <form className="input_form" onSubmit={addLocation}>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Display Language</label>
                      <Select
                        required
                        // disabled={location?.id}
                        renderValue={(displayLanguage) => displayLanguage}
                        style={{ width: "100%", height: "45px" }}
                        value={displayLanguage}
                        onChange={(e) => handleDisplayLanguageChange(e)}
                      >
                        {languageOption.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={`${item.english_name}-${item.id}`}
                          >
                            {item.english_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  {/* <div className="w-100"></div> */}
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Display Agency</label>
                      <Select
                        required
                        // disabled={location?.id}
                        renderValue={(displayAgency) => displayAgency}
                        style={{ width: "100%", height: "45px" }}
                        value={displayAgency}
                        onChange={(e) => handleDisplayAgencyChange(e)}
                      >
                        {agencyOption.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={`${item.agencyCode}-${item.id}`}
                          >
                            {item.agencyCode} 
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Display Country</label>
                      <Select
                        required
                        // disabled={location?.id}
                        renderValue={(displayCountry) => displayCountry}
                        style={{ width: "100%", height: "45px" }}
                        value={displayCountry}
                        onChange={(e) => handleDisplayCountryChange(e)}
                      >
                        {countryOption.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={`${item.country_name}-${item.id}`}
                          >
                            {item.country_name} {item.marketCode}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Continent</label>
                      <input
                        required
                        className="f_input"
                        type="text"
                        value={continent}
                        onChange={(e) => setContinent(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="iti_inner">
                      <div className="icheck-primary d-inline">
                        <input
                          type="checkbox"
                          id="customCheckbox4"
                          name="r10"
                          checked={isActive}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="customCheckbox4">
                          {isActive ? (
                            <p>
                              <span style={{ fontWeight: 600 }}>Active</span>
                            </p>
                          ) : (
                            <p>
                              <span style={{ fontWeight: 200 }}>Active</span>
                            </p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-right">
                <button className="btn main_submit">
                  {location?.id ? "Update" : "Add"} Location
                </button>
              </div>
            </form>
          </div>
          <div className="pt-3"></div>
        </div>
      </section>
    </div>
  );
};

export default LocationInputs;
