import React from "react";
import { getImage } from "../../../../../utils/getImages";
import "./app.css";
const TablePreview4 = ({ cardData, activetab }) => {

  return (
    <div style={{ padding: "10px" }}>
      <div className="mt-5 baggage-policies">
        <div className="box-bring">
          <div className="box-header-cruise text-center p-3">
            <h2>
              {" "}
              {activetab === "english"
                ? cardData?.subtitle
                : activetab === "simplified_chinese"
                ? cardData?.simplified_chinese_subtitle
                : activetab === "arabic"
                ? cardData?.arabic_subtitle
                : cardData?.traditional_chinese_subtitle}
            </h2>
          </div>

          <div className="row box-bring-content">
            {" "}
            {cardData?.dataArray?.map((item) => (
              <div className="baggage-policies-item col-md-4">
                <div className="d-flex flex-direction-column justify-content-center">
                  <div className="baggage-policies-description  text-center pb-4">
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          activetab === "english"
                            ? item?.description
                            : activetab === "simplified_chinese"
                            ? item?.simplified_chinese_description
                            : activetab === "arabic"
                            ? item?.arabic_description
                            : item?.traditional_chinese_description,
                      }}
                      data-lang='{"CN":"您的郵輪⾸選"}'
                      style={{
                        direction: activetab === "arabic" && "rtl",
                      }}
                    />
                  </div>
                  {item?.dataArray?.map((item,index) => (
                    <div className="baggage-policies-img " key={index}>
                      <img
                        className="thumbnail-image"
                        alt=""
                        src={getImage(
                          activetab === "english"
                            ? item?.image
                            : activetab === "simplified_chinese"
                            ? item?.simplified_chinese_image
                            : activetab === "arabic"
                            ? item?.arabic_image
                            : item?.traditional_chinese_image
                        )}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          
          </div>
        </div>
      </div>
   
    </div>
  );
};

export default TablePreview4;
