import React, { useState } from "react";
import "./app.css";
import { getImage } from "../../../../../utils/getImages";
const PreviewFAQ = ({ cardData, activetab }) => {
  const [active, setActive] = useState(0);
  return (
    <div style={{ marginLeft: "10px" }}>
      <h3 className="title-color mb-4" style={{ marginLeft: "10px" }}>
        {" "}
        {activetab === "english"
          ? cardData?.title
          : activetab === "simplified_chinese"
          ? cardData?.simplified_chinese_title
          : cardData?.traditional_chinese_title}
      </h3>

      <div
        style={{
          display: "flex",
          // alignItems: "center",
          flexWrap: "wrap",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <div className="row" style={{ width: "100%" }}>
          {cardData?.dataArray?.map((item, index) => (
            <div
              style={{
                width: "100%",
                borderRadius: "10px",
                overflow: "hidden",
              }}
            >
              <div
                className="faq-holder border position-relative overflow-hidden rounded-top"
                style={{ borderRadius: "10px", overflow: "hidden", margin: 20 }}
              >
                <div className="heading">
                  <p className="p-3">
                    {" "}
                    {activetab === "english"
                      ? item?.title
                      : activetab === "simplified_chinese"
                      ? item?.simplified_chinese_title
                      : item?.traditional_chinese_title}
                  </p>
                </div>

                <div className="faqs">
                  {item?.dataArray?.map((item, index) => (
                    <div className={"que active"} onClick={(item)=>setActive(index)} >
                      <p>
                        {" "}
                        {activetab === "english"
                          ? item?.title
                          : activetab === "simplified_chinese"
                          ? item?.simplified_chinese_title
                          : item?.traditional_chinese_title}
                      </p>

                      <p
                        style={{
                          display: active === index ? "block" : "none",
                          direction: activetab === "arabic" && "rtl",
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            activetab === "english"
                              ? item?.answer
                              : activetab === "simplified_chinese"
                              ? item?.simplified_chinese_answer
                              : activetab === "arabic"
                              ? item?.arabic_answer
                              : item?.traditional_chinese_answer,
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PreviewFAQ;
