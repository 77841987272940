import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";

const LocationFilter = ({ locationData, getData, setLocationId, locationId, width, setPage }) => {
  const [selectedValue, setSelectedValue] = useState(
    locationData.find((item) => item.location_id === locationId) || null
  );

  useEffect(() => {
    if (selectedValue) {
      getData(1);
      setPage(1);
    }
  }, [selectedValue]);

  return (
    <Autocomplete
      options={locationData}
      getOptionLabel={(option) => option.location}
      value={selectedValue}
      onChange={(event, newValue) => {
        setSelectedValue(newValue);
        setLocationId(newValue ? newValue.location_id : "");
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Location"
          variant="outlined"
          size="small"
          style={{ width: width || "22%", height: "40px", marginRight: "10px" }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.location_id === value.location_id}
    />
  );
};

export default LocationFilter;
