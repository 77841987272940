import React, { useState } from "react";
import ActionDropBar from "../../ActionDropBar/ActionDropBar";

const DataCard = ({ index, title, array, setArray, children,color,activeDrop,setActiveDrop,active }) => {

  const onDelete = () => {
    let data = array.filter((item, dataindex) => dataindex != index);
    setArray(data);
  };
  return (
    <div
      style={{
        background:color,
        border: "1px solid lightgray",
        marginBottom: "5px",
 boxShadow: `0 0 0 0 rgba(38, 143, 255, 0.5)`
      }}
    >
      {" "}
      <ActionDropBar
        handleDelete={onDelete}
        title={`  ${index + 1}. ${title}`}
        active={activeDrop==index}
        setActive={(active)=>active?setActiveDrop(index):setActiveDrop()}
      />
      {activeDrop==index && <div style={{ marginTop: "10px" }}>{children}</div>}
    </div>
  );
};

export default DataCard;
