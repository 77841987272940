import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import {
  createFareCode,
  getCountries,
  updateFareCodeData,
} from "../../utils/ApiCalls/FareCode";
import { checkFields } from "../../utils/Validation/checkFields";
import { addlog } from "../../utils/ApiCalls/Logs";
import { createLocationData } from "../../utils/ApiCalls/Location";

const FareCodeInputs = ({ codes }) => {
  const [isActive, setIsActive] = useState(true);
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [error, setError] = useState();
  const [countries, setCounteries] = useState([]);
  const [itravelCountry, setItravelCountry] = useState("");
  const [itravelMarketCode, setItravelMarktCode] = useState();
  const [itravelAgencyCode, setItravelAgencyCode] = useState();
  const [currencyCode, setCurrencyCode] = useState();
  const [detectedCountry, setDetectedCountry] = useState("");
  const [assignedMarketCode, setAssignedMarketCode] = useState();
  const [sc_currency_code, setsc_currency_code] = useState();
  const [tc_currency_code, settc_currency_code] = useState();
  const [arabic_currency_code, setarabic_currency_code] = useState();


  const navigate = useNavigate();

  useEffect(() => {
    if (codes) {
      setItravelAgencyCode(codes?.agencyCode);
      setItravelCountry(codes?.itravel_country);
      setItravelMarktCode(codes?.marketCode);
      setCurrencyCode(codes?.currencyCode);
      setDetectedCountry(codes?.detected_country);
      setAssignedMarketCode(codes?.assigned_market_code);
      setIsActive(codes?.is_active === 1 ? true : false);
      setsc_currency_code(codes?.sc_currency_code);
      settc_currency_code(codes?.tc_currency_code);
      setarabic_currency_code(codes?.arabic_currency_code);
    }
  }, [codes]);



  const handleCheckboxChange = () => {
    setIsActive(!isActive);
  };
  useEffect(() => {
    getOptionLocation();
  }, []);

  const getOptionLocation = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getCountries();

    if (data.error) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    } else {
      console.log(data)
      setCounteries(data);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const addCodes = async (e) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    e.preventDefault();
    let added = false;
    let dataValues = {
      agencyCode: itravelAgencyCode,
      marketCode: itravelMarketCode,
      currencyCode: currencyCode,
      itravel_country: itravelCountry,
      detected_country: detectedCountry,
      assigned_market_code: assignedMarketCode,
      sc_currency_code: sc_currency_code,
      tc_currency_code: tc_currency_code,
      arabic_currency_code: arabic_currency_code,
      country_code: countries.find(item => item.country_name === detectedCountry)?.country_code,
      is_active: isActive ? 1 : 0,
    };
    let valid = checkFields(dataValues);
    if (valid) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: valid, type: "error" },
      });
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
      setError(valid);
      console.log(valid);
    } else {
      setError();
      const values = {
        ...dataValues,
      };

      let data;
      if (codes?.id) {
        data = await updateFareCodeData(codes.id, values);
      } else {
        data = await createFareCode(values);
      }
      if (data?.error) {
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: data.error, type: "error" },
        });
        added = false;
      } else {
        added = true;
      }
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });

      if (codes?.id) {
        addlog(
          "Market Updated",
          "Market",
          {
            id: codes.id,
            title: values.agencyCode,
          },
          userDetails.id
        );
      } else {
        addlog(
          "Market Created",
          "Market",
          {
            id: data.id,
            title: data.agencyCode,
          },
          userDetails.id
        );

        let dataValues = {
          language_id: 1,
          agency_id:data?.id,
          location_code:countries.find(item => item.country_name== detectedCountry)?.country_code,
          location_name:detectedCountry,
          country_id: countries.find(item => item.country_name== detectedCountry)?.id,
          continent:"Asia",
          is_active:1,
        };
        let value = await createLocationData(dataValues);
    
      }
      if (added && !codes?.id) {
        navigate("/masters/markets");
      } else {
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: "Values updated", type: "success" },
        });
        navigate("/masters/markets");
      }
    }
  };
  return (
    <div>
      <section className="content">
        {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )}

        <div className="container-fluid">
          <div className="card">
            <form className="input_form" onSubmit={addCodes}>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Itravel Country</label>
                      <Select
                        required
                        style={{ width: "100%", height: "45px" }}
                        value={itravelCountry}
                        onChange={(e) => setItravelCountry(e.target.value)}
                      >
                        {countries.map((item, index) => (
                          <MenuItem key={index} value={item.country_name}>
                            {item.country_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Itravel Market Code</label>
                      <input
                        required
                        className="f_input"
                        type="text"
                        value={itravelMarketCode}
                        onChange={(e) => setItravelMarktCode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Itravel Agency Code</label>
                      <input
                        required
                        className="f_input"
                        type="text"
                        value={itravelAgencyCode}
                        onChange={(e) => setItravelAgencyCode(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Currency Code</label>
                      <input
                        required
                        className="f_input"
                        type="text"
                        value={currencyCode}
                        onChange={(e) => setCurrencyCode(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Simplified Chinese Currency Code</label>
                      <input
                        required
                        className="f_input"
                        type="text"
                        value={sc_currency_code}
                        onChange={(e) => setsc_currency_code(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Treditional Chinese Currency Code</label>
                      <input
                        required
                        className="f_input"
                        type="text"
                        value={tc_currency_code}
                        onChange={(e) => settc_currency_code(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Arabic Currency Code</label>
                      <input
                        required
                        className="f_input"
                        type="text"
                        value={arabic_currency_code}
                        onChange={(e) => setarabic_currency_code(e.target.value)}
                      />
                    </div>
                  </div>


                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Detected Country</label>
                      <Select
                        required
                        style={{ width: "100%", height: "45px" }}
                        value={detectedCountry}
                        onChange={(e) => setDetectedCountry(e.target.value)}
                      >
                        {countries.map((item, index) => (
                          <MenuItem key={index} value={item.country_name}>
                            {item.country_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Assigned Market code</label>
                      <input
                        required
                        className="f_input"
                        type="text"
                        value={assignedMarketCode}
                        onChange={(e) => setAssignedMarketCode(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="iti_inner">
                      <div className="icheck-primary d-inline">
                        <input
                          type="checkbox"
                          id="customCheckbox4"
                          name="r10"
                          checked={isActive}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="customCheckbox4">
                          {isActive ? (
                            <p>
                              <span style={{ fontWeight: 600 }}>Active</span>
                            </p>
                          ) : (
                            <p>
                              <span style={{ fontWeight: 200 }}>Active</span>
                            </p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-right">
                <button className="btn main_submit">
                  {codes?.id ? "Update" : "Add"} Market
                </button>
              </div>
            </form>
          </div>
          <div className="pt-3"></div>
        </div>
      </section>
    </div>
  );
};

export default FareCodeInputs;
