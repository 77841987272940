import Blogview from "../Previews/Ship/Terms/Blogview";
import BlogThumbnailPreview from "../Previews/Ship/ThumbnailPreview/BlogPreview";
import ThumbnailPreview from "../Previews/Ship/ThumbnailPreview/Preview";

export const blogDetails = {
  form: [
    {
      title: "Page Name",
      key: "page_name",
      className: "col-lg-12",
      type: "input",
    },
    {
      title: "Meta Description",
      key: "meta_description",
      className: "col-lg-12",
      type: "input",
    },
  ],
  component: [
    {
      type: 1,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
        },
        {
          title: "English Thumbnail Image",
          key: "image",
          className: "col-lg-3",
          type: "file",
          placeholder: "Upload English Thumbnail Image",
        },
        {
          title: "Simplified Chinese Thumbnail Image",
          key: "simplified_chinese_image",
          className: "col-lg-3",
          placeholder: "Upload Simplified Chinese Thumbnail Image ",
          type: "file",
        },
        {
          title: "Traditional Chinese Thumbnail Image",
          key: "traditional_chinese_image",
          className: "col-lg-3",
          placeholder: "Upload Traditional Chinese Thumbnail Image ",
          type: "file",
        },
        {
          title: "Arabic Thumbnail Image",
          key: "arabic_image",
          className: "col-lg-3 arabic",
          type: "file",
          placeholder: "Upload Arabic Thumbnail Image ",
        },
        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "input",
        },
      ],
      preview: (activetab, cardData) => (
        <BlogThumbnailPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 5,
      fields: [
        {
          title: "English Header",
          key: "description",
          className: "col-lg-3",
          type: "html",
          type: "editor",
          height: "300px",
        },
        {
          title: "Simplified Chinese Header",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          type: "editor",
          height: "300px",
        },
        {
          title: "Traditional Chinese Header",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          type: "editor",
          height: "300px",
        },
        {
          title: "Arabic Header",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          type: "editor",
          height: "300px",
        },
        {
          title: "Page Image",
          key: "image",
          className: "col-lg-3",
          placeholder: "Upload Page Image",
          type: "file",
        },
        {
          title: "Simplified Chinese Page Image",
          key: "simplified_chinese_image",
          className: "col-lg-3",
          placeholder: "Upload Simplified Chinese Page Image ",
          type: "file",
        },
        {
          title: "Traditional Chinese Page Image",
          key: "traditional_chinese_image",
          className: "col-lg-3",
          placeholder: "Upload Traditional Chinese Page Image ",
          type: "file",
        },
        {
          title: "Arabic Page Image",
          key: "arabic_image",
          className: "col-lg-3 arabic",
          type: "file",
          placeholder: "Upload Arabic Page Image ",
        },
        {
          title: "English Footer",
          key: "description1",
          className: "col-lg-3",
          type: "html",
          type: "editor",
          height: "300px",
        },
        {
          title: "Simplified Chinese Footer",
          key: "simplified_chinese_description1",
          className: "col-lg-3",
          type: "html",
          type: "editor",
          height: "300px",
        },
        {
          title: "Traditional Chinese Footer",
          key: "traditional_chinese_description1",
          className: "col-lg-3",
          type: "html",
          type: "editor",
          height: "300px",
        },
        {
          title: "Arabic Footer",
          key: "arabic_description1",
          className: "col-lg-3",
          type: "html",
          type: "editor",
          height: "300px",
        },
      
      ],
      preview: (activetab, cardData) => (
        <Blogview activetab={activetab} cardData={cardData} />
      ),
    },
  ],
};
