import React, { useEffect, useState } from "react";
import SelectInputs from "./SelectInputs";
import { Chip } from "@mui/material";
const LocationAndExpcept = ({
  nondisplay,
  displayLocation,
  locationOption,
  setDisplayLocation,
  handleDisplayLocationChange,
}) => {
  const [locations, setLocation] = useState([]);
  const [excepetLocation, setExcepetLocation] = useState([]);
  const [displayExcept, setDisplayExcept] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllExcept, setSelectAllExpect] = useState(false);
  useEffect(() => {
    setLocation(locationOption);
    setExcepetLocation(locationOption);
  }, [locationOption]);
  useEffect(() => {
    setLocation(
      locationOption.filter((item) => !displayExcept.includes(item.id))
    );
    setExcepetLocation(
      locationOption.filter((item) => !displayLocation.includes(item.id))
    );
  }, [displayExcept, displayLocation]);

  

  const handleCheckboxChange = () => {
    if (!selectAll) {
      setDisplayLocation(locations.map((item) => item.id));
    } else {
      setDisplayLocation([]);
    }
    setSelectAll(!selectAll);
  };
  const handleCheckboxChange2 = () => {
  
    if (!selectAllExcept) {
      setDisplayExcept(locations.map((item) => item.id));
    } else {
      setDisplayExcept([]);
    }
    setSelectAllExpect(!selectAllExcept);
  };
  const handleDelete = (value) => {
    setDisplayLocation(displayLocation?.filter((item) => item !== value));
  };
  const handleExceptDelete = (value) => {
    setDisplayExcept((data) => data.filter((item) => item !== value));
  };
  return (
    <div className="col-lg-8 col-md-12 col-sm-12 col-12 row">
      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="fieldrow">
          <label className="f_label">Display Location <span style={{color: "red"}}>*</span> </label>
          <SelectInputs
            locationType="DisplayLocation"
            selectedValue={displayLocation}
            // disabled={banner.id}
            options={locations}
            setSelectedValue={(e) => {
              setDisplayLocation(e);
            }}
            style={{ width: "100%", height: "45px" }}
            value={displayLocation}
           
            onChange={(e) => handleDisplayLocationChange(e)}
          />
          <div className="iti_inner">
            <div className="icheck-primary d-inline">
              <input
                type="checkbox"
                id="customCheckbox6"
                name="r10"
                checked={selectAll}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="customCheckbox6">
                {selectAll ? (
                  <p>
                    <span style={{ fontWeight: 600 }}>Remove All</span>
                  </p>
                ) : (
                  <p>
                    <span style={{ fontWeight: 200 }}>Select All</span>
                  </p>
                )}
              </label>
            </div>
          </div>
         {displayLocation?.length>0&& <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "5px",

              maxHeight: "100px",
              border: "1px solid black",
              padding: "10px",
              overflowY: "scroll",
              borderRadius: "6px",
            }}
          >
            {displayLocation?.map((data, index) => (
              <Chip
              onDelete={() => handleDelete(data)}
                key={index}
                size="small"
                sx={{
                  borderRadius: "100px",
                }}

                label={
                  locations.find((item) => item.id === data)?.location_name
                }
              ></Chip>
            ))}
          </div>}
        </div>
      </div>
     {!nondisplay&& <div className="col-lg-6 col-md-12 col-sm-12 col-12">
        <div className="fieldrow">
          <label className="f_label">None Display Location</label>
          <SelectInputs
            locationType="NoneDisplayLocation"
            selectedValue={displayExcept}
            // disabled={banner.id}
            options={excepetLocation}
            setSelectedValue={(e) => {
              setDisplayExcept(e);
            }}
            style={{ width: "100%", height: "45px" }}
            value={displayLocation}
            onChange={(e) => handleDisplayLocationChange(e)}
          />
            <div className="iti_inner">
            <div className="icheck-primary d-inline">
              <input
                type="checkbox"
                id="customCheckbox5"
                name="r10"
                checked={selectAllExcept}
                onChange={handleCheckboxChange2}
              />
              <label htmlFor="customCheckbox5">
                {selectAllExcept ? (
                  <p>
                    <span style={{ fontWeight: 600 }}>Remove All</span>
                  </p>
                ) : (
                  <p>
                    <span style={{ fontWeight: 200 }}>Select All</span>
                  </p>
                )}
              </label>
            </div>
          </div>
          {displayExcept?.length>0&&<div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "5px",
              marginTop: "10px",
              maxHeight: "100px",
              border: "1px solid black",
              padding: "10px",
              overflowY: "scroll",
              borderRadius: "6px",
            }}
          >
            {displayExcept.map((data, index) => (
              <Chip
                key={index}
                size="small"
                sx={{
                  borderRadius: "100px",
                }}
                onDelete={() => handleExceptDelete(data)}
                label={
                  excepetLocation.find((item) => item.id === data)
                    ?.location_name
                }
              ></Chip>
            ))}
          </div>}
        </div>
      </div>}
    </div>
  );
};

export default LocationAndExpcept;
