import EventAddCard from "../../../../Pages/WebPages/Events/EventAddCard";
import Card3Ship from "../Previews/Card3Ship/Preview";
import Card4Ship from "../Previews/Card4Ship/Preview";
import Card5Ship from "../Previews/Card5Ship/Preview";
import FooterShip from "../Previews/FooterShip/Preview";
import ShipBannerPreview from "../Previews/Ship/Banner/Preview";
import ShipDealPreview from "../Previews/Ship/DealCard/Preview";

export const eventsdata = {
  form: [
    {
      title: "Page Name",
      key: "page_name",
      className: "col-lg-12",
      type: "input",
    },
    {
      title: "Meta Description",
      key: "meta_description",
      className: "col-lg-12",
      type: "input",
    },
  ],
  component: [
    {
      type: 1,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false
        },
        {
          title: "Page Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Page Image",
          type: "file",
          required: false
        },
        {
          title: "English Page Title",
          key: "page_title",
          className: "col-lg-3",
          type: "input",
          required: false
        },
        {
          title: "Simplified Chinese Page Title",
          key: "simplified_chinese_page_title",
          className: "col-lg-3",
          type: "input",
          required: false
        },
        {
          title: "Traditional Chinese Page Title",
          key: "traditional_chinese_page_title",
          className: "col-lg-3",
          type: "input",
          required: false
        },
        {
          title: "Arabic Page Title",
          key: "arabic_page_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",required: false
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",required: false
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",required: false
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",required: false
        },
      
      ],
      preview: (activetab, cardData) => (
        <ShipBannerPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 4,
      component: (oldData,preview,dataCard,setDataCard,activetab,item) => (
        <EventAddCard
          oldData={oldData}
          preview={preview}
          dataCard={dataCard}
          setDataCard={setDataCard}
          activetab={activetab}
          item={item}
        >
          
        </EventAddCard>
      ),
    },
  ],
};
