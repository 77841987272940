import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Layout from "../../../Layout";
import HeaderBread from "../../../components/HeaderBread";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import { getCabinTypes } from "../../../utils/ApiCalls/CabinTypes";
import CabinTypeInput from "../../../components/CabinTypeInput";
import CabinCategoryInput from "../../../components/CabinCategoryInput";
import { getCabinCatergoryData } from "../../../utils/ApiCalls/CabinCategory";

const EditCabinCategory = () => {
  const params = useParams();
  const [cabintype, setCabinType] = useState({});
  const [{ useDetails }, dispatch] = useDataLayerValue();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Cabin Category",
      route: "/masters/cabin-category",
    },
    {
      title: "Edit Cabin Category",
      route: "/masters/cabin-category/edit",
    },
  ];
  useEffect(() => {
    getData(params?.id);
  }, [params]);

  const getData = async (value) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getCabinCatergoryData({ id: value });

    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.count === 1) {
      setCabinType(data.data[0]);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <Layout type={"update_per"} module_data={"Cabin Category"}>
      <CustomHeaderTitle title={"Edit Cabin Category"} />
      <HeaderBread
        navigation={navigation}
        title={"Edit Cabin Category"}
      ></HeaderBread>
      {cabintype && <CabinCategoryInput cabintypes={cabintype} />}
    </Layout>
  );
};

export default EditCabinCategory;
