import React from "react";
import { CelebrationPackages } from "../../../components/template/CustomTemplate/Pages/CelebrationPackages";
import CustomTemplates from "./CustomTemplate";

const AddDealsPage = () => {
  
  return (
    <CustomTemplates slug={"celebration-packages"} attributes={CelebrationPackages} />
  );
};

export default AddDealsPage;
