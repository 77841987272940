import { Button, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../Layout";
import HeaderBread from "../../../components/HeaderBread";
import CustomizedTables from "../../../components/Tables/BasicTable";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";

import SearchField from "../../../components/SearchField";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import CustomizedTable from "../../../components/UI/CustomizedTable";
import { deleteDealDetailsData, getDealDetailsData, updateDealDetailsData } from "../../../utils/ApiCalls/DealDetails";
import { constants } from "../../../constants/constants";


const DealDetails = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Deal Details ",
      route: "/cms/homepage/deal-details",
    },
  ];
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [, dispatch] = useDataLayerValue();
  const sortHandler = async (options) => {
    if (Object.keys(options).length > 0) {
      let sort, order;
      sort = options.columnKey;
      order =
        options.order === "ascend"
          ? "asc"
          : options.order === "descend"
          ? "desc"
          : undefined;
      await getData(1, sort, order);
    }
  };

  const columns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (text, record, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Image",
      width: 100,
      dataIndex: "image",
      key: "image",
      render: (image) => (
        <img src={`${constants.MEDIAURL}/${image}`} style={{ width: "100px", height: "80px" }} />
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => "",
      width: 50,
    },
    {
      title: "Vessel",
      dataIndex: "vessel",
      key: "vessel",
      // sorter: (a, b) => "",
      width: 50,
      render: (vessel) => (
        <p>{vessel.name}</p>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: 100,
      sorter: (a, b) => "",
      render: (item, record, index) => (
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            checked={item ? true : false}
            onChange={(value) => {
              handleUpdate(record.id, {
                is_active: value.target.checked ? 1 : 0,
              });
            }}
            className="custom-control-input"
            id={"customSwitch1" + index}
          />
          <label
            className="custom-control-label"
            style={{}}
            for={"customSwitch1" + index}
          ></label>
        </div>
      ),
    },

    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (text, record) => (
        <div className="table_action_btn">
          <div className="btn" onClick={() => handleEdit(record.id)}>
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <div className="btn delete_btn" onClick={() => handleDelete(record.id)}>
            <i
              className="ri-delete-bin-line"
              data-toggle="tooltip"
              title="Delete"
            ></i>
          </div>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getData(page);
  }, [page, pageSize, search]);

  const getData = async (value, sort, order) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let args = { search: search, page: value, limit: pageSize };
    if (order) {
      if (sort) {
        args = { ...args, sort };
      }
      args = { ...args, order };
    }

    let data = await getDealDetailsData(args);
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.data) {
      setRows(data.data);
      setCount(data.count);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const handleEdit = async (id) => {
    navigate("/cms/homepage/deal-details/edit/" + id);
  };
  const handleDelete = async (id) => {
    deleteDealDetailsData(id);
    getData();
  };

  const handleUpdate = async (id, values) => {
    let data = await updateDealDetailsData(id, values);
    if (data.error) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    }
    getData(1);
  };

  return (
    <Layout>
      <CustomHeaderTitle title={"Deal Details"} />
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"Deal Details"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <div className="card">
              <div className="card-header two_item">
                <h5 className="card-title">Deal Details</h5>
                <Link to="/cms/homepage/deal-details/add" className="btn add_v_btn">
                  Add Deal Details
                </Link>
              </div>
              <div className="card-body">
                <div className="table_holder">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div style={{ maxWidth: 200 }}>
                      <SearchField
                        value={search}
                        SearchValue={() => {getData(1);
                          setPage(1);
                        }}
                        setValue={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <CustomizedTable
                    setRows={setRows}
                    setPageSize={setPageSize}
                    setPage={setPage}
                    pageSize={pageSize}
                    page={page}
                    count={count}
                    columns={columns}
                    rows={rows}
                    sortHandler={sortHandler}
                    title={"Deal Details"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default DealDetails;
