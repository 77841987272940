import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import ReactQuill from "react-quill"; // Import React Quill
import { MenuItem, Select } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { checkFields } from "../../utils/Validation/checkFields";
import { uploadFile } from "../../utils/ApiCalls/Document";
import RatePlanInput from "./Components/RatePlanInput";
import SelectInputs from "../../constants/SelectInputs";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { getLocations, getAllLocations } from "../../utils/ApiCalls/Locations";
import SwitchBar from "./SwitchBar";

import {
  createSpecialPages,
  createSpecialPagesPackage,
  getAgencies,
  getSpecialPagePackages,
  getVessels,
  updateSpecialPagesData,
  getMarketCodeByAgency,
  getAgencyById,
  getLocationByAgency,
  getPageDataLocation,
  getLocationSpecialPageData,
  deleteSpecialPagesData,
  getSpecialPageLocation,
} from "../../utils/ApiCalls/SpecialPage";
// import { getSpecialPagePackages, getItineary } from "../../utils/ApiCalls/SpecialPage";

import { Table } from "antd";
import moment from "moment";
import { Chip } from "@mui/material";
import UploadFileImage from "../imageUpload/uploadFileImage";
import LocationAndExpcept from "../../constants/LocationAndExpcept";
import FareCodeList from "./FareCodeList";
import { constants } from "../../constants/constants";
import { addlog } from "../../utils/ApiCalls/Logs";
import { IMAGE_SUB_TYPE, IMAGE_TYPE } from "../../constants/ImageConstant";
import { uploadData } from "../../utils/ApiCalls/Upload";
import DateAndTimePicker from "../DateAndTimePicker";
import UploadFileImageBanner2 from "../Banner/uploadFileImageBanner copy";

const MySwal = withReactContent(Swal);

const SpecialPagesInputs = ({ page, iteneries, agencyMargetCodes }) => {
  const [englishTitle, setEnglishTitle] = useState("");
  const [locations, setLocation] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [slug, setSlug] = useState("");
  const [simplifiedChineseTitle, setSimplifiedChineseTitle] = useState("");
  const [traditionalChineseTitle, setTraditionalChineseTitle] = useState("");
  const [ArabicTitle, setArabicTitle] = useState("");
  const [englishdescription, setEnglishDescription] = useState("");
  const [includecode, setIncludeCode] = useState(false);
  const [simplifiedChineseDescription, setSimplifiedChineseDescription] =
    useState("");
  const [traditionalChineseDescripton, setTraditionalChineseDescripton] =
    useState("");
  const [ArabicDescription, setArabicDescription] = useState("");

  const [isActive, setIsActive] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [traditionalChineseFile, setTraditionalChineseFile] = useState(null);
  const [simplifiedChineseFile, setSimplifiedChineseFile] = useState(null);
  const [ArabicFile, setArabicFile] = useState(null);
  const [url, setUrl] = useState("");
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [error, setError] = useState();
  const [activeTab, setActiveTab] = useState("page-setup");

  const [from_date, setFormDate] = useState();
  const [to_date, setToDate] = useState();
  const [valid_from, setValidFrom] = useState("");
  const [valid_to, setValidTo] = useState("");
  const [valid_till, setValidTill] = useState();
  const [specialPackageOption, setSpecialPackageOption] = useState([]);

  const [agencyOptions, setAgencyOptions] = useState([]);
  const [vesselOptions, setVesselOptions] = useState([]);
  const [agencyMarketOptions, setAgencyMarketOptions] = useState([]);
  const [displayLocation, setDisplayLocation] = useState([]);
  const [locationOption, setLocationOption] = useState([]);

  const [agency, setAgency] = useState("");
  const [vessel, setVessel] = useState("");
  const [agencyMarketCode, setAgencyMarketCode] = useState("");
  const [itineraries, setItineraries] = useState([]);
  const [selectedOption, setSelectedOption] = useState("specificRatePlans");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaKeyWords, setMetaKeyWords] = useState("");
  const [currentLocation, setCurrentLocation] = useState([]);

  const navigate = useNavigate();


  useEffect(() => {
    if (page) {
      getLocationData();
      if (page?.english_display_title) {
        let value = page.english_display_title.split("-");
        if (value && value.length > 1)
          setEnglishTitle(value.slice(0, value.length - 1).join("-"));
        else setEnglishTitle(value.join(""));
      } else {
        setEnglishTitle(page.english_display_title);
      }

      if (page?.simple_chinese_display_title) {
        let value = page.simple_chinese_display_title.split("-");
        if (value && value.length > 1)
          setSimplifiedChineseTitle(value.slice(0, value.length - 1).join("-"));
        else setSimplifiedChineseTitle(value.join(""));
      } else {
        setSimplifiedChineseTitle(page.simple_chinese_display_title);
      }

      if (page?.traditional_chinese_display_title) {
        let value = page.traditional_chinese_display_title.split("-");
        if (value && value.length > 1)
          setTraditionalChineseTitle(
            value.slice(0, value.length - 1).join("-")
          );
        else setTraditionalChineseTitle(value.join(""));
      } else {
        setTraditionalChineseTitle(page.traditional_chinese_display_title);
      }
      setEnglishDescription(page?.english_description);
      setSimplifiedChineseDescription(page?.simplified_chinese_description);
      setEnglishDescription(page?.english_description);
      setTraditionalChineseDescripton(page?.traditional_chinese_description);
      setArabicDescription(page?.arabic_description);
      setArabicTitle(page?.arabic_display_title);

      setFormDate(page?.from_date);

      setToDate(page?.to_date);
      // setValidTill(page?.valid_till);
      setValidTo(page?.valid_to || page?.to_date);
      setValidFrom(page?.valid_from);
      setAgency(page?.agency?.agencyCode + "");
      setVessel(page?.vessel_id + "");

      setAgencyMarketOptions(agencyMargetCodes);

      setAgencyMarketCode(page?.agency_id);
      setMetaDescription(page?.meta_description);
      setMetaKeyWords(page?.meta_keyword);
      setUrl(page?.url);
      setSlug(page.url?.replaceAll(`${constants.PRODUCTION_URL}/special-pages/`, ""))
      setIsActive(page?.is_active === 1 ? true : false);
      if (iteneries?.length > 0) setItineraries(iteneries || []);
      setSelectedOption(page?.selected_type);
    }
  }, [page]);

  useEffect(() => {
    const agencyIds = displayLocation.map(
      (item) => locationOption.find((l) => l.id === item)?.agency_id
    );
    setAgencyMarketCode(agencyIds);
    // setDisplayLocation(e);
  }, [displayLocation]);

  useEffect(() => {
    if (activeTab === "iti-tab") {
      getData();
    }
  }, [activeTab]);
  const getData = async (value, sort, order) => {
    let args = {
      search: "",
      page: 1,
      limit: 100000,
      id: itineraries,
      agencyId: agencyMarketCode,
      vessel_id: vessel,
      selected_type: selectedOption,
    };

    args.fromDate = from_date;
    args.toDate = to_date;
    let data = await getSpecialPageLocation(args);

  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleCheckboxChange = () => {
    try {
      if (
        moment(valid_from).format("YYYY-MM-DD") <=
        moment(new Date()).format("YYYY-MM-DD") &&
        moment(valid_to).format("YYYY-MM-DD") >=
        moment(new Date()).format("YYYY-MM-DD")
      )
        setIsActive(!isActive);
      else {
        if (
          moment(valid_from).format("YYYY-MM-DD") >
          moment(new Date()).format("YYYY-MM-DD")
        ) {
          // setIsActive(false);
          throw new Error(
            `Special Page validity is from ${moment(valid_from).format(
              "DD-MM-YYYY"
            )} cannot activate it`
          );
        }

        if (
          // new Date(record.valid_to) <= new Date()
          moment(valid_to).format("YYYY-MM-DD") <
          moment(new Date()).format("YYYY-MM-DD")
        ) {
          // setIsActive(false)
          throw new Error(
            `Special Page validity is from ${moment(valid_from).format(
              "DD-MM-YYYY"
            )} cannot activate it`
          );
        }
      }
    } catch (e) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: {
      //     message: e.message || "Unknown error occurred",
      //     type: "error",
      //   },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e.message,
      });
      // setError(e.message);
    }
  };

  const handleDelete = (value) => {
    setDisplayLocation((data) => data.filter((item) => item !== value));
  };

  useEffect(() => {
    getOptionLocation();
  }, []);

  // useEffect(() => {

  // }, [englishTitle, simplifiedChineseTitle, traditionalChineseTitle]);

  const getOptionLocation = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });

    let data = await getAgencies();
    let vessels = await getVessels();
    if (data.error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.error,
      });
    } else {
      setAgencyOptions(data);
      setVesselOptions(vessels);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  useEffect(() => { }, [activeTab]);

  useEffect(() => {
    if (!page?.id) {
      setLocationOption([]);
      setDisplayLocation([]);
    }
  }, [agency]);

  const getLocationData = async () => {
    if (page.image) {
      let data = await getLocationSpecialPageData({ image: page?.image });
      let locations = await getLocationByAgency({
        agency_code: agency || page?.agency?.agencyCode,
      });

      if (data.error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.error,
        });
      } else {
        setLocationOption(locations);
        setCurrentLocation(data);
        setCurrentLocation(data);
        setDisplayLocation(data?.map((item) => item.location_id) || []);
      }
    }
  };
  let getURl = () => {
    let newURL;
    let invalidTitle = [undefined, null];
    if (slug) {
      newURL = slug
    }
    else if (!invalidTitle.includes(englishTitle)) {
      newURL = englishTitle.toLocaleLowerCase().replaceAll(" ", "_");
    }
    else if (!englishTitle && !invalidTitle.includes(simplifiedChineseTitle)) {
      newURL = simplifiedChineseTitle.toLocaleLowerCase().replaceAll(" ", "_");
    }
    else if (
      !englishTitle &&
      !simplifiedChineseTitle &&
      !invalidTitle.includes(traditionalChineseTitle)
    ) {
      newURL = traditionalChineseTitle.toLocaleLowerCase().replaceAll(" ", "_");
    }
    newURL = newURL.toLocaleLowerCase().replaceAll(" ", "_")
    return (`${constants.PRODUCTION_URL}/special-pages/${newURL}`);
  }

  const addPage = async (e) => {
    e.preventDefault();
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let dataValues = {
      image: page?.image,
      traditional_chinese_image: page?.traditional_chinese_image,
      simplified_chinese_image: page?.simplified_chinese_image,
      english_display_title: englishTitle,
      simple_chinese_display_title: simplifiedChineseTitle,
      traditional_chinese_display_title: traditionalChineseTitle,
      english_description: englishdescription,
      simplified_chinese_description: simplifiedChineseDescription,
      traditional_chinese_description: traditionalChineseDescripton,
      arabic_image: page?.arabic_image,
      arabic_display_title: ArabicTitle,
      arabic_description: ArabicDescription,
      is_active: 1,
      from_date: from_date,
      selected_type: selectedOption,
      to_date: to_date,
      valid_from: new Date(valid_from),
      valid_to: new Date(valid_to),
      url: getURl(),
      agency_id: agencyMarketCode,
      vessel_id: vessel,
      show_home: page?.show_home || 0
    };
    console.log(dataValues);

    try {
      if (selectedFile) {
        if (typeof selectedFile === "string") {
          dataValues.image = selectedFile.replace(constants.MEDIAURL + "/", "");
        } else {
          let imageType = {
            type: IMAGE_TYPE.DEAL_IMG,
            subType: IMAGE_SUB_TYPE.ENGLISH_IMG,
          };
          let image = await uploadData(selectedFile, imageType);
          if (image?.error) throw new Error(image.error);

          dataValues.image = image?.url;
        }
      } else {
        dataValues.image = ""
      }
      if (traditionalChineseFile) {
        if (typeof traditionalChineseFile === "string") {
          dataValues.traditional_chinese_image = traditionalChineseFile.replace(constants.MEDIAURL + "/", "");
        } else {
          let imageType = {
            type: IMAGE_TYPE.DEAL_IMG,
            subType: IMAGE_SUB_TYPE.TRADITIONAL_CHINESE_IMG,
          };
          let image = await uploadData(traditionalChineseFile, imageType);
          if (image?.error) throw new Error(image.error);

          dataValues.traditional_chinese_image = image?.url;
        }
      } else {
        dataValues.traditional_chinese_image = ""
      }
      if (simplifiedChineseFile) {
        if (typeof simplifiedChineseFile === "string") {
          dataValues.simplified_chinese_image = simplifiedChineseFile.replace(constants.MEDIAURL + "/", "");
        } else {
          let imageType = {
            type: IMAGE_TYPE.DEAL_IMG,
            subType: IMAGE_SUB_TYPE.TRADITIONAL_CHINESE_IMG,
          };
          let image = await uploadData(simplifiedChineseFile);
          if (image?.error) throw new Error(image.error);

          dataValues.simplified_chinese_image = image?.url;
        }
      }
      else {
        dataValues.simplified_chinese_image = ""
      }

      if (ArabicFile) {
        if (typeof ArabicFile === "string") {
          dataValues.arabic_image = ArabicFile.replace(constants.MEDIAURL + "/", "");
        } else {
          let imageType = {
            type: IMAGE_TYPE.DEAL_IMG,
            subType: IMAGE_SUB_TYPE.TRADITIONAL_CHINESE_IMG,
          };
          let image = await uploadData(ArabicFile);
          if (image?.error) throw new Error(image.error);

          dataValues.arabic_image = image?.url;
        }
      } else {
        dataValues.arabic_image = ""
      }

      let requiredFields = ["english_image"];
      if (itineraries?.length < 1 || !itineraries) {
        throw new Error("Add Fare Code");
      }

      const isChinaSelecetd = displayLocation.find(
        (location) =>
          locationOption.find((l) => location === l.id)?.location_code === "CN"
      );

      if (isChinaSelecetd) {
        requiredFields.push(
          "traditional_chinese_image",
          "simplified_chinese_image"
        );
      }

      let valid = checkFields(dataValues, "deals", requiredFields);
      dataValues.meta_description = metaDescription;
      dataValues.meta_keyword = metaKeyWords;
      if (valid) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: valid,
        });
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      } else {
        try {
          let args = {
            limit: 10,
            page: 1,
            search: "",
            id: itineraries,
            agencyId: agencyMarketCode,
            vessel_id: vessel,
            selected_type: selectedOption,
            itinearyId: "",
            fromDate: from_date,
            toDate: to_date,
          };

          let fareData = await getSpecialPagePackages(args);
          if (fareData.data && !(fareData.data.length > 0)) {
            throw new Error("No matching itinearies found");
          }

          setError();

          await Promise.all(
            currentLocation.map(async (item) => {
              if (!displayLocation.includes(item.location_id)) {
                await deleteSpecialPagesData(item.id);
              }
            })
          );
          await Promise.all(
            displayLocation.map(async (item, index) => {
              let values = {
                location_id: item,
                ...dataValues,
              };
              const searchLocationByAgency = locationOption.find(
                (l) => l.id === item
              );

              let args = {
                search: "",
                page: 1,
                limit: 100000,
                id: itineraries,
                agencyId: agencyMarketCode,
                vessel_id: vessel,
                selected_type: selectedOption,
              };

              args.fromDate = from_date;
              args.toDate = to_date;
              let location = await getSpecialPageLocation(args);
              let datalocation = location?.data;
              values = {
                ...values,

                agency_id: searchLocationByAgency.agency_id,
                english_display_title: values.english_display_title
                  ? `${values.english_display_title}-${searchLocationByAgency?.agency?.marketCode}`
                  : values.english_display_title,
                simple_chinese_display_title:
                  values.simple_chinese_display_title
                    ? `${values.simple_chinese_display_title}-${searchLocationByAgency?.agency?.marketCode}`
                    : values.simple_chinese_display_title,
                traditional_chinese_display_title:
                  values.traditional_chinese_display_title
                    ? `${values.traditional_chinese_display_title}-${searchLocationByAgency?.agency?.marketCode}`
                    : values.traditional_chinese_display_title,
                url: (!includecode) ? values.url : slug.includes("-" + searchLocationByAgency?.agency?.marketCode) ? values.url : `${values.url}-${searchLocationByAgency?.agency?.marketCode}`,
                departure_port: datalocation?.departure_port,
                simplified_departure_port:
                  datalocation?.simplified_departure_port,
                traditional_departure_port:
                  datalocation?.traditional_departure_port,
                arabic_departure_port: datalocation?.arabic_departure_port,
                destinations: datalocation?.destinations,
                sailing_date: datalocation?.sailing_date,
                simplified_destinations: datalocation?.simplified_destinations,
                traditional_destinations:
                  datalocation?.traditional_destinations,
                arabic_destinations: datalocation?.arabic_destinations,
              };
              let specialPage;
              let res;

              if (page?.id) {
                let locationValue = currentLocation.filter(
                  (data) => data.location_id === item
                );
                if (locationValue.length > 0) {
                  let { ...rest } = values;
                  if (item === page.location_id) {
                    specialPage = await updateSpecialPagesData(
                      locationValue[0].id,
                      values
                    );
                  } else {
                    specialPage = await updateSpecialPagesData(
                      locationValue[0].id,
                      rest
                    );
                  }
                } else {
                  specialPage = await createSpecialPages({
                    ...values,
                    recordIteration: index + 1,
                  });
                }
              } else
                specialPage = await createSpecialPages({
                  ...values,
                  recordIteration: index + 1,
                });
              if (specialPage?.error) {
                throw new Error(specialPage.error);
              }
              let data;

              if (specialPage?.id) {
                data = await createSpecialPagesPackage({
                  special_page_id: specialPage?.id,
                  fare_codes: itineraries,
                });
              }
              if (page?.id) {
                addlog(
                  "Special Pages Updated",
                  "Special_Pages",
                  {
                    id: page.id,
                    title:
                      dataValues.english_display_title ||
                      page.english_display_title,
                    url: url || page.url,
                  },
                  userDetails.id
                );
              } else {
                addlog(
                  "Special Pages Created",
                  "Special_Pages",
                  {
                    id: data.id,
                    title: dataValues.english_display_title,
                    url: dataValues.url,
                  },
                  userDetails.id
                );
              }
              if (!page?.id) {
                navigate("/cms/special-pages");
              } else {
                navigate("/cms/special-pages");
                dispatch({
                  type: "SET_ERROR_MESSAGE",
                  errMess: { message: "Values updated", type: "success" },
                });
              }
            })
          );
        } catch (error) {

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message || "Unknown error occurred",
          });
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
          // setError(error.message);
        }
      }
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
     
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message || "Unknown error occurred",
      });
    }
  };

  const validatePage = () => {
    const valid = checkFields({
      agency,
      market_code: agencyMarketCode,
      cruise_name: vessel,
    });
    if (valid) {
      setActiveTab("page-setup");
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: valid, type: "error" },
      });
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
      setError(valid);
    }
    return valid;
  };

  const handleLocationChange = () => {
    if (!selectAll) {
      setDisplayLocation(locations.map((item) => item.id));
    } else {
      setDisplayLocation([]);
    }
    setSelectAll(!selectAll);
  };

  const handleCheckboxLocationChange = () => {
    if (!selectAll) {
      setDisplayLocation(locationOption.map((item) => item.id));
    } else {
      setDisplayLocation([]);
    }
    setSelectAll(!selectAll);
  };


  return (
    <div>
      <div style={{ marginLeft: "10px" }}>
        <SwitchBar
          tabs={[
            { title: "Page Setup", value: "page-setup" },
            { value: "iti-tab", title: " Itineraries" },
            { value: "iti-seo", title: "SEO" },
          ]}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>

      <section className="content">
        {/* {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )} */}

        {activeTab === "page-setup" && (
          <div className="container-fluid">
            <div className="card">
              <form className="input_form" onSubmit={addPage}>
                <div className="card-body">
                  <div className="row">
                    {/* <LocationAndExpcept
                      displayLocation={displayLocation}
                      banner={page}
                      locationOption={locationOption}
                      options={locationOption}
                      setDisplayLocation={setDisplayLocation}
                      handleDisplayLocationChange={handleDisplayLocationChange}
                    /> */}

                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">
                          English Display Title{" "}
                          {!englishTitle &&
                            !simplifiedChineseTitle &&
                            !traditionalChineseTitle && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                        </label>
                        <input
                          // required
                          className="f_input"
                          type="text"
                          value={englishTitle}
                          onChange={(e) => {
                            if (e.target.value.length < 44) {
                              setEnglishTitle(e.target.value);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">
                          Simplified Chinese Display Title
                          {!englishTitle &&
                            !simplifiedChineseTitle &&
                            !traditionalChineseTitle && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                        </label>
                        <input
                          // required
                          className="f_input"
                          type="text"
                          value={simplifiedChineseTitle}
                          onChange={(e) => {
                            if (e.target.value.length < 44)
                              setSimplifiedChineseTitle(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">
                          Traditional Chinese Display Title
                          {!englishTitle &&
                            !simplifiedChineseTitle &&
                            !traditionalChineseTitle && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                        </label>
                        <input
                          // required
                          className="f_input"
                          type="text"
                          value={traditionalChineseTitle}
                          onChange={(e) => {
                            if (e.target.value.length < 44)
                              setTraditionalChineseTitle(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">
                          Arabic Display Title
                          {!englishTitle &&
                            !simplifiedChineseTitle &&
                            !traditionalChineseTitle && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                        </label>
                        <input
                          // required
                          className="f_input"
                          style={{ direction: "rtl" }}
                          type="text"
                          value={ArabicTitle}
                          onChange={(e) => {
                            if (e.target.value.length < 44)
                              setArabicTitle(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">
                          Agency <span style={{ color: "red" }}> *</span>
                        </label>
                        <Select
                          required
                          style={{ width: "100%", height: "45px" }}
                          value={agency}
                          onChange={async (e) => {
                            setAgency(e.target.value);
                            let locations = await getLocationByAgency({
                              agency_code: e.target.value,
                            });
                            setLocationOption(locations);
                          }}
                        >
                          {agencyOptions.map((item, index) => (
                            <MenuItem key={index} value={item?.agencyCode}>
                              {item.agencyCode}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">
                          Cruise Name <span style={{ color: "red" }}> *</span>
                        </label>
                        <Select
                          required
                          style={{ width: "100%", height: "45px" }}
                          value={vessel}
                          onChange={(e) => setVessel(e.target.value)}
                        >
                          {vesselOptions.map((item, index) => (
                            <MenuItem key={index} value={item?.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">
                          Display Location{" "}
                          <span style={{ color: "red" }}>*</span>{" "}
                        </label>
                        <SelectInputs
                          modules={"special_page"}
                          locationType="DisplayLocation"
                          selectedValue={displayLocation}
                          // disabled={banner.id}
                          options={locationOption}
                          setSelectedValue={(e) => {

                            setDisplayLocation(e);
                          }}
                          style={{ width: "100%", height: "45px" }}
                          value={displayLocation}
                          onChange={(e) => handleLocationChange(e)}
                        />
                        <div className="iti_inner">
                          <div className="icheck-primary d-inline">
                            <input
                              type="checkbox"
                              id="customCheckbox6"
                              name="r10"
                              checked={selectAll}
                              onChange={handleCheckboxLocationChange}
                            />
                            <label htmlFor="customCheckbox6">
                              {selectAll ? (
                                <p>
                                  <span style={{ fontWeight: 600 }}>
                                    Remove All
                                  </span>
                                </p>
                              ) : (
                                <p>
                                  <span style={{ fontWeight: 200 }}>
                                    Select All
                                  </span>
                                </p>
                              )}
                            </label>
                          </div>
                        </div>
                        {displayLocation?.length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "5px",

                              maxHeight: "100px",
                              border: "1px solid black",
                              padding: "10px",
                              overflowY: "scroll",
                              borderRadius: "6px",
                            }}
                          >
                            {displayLocation.map((data, index) => (
                              <Chip
                                onDelete={() => handleDelete(data)}
                                key={index}
                                size="small"
                                sx={{
                                  borderRadius: "100px",
                                }}
                                label={
                                  locationOption.find(
                                    (item) => item.id === data
                                  )?.location_name
                                }
                              ></Chip>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    {/* <div className="col-lg-4"></div> */}

                    <UploadFileImageBanner2
                      mandatory={"*"}
                      className="col-lg-3"
                      width={778}
                      mediaType={"image"}
                      height={542}
                      image={
                        page?.image
                          ? `${constants.MEDIAURL}/${page?.image}`
                          : page?.image
                      }
                      label={"English Page Image"}
                      placeholder={"Upload English Page Image"}
                      selectedFile={selectedFile}

                      setSelectedFile={setSelectedFile}
                    />
                    <UploadFileImageBanner2
                      mediaType={"image"}
                      mandatory={
                        displayLocation.find(
                          (location) =>
                            locationOption.find((l) => location === l.id)
                              ?.location_code === "CN"
                        )
                          ? "*"
                          : ""
                      }
                      className="col-lg-3"
                      width={778}

                      height={542}
                      image={
                        page?.simplified_chinese_image
                          ? `${constants.MEDIAURL}/${page?.simplified_chinese_image}`
                          : page?.simplified_chinese_image
                      }
                      label={"Simplified Chinese Page Image"}
                      placeholder={"Upload Simplified Chinese Page Image "}
                      selectedFile={simplifiedChineseFile}
                      setSelectedFile={setSimplifiedChineseFile}
                    />
                    <UploadFileImageBanner2
                      mediaType={"image"}
                      mandatory={
                        displayLocation.find(
                          (location) =>
                            locationOption.find((l) => location === l.id)
                              ?.location_code === "CN"
                        )
                          ? "*"
                          : ""
                      }
                      className="col-lg-3"
                      width={778}
                      height={542}
                      image={
                        page?.traditional_chinese_image
                          ? `${constants.MEDIAURL}/${page?.traditional_chinese_image}`
                          : page?.traditional_chinese_image
                      }
                      label={"Traditional Chinese Page Image "}
                      placeholder={"Upload Traditional Chinese Page Image "}
                      selectedFile={traditionalChineseFile}
                      setSelectedFile={setTraditionalChineseFile}
                    />

                    <UploadFileImageBanner2
                      mediaType={"image"}
                      mandatory={
                        displayLocation.find(
                          (location) =>
                            locationOption.find((l) => location === l.id)
                              ?.location_code === "CN"
                        )
                          ? "*"
                          : ""
                      }
                      className="col-lg-3"
                      width={778}
                      height={542}
                      image={
                        page?.arabic_image
                          ? `${constants.MEDIAURL}/${page?.arabic_image}`
                          : page?.arabic_image
                      }
                      label={"Arabic Page Image "}
                      placeholder={"Upload Arabic Page Image "}
                      selectedFile={ArabicFile}
                      setSelectedFile={setArabicFile}
                    />

                    <div className="col-3">
                      <div className="fieldrow">
                        <label className="f_label">English Description</label>
                        <ReactQuill
                          value={englishdescription}
                          onChange={(e) => {
                            setEnglishDescription(e);
                          }}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fieldrow">
                        <label className="f_label">
                          Simplified Chinese Description
                        </label>
                        <ReactQuill
                          value={simplifiedChineseDescription}
                          onChange={(e) => setSimplifiedChineseDescription(e)}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fieldrow">
                        <label className="f_label">
                          Traditional Chinese Description
                        </label>
                        <ReactQuill
                          value={traditionalChineseDescripton}
                          onChange={setTraditionalChineseDescripton}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="fieldrow">
                        <label className="f_label">Arabic Description</label>
                        <ReactQuill
                          value={ArabicDescription}
                          onChange={setArabicDescription}
                          modules={modules}
                          formats={formats}
                        />
                      </div>
                    </div>
                    <div className="row w-100">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="add_iti">
                          <h4 className="sub_title2">Add Itineraries</h4>
                          <div className="iti_inner">
                            <div className="icheck-primary d-inline">
                              <input
                                type="radio"
                                value="specificRatePlans"
                                checked={selectedOption === "specificRatePlans"}
                                onChange={handleRadioChange}
                                id="radioPrimary1"
                                name="r1"
                              />
                              <label for="radioPrimary1">
                                Specific Rate Plans
                              </label>
                            </div>
                            <div className="icheck-primary d-inline">
                              <input
                                type="radio"
                                value="rateplan"
                                checked={selectedOption === "rateplan"}
                                onChange={handleRadioChange}
                                id="radioPrimary2"
                                name="r1"
                              />
                              <label for="radioPrimary2">Rate Plan</label>
                            </div>
                            <div className="icheck-primary d-inline">
                              <input
                                type="radio"
                                value="rateplantype"
                                checked={selectedOption === "rateplantype"}
                                onChange={handleRadioChange}
                                id="radioPrimary3"
                                name="r1"
                              />
                              <label for="radioPrimary3">Rate Plan Type</label>
                            </div>
                            <div className="icheck-primary d-inline">
                              <input
                                type="radio"
                                value="promoindex"
                                checked={selectedOption === "promoindex"}
                                onChange={handleRadioChange}
                                id="radioPrimary4"
                                name="r1"
                              />
                              <label for="radioPrimary4">Promo Index</label>
                            </div>
                          </div>
                          <div className="iti_input">
                            <RatePlanInput
                              selectedOption={selectedOption}
                              chips={itineraries}
                              setChips={setItineraries}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="sailing_date">
                          <h4 className="sub_title2">Sailing Date</h4>
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                              <div className="fieldrow">
                                <label className="f_label">
                                  From <span style={{ color: "red" }}> *</span>
                                </label>
                                <input
                                  required
                                  value={from_date}
                                  onChange={(e) => {

                                    setFormDate(e.target.value);
                                    setValidFrom("");
                                    setToDate("");
                                    setValidTo("");
                                  }}
                                  className="f_input"
                                  type="date"
                                  min={
                                    page?.created_at
                                      ? moment(
                                        new Date(page?.created_at)
                                      ).format("YYYY-MM-DD")
                                      : moment(new Date()).format("YYYY-MM-DD")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                              <div className="fieldrow">
                                <label className="f_label">
                                  To <span style={{ color: "red" }}> *</span>
                                </label>
                                <input
                                  required
                                  className="f_input"
                                  value={to_date}
                                  onChange={(e) => {
                                    setToDate(e.target.value);
                                    setValidTo(e.target.value);
                                  }}
                                  type="date"
                                  min={moment(from_date).format("YYYY-MM-DD")}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="fieldrow">
                          <label className="f_label">Page Slug</label>
                          <input
                            className="f_input w-75"
                            value={slug}
                            onChange={(e) => setSlug(e.target.value)}
                            type="text"
                            readonly
                          />
                          <div className="col-12">
                            <div className="iti_inner">
                              <div className="icheck-primary d-inline">

                                <p style={{ fontSize: 14, color: "grey" }}>{`${constants.PRODUCTION_URL}/special-pages/${slug?.toLocaleLowerCase().replaceAll(" ", "_")}${includecode ? "-(market code)" : ""}`}</p>
                                <input
                                  type="checkbox"
                                  id="customCheckboxmarkt"
                                  name="r10"
                                  disabled={slug.includes("-")}
                                  checked={includecode}
                                  onChange={(e) => setIncludeCode(e.target.checked)}
                                />
                                <label htmlFor="customCheckboxmarkt">
                                  {includecode ? (
                                    <p>
                                      <span style={{ fontWeight: 600 }}>Include Market Code</span>
                                    </p>
                                  ) : (
                                    <p>
                                      <span style={{ fontWeight: 200 }}>Include Market Code</span>
                                    </p>
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="sailing_date">
                          <h4 className="sub_title2">Valid Date</h4>
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                              <div className="fieldrow">
                                <label className="f_label">
                                  From <span style={{ color: "red" }}> *</span>
                                </label>
                                {/* <input
                                  disabled={from_date && to_date ? false : true}
                                  required
                                  value={valid_from}
                                  onChange={(e) => {
                                    setValidFrom(e.target.value);
                                    setValidTo("");
                                    if (
                                      moment(e.target.value).format(
                                        "YYYY-MM-DD"
                                      ) >
                                      moment(new Date()).format("YYYY-MM-DD")
                                    ) {
                                      setIsActive(false);
                                    }
                                    if (
                                      moment(e.target.value).format(
                                        "YYYY-MM-DD"
                                      ) <=
                                      moment(new Date()).format("YYYY-MM-DD")
                                    ) {
                                      setIsActive(true);
                                    }
                                  }}
                                  className="f_input"
                                  type="date"
                                  max={
                                    to_date &&
                                    moment(to_date).format("YYYY-MM-DD")
                                  }
                                  min={
                                    page?.created_at
                                      ? moment(
                                          new Date(page.created_at)
                                        ).format("YYYY-MM-DD")
                                      : moment(new Date()).format("YYYY-MM-DD")
                                  }
                                /> */}
                                <DateAndTimePicker
                                  max={

                                    moment(to_date || new Date()).format("YYYY-MM-DD")
                                  }
                                  disabled={from_date && to_date ? false : true}
                                  min={
                                    page?.created_at
                                      ? moment(
                                        new Date(page.created_at)
                                      ).format("YYYY-MM-DD")
                                      : moment(new Date()).format("YYYY-MM-DD")
                                  }
                                  value={valid_from}
                                  setValue={(e) => {
                                    setValidFrom(e);
                                    setValidTo("");
                                    if (
                                      moment(e).format("YYYY-MM-DD") >
                                      moment(new Date()).format("YYYY-MM-DD")
                                    ) {
                                      setIsActive(false);
                                    }
                                    if (
                                      moment(e).format("YYYY-MM-DD") <=
                                      moment(new Date()).format("YYYY-MM-DD")
                                    ) {
                                      setIsActive(true);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                              <div className="fieldrow">
                                <label className="f_label">
                                  To <span style={{ color: "red" }}> *</span>
                                </label>
                                {/* <input
                                  disabled={valid_from ? false : true}
                                  required
                                  className="f_input"
                                  value={valid_to}
                                  type="date"
                                  onChange={(e) => {
                                    setValidTo(e.target.value);
                                    // if(moment(valid_from).format("YYYY-MM-DD") > moment(new Date()).format("YYYY-MM-DD")){
                                    //   setIsActive(false)
                                    // }
                                    if (
                                      // new Date(record.valid_to) <= new Date()
                                      moment(e.target.value).format(
                                        "YYYY-MM-DD"
                                      ) <
                                      moment(new Date()).format("YYYY-MM-DD")
                                    ) {
                                      setIsActive(false);
                                    }
                                  }}
                                  max={moment(to_date).format("YYYY-MM-DD")}
                                  min={moment(valid_from).format("YYYY-MM-DD")}
                                /> */}
                                <DateAndTimePicker
                                  max={moment(to_date).format("YYYY-MM-DD")}
                                  min={moment(valid_from).format("YYYY-MM-DD")}
                                  value={valid_to}
                                  disabled={valid_from ? false : true}
                                  setValue={(e) => {
                                    setValidTo(e);

                                    if (
                                      moment(e).format("YYYY-MM-DD") <
                                      moment(new Date()).format("YYYY-MM-DD")
                                    ) {

                                      setIsActive(false);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12"></div>
                    <div className="col-12">
                      <div className="iti_inner">
                        <div className="icheck-primary d-inline">
                          <input
                            type="checkbox"
                            id="customCheckbox4"
                            name="r10"
                            checked={isActive}
                            onChange={handleCheckboxChange}
                          />
                          <label htmlFor="customCheckbox4">
                            {isActive ? (
                              <p>
                                <span style={{ fontWeight: 600 }}>Active</span>
                              </p>
                            ) : (
                              <p>
                                <span style={{ fontWeight: 200 }}>Active</span>
                              </p>
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer text-right">
                  <button className="btn main_submit">
                    {page?.id ? "Update" : "Add"} Special Page
                  </button>
                </div>
              </form>
            </div>
            <div className="pt-3"></div>
          </div>
        )}
        {activeTab === "iti-tab" && !validatePage() && (
          <div className="container-fluid">
            <div className="card">
              <FareCodeList
                locationOption={locationOption}
                displayLocation={displayLocation}
                itineraries={itineraries}
                from_date={from_date}
                to_date={to_date}
                valid_from={valid_from}
                valid_to={valid_to}
                activeTab={activeTab}
                agency={agencyMarketCode}
                selectedOption={selectedOption}
                vessel={vessel}
              />
            </div>
          </div>
        )}
        {activeTab === "iti-seo" && (
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="fieldrow">
                      <label className="f_label">Meta Title</label>
                      <ReactQuill
                        value={metaKeyWords}
                        onChange={setMetaKeyWords}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="fieldrow">
                      <label className="f_label">Meta Description</label>
                      <ReactQuill
                        value={metaDescription}
                        onChange={setMetaDescription}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default SpecialPagesInputs;
