import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderBread from "../../../components/HeaderBread";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Layout from "../../../Layout";

import { message } from "antd";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import CustomizedTable from "../../../components/UI/CustomizedTable";
import DeletePop from "../../../constants/ErrorPermissionModal";
import { getPermission } from "../../../utils/ApiCalls/Permission";
import {
  deleteUsers,
  getUsers,
  updateUsers,
} from "../../../utils/ApiCalls/Users";
import { checkPermission } from "../../../utils/Validation/checkPermission";

const UserManagment = () => {
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "User Managment",
      route: "/profile/user-management",
    },
  ];
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [{ permission }, dispatch] = useDataLayerValue();
  const navigate=useNavigate()
  const handleEdit = async (id) => {
    if (checkPermission(permission, "update_per", "User Management")) {
      navigate("/profile/user-management/edit/" + id);
    } else {
      message.error("Update permission missing");
    }
  };
  const columns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (position, record, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      width: 50,
      render: (text, record, index) => (
        <div>
          {record?.firstName} {record.lastName}
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 50,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 50,
      render: (item, record) => (
        <Select
          style={{
            width: "100%",
            height: "45px",
            background: "white",
          }}
          value={item}
          onChange={async (e) => {
            // setRole(e.target.value)

            let update = await updateUsers(record?.id, {
              role: e.target.value,
            });
            getData(page);
          }}
        >
          {roles?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      ),
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (text, record) => (
        <div className="table_action_btn">
          
              {" "}
              <div className="btn" onClick={() => handleEdit(record.id, record.title)}>
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <DeletePop onConfirm={() => handleDelete(record.id)}>
            <div >
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getData(page);
  }, [page, pageSize]);
  const handleDelete = async (id) => {
    if (checkPermission(permission, "delete_per", "Role")) {
      await deleteUsers(id);
      await getData(page);
    } else {
      message.error("Missing Permission for delete");
    }
  };

  const getData = async (value) => {
    let data = await getUsers({ page: value, limit: pageSize });
    const per = await getPermission();
    setRoles(per?.data?.map((item) => item.name));
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.data) {
      setRows(data.data);
      setCount(data.count);
    }
  };
  return (
    <Layout type={"read_per"} module_data={"user_management"}>
      <CustomHeaderTitle title={"User Managment"} />
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"User Managment"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <div className="card">
              <div className="card-header two_item">
                <h5 className="card-title">Users List</h5>
                <div className="two_item">
                  <Link
                    to="/profile/user-management/add"
                    className="btn export_v_btn mx-2"
                  >
                    Add User
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <div className="table_holder">
                  {roles?.length > 0 && (
                    <CustomizedTable
                      setRows={setRows}
                      setPageSize={setPageSize}
                      setPage={setPage}
                      pageSize={pageSize}
                      page={page}
                      count={count}
                      columns={columns}
                      rows={rows}
                      title={"NewsLetter"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default UserManagment;
