import React from "react";
import { getImage } from "../../../../../../utils/getImages";
import "./app.css";
const BlogThumbnailPreview = ({ cardData, activetab }) => {
  return (
    <div>
      <div className="card-body" style={{margin:"20px"}}></div>
      <div className="destination-package" style={{ width: "200px",margin:"20px" }}>
        <img
        alt=""
         src={
          getImage(cardData?.image)
             }
         className="w-100 rounded"
        />

        
          <div>
            <div >
              <p  data-lang='{ "CN":"皇宫庭苑别墅" }'>
              {activetab === "english"
                  ? cardData?.title
                  : activetab === "simplified_chinese"
                  ? cardData?.simplified_chinese_title
                  : activetab === "arabic"
                  ? cardData?.arabic_title
                  : cardData?.traditional_chinese_title}
              </p>
              <div
          style={{
            direction: activetab === "arabic" && "rtl",
            fontSize: "10px",
            fontWeight: "400",
            marginTop: -15,
          }}
          dangerouslySetInnerHTML={{
            __html:
              activetab === "english"
                ? cardData?.description
                : activetab === "simplified_chinese"
                  ? cardData?.simplified_chinese_description
                  : activetab === "arabic"
                    ? cardData?.arabic_description
                    : cardData?.traditional_chinese_description,
          }}
        />
            </div>

          </div>
     
      </div>
    </div>
  );
};

export default BlogThumbnailPreview;
