import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Layout from "../../../Layout";
import HeaderBread from "../../../components/HeaderBread";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import { getAnnouncement } from "../../../utils/ApiCalls/Announcement";
import AnnouncementInputs from "../../../components/Announcement/NewsLetterInput";

const EditAnnouncement = () => {
  const params = useParams();
  const [Announcement, setAnnouncement] = useState({});
  const [{ useDetails }, dispatch] = useDataLayerValue();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Announcement",
      route: "/cms/homepage/announcement",
    },
    {
      title: "Edit Announcement",
      route: "/cms/homepage/announcement/edit",
    },
  ];
  useEffect(() => {
    getData(params?.id);
  }, [params]);

  const getData = async (value) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getAnnouncement({ id: value });

    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.count === 1) {
      setAnnouncement(data.data[0]);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <Layout type={"update_per"} module_data={"Announcement"}>
      <CustomHeaderTitle title={"Edit Experience"} />
      <HeaderBread
        navigation={navigation}
        title={"Edit Announcement"}
      ></HeaderBread>
      {Announcement && <AnnouncementInputs newsletter={Announcement} />}
    </Layout>
  );
};

export default EditAnnouncement;
