import { message } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Layout from "../../../Layout";
import YouTubeEmbed from "../../../components/Banner/YouTubeEmbed";
import HeaderBread from "../../../components/HeaderBread";
import Logs from "../../../components/Logs";
import SwitchBar from "../../../components/Pages/SwitchBar";
import SearchField from "../../../components/SearchField";
import ActiveInactive from "../../../components/UI/ActiveInactive";
import CustomizedDragNDrop from "../../../components/UI/CustomizedDragNDrop";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import DeletePop from "../../../constants/ErrorPermissionModal";
import { constants } from "../../../constants/constants";
import ValidDateUI from "../../../constants/validDateUI";
import {
  deleteBannerData,
  getBannerData,
  updateBannerData,
  updatePosition,
} from "../../../utils/ApiCalls/Banner";
import { addlog } from "../../../utils/ApiCalls/Logs";
import { checkPermission } from "../../../utils/Validation/checkPermission";



const Banner = () => {
  const navigate = useNavigate();
  const [{ userDetails, permission }] = useDataLayerValue();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Banner List",
      route: "/cms/homepage/banner",
    },
  ];
  const [active, setActive] = useState("listing");
  const [regionalActive, setRegionalActive] = useState("SG");
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [positionVal, setPositionVal] = useState([]);
  const [, dispatch] = useDataLayerValue();
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [status, setStatus] = useState("");
  const [refreshlog, setRefreshLog] = useState(true);

  const sortHandler = async (options) => {
    if (Object.keys(options).length > 0) {
      let sort, order;
      sort = options.columnKey;
      order =
        options.order === "ascend"
          ? "asc"
          : options.order === "descend"
          ? "desc"
          : undefined;
      setSort(sort);
      setOrder(order);
      await getData(1, sort, order);
    }
  };

  const setPositionalValue = (data) => {
    const mappedPositionVal = data.map((d) => ({
      id: d.id,
      pos: d.position,
    }));
    setPositionVal(mappedPositionVal);
  };

  const updateBannerPosition = async (banners) => {
    // e.preventDefault();
    if (checkPermission(permission, "update_per", "Banner")) {
      try {
        let args = { search: search, page: page, limit: pageSize };
        if (regionalActive) {
          args = { ...args, location: regionalActive };
        }
        if (status !== "") {
          args = { ...args, is_active: status };
        }

        const data = await updatePosition(banners, args);
        addlog(
          "Banner Position Updated",
          "Banner",
          { location_id: banners[0].location_id },
          userDetails.id
        );
        if (data.error) {
          // dispatch({
          //   type: "SET_ERROR_MESSAGE",
          //   errMess: { message: data.error, type: "error" },
          // });
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data.error,
          });
        } else if (data.data) {
          console.log("data====>", data.data);
          setRows(data.data);
          setCount(data.count);
          setPositionalValue(data.data);
        }
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      } catch (error) {
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message || "Unknown error occurred",
        });
      }
    } else {
      message.error("Update permission missing");
    }
  };

  const columns = [
    {
      title: "Dno",
      width: 10,
      dataIndex: "id",
      key: "position",
      fixed: "left",
      render: (position, record, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Image",
      width: 30,
      dataIndex: "image",
      key: "image",
      render: (image, record) => {
        if (record.content_type === "video") {
          return (
            <video
              src={`${constants.MEDIAURL}/${image}`}
              style={{ width: "100px", height: "80px" }}
            />
          );
        } else if (record.content_type === "link") {
          return (
            <YouTubeEmbed
              videoUrl={`${constants.MEDIAURL}/${image}`}
              width="100px"
              height="80px"
            />
          );
        } else {
          // Default to rendering an image
          return (
            <img
              src={`${constants.MEDIAURL}/${image}`}
              style={{ width: "100px", height: "80px" }}
            />
          );
        }
      },
    },
    {
      title: "Valid date",
      dataIndex: "valid_date",
      key: "valid_till",
      width: 10,

      render: (text, record, index) => {
        return <ValidDateUI record={record} handleUpdate={handleUpdate} />;
      },
      // sorter: (a, b) => "",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "english_title",
      sorter: (a, b) => "",
      width: 400,
      render: (text, record, index) => (
        <p dangerouslySetInnerHTML={{ __html: text }} />
      ),
    },
    {
      title: "Display Location",
      dataIndex: "location",
      key: "location",
      width: 20,
      sorter: (a, b) => "",
      render: (item) => <p>{item.location_name}</p>,
    },

    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: 30,
      sorter: (a, b) => "",
      render: (item, record, index) => (
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            checked={item ? true : false}
            onChange={(value) => {
              try {
                if (new Date(record.valid_from) > new Date()) {
                  throw new Error(`Banner is not yet started`);
                }

                if (new Date(record.valid_to) < new Date()) {
                  throw new Error(`Banner is expired`);
                }
                handleUpdate(
                  record.id,
                  {
                    is_active: value.target.checked ? 1 : 0,
                  },
                  record.title,
                  record.location_id
                );
              } catch (error) {
                dispatch({
                  type: "SET_LOADING",
                  loading: false,
                });

                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error.message || "Unknown error occurred",
                });
              }
            }}
            className="custom-control-input"
            id={"customSwitch1" + index}
          />
          <label
            className="custom-control-label"
            style={{}}
            for={"customSwitch1" + index}
          ></label>
        </div>
      ),
    },

    {
      title: "Action",
      key: "operation",
      width: 30,
      render: (text, record) => (
        <div className="table_action_btn">
          <div className="btn" onClick={() => handleEdit(record.id, record.title)}>
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>

          <DeletePop
            onConfirm={() =>
              handleDelete(record.id, record.title, record.location_id)
            }
          >
            <div>
              {" "}
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getData(page, sort, order);
  }, [page, pageSize, search]);

  useEffect(() => {
    getData(page, sort, order);
  }, [regionalActive]);

  const getData = async (value, sort, order) => {
    console.log(regionalActive);
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let args = { search: search, page: value, limit: pageSize };
    if (regionalActive) {
      args = { ...args, location: regionalActive };
    }
    if (status !== "") {
      args = { ...args, is_active: status };
    }
    if (order) {
      if (sort) {
        args = { ...args, sort };
      }
      args = { ...args, order };
    }

    let data = await getBannerData(args);
    if (data.error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.error,
      });
    } else if (data.data) {
      setRows(data.data);
      setCount(data.count);
      setPositionalValue(data.data);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  const handleEdit = async (id) => {
    if (checkPermission(permission, "update_per", "Banner")) {
      navigate("/cms/homepage/banner/edit/" + id);
    } else {
      message.error("Update permission missing");
    }
  };
  const handleDelete = async (id, title, location_id) => {
    if (checkPermission(permission, "delete_per", "Banner")) {
      deleteBannerData(id);
      addlog(
        "Banner Deleted",
        "Banner",
        { id, title, location_id },
        userDetails.id
      );
      setRefreshLog((item) => !item);
      getData(page, sort, order);
    } else {
      message.error("Missing Permission for delete ");
    }
  };

  const handleUpdate = async (id, values, title, location_id) => {
    if (checkPermission(permission, "update_per", "Banner")) {
      let data = await updateBannerData(id, values);
      if (data.error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.error,
        });
      } else {
        addlog(
          "Banner " +
            (!values.valid_from
              ? values.is_active === 1
                ? "Actived"
                : "Deactivated"
              : "updated"),
          "Banner",
          { ...values, id, title, location_id },
          userDetails.id
        );
      }
      setRefreshLog((item) => !item);
      getData(page, sort, order);
    } else {
      message.error("Update permission missing");
    }
  };

  return (
    <Layout type={"read_per"} module_data={"banner"}>
      <CustomHeaderTitle title={"Banner"} />

      <div>
        <div>
          <HeaderBread navigation={navigation} title={"Banner"}></HeaderBread>
        </div>

        <section className="content" style={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",

              flexDirection: "column",
            }}
          ></div>
          <div className="container-fluid">
            {" "}
            <SwitchBar
              tabs={[
                { title: "Banner List", value: "listing" },
                { value: "logs", title: " History Logs" },
              ]}
              activeTab={active}
              setActiveTab={setActive}
            />
            {active === "listing" && (
              <div className="card">
                <div className="card-header two_item">
                  <h5 className="card-title">Banner List</h5>
                  <div className="two_item" style={{ width: "350px" }}>
                    <ActiveInactive
                      getData={getData}
                      setStatus={setStatus}
                      status={status}
                      setPage={setPage}
                    />
                    <Link to="/cms/homepage/banner/add" className="btn add_v_btn">
                      Add Banner
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table_holder">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div style={{ maxWidth: 200 }}>
                        <SearchField
                          value={search}
                          SearchValue={() => {
                            setPage(1);
                          }}
                          setValue={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <SwitchBar
                      tabs={[
                        { title: "Singapore", value: "SG" },
                        { value: "MY", title: "Malaysia" },
                        { value: "ID", title: "Indonesia" },
                        { value: "IN", title: "India" },
                        { value: "CN", title: "China" },
                        { value: "TW", title: "Taiwan" },
                        { value: "HK", title: "Hong Kong" },
                        { value: "PH", title: "Philippines" },
                        { value: "AE", title: "UAE" },
                      

                        { value: "OTHER", title: "All Others" },
                      ]}
                      setPageSize={setPageSize}
                      module={"country-tab"}
                      setPage={setPage}
                      setRows={setRows}
                      setCount={setCount}
                      activeTab={regionalActive}
                      setActiveTab={setRegionalActive}
                      setSearch={setSearch}
                    />
                    {rows && (
                      <CustomizedDragNDrop
                        setRows={setRows}
                        setPageSize={setPageSize}
                        setPage={setPage}
                        pageSize={pageSize}
                        page={page}
                        count={count}
                        columns={
                          regionalActive !== "OTHER"
                            ? columns.filter((c) => c.key !== "location")
                            : columns
                        }
                        rows={rows}
                        title={"Banner"}
                        sortHandler={sortHandler}
                        updatePosition={updateBannerPosition}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}{" "}
          </div>
        </section>

        {active === "logs" && (
          <Logs
            type={"Banner"}
            slug={"/cms/homepage/banner/edit/"}
            refreshlog={refreshlog}
          />
        )}
      </div>
    </Layout>
  );
};

export default Banner;
