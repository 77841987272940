import { Breadcrumbs, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const HeaderBread = ({ title, navigation }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <div
        className="m-0"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {" "}
        <div
          onClick={() => {
            navigate(-1);
          }}
          style={{
            marginBottom: "10px",
            width: "40px",
            height: "40px",
            borderRadius: "100px",
            color: "red",
            border: "2px solid red",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "10px",
            cursor: "pointer",
          }}
        >
          <ArrowBackIcon></ArrowBackIcon>
        </div>{" "}
        <h3> {title}</h3>
      </div>
      <Breadcrumbs aria-label="breadcrumb">
        {navigation.map((item, index) =>
          index === navigation.length - 1 ? (
            <Typography key={index} color="text.primary">{item.title}</Typography>
          ) : (
            <Link key={index} underline="hover" color="inherit" to={item.route}>
              {item.title}
            </Link>
          )
        )}
      </Breadcrumbs>
    </div>
  );
};

export default HeaderBread;
