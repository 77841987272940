import React, { useState, useEffect } from "react";
import { Box, Chip, MenuItem, Select } from "@mui/material";

const Vessel = ({ vesselData, getData, setVesselId, setCabinCategoryId }) => {
//   const [options, setOption] = useState(vesselData || []);
  const [selectedValue, setSelectedValue] = useState("");
  useEffect(() => {
    getData(1);
  }, [selectedValue]);
//   console.log("vessel Data===>", options);
  return (
    <Select
      style={{ width: "15%", height: "40px", marginRight:"10px" }}
      required
      displayEmpty
      renderValue={selectedValue !== "" ? undefined : () => "Select Vessel"}
      value={selectedValue}
      onChange={(e) => {
        setVesselId(e.target.value);
        setSelectedValue(e.target.value);
        setCabinCategoryId("");
      }}
    >
      {vesselData.map((item, index) => (
        <MenuItem key={index} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default Vessel;
