import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";


export const uploadFile = async (file, imageType,real) => {
 
  try {
    const formData = new FormData();
    formData.append("file", file);
if(real)
    formData.append('real', real);
    formData.append('imageSubType', imageType?.subType)
    let res = await Api.post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res?.data;
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};


export const uploadMultipleFile = async (files) => {
  console.log(files);
  try {
    const formData = new FormData();
    // formData.append("file", files);
    files.forEach((file, index)=>{
      formData.append(`files`, file, file.name);
    })
  
    let res = await Api.post("/upload/multi", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res?.data;
  } catch (e) {
    console.log(e);
    return { error: "Something Wrong" };
  }
};

export const clearCache = async () => {

  try {
   
    let res = await Api.get("/upload/clearCache");
    
    return res?.data;
  } catch (e) {
    console.log(e);
    return { error: "Something Wrong" };
  }
};
