import React, { useEffect, useCallback, useMemo } from "react";
import _ from "lodash";

const CustomSubmit = React.memo(({ data1, data2, handleSubmit }) => {
  // Memoize the comparison result to prevent recalculations
  const hasChanges = useMemo(() => !_.isEqual(data1, data2), [data1, data2]);

  // Memoize the click handler to avoid re-creating the function
  const handleSave = useCallback(() => {
   
    handleSubmit();
  }, [ handleSubmit]);

  return (
    <div className="text-right" style={{ marginBottom: "10px" }}>
     
     <button onClick={handleSave} className="btn main_submit" style={{background:"green"}}>
        Publish
      </button>
    </div>
  );
});

export default CustomSubmit;
