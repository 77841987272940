import React from "react";
import "./app.css";
import { getImage } from "../../../../../utils/getImages";
const Card5Palace = ({ cardData, activetab }) => {
  return (
    <div>
      <div className="col-md-12 text-center py-5">
        <h2 className="title-color px-3 px-md-0 mt-10" style={{color:"gray"}}>
          {activetab === "english"
            ? cardData?.title
            : activetab === "simplified_chinese"
            ? cardData?.simplified_chinese_title
            : activetab === "arabic"
            ? cardData?.arabic_title
            : cardData?.traditional_chinese_title}
        </h2>
        <div
          id="icon-lists"
          className="row align-items-center"
          style={{ marginTop: "20px" }}
        >
          
          {cardData?.dataArray?.map((item) => (
            <div className="col-6 col-md-3">
                <div className="event-holder" style={{}}>
                {/* <img src="https://webpages.rwcruises.com/assets/img/about-us/balcony-class/1.jpg" style={{}} alt="" className="w-100 rounded"/> */}
                   
                <img
                  src={
                    getImage(item?.image)
                   }
              className="w-100 rounded"
                /> <div className="info">
                      <h5 style={{fontWeight:"600"}} dangerouslySetInnerHTML={{__html:activetab === "english"
                    ? item?.title
                    : activetab === "simplified_chinese"
                    ? item?.simplified_chinese_title
                    : activetab === "arabic"
                    ? item?.arabic_title
                    : item?.traditional_chinese_title}}/>
                    </div>
                </div>
          
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Card5Palace;
