import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";

export const LoginUser = async (data) => {
  try {
    let res = await Api.post("/users/login", data);
    if (res?.data?.message) {
      return { error: res.data.message };
    }
    return res?.data;
  } catch (e) {
    console.log(e);
    let error = modifyError(e);
    return { error: error.message };
  }
};
export const RegisterUser = async (data) => {
  try {
    let res = await Api.post("/users", data);
    return res?.data;
  } catch (e) {
    return { error: "Try With other email address" };
  }
};
export const ResetPassword = async (data) => {
  try {
    let res = await Api.post("/users/resetPassword", data);

    return res?.data;
  } catch (e) {
    console.log(e);
    return { error: "Something Wrong" };
  }
};

export const updatePassword = async (data) => {
  try {
    let res = await Api.post("/users/updatePassword", data);
    return res?.data;
  } catch (e) {
    console.log(e);
    return { error: "Something Wrong" };
  }
};
export const updateConfirmPassword = async (data) => {
  try {
    let res = await Api.post("/users/updateConfirmPassword", data);
    return res?.data;
  } catch (e) {
    console.log(e);
    return { error: "Something Wrong" };
  }
};

export const validateUserToken = async () => {
  try {
    let res = await Api.get("/users/validate");
    return res?.data;
  } catch (e) {
    console.log(e);
    return { error: "User not found" };
  }
};
