import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";

export const getPromoIndex = async (query) => {
  try {
    let res = await Api.get("/promo-index", {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const updatePromoIndex = async (id, data) => {
  try {
    let res = await Api.patch("/promo-index/" + id, data);
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};
