import EventAddCard from "../../../../Pages/WebPages/Events/EventAddCard";
import Card3Ship from "../Previews/Card3Ship/Preview";
import Event3 from "../Previews/Destination/3/Event3";
import Destination3 from "../Previews/Destination/3/Preview";
import EventDetailsCard from "../Previews/EventDetails/EventDetailsCard";
import ThumbnailPreview from "../Previews/Ship/ThumbnailPreview/Preview";

export const eventDetails = {
  form: [
    {
      title: "Page Name",
      key: "page_name",
      className: "col-lg-12",
      type: "input",
    },
    {
      title: "Meta Description",
      key: "meta_description",
      className: "col-lg-12",
      type: "input",
    },
  ],
  component: [
    {
      type: 1,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
        },
        {
          title: "English Thumbnail Image",
          key: "image",
          className: "col-lg-3",
          type: "file",
          placeholder: "Upload English Thumbnail Image",
        },
        {
          title: "Simplified Chinese Thumbnail Image",
          key: "simplified_chinese_image",
          className: "col-lg-3",
          placeholder: "Upload Simplified Chinese Thumbnail Image ",
          type: "file",
        },
        {
          title: "Traditional Chinese Thumbnail Image",
          key: "traditional_chinese_image",
          className: "col-lg-3",
          placeholder: "Upload Traditional Chinese Thumbnail Image ",
          type: "file",
        },
        {
          title: "Arabic Thumbnail Image",
          key: "arabic_image",
          className: "col-lg-3 arabic",
          type: "file",
          placeholder: "Upload Arabic Thumbnail Image ",
        },
        {
          title: "Start Date",
          key: "start_date",
          className: "col-lg-3",
          type: "date",
        },
        {
          title: "End Date",
          key: "end_date",
          className: "col-lg-3",
          type: "date",
        },
        {
          title: "Ship",
          key: "ship",
          className: "col-lg-3",
          type: "ship",
        },
      ],
      preview: (activetab, cardData) => (
        <ThumbnailPreview activetab={activetab} cardData={cardData} />
      ),
    },

    {
      type: 2,
      fields: [
        {
          title: "English Page Image",
          key: "image",
          className: "col-lg-3",
          type: "file",
          placeholder: "Upload English Page Image",
        },
        {
          title: "Simplified Chinese Page Image",
          key: "simplified_chinese_image",
          className: "col-lg-3",
          placeholder: "Upload Simplified Chinese Page Image ",
          type: "file",
        },
        {
          title: "Traditional Chinese Page Image",
          key: "traditional_chinese_image",
          className: "col-lg-3",
          placeholder: "Upload Traditional Chinese Page Image ",
          type: "file",
        },
        {
          title: "Arabic Page Image",
          key: "arabic_image",
          className: "col-lg-3 arabic",
          type: "file",
          placeholder: "Upload Arabic Page Image ",
        },
        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
        },
      ],
      level1: [
        {
          title: "Enter English Button title ",
          key: "title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Button title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese  Button title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic  Button title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
        },
        {
          title: "Enter country ",
          key: "country",
          className: "col-lg-6",
          type: "input",
        },

        {
          title: "Enter Link ",
          key: "link",
          className: "col-lg-6",
          type: "input",
        },
      ],
      preview: (activetab, cardData) => (
        <EventDetailsCard activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 6,
      fields: [
        {
          title: "English filter text",
          key: "filter_text",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese filter text",
          key: "simplified_chinese_filter_text",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese filter text",
          key: "traditional_chinese_filter_text",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic filter text",
          key: "arabic_filter_text",
          className: "col-lg-3 arabic",
          type: "input",
        },
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
         
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
         
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
         
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
         
        },
        {
          title: "English Note",
          key: "note",
          className: "col-lg-3",
          type: "html",
        
        },
        {
          title: "Simplified Chinese note",
          key: "simplified_chinese_note",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Traditional Chinese note",
          key: "traditional_chinese_note",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Arabic note",
          key: "arabic_note",
          className: "col-lg-3 arabic",
          type: "html",
        },
        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Show Events",
          key: "show_event",
          className: "col-lg-6",
          type: "select",
          option:[{
            id:"yes",
            name:"Yes"
          },{id:"no",name:"No"}]
        },
      ],
      level1: [
        {
          title: "Show Description",
          key: "show_desc",
          className: "col-lg-6",
          type: "select",
          option: [
            {
              id: "yes",
              name: "Yes",
            },
            { id: "no", name: "No" },
          ],
        },
        {
          title: "Show Menu",
          key: "show_menu",
          className: "col-lg-6",
          type: "select",
          option: [
            {
              id: "yes",
              name: "Yes",
            },
            { id: "no", name: "No" },
          ],
        },

        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
        },
      ],
      level2: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false
        },
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "html",
          required: false
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "html",
          required: false
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "html",
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "English Subdescription",
          key: "subdescription",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Simplified Chinese Subdescription",
          key: "simplified_chinese_subdescription",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Traditional Chinese Subdescription",
          key: "traditional_chinese_subdescription",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Arabic Subdescription",
          key: "arabic_subdescription",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "English Filter Name",
          key: "filter_name",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Filter Name",
          key: "simplified_chinese_filter_name",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Filter Name",
          key: "traditional_chinese_filter_name",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Filter Name",
          key: "arabic_filter_name",
          className: "col-lg-3 arabic",
          type: "input",
        },
        {
          title: "English tag",
          key: "tag",
          className: "col-lg-3",
          type: "input",
         
        },
        {
          title: "Simplified Chinese tag",
          key: "simplified_chinese_tag",
          className: "col-lg-3",
          type: "input",
        
        },
        {
          title: "Traditional Chinese tag",
          key: "traditional_chinese_tag",
          className: "col-lg-3",
          type: "input",
       
        },
        {
          title: "Arabic tag",
          key: "arabic_tag",
          className: "col-lg-3 arabic",
          type: "input",
         
        },
        {
          title: "Card Type",
          key: "type",
          className: "col-lg-3 arabic",
          type: "select",
          option: [{ id: "1", name: "Type1" }, { id: "2", name: "Type2" }, { id: "3", name: "Type3" }]
        },

      ],
      level3: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false
        }
      ],
      preview: (activetab, cardData) => (
        <Event3 activetab={activetab} cardData={cardData} />
      ),
    },
  
  ],
};
