import React from "react";
import "./app.css";
import { getImage } from "../../../../../../utils/getImages";
import { getLang } from "../../lang/getlang";
const PalaceCardPreview = ({ cardData, activetab }) => {
  return (
    <div>
      <div className="col-md-12  py-5">
        <div
          className="col-12 text-center mb-5"
          style={{ display: "flex", alignItems: "center", width: "100%" }}
        >
          <ul
            className="page-tab scroll-tab"
            style={{
              display: "flex",
              gap: "10px",

              listStyleType: "none",
              padding: 0,
              alignItems: "center",
              justifyContent: "center",
              margin: 0,
              width: "100%",
            }}
          >
            {cardData?.dataArray?.map((item, index) => (
              <li data-scroll-tab-seq={index + 1 + ""} style={{ textTransform: "uppercase" }} className="">
                {activetab === "english"
                  ? item?.title
                  : activetab === "simplified_chinese"
                    ? item.simplified_chinese_title
                    : activetab === "arabic"
                      ? item?.arabic_title
                      : item?.traditional_chinese_title}
              </li>
            ))}
          </ul>
        </div>

        <div
          id="icon-lists"
          className="row align-items-center"
          style={{ marginTop: "20px" }}
        >
          {cardData?.dataArray?.map((item) => (
            <div className="container-fluid py-3 py-md-5 ">
              <div className="container py-4">
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <img
                      src={
                        getImage(item?.image)
                      }
                      alt="Genting Dream"
                      className="w-100 rounded"
                    />
                  </div>

                  <div className="col-md-6 mt-4 mt-md-0 px-3 px-md-5">
                    <h1 className="title-color mb-4" style={{ color: "#E4BE83" }}>
                      {activetab === "english"
                        ? item?.title
                        : activetab === "simplified_chinese"
                          ? item.simplified_chinese_title
                          : activetab === "arabic"
                            ? item?.arabic_title
                            : item?.traditional_chinese_title}
                    </h1>
                    <p>
                      <div contentEditable='true' dangerouslySetInnerHTML={{
                        __html: activetab === "english"
                          ? item?.description
                          : activetab === "simplified_chinese"
                            ? item.simplified_chinese_description
                            : activetab === "arabic"
                              ? item?.arabic_description
                              : item?.traditional_chinese_description
                      }}></div>

                    </p>

                    <p className="download-itinerary" >

                      <a
                        className="langEN"
                        style={{ color: "#E4BE83", border: "1px solid #E4BE83" }}
                        href={getImage(activetab === "english"
                          ? item?.english_file
                          : activetab === "simplified_chinese"
                            ? item?.simplified_chinese_file
                            : item?.traditional_chinese_file)}
                        target="_blank"
                      >
                        {getLang("downloadPlace",activetab)}
                      </a>


                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}

        </div>
      </div>
    </div>
  );
};

export default PalaceCardPreview;
