import React from "react";
import LocationInput from "../../components/Location/LocationInput";
import HeaderBread from "../../components/HeaderBread";
import Layout from "../../Layout";
import { useNavigate } from "react-router-dom";
import CustomHeaderTitle from "../../components/constants/CustomHeaderTitle";

const CreateLocation = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Location List",
      route: "/cms/homepage/location",
    },
    {
      title: "Add Deal",
      route: "/cms/homepage/location/add",
    },
  ];

  return (
    <Layout type={"create_per"} module_data={"Location"}>
        <CustomHeaderTitle title={"Add Location"}/>
      <HeaderBread navigation={navigation} title={"Add Location"}></HeaderBread>
      <LocationInput location={{}} />
    </Layout>
  );
};

export default CreateLocation;
