import React, { useEffect, useState } from "react";
import { getWebpageByID } from "../../../utils/ApiCalls/WebPages";
import { getImage } from "../../../utils/getImages";
import moment from "moment";

const Preview = ({ cardData,activetab }) => {
  const [events, setEvents] = useState([]);
  const getData = async (ids) => {
    console.log({ ids });
    const res = await getWebpageByID({ ids:(ids||[]) });
    setEvents(res.data);
  };
  useEffect(() => {
    getData(cardData?.show);
  }, [cardData]);

  return <div>   <div
  className="row"
    style={{
      display: "flex",
      // alignItems: "center",
      flexWrap: "wrap",
      flexDirection:"row",
    }}
  >
  {events?.map((item) => (<div className="destination-package col-3" style={{marginBottom:"10px"}}>
    <img
     src={
      getImage(item?.image)
         }
     className="w-100 rounded"
    />

    <a href="">
     
      <div>
        <div className="info">
          <p className="place" style={{fontWeight:"bold",fontSize:"18px",marginBottom:"-3px"}} data-lang='{ "CN":"皇宫庭苑别墅" }'>
            
          {activetab === "english"
              ? item?.title
              : activetab === "simplified_chinese"
              ? item?.simplified_chinese_title
              : activetab === "arabic"
              ? item?.arabic_title
              : item?.traditional_chinese_title}
          </p>
          <p className="place" style={{fontWeight:"lighter",fontSize:"14px"}}>
            {moment(item.start_date).format("DD MMM")+"-"+moment(item.end_date).format("DD MMM YYYY")}
          </p>
        </div>

      </div>
    </a>
  </div>))}
</div></div>;
};

export default Preview;
