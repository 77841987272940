import Api from "../../Api";

export const getAgencies=async()=>{
    try {
        let res = await Api.get("/agency-master");
        // console.log("agemcies ===>", res.data)
        return (res?.data || [])
    }
    catch (e) {
        console.log(e);
        return ([])
    }
}