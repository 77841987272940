import { MenuItem, Select } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill"; // Import React Quill
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { IMAGE_SUB_TYPE, IMAGE_TYPE } from "../../constants/ImageConstant";
import LocationAndExpcept from "../../constants/LocationAndExpcept";
import { constants } from "../../constants/constants";
import {
  createBanner,
  deleteBannerData,
  getBannerDataLocation,
  updateBannerData,
} from "../../utils/ApiCalls/Banner";
import { uploadFile } from "../../utils/ApiCalls/Document";
import { getAllLocations } from "../../utils/ApiCalls/Locations";
import { addlog } from "../../utils/ApiCalls/Logs";
import { checkFields } from "../../utils/Validation/checkFields";
import UploadFileImageBanner2 from "./uploadFileImageBanner copy";


const BannerInputs = ({ banner }) => {
  const [englishTitle, setEnglishTitle] = useState(banner?.englishTitle || "");
  const [description, setDescription] = useState(banner?.description || "");
  const [simplifiedChineseTitle, setSimplifiedChineseTitle] = useState(
    banner?.simplifiedChineseTitle || ""
  );
  const [currentLocation, setCurrentLocation] = useState([]);
  const [traditionalChineseTitle, setTraditionalChineseTitle] = useState(
    banner?.traditionalChineseTitle || ""
  );
  const [arabicTitle, setArabicTitle] = useState(
    banner?.arabicTitle || ""
  );
  const [isActive, setIsActive] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState(banner?.title || "");
  const [displayLocation, setDisplayLocation] = useState([]);

  const [locationOption, setLocationOption] = useState([]);
  const [mediaOption, setMediaOption] = useState("image");
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [valid_from, setValidFrom] = useState();
  const [bannerUrlData, setBannerUrlData] = useState({});
  const [valid_to, setValidTo] = useState();
  const [traditional_chinese_image, setTraditional_chinese_image] =
    useState(null);
  const [simplified_chinese_image, setSimplified_chinese_image] =
    useState(null);
  const [arabic_image, setArabic_image] = useState(null);
  const [error, setError] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (banner) {
      if (banner.image) {
        getLocationData();
      }
      setTitle(banner?.title);
      setDescription(banner?.description);
      setSimplifiedChineseTitle(banner?.simplified_chinese_title);
      setTraditionalChineseTitle(banner?.traditional_chinese_title);
      setArabicTitle(banner?.arabic_title);
      setEnglishTitle(banner?.english_title);
      // setUrl(banner?.url);
      setMediaOption(banner?.content_type || "image");
      if (banner.location_id) setDisplayLocation([banner.location_id]);
      setIsActive(banner?.is_active === 1 ? true : false);

      setValidFrom(banner?.valid_from);
      setValidTo(banner?.valid_to);
    }
  }, [banner]);



  const getLocationData = async () => {
    let data = await getBannerDataLocation({ image: banner.image });

    if (data.error) {

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.error,
      });
    } else {
      setCurrentLocation(data);
      let val = {};
      console.log(data)
      data.map(item => {
        val[item.location_id] = item.url
      })
      console.log(val)
      setBannerUrlData(val)
      setDisplayLocation(data?.map((item) => item.location_id) || []);
    }
  };
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleEnglishTitleChange = (content) => {
    setEnglishTitle(content);
  };

  const handleSimplifiedChineseTitleChange = (content) => {
    setSimplifiedChineseTitle(content);
  };

  const handleTraditionalChineseTitleChange = (content) => {
    setTraditionalChineseTitle(content);
  };
  const handleArabicTitleChange = (content) => {
    setArabicTitle(content);
  };

  const handleCheckboxChange = () => {
    setIsActive(!isActive);
  };

  const handleDisplayLocationChange = (selectedOptions) => {
    setDisplayLocation(selectedOptions.target.value);
  };
  useEffect(() => {
    getOptionLocation();
  }, []);

  const getOptionLocation = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getAllLocations();

    if (data.error) {

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.error,
      });
    } else {
      setLocationOption(data?.data || []);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const addBanner = async (e) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    e.preventDefault();
    let dataValues = {

      title,
      content_type: mediaOption,
      description,
      order_by: 1,
      is_active: isActive ? 1 : 0,
      english_title: englishTitle,
      simplified_chinese_title: simplifiedChineseTitle,
      traditional_chinese_title: traditionalChineseTitle,
      arabic_title: arabicTitle,
      valid_from: valid_from,
      valid_to: valid_to,
    };

    try {
      if (selectedFile) {
        if (typeof selectedFile === "string") {
          dataValues.image = selectedFile.replace(constants.MEDIAURL + "/", "");
        } else {
          let imageType = {
            type: IMAGE_TYPE.BANNER_IMG,
            subType: IMAGE_SUB_TYPE.ENGLISH_IMG,
          };
          let image = await uploadFile(selectedFile, imageType);
          if (image?.error) throw new Error(image.error);
          dataValues.image = image?.url;
        }
      } else {
        dataValues.image = "";
      }
      if (simplified_chinese_image) {
        if (typeof simplified_chinese_image === "string") {
          dataValues.simplified_chinese_image = simplified_chinese_image.replace(constants.MEDIAURL + "/", "");;
        } else {
          let imageType = {
            type: IMAGE_TYPE.BANNER_IMG,
            subType: IMAGE_SUB_TYPE.SIMPLIFIED_CHINESE_IMG,
          };

          let image2 = await uploadFile(simplified_chinese_image, imageType);
          if (image2?.error) throw new Error(image2.error);
          dataValues.simplified_chinese_image = image2?.url;
        }
      } else {
        dataValues.simplified_chinese_image = "";
      }
      if (traditional_chinese_image) {
        if (typeof traditional_chinese_image === "string") {
          dataValues.traditional_chinese_image = traditional_chinese_image.replace(constants.MEDIAURL + "/", "");;
        } else {
          let imageType = {
            type: IMAGE_TYPE.BANNER_IMG,
            subType: IMAGE_SUB_TYPE.TRADITIONAL_CHINESE_IMG,
          };

          let image3 = await uploadFile(traditional_chinese_image, imageType);
          if (image3?.error) throw new Error(image3.error);
          dataValues.traditional_chinese_image = image3?.url;
        }
      }
      else {
        dataValues.traditional_chinese_image = "";
      }
      if (arabic_image) {
        if (typeof arabic_image === "string") {
          dataValues.arabic_image = arabic_image.replace(constants.MEDIAURL + "/", "");
        } else {
          let imageType = {
            type: IMAGE_TYPE.BANNER_IMG,
            subType: IMAGE_SUB_TYPE.ARABIC_IMG,
          };

          let image3 = await uploadFile(arabic_image, imageType);
          if (image3?.error) throw new Error(image3.error);
          dataValues.arabic_image = image3?.url;
        }
      } else {
        dataValues.arabic_image = "";
      }

      let requiredFields = [];
      if (mediaOption === "image") requiredFields = ["english_image"];
      if (mediaOption === "video") requiredFields = ["english_video"];
      if (mediaOption === "link") requiredFields = ["english_link"];

      const isChinaSelecetd = displayLocation.find(
        (location) =>
          locationOption.find((l) => location === l.id)?.location_code === "CN"
      );

      if (isChinaSelecetd) {
        if (mediaOption === "image")
          requiredFields.push(
            "traditional_chinese_image",
            "simplified_chinese_image",

          );
        if (mediaOption === "video")
          requiredFields.push(
            "traditional_chinese_video",
            "simplified_chinese_video"
          );
        if (mediaOption === "link")
          requiredFields.push(
            "traditional_chinese_link",
            "simplified_chinese_link"
          );
      }

      let valid = checkFields(dataValues, "banners", requiredFields);
      if (valid) {
      
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: valid,
        });
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
        // setError(valid);
      } else {
        try {
          setError();
          await Promise.all(
            currentLocation.map(async (item) => {
              if (!displayLocation.includes(item.location_id)) {

                await deleteBannerData(item.id);
              }
            })
          );
          await Promise.all(
            displayLocation.map(async (item, index) => {
              console.log(item,bannerUrlData[item])
              let values = {
                location_id: item,
                url: bannerUrlData[item],
                ...dataValues,
              };

              let data;
              if (banner?.id) {
                let locationValue = currentLocation.filter(
                  (data) => data.location_id === item
                );
                if (locationValue.length > 0) {
                  let { ...rest } = values;
                  if (item === banner.location_id) {
                    data = await updateBannerData(locationValue[0].id, values);
                  } else {
                    data = await updateBannerData(locationValue[0].id, rest);
                  }
                } else {
                  await createBanner({ ...values, recordIteration: index + 1 });
                }
              } else {
                values = {
                  ...values,
                  recordIteration: index + 1,
                };
                data = await createBanner(values);
              }

              if (data?.error) {
                throw new Error(data.error);
              } else {
                if (banner?.id) {
                  addlog(
                    "Banner Updated",
                    "Banner",
                    {
                      id: banner.id,
                      title: values.title || banner.title,
                      url:values.url || banner.url,
                      location_id: item,
                    },
                    userDetails.id
                  );
                } else {
                  addlog(
                    "Banner Created",
                    "Banner",
                    {
                      id: data.id,
                      title: data.title,
                      url: data.url,
                      location_id: item,
                    },
                    userDetails.id
                  );
                }
              }
            })
          );


          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
          if (!banner.id) {
            navigate("/cms/homepage/banner");
          } else {
            navigate("/cms/homepage/banner");
            dispatch({
              type: "SET_ERROR_MESSAGE",
              errMess: { message: "Values updated", type: "success" },
            });
          }
        } catch (error) {
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
          // dispatch({
          //   type: "SET_ERROR_MESSAGE",
          //   errMess: {
          //     message: error.message || "Unknown error occurred",
          //     type: "error",
          //   },
          // });
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message || "Unknown error occurred",
          });
        }
      }
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: {
      //     message: error.message || "Unknown error occurred",
      //     type: "error",
      //   },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message || "Unknown error occurred",
      });
    }
  };
  return (
    <div>
      <section className="content">
        {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )}

        <div className="container-fluid">
          <div className="card">
            <form className="input_form" onSubmit={addBanner}>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <LocationAndExpcept
                      displayLocation={displayLocation}
                      banner={banner}
                      locationOption={locationOption}
                      options={locationOption}
                      setDisplayLocation={setDisplayLocation}
                      handleDisplayLocationChange={handleDisplayLocationChange}
                    />

                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">Media Type</label>
                        <Select
                          required
                          style={{ width: "100%", height: "45px" }}
                          value={mediaOption}
                          onChange={(e) => {
                            setMediaOption(e.target.value);
                          }}
                        >
                          {["image", "video", "link"].map((item, index) => (
                            <MenuItem key={index} value={item}>
                              {item.split("")[0].toUpperCase() + item.slice(1, item.length)}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                  <UploadFileImageBanner2
                    className="col-lg-3"
                    image={
                      banner?.image
                        ? `${constants.MEDIAURL}/${banner?.image}`
                        : banner?.image
                    }
                    mandatory={"*"}
                    label={"English banner " + mediaOption}
                    placeholder={"Upload Banner"}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    mediaType={mediaOption}
                  />

                  <UploadFileImageBanner2
                    className="col-lg-3"
                    image={
                      banner?.simplified_chinese_image
                        ? `${constants.MEDIAURL}/${banner?.simplified_chinese_image}`
                        : banner?.simplified_chinese_image
                    }
                    mandatory={
                      displayLocation.find(
                        (location) =>
                          locationOption.find((l) => l.id === location)
                            ?.location_code === "CN"
                      )
                        ? "*"
                        : ""
                    }
                    label={"Simplified Chinese Banner " + mediaOption}
                    placeholder={
                      "Upload Simplified Chinese Banner"
                    }
                    selectedFile={simplified_chinese_image}
                    setSelectedFile={setSimplified_chinese_image}
                    mediaType={mediaOption}
                  />

                  <UploadFileImageBanner2
                    className="col-lg-3"
                    image={
                      banner?.traditional_chinese_image
                        ? `${constants.MEDIAURL}/${banner?.traditional_chinese_image}`
                        : banner?.traditional_chinese_image
                    }
                    label={"Traditional Chinese Banner " + mediaOption}
                    placeholder={
                      "Upload Traditional Chinese Banner"
                    }
                    mandatory={
                      displayLocation.find(
                        (location) =>
                          locationOption.find((l) => l.id === location)
                            ?.location_code === "CN"
                      )
                        ? "*"
                        : ""
                    }
                    selectedFile={traditional_chinese_image}
                    setSelectedFile={setTraditional_chinese_image}
                    mediaType={mediaOption}
                  />

                  <UploadFileImageBanner2
                    className="col-lg-3"
                    image={
                      banner?.arabic_image
                        ? `${constants.MEDIAURL}/${banner?.arabic_image}`
                        : banner?.arabic_image
                    }
                    label={"Arabic Banner " + mediaOption}
                    placeholder={
                      "Upload Arabic Banner"
                    }
                    selectedFile={arabic_image}
                    setSelectedFile={setArabic_image}
                    mediaType={mediaOption}
                  />

                  {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Url</label>
                      <input
                        className="f_input"
                        // required
                        type="text"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </div>
                  </div> */}
                  <div className="col-lg-8 col-md-6 col-sm-6 col-6">
                    {/* <div className="sailing_date "> */}
                    {/* <h4 className="sub_title2">Valid Date</h4> */}
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="fieldrow">
                          <label className="f_label">
                            {" "}
                            Valid From <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            required
                            value={valid_from}
                            onChange={(e) => {
                              setValidFrom(e.target.value);
                              setValidTo("");
                            }}
                            className="f_input"
                            type="date"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="fieldrow">
                          <label className="f_label">
                            Valid To <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            required
                            className="f_input"
                            value={valid_to}
                            onChange={(e) => {
                              setValidTo(e.target.value);
                            }}
                            min={moment(valid_from).format("YYYY-MM-DD")}
                            type="date"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-3"></div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">English Title</label>
                      <ReactQuill
                        value={englishTitle}
                        onChange={handleEnglishTitleChange}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                        Simplified Chinese Title
                      </label>
                      <ReactQuill
                        value={simplifiedChineseTitle}
                        onChange={handleSimplifiedChineseTitleChange}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                        Traditional Chinese Title
                      </label>
                      <ReactQuill
                        value={traditionalChineseTitle}
                        onChange={handleTraditionalChineseTitleChange}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                        Arabic Title
                      </label>
                      <ReactQuill
                        value={arabicTitle}
                        onChange={handleArabicTitleChange}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  {displayLocation.map(item => <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Url {locationOption.find((l) => item === l.id)?.location_name}</label>
                      <input
                        className="f_input"
                        // required
                        type="text"
                        value={bannerUrlData?.[item]}
                        onChange={(e) => setBannerUrlData(data => ({
                          ...data,
                          [item]: e.target.value,
                        }))}
                      />
                    </div>
                  </div>)}
                  <div className="col-12">
                    <div className="iti_inner">
                      <div className="icheck-primary d-inline">
                        <input
                          type="checkbox"
                          id="customCheckbox4"
                          name="r10"
                          checked={isActive}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="customCheckbox4">
                          {isActive ? (
                            <p>
                              <span style={{ fontWeight: 600 }}>Active</span>
                            </p>
                          ) : (
                            <p>
                              <span style={{ fontWeight: 200 }}>Active</span>
                            </p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-right">
                <button className="btn main_submit">
                  {banner.id ? "Update" : "Add"} Banner
                </button>
              </div>
            </form>
          </div>
          <div className="pt-3"></div>
        </div>
      </section>
    </div>
  );
};

export default BannerInputs;
