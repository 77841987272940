import { Component1 } from "./Component1";
import { Component2 } from "./Component2";
import { Component3 } from "./Component3";
import { Component4 } from "./Component4";

export const CustomComponents = {
    component: [{
        id: 1,
        title: "Banner 1",
        component: Component2
    }, {
        id: 2,
        title: "Banner 2",
        component: Component1
    },
    {
        id: 3,
        title: "Side Card 1",
        component: Component3
    },
    {
        id: 4,
        title: "List 1",
        component: Component4
    }

    ],
};
