import React, { useEffect, useState } from "react";
import "./app.css";
import { getImage } from "../../../../../utils/getImages";
const TablePreview = ({ cardData, activetab }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(cardData.dataArray);
  }, [cardData]);
  return (
    <div style={{ padding: "10px" }}>
      <div>
        <h1 className="title-color mb-4" data-lang='{ "CN":"客房" }'>
          {activetab === "english"
            ? cardData?.title
            : activetab === "simplified_chinese"
            ? cardData?.simplified_chinese_title
            : activetab === "arabic"
            ? cardData?.arabic_title
            : cardData?.traditional_chinese_title}
        </h1>
      </div>
      <div className="col-md-12 col-sm-12 prepare-cruise-bring row" style={{width:"100%"}}>
        <div className="box-bring " style={{width:"100%"}}>
          <div className="box-header-cruise text-center p-3">
            <h2> {activetab === "english"
            ? cardData?.subtitle
            : activetab === "simplified_chinese"
            ? cardData?.simplified_chinese_subtitle
            : activetab === "arabic"
            ? cardData?.arabic_subtitle
            : cardData?.traditional_chinese_subtitle}</h2>
          </div>
          <div className="box-bring-content" style={{width:"100%"}}>
            {Array(
              parseInt(((cardData?.dataArray?.length || 0) / 2).toFixed(0))
            )
              .fill()
              .map((item, index) => (
                <div className="box-bring-item">
                  <div className="box-bring-item-list d-flex  flex-direction-column box-item-left border-bottom">
                    <div className="d-flex align-items-center pt-3 pb-3 pl-4 pr-4 border-bottom box-bring-content-item">
                      <div className="prepare-cruise-bring-img text-center">
                        <img
                          className="thumbnail-image"
                          alt=""
                          src={getImage(data?.[index * 2]?.image)}
                        />
                      </div>
                      <div className="prepare-cruise-bring-description ml-4 bring-text">
                        <p className="m-0">
                          {activetab === "english"
                            ? data?.[index * 2]?.title
                            : activetab === "simplified_chinese"
                            ? data?.[index * 2]?.simplified_chinese_title
                            : activetab === "arabic"
                            ? data?.[index * 2]?.arabic_title
                            : data?.[index * 2]?.traditional_chinese_title}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="box-bring-item-list d-flex flex-direction-column box-item-right border-bottom">
                    <div className="d-flex align-items-center pt-3 pb-3 pl-4 pr-4 border-bottom box-bring-content-item">
                      <div className="prepare-cruise-bring-img text-center">
                        <img
                          className="thumbnail-image"
                          alt=""
                          src={getImage(data?.[index * 2 + 1]?.image)}
                           />
                      </div>
                      <div className="prepare-cruise-bring-description ml-4 bring-text">
                        <p className="m-0"> {activetab === "english"
                            ? data?.[index * 2 + 1]?.title
                            : activetab === "simplified_chinese"
                            ? data?.[index * 2 + 1]?.simplified_chinese_title
                            : activetab === "arabic"
                            ? data?.[index * 2 + 1]?.arabic_title
                            : data?.[index * 2 + 1]?.traditional_chinese_title}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TablePreview;
