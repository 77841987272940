import EventDetailsCard from "../../Previews/EventDetails/EventDetailsCard";

export const Component1 = {
    type: 2,
    fields: [
        {
            title: "English Page Image",
            key: "image",
            className: "col-lg-3",
            type: "file",
            placeholder: "Upload English Page Image",
        },
        {
            title: "Simplified Chinese Page Image",
            key: "simplified_chinese_image",
            className: "col-lg-3",
            placeholder: "Upload Simplified Chinese Page Image ",
            type: "file",
        },
        {
            title: "Traditional Chinese Page Image",
            key: "traditional_chinese_image",
            className: "col-lg-3",
            placeholder: "Upload Traditional Chinese Page Image ",
            type: "file",
        },
        {
            title: "Arabic Page Image",
            key: "arabic_image",
            className: "col-lg-3 arabic",
            type: "file",
            placeholder: "Upload Arabic Page Image ",
        },
        {
            title: "English Description",
            key: "description",
            className: "col-lg-3",
            type: "html",
        },
        {
            title: "Simplified Chinese Description",
            key: "simplified_chinese_description",
            className: "col-lg-3",
            type: "html",
        },
        {
            title: "Traditional Chinese Description",
            key: "traditional_chinese_description",
            className: "col-lg-3",
            type: "html",
        },
        {
            title: "Arabic Description",
            key: "arabic_description",
            className: "col-lg-3",
            type: "html",
        },
    ],
    level1: [
        {
            title: "Enter Button title ",
            key: "title",
            className: "col-lg-4",
            type: "input",
        },
        {
            title: "Enter country ",
            key: "country",
            className: "col-lg-4",
            type: "input",
        },

        {
            title: "Enter Link ",
            key: "link",
            className: "col-lg-4",
            type: "input",
        },
    ],
    preview: (activetab, cardData) => (
        <EventDetailsCard activetab={activetab} cardData={cardData} />
    ),
    sampleData: {
        "id": 306,
        "component_name": 1,
        "logo": "",
        "image": "1728922106517featured_EN(1).jpg",
        "image1": "",
        "image2": "",
        "image3": "",
        "image4": "",
        "image5": "",
        "dataArray": [],
        "arabic_file": "",
        "arabic_logo": "",
        "description": "<p><span style=\"color: rgb(0, 0, 0);\">Discover the rich flavors of Okinawa with a tasting of Awamori, a traditional spirit crafted</span></p><p><span style=\"color: rgb(0, 0, 0);\">from long-grain rice and aged to perfection. Savor its smooth, aromatic profiles and explore</span></p><p><span style=\"color: rgb(0, 0, 0);\">the cultural heritage of the Ryukyu Islands in a relaxed and inviting setting.</span></p>",
        "arabic_image": "1728922106958featured_EN(1).jpg",
        "english_file": "",
        "arabic_file_1": "",
        "arabic_file_2": "",
        "english_file_1": "",
        "english_file_2": "",
        "arabic_description": "<p><span style=\"color: rgb(0, 0, 0);\">Discover the rich flavors of Okinawa with a tasting of Awamori, a traditional spirit crafted</span></p><p><span style=\"color: rgb(0, 0, 0);\">from long-grain rice and aged to perfection. Savor its smooth, aromatic profiles and explore</span></p><p><span style=\"color: rgb(0, 0, 0);\">the cultural heritage of the Ryukyu Islands in a relaxed and inviting setting.</span></p>",
        "simplified_chinese_file": "",
        "simplified_chinese_logo": "",
        "simplified_chinese_image": "1728922107365featured_SC(1).jpg",
        "traditional_chinese_file": "",
        "traditional_chinese_logo": "",
        "simplified_chinese_file_1": "",
        "simplified_chinese_file_2": "",
        "traditional_chinese_image": "1728922107593featured_TC(1).jpg",
        "traditional_chinese_file_1": "",
        "traditional_chinese_file_2": "",
        "simplified_chinese_description": "<p><span style=\"color: rgb(0, 0, 0);\">踏上品味沖绳传统酒泡盛的旅程，这款由长粒米酿制并经过精心熟成的美酒，带来顺滑且芬芳的风味。</span></p><p><span style=\"color: rgb(0, 0, 0);\">在轻松愉悦的氛围中，感受其顺滑芳香的风味，探索琉球群岛丰富的文化遗产。</span></p>",
        "traditional_chinese_description": "<p>踏上品味沖繩傳統酒泡盛的旅程，這款由長粒米釀製並經過精心熟成的美酒，帶來順滑且芬芳的風味。</p><p>在輕鬆愉悅的氛圍中，感受其順滑芳香的風味，探索琉球群島豐富的文化遺產。</p>"
    }
}