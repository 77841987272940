import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../Layout";
import HeaderBread from "../../../components/HeaderBread";

import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import SearchField from "../../../components/SearchField";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";

import CustomizedTable from "../../../components/UI/CustomizedTable";
import SwitchBar from "../../../components/Pages/SwitchBar";
import Logs from "../../../components/Logs";
import {
  deleteCabinTypes,
  getCabinTypes,
  updateCabinTypes,
} from "../../../utils/ApiCalls/CabinTypes";
import { addlog } from "../../../utils/ApiCalls/Logs";
import DeletePop from "../../../constants/ErrorPermissionModal";
import { message } from "antd";
import { checkPermission } from "../../../utils/Validation/checkPermission";

const CabinTypes = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Cabin Types",
      route: "/masters/cabin-types",
    },
  ];
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [active, setActive] = useState("listing");
  const [{ userDetails, permission }, dispatch] = useDataLayerValue();

  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const sortHandler = async (options) => {
    if (Object.keys(options).length > 0) {
      let sort, order;
      sort = options.columnKey;
      order =
        options.order === "ascend"
          ? "asc"
          : options.order === "descend"
          ? "desc"
          : undefined;
      await getData(1, sort, order);
    }
  };

  const columns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (text, record, index) => (page - 1) * 10 + index + 1,
    },

    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => "",
      width: 50,
    },

    {
      title: "Display Name",
      dataIndex: "display_name",
      key: "display_name",
      sorter: (a, b) => "",
      width: 50,
    },

    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: 30,
      sorter: (a, b) => "",
      render: (item, record, index) => (
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            checked={item ? true : false}
            onChange={(value) => {
              try {
                if (new Date(record.valid_from) > new Date()) {
                  throw new Error(`Banner is not yet started`);
                }

                if (new Date(record.valid_to) < new Date()) {
                  throw new Error(`Banner is expired`);
                }
                handleUpdate(
                  record.id,
                  {
                    is_active: value.target.checked ? 1 : 0,
                  },
                  record.title,
                  record.location_id
                );
              } catch (error) {
                dispatch({
                  type: "SET_LOADING",
                  loading: false,
                });
                dispatch({
                  type: "SET_ERROR_MESSAGE",
                  errMess: {
                    message: error.message || "Unknown error occurred",
                    type: "error",
                  },
                });
              }
            }}
            className="custom-control-input"
            id={"customSwitch1" + index}
          />
          <label
            className="custom-control-label"
            style={{}}
            for={"customSwitch1" + index}
          ></label>
        </div>
      ),
    },
    {
      title: "Action",
      key: "operation",
      width: 40,
      render: (text, record) => (
        <div className="table_action_btn">
          <div className="btn" onClick={() => handleEdit(record.id)}>
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <DeletePop onConfirm={() => handleDelete(record.id)}>
            <div>
              {" "}
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getData(page);
  }, [page, pageSize, search]);

  const handleUpdate = async (id, values, title, location_id) => {
    let data = await updateCabinTypes(id, values);
    if (data.error) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    } else {
      addlog(
        "CabinTypes " + (values.is_active === 1 ? "Actived" : "Deactivated"),
        "CabinTypes",
        { ...values, id, title, location_id },
        userDetails.id
      );
    }
    getData(page, sort, order);
  };
  const getData = async (value, sort, order) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let args = { search: search, page: value, limit: pageSize };
    if (order) {
      if (sort) {
        args = { ...args, sort };
      }
      args = { ...args, order };
    }

    let data = await getCabinTypes(args);
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.data) {
      console.log({ data });
      setRows(data.data);
      setCount(data.count);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  const handleEdit = async (id) => {
    if (checkPermission(permission, "update_per", "Cabin Types")) {
      navigate("/masters/cabin-types/edit/" + id);
    } else {
      message.error("Update permission missing");
    }
  };
  const handleDelete = async (id) => {
    if (checkPermission(permission, "delete_per", "Cabin Types")) {
      deleteCabinTypes(id);
      getData();
    } else {
      message.error("Missing Permission for delete ");
    }
  };

  return (
    <Layout type={"read_per"} module_data={"Cabin Types"}>
      <CustomHeaderTitle title={"Cabin Types"} />
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"Cabin Types"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <SwitchBar
              tabs={[
                { title: "Cabin Types List", value: "listing" },
                { value: "logs", title: " History Logs" },
              ]}
              activeTab={active}
              setActiveTab={setActive}
            />
            {active === "listing" && (
              <div className="card">
                <div className="card-header two_item">
                  <h5 className="card-title">Cabin Types List</h5>
                  <Link to="/masters/cabin-types/add" className="btn add_v_btn">
                    Add Cabin Types
                  </Link>
                </div>
                <div className="card-body">
                  <div className="table_holder">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div style={{ maxWidth: 200 }}>
                        <SearchField
                          value={search}
                          SearchValue={() => {
                            setPage(1);
                          }}
                          setValue={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {rows && (
                      <CustomizedTable
                        setRows={setRows}
                        setPageSize={setPageSize}
                        setPage={setPage}
                        pageSize={pageSize}
                        page={page}
                        count={count}
                        columns={columns}
                        rows={rows}
                        title={"Cabin Types"}
                        sortHandler={sortHandler}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        {active === "logs" && (
          <Logs
            type={"CabinTypes"}
            slug={"/masters/cabin-types/edit/"}
            refreshlog={active}
          />
        )}
      </div>
    </Layout>
  );
};

export default CabinTypes;
