import { FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import DyanmicFrom from "../../../DyanmicForm/DyanmicFrom";
import DataCard from "../DataCard";
import FromData from "../DealCard/FormCard";
import FormCard1 from "../DealCard/FormCard1";
import { message } from "antd";
import CustomSubmit from "./CustomSubmit";

const Custom2StageForm = ({
  preview,
  dataCard,
  setDataCard,
  children,
  error,
  oldData,
  rootData,
  updateComponentData, page_id,
  level2,
  level1,
}) => {
  const [activeSection, setActiveSection] = useState();
  const [active, setActive] = useState(false);

  const [cardData, setCardData] = useState({
    image: null,
    title: "",
    simplified_chinese_title: "",
    traditional_chinese_title: "",
  });
  useEffect(() => {
    if (oldData) setDataCard(oldData);
  }, [oldData]);


  useEffect(() => {
    setActive(preview);
  }, [preview]);

  const addData = (index, cardData, type) => {
    let array = [];
    if (type) {
      array = [...dataCard.dataArray];
      array[index] = cardData;
    } else if (dataCard.dataArray) array = [...dataCard.dataArray, cardData];
    else array = [cardData];
    setDataCard({ ...dataCard, dataArray: array });
    if (type) {
      message.success("data updated click on publish to publish changes");
    } else {
      message.success("Created");
    }
  };

  const handleChange = (event) => {
    setActive(event.target.checked);
    console.log("Switch changed:", event.target.checked);
  };
  return (
    <div className="card">
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        {" "}
        <FormControlLabel
          control={<Switch checked={active} onChange={handleChange} />}
          label="Preview"
        />
      </div>

      {active && children}
      {!active && (
        <div className="card-body">
          <div className="row">
            <DyanmicFrom
              error={error}
              rootData={rootData}
              level={1}
              attributes={level1 || []}
              data={dataCard}
              setData={(e) => {
                setDataCard(e);
              }}
            />
          </div>
          {dataCard?.dataArray?.map((item, index) => {
            return (
              <DataCard
                color="#f7f7f7"
                title={item?.title}
                activeDrop={activeSection}
                rootData={rootData}
                setActiveDrop={setActiveSection}
                index={index}
                array={dataCard?.dataArray}
                setArray={(e) => {
                  setDataCard({ ...dataCard, dataArray: e });
                }}
              >
                <FromData
                  attributes={level2}
                  active={activeSection == index}
                  data={item}
                  updateData={addData}
                  rootData={rootData}
                  index={index + 1}
                />
              </DataCard>
            );
          })}
          <FormCard1 button={"Card"} add updateData={addData} />
          {page_id && <CustomSubmit data1={dataCard} data2={oldData} handleSubmit={() => updateComponentData(dataCard)} />
          }
        </div>
      )}
    </div>
  );
};

export default Custom2StageForm;
