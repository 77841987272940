import React from "react";
import { getLang } from "../../lang/getlang";
import "./app.css";
const MailPreview = ({ cardData, activetab }) => {
  return (
    <div>
      <div
        className="info"
        style={{
          background: "#CB001F",
          padding: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ maxWidth: "90%" }}>
          <h3 style={{ color: "white", fontWeight: "bold" }}>
            {getLang("emailtext1",activetab)}
            <a href="mailto:sg.corporate@rwcruises.com?subject=MICE web enquiry">
              <span style={{ color: "#E5BF83" }}>
               {cardData?.email1}
              </span>
            </a>{" "}
            /<br />
            <a href="mailto:my.corporate@rwcruises.com?subject=MICE web enquiry">
              <span style={{ color: "#E5BF83" }} className="corporate">{cardData?.email2}</span>
            </a>{" "}
           {getLang("emailtext2",activetab)}
          </h3>
        
        </div>
      </div>
    </div>
  );
};

export default MailPreview;
