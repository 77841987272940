import Api from "../../Api";

export const createUsers = async (data) => {

  try {
    let res = await Api.post("/users", data);
    return res?.data;
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const getUsers = async (query) => {
  try {
    let res = await Api.get("/users", {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const exportUsers = async (query) => {
  try {
    let res = await Api.get("/users/export", {
      responseType:"arraybuffer"
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const deleteUsers = async (id) => {
  try {
    let res = await Api.delete("/users/" + id);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updateUsers = async (id, data) => {
  try {
    let res = await Api.patch("/users/" + id, data);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to Update" };
  }
};
