import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  LoginUser,
  RegisterUser,
  updatePassword,
} from "../utils/ApiCalls/UserCalls";
import { useDataLayerValue } from "../DataLayer/DataLayer";
import CustomHeaderTitle from "../components/constants/CustomHeaderTitle";
import { getPermission } from "../utils/ApiCalls/Permission";

const Register = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [, dispatch] = useDataLayerValue();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    console.log({ token });
  }, [token]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await updatePassword({ token, password, confirmPassword });
    let value = await getPermission({ search: data.data?.role });
    if (data.message)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.message, type: "success" },
      });
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.data) {
      localStorage.setItem("token", data.token);
      dispatch({
        type: "SET_USER_DETAILS",
        userDetails: data.data,
      });
      dispatch({
        type: "SET_PERMISSION",
        permission: value?.data?.[0],
      });
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
      navigate("/");
    }
  };
  return (
    <div className="wrapper login_wrap">
      <img className="bg_img" src="./dist/img/login_bg.jpg" alt="" />
      <CustomHeaderTitle title={"Reset Password"} />
      <div className="login_inner">
        <div className="login-box">
          <div className="box_inner">
            <img className="box_img" src="./dist/img/logo_login.png" alt="" />
            <h4 className="login_title">Create Password</h4>
            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  className="form-control"
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <i className="ri-lock-password-line"></i>
                  </div>
                </div>
              </div>
              <div className="input-group mb-3">
                <input
                  className="form-control"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <i className="ri-lock-password-line"></i>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <button type="submit" className="btn btn_sign">
                    Sign In
                  </button>
                </div>
              </div>
            </form>

            {error && (
              <p className="mb-1">
                <p style={{ color: "red" }}>{error}</p>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
