import Api from "../../Api";

export const getCountries = async()=>{
    try {
        let res = await Api.get("/countries");
        console.log("===>", res.data)
        return (res?.data || [])
    }
    catch (e) {
        console.log(e);
        return ([])
    }
}