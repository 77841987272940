import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import EditIcon from "@mui/icons-material/Edit";
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeletePop from "../../constants/ErrorPermissionModal";
const ActionDropBar = ({
  title,
  handleDelete,
  active,
  setActive,
  sub,
  handleEdit,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
        alignItems: "center",
        borderBottom: "1px solid lightgray",
      }}
    >
      <div style={{display:"flex",alignItems:"center",gap:"10px"}}>
        <div
        onClick={() => setActive(!active)}
          style={{
            border: "2px solid black",
            borderRadius: "100px",cursor: "pointer",
          }}
        >
            {active ? <ArrowDropUpIcon></ArrowDropUpIcon> : <ArrowDropDownIcon />}
         
        </div>   <div
        style={{
          display: "flex",
          fontWeight: "bold",
        }}
      >
          <div className="datatite" style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}} dangerouslySetInnerHTML={{
                            __html:title
                          }}
                        />
   
      </div>{" "}
      </div>{" "}
   
      <div
        style={{
          display: "flex",
          fontWeight: "bold",
        }}
      >
        {sub && (
          <div style={{ cursor: "pointer" }} onClick={handleEdit}>
            <EditIcon />
          </div>
        )}
        <div style={{ cursor: "pointer",color:"#9f9f9f" }} >
        <DeletePop
            onConfirm={handleDelete}
          > <DeleteIcon  style={{color:"#9f9f9f"}}/></DeletePop>
        </div>
     
      </div>
    </div>
  );
};

export default ActionDropBar;
