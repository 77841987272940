import React, { useEffect, useState } from "react";
import Register from "../../Register";
import { useParams } from "react-router-dom";
import { getLeadsData } from "../../../utils/ApiCalls/Leads";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import { getUsers } from "../../../utils/ApiCalls/Users";

const EditUser = () => {
  const params = useParams();
  const [leads, setLeads] = useState({});
  const [{ userDetails }, dispatch] = useDataLayerValue();
  useEffect(() => {
    getData(params?.id);
  }, [params]);
  const getData = async (value) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getUsers({ id: value });

    console.log({ value });
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.count === 1) {
      setLeads(data.data[0]);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return <Register  user={leads}/>;
};

export default EditUser;
