import React, { useEffect, useState } from "react";
import "./app.css";
import { getImage } from "../../../../../utils/getImages";
const KnowPreview = ({ cardData, activetab }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(cardData.dataArray);
  }, [cardData]);
  return (
    <div style={{ padding: "10px" }}>
      <h1 className="title-color mb-4" style={{ textAlign: "center" }}>
        {activetab === "english"
          ? cardData?.title
          : activetab === "simplified_chinese"
          ? cardData?.simplified_chinese_title
          : activetab === "arabic"
          ? cardData?.arabic_title
          : cardData?.traditional_chinese_title}
      </h1>
      <h2
        style={{ textAlign: "center", fontSize: "16px", marginBottom: "20px" }}
      >
        {" "}
        {activetab === "english"
          ? cardData?.subtitle
          : activetab === "simplified_chinese"
          ? cardData?.simplified_chinese_subtitle
          : activetab === "arabic"
          ? cardData?.arabic_subtitle
          : cardData?.traditional_chinese_subtitle}
      </h2>
      {cardData?.dataArray?.map((item) => (
        <div
          className="row"
          style={{ border: "1px solid black", borderRadius: "10px" }}
        >
          <div className="col-md-12">
            <div className="destination-card">
              <div className="info mt-4 ms-4">
                <h3 className="title-color">
                  {activetab === "english"
                    ? item?.title
                    : activetab === "simplified_chinese"
                    ? item?.simplified_chinese_title
                    : activetab === "arabic"
                    ? item?.arabic_title
                    : item?.traditional_chinese_title}
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      activetab === "english"
                        ? item?.description
                        : activetab === "simplified_chinese"
                        ? item?.simplified_chinese_description
                        : activetab === "arabic"
                        ? item?.arabic_description
                        : item?.traditional_chinese_description,
                  }}
                />
                <img
                  src={getImage(activetab === "english"
                    ? item?.image
                    : activetab === "simplified_chinese"
                    ? item?.simplified_chinese_image
                    : activetab === "arabic"
                    ? item?.arabic_image
                    : item?.traditional_chinese_image)}
                  style={{ maxWidth: "30%" }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <img
              src={getImage(item.logo)}
              className="w-100 d-block"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default KnowPreview;
