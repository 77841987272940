import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import moment from "moment";
import React, { useState } from "react";
import Swal from "sweetalert2";
import DateAndTimePicker from "../components/DateAndTimePicker";
import { useDataLayerValue } from "../DataLayer/DataLayer";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  p: 4,
};

function isHTML(str) {
  var doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

const ValidDateUI = ({ record, handleUpdate }) => {
 
  const [valid_from, setValidFrom] = useState(record?.valid_from);
  const [valid_to, setValidTo] = useState(record?.valid_to);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [, dispatch] = useDataLayerValue();


  return (
    <>
   
      <div style={{ display: "flex" }}>
        <p style={{ padding: "8px" }}>{`${moment(record.valid_from).format(
          "DD/MM/YYYY"
        )} - ${moment(record.valid_to).format("DD/MM/YYYY")}`}</p>
        <div
          className="btn"
          onClick={() => {
            handleOpen();

          }}
        >
          <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
        </div>
      </div>

      <Modal
        open={open}
        onClose={(e) => {
          handleClose();
      
        }}
        sx={{ border: "none" }}
      >
        <Box sx={style}>
          {isHTML() ? (
            <h4
              style={{ fontWeight: "bold" }}
              dangerouslySetInnerHTML={{ __html: record.english_title }}
            />
          ) : (
            <h4 style={{ fontWeight: "bold" }}>{record.title}</h4>
          )}
          <form className="input_form">
            <div className="row">
              <div className="col-lg-12 col-md-6 col-sm-6 col-6">
                <div className="fieldrow">
                  <label className="f_label"> Valid From</label>

                  {/* <input
                    required
                    value={valid_from}
                    onChange={(e) => {
                      setValidFrom(e);
                      setValidTo("");
                    }}
                    max={record.to_date && moment(record.to_date)
                      .format("YYYY-MM-DD")}
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    type="date"
                    className="f_input"
                    // type="date"
                  /> */}
                  <DateAndTimePicker
                    required
                    value={valid_from}
                    setValue={(e) => {
                      setValidFrom(e);
                      setValidTo("");
                    }}
                    max={
                      record.to_date &&
                      moment(record.to_date).format("YYYY-MM-DD")
                    }
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    type="date"
                    className="f_input"
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-6 col-sm-6 col-6">
                <div className="fieldrow">
                  <label className="f_label">Valid To</label>
                  {/* <input
                    required
                    type="date"
                    className="f_input"
                    value={valid_to}
                    onChange={(e) => {
                      setValidTo(e.target.value);
                    }}
                    max={record.to_date && moment(record.to_date).format("YYYY-MM-DD")}
                    min={moment(valid_from).format("YYYY-MM-DD")}
                  /> */}
                  <DateAndTimePicker
                    required
                    type="date"
                    className="f_input"
                    value={valid_to}
                    setValue={(e) => {
                      setValidTo(e);
                    }}
                    max={
                      record.to_date &&
                      moment(record.to_date).format("YYYY-MM-DD")
                    }
                    min={moment(valid_from).format("YYYY-MM-DD")}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: "10%",
              }}
            >
              <Button
                variant="contained"
                onClick={(e) => {
                  handleClose();
                  try {
                    if (!moment(valid_from).isValid()) {
                      throw new Error("Please provide valid_from date");
                    }
                    if (!moment(valid_to).isValid()) {
                      throw new Error("Please provide valid_to date");
                    }

                    handleUpdate(
                      record.id,
                      {  valid_from: new Date(valid_from),
                        valid_to: new Date(valid_to),},
                      record.title,
                      record.location_id
                    );
                
                  } catch (error) {
                    dispatch({
                      type: "SET_LOADING",
                      loading: false,
                    });
                 
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: error.message,
                    });
                  }
                }}
              >
                Save
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
      {/* </form> */}
    </>
  );
};

export default ValidDateUI;
