import React from "react";
import { useParams } from "react-router-dom";
import { ships } from "../../../components/template/CustomTemplate/Pages/Ships";
import CustomTemplates from "../Deals/CustomTemplate";
import { Mediaandresource } from "../../../components/template/CustomTemplate/Pages/MediaandResource";

const MediaAndResource = () => {
  const params = useParams();

  return (
    <CustomTemplates slug={"press-releases"}  attributes={Mediaandresource} />
  );
};

export default MediaAndResource;
