import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";

export const getLocationData = async (query) => {
  try {
    let res = await Api.get("/location", {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

// export const getFare = async (query) => {
//   try {
//     let res = await Api.get("/packages/fares", {
//       params: query,
//     });
//   
//     return res?.data || [];
//   } catch (e) {
//     let error = modifyError(e);
//     return { error: error.message };
//   }
// };

export const getLocationDataById = async (id) => {
  try {
    let res = await Api.get("/location/" + id);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const createLocationData = async (data) => {
  try {
    let res = await Api.post("/location", data);
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const updateLocationStatus = async (id, data) => {
  try {
    let res = await Api.patch("/location/" + id, data);
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const updateLocationData = async (id, data) => {
  try {
    let res = await Api.patch("/location/" + id, data);
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const deleteLocationData = async (id) => {
  try {
    let res = await Api.delete("/location/" + id);
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};
