import React, { useState, useEffect } from "react";
import { Box, Chip, MenuItem, Select } from "@mui/material";

const MarketCode = ({ data, getData, setLocationId, locationId, setPage }) => {
//   const [options, setOption] = useState(vesselData || []);
  const [selectedValue, setSelectedValue] = useState(locationId);
  useEffect(() => {
    getData(1);
    setPage(1);
  }, [selectedValue]);
  console.log("market-code-data====>", data, locationId);
  return (
    <Select
      style={{ width: "10%", height: "40px", marginRight:"10px" }}
      required
      displayEmpty
      renderValue={locationId !== "" ? undefined : () => "Select Location"}
      value={selectedValue}
      onChange={(e) => {
        console.log("val===>", e.target.value)
        setLocationId(e.target.value);
        setSelectedValue(e.target.value);
      }}
    >
      {data.map((item, index) => (
        <MenuItem key={index} value={item.id}>
          {item.location_name} {item?.agency?.marketCode? `(${item?.agency?.marketCode})`: ""}
        </MenuItem>
      ))}
    </Select>
  );
};

export default MarketCode;
