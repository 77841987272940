import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../Layout";
import HeaderBread from "../../../components/HeaderBread";
import CustomizedTables from "../../../components/Tables/BasicTable";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";

import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import {
  deleteNewsLetter,
  getNewsLetter,
  exportNewsletter,
} from "../../../utils/ApiCalls/NewsLetter";
import CustomizedTable from "../../../components/UI/CustomizedTable";
import * as XLSX from "xlsx";
import moment from "moment";
import DeletePop from "../../../constants/ErrorPermissionModal";
import { checkPermission } from "../../../utils/Validation/checkPermission";
import { message } from "antd";

const NewsLetter = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Newsletter",
      route: "/cms /news-letter",
    },
  ];
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [{ permission }, dispatch] = useDataLayerValue();
  const columns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (position, record, index) =>((page - 1) * pageSize) + index + 1,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 50,
    },

    {
      title: "Location",
      dataIndex: "location_name",
      key: "location_name",
      width: 50,
      render: (item) => <p>{item}</p>,
    },

    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 50,
      render: (item) => (
        <p> {moment(item).local().format("MMMM DD,YYYY hh:mm a")}</p>
      ),
    },

    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (text, record) => (
        <div className="table_action_btn">
          <div className="btn" onClick={() => handleEdit(record.id)}>
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <DeletePop onConfirm={() => handleDelete(record.id)}>
            <div>
              {" "}
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getData(page);
  }, [page, pageSize]);

  const getData = async (value) => {
    let data = await getNewsLetter({ page: value, limit: pageSize });
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.data) {
      setRows(data.data);
      setCount(data.count);
    }
  };
  const handleEdit = async (id) => {
    if (checkPermission(permission, "update_per", "NewsLetter")) {
      navigate("/cms/news-letter/edit/" + id);
    } else {
      message.error("Update permission missing");
    }
  };
  const handleDelete = async (id) => {
    if (checkPermission(permission, "delete_per", "NewsLetter")) {
      deleteNewsLetter(id);
      getData();
    } else {
      message.error("Missing Permission for delete");
    }
  };

  const handleDownload = async (e) => {
    if (checkPermission(permission, "download_per", "NewsLetter")) {
      try {
        const response = await exportNewsletter();
        const blob = new Blob([response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "newsletter.xlsx";

        // Append the link to the body and trigger the download
        document.body.appendChild(a);
        a.click();

        // Remove the link from the body
        document.body.removeChild(a);
      } catch (error) {
        console.error("Error downloading Excel file:", error);
      }
    } else {
      message.error("Download permission missing");
    }
  };

  return (
    <Layout type={"read_per"} module_data={"Newsletter"}>
      <CustomHeaderTitle title={"Newsletter"} />
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"Newsletter"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <div className="card">
              <div className="card-header two_item">
                <h5 className="card-title">Newsletter List</h5>
                <div className="two_item">
                  {/* <Link to="/cms/news-letter/add" className="btn add_v_btn mx-2">
                    Add Newsletter
                  </Link> */}

                  <Link
                    onClick={(e) => handleDownload(e)}
                    className="btn export_v_btn mx-2"
                  >
                    Export Newsletter
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <div className="table_holder">
                  <CustomizedTable
                    setRows={setRows}
                    setPageSize={setPageSize}
                    setPage={setPage}
                    pageSize={pageSize}
                    page={page}
                    count={count}
                    columns={columns}
                    rows={rows}
                    title={"NewsLetter"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default NewsLetter;
