import ArabicTerms from "../Previews/Ship/ArabicTerms/Preview";
import SimplifiedTerms from "../Previews/Ship/SmplofiedTerms/Preview";
import TermsPreview from "../Previews/Ship/Terms/Preview";
import TreditionalTerms from "../Previews/Ship/TreditionalTerms/Preview";

export const PassageTermsPage = {
  form: [
    {
      title: "Page Name",
      key: "page_name",
      className: "col-lg-12",
      type: "input",
    },
    {
      title: "Meta Description",
      key: "meta_description",
      className: "col-lg-12",
      type: "input",
    },
  ],
  component: [
    {
      type: 1,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-12",
          type: "editor",
          required: false,
        }
      ],
      preview: (activetab, cardData) => (
        <TermsPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 1,
      fields: [
     
       {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-12",
          type: "editor",
          required: false,
        }],
      preview: (activetab, cardData) => (
        <SimplifiedTerms activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 1,
      fields: [
       
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-12",
          type: "editor",
          required: false,
        }
      ],
      preview: (activetab, cardData) => (
        <TreditionalTerms activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 1,
      fields: [
     
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-12",
          type: "editor",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <ArabicTerms activetab={activetab} cardData={cardData} />
      ),
    },
    
  ],
};
