import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";

export const createDealDetails = async (data) => {
  console.log(data);
  try {
    let res = await Api.post("/cabin-details", data);
    return res?.data;
  } catch (e) {
    console.log(e);
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getDealDetailsData = async (query) => {
  try {
    let res = await Api.get("/cabin-details", {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const deleteDealDetailsData = async (id) => {
  try {
    let res = await Api.delete("/cabin-details/" + id);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updateDealDetailsData = async (id, data) => {
  try {
    let res = await Api.patch("/cabin-details/" + id, data);
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getVessels = async () => {
  try {
    let res = await Api.get("/vessels", {
      params: { fields: "id,name" },
    });
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};
