import React from "react";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import HeaderBread from "../../../components/HeaderBread";
import NewsLetterInputs from "../../../components/NewsLetter/NewsLetterInput";
import Layout from "../../../Layout";
import TemplatesInputInputs from "../../../components/Templates/TemplatesInput";

const CreateWebpageTeamplate = () => {
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Templates",
      route: "/webpages/templates",
    },
    {
      title: "Add Templates",
      route: "/webpages/templates/add",
    },
  ];

  return (
    <Layout type={"create_per"} module_data={"Templates"}>
      <CustomHeaderTitle title={"Create Templates"} />
      <HeaderBread
        navigation={navigation}
        title={"Add Templates"}
      ></HeaderBread>
      <TemplatesInputInputs newsletter={{}} />
    </Layout>
  );
};

export default CreateWebpageTeamplate;
