import React, { useState, useEffect } from "react";
import { Box, Chip, MenuItem, Select } from "@mui/material";

const BookingStatus = ({ getData, setStatus, status, width,setPage }) => {
  const [options, setOption] = useState([
    {
      id: 2,
      value: "Success",
      realValue: 0,
    },
    {
      id: 1,
      value: "Pending",
      realValue: 1,
    },
    {
      id: 3,
      value: "ALL",
      realValue: -1,
    }
  ]);
  const [selectedValue, setSelectedValue] = useState("");
  useEffect(() => {
    if(selectedValue){
      getData(1);
      setPage(1);
    }
  }, [status]);
  return (
    <Select
      style={{ width: width || "50%" ,  height: "40px", marginRight:"10px" }}
      required
      displayEmpty
      renderValue={selectedValue !== "" ? undefined : () => "Booking Status"}
      value={selectedValue}
      onChange={(e) => {
        console.log("value ====>", e.target.value);
        if (e.target.value === 1) {
          console.log("here ---- 1");
          setStatus("success");
          // getData(1)
        } else if (e.target.value === 2) {
          console.log("here ---- 2");
          setStatus("pending");
          // getData(1);
        } else {
            console.log("here ---- 3");  
          setStatus("");
          // getData(1);
        }

        setSelectedValue(e.target.value);
      }}
    >
      {options.map((item, index) => (
        <MenuItem key={index} value={item.id}>
          {item.value}
        </MenuItem>
      ))}
    </Select>
  );
};

export default BookingStatus;
