import ArticaleCard from "../Previews/ArticaleCard/Preview";
import ImageGallary from "../Previews/ImageGallary/Preview";

export const Mediaandresource = {
  form: [
    {
      title: "Page Name",
      key: "page_name",
      className: "col-lg-12",
      type: "input",
    },
    {
      title: "Meta Description",
      key: "meta_description",
      className: "col-lg-12",
      type: "input",
    },
  ],
  component: [
    {
      type: 2,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
        },
      ],
      level1: [
        {
          title: "English File",
          key: "english_file",
          className: "col-lg-3",
          type: "file",
          placeholder: "Upload English File",
          filetype: "pdf",
        },
        {
          title: "Simplified Chinese File",
          key: "simplified_chinese_file",
          className: "col-lg-3",
          placeholder: "Upload Simplified Chinese File ",
          type: "file",
          filetype: "pdf",
        },
        {
          title: "Traditional Chinese File",
          key: "traditional_chinese_file",
          className: "col-lg-3",
          placeholder: "Upload Traditional Chinese File ",
          type: "file",
          filetype: "pdf",
        },
        {
          title: "Arabic File",
          key: "arabic_file",
          className: "col-lg-3 arabic",
          type: "file",
          placeholder: "Upload Arabic File ",
          filetype: "pdf",
        },
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
        },
        ,
        {
          title: "English date",
          key: "date",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese date",
          key: "simplified_chinese_date",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese date",
          key: "traditional_chinese_date",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic date",
          key: "arabic_date",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Ship",
          key: "ship",
          className: "col-lg-3",
          type: "ship",
        },
        {
          title: "Year",
          key: "year",
          className: "col-lg-3",
          type: "select",
          option: [
            {
              id: "2025",
              name: "2025",
            },
            {
              id: "2024",
              name: "2024",
            },
            {
              id: "2023",
              name: "2023",
            },
            {
              id: "2022",
              name: "2022",
            },
          ],
        },
      ],
      preview: (activetab, cardData) => (
        <ArticaleCard activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 2,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
        },
      ],
      level1: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false,
        },
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
        },
        {
          title: "English filter",
          key: "filter",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese filter",
          key: "simplified_chinese_filter",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese filter",
          key: "traditional_chinese_filter",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic filter",
          key: "arabic_filter",
          className: "col-lg-3 arabic",
          type: "input",
        },
        {
          title: "Ship",
          key: "ship",
          className: "col-lg-3",
          type: "ship",
        },
      ],
      preview: (activetab, cardData) => (
        <ImageGallary activetab={activetab} cardData={cardData} />
      ),
    },
  ],
};
