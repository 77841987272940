import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";

export const addlog = async (actions, type, data, user_id) => {
  let payloaddata = {
    actions,
    type,
    data:JSON.stringify(data),
    user_id,
  };
  try {
    let res = await Api.post("/log", payloaddata);
    return res?.data;
  } catch (e) {
    console.log(e);
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getlogData = async (query) => {
  try {
    let res = await Api.get("/log", {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const deletelogData = async (id) => {
  try {
    let res = await Api.delete("/log/" + id);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updatelogData = async (id, data) => {
  try {
    let res = await Api.patch("/log/" + id, data);
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};
