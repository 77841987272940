import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoginUser } from "../utils/ApiCalls/UserCalls";
import { useDataLayerValue } from "../DataLayer/DataLayer";
import { setToken } from "../utils/Api";
import CustomHeaderTitle from "../components/constants/CustomHeaderTitle";
import { getPermission } from "../utils/ApiCalls/Permission";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [, dispatch] = useDataLayerValue();
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await LoginUser({ email, password });
    let value = await getPermission({ search: data.data?.role });
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.data) {
      localStorage.setItem("userDetails", JSON.stringify(data.data));

      localStorage.setItem(
        "permission",
        JSON.stringify(value?.data?.[0] || {})
      );
      setToken(data.token);
      dispatch({
        type: "SET_TOEKN",
        token: data.token,
      });
      dispatch({
        type: "SET_USER_DETAILS",
        userDetails: data.data,
      });
      dispatch({
        type: "SET_PERMISSION",
        permission: value?.data?.[0],
      });
      navigate("/");
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <div className="wrapper login_wrap">
      <img className="bg_img" src="./dist/img/login_bg.jpg" alt="" />

      <CustomHeaderTitle title={"Login"} />
      <div className="login_inner">
        <div className="login-box">
          <div className="box_inner">
            <img className="box_img" src="./dist/img/logo_login.png" alt="" />
            <h4 className="login_title">Login</h4>
            <form onSubmit={handleSubmit}>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  required
                  onChange={handleEmailChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <i className="ri-mail-line"></i>
                  </div>
                </div>
              </div>
              <div className="input-group mb-4">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  required
                  value={password}
                  onChange={handlePasswordChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <i className="ri-lock-password-line"></i>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <button type="submit" className="btn btn_sign">
                    Sign In
                  </button>
                </div>
              </div>
            </form>

            <div className="social-auth-links text-center mb-3">
              <p>- OR -</p>
            </div>

            <p className="mb-1">
              <Link to="/forget-password">I forgot my password</Link>
            </p>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <div className="col-6 ">
                <button
                  onClick={() => navigate("/register")}
                  className="btn btn_sign"
                >
                  Create New Account
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
