import React from "react";
import { useParams } from "react-router-dom";
import { ships } from "../../../components/template/CustomTemplate/Pages/Ships";
import CustomTemplates from "../Deals/CustomTemplate";
import { eventDetails } from "../../../components/template/CustomTemplate/Pages/eventDetails";

const EditEventsPage = () => {
  const params = useParams();

  return (
    <CustomTemplates page_type={"events_item"} slug={params.id}  attributes={eventDetails} />
  );
};

export default EditEventsPage;
