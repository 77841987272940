import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";

export const addModule = async (data) => {
  try {
    let res = await Api.post("/modules", data);
    return res?.data;
  } catch (e) {
    console.log(e);
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const addPermission = async (data) => {
  try {
    let res = await Api.post("/permission", data);
    return res?.data;
  } catch (e) {
    console.log(e);
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getModule = async (query) => {
  try {
    let res = await Api.get("/modules", {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const getPermission = async (query) => {
  try {
    let res = await Api.get("/permission", {
      params: query,
    });
    
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const deleteRole = async (id) => {
  try {
    let res = await Api.delete("/permission/" + id);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updatePermission = async (id, data) => {
  try {
    let res = await Api.patch("/permission/" + id, data);
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};
