import React from "react";
import "./app.css";
import { constants } from "../../../../../constants/constants";
import { getImage } from "../../../../../utils/getImages";
const SafetyGuidsCard = ({ cardData, activetab }) => {
  return (
    <div style={{ margin: "10px",padding:"20px" }}>
      <h1 className="title-color mb-4">
        {" "}
        {activetab === "english"
          ? cardData?.title
          : activetab === "simplified_chinese"
          ? cardData?.simplified_chinese_title
          : activetab === "arabic"
          ? cardData?.arabic_title
          : cardData?.traditional_chinese_title}
      </h1>
      <div
        className="row"
        style={{
          width: "100%",
          display: "flex",
          // alignItems: "center",
          flexWrap: "wrap",
          flexDirection: "row",
        }}
      >
        {cardData?.dataArray?.map((item) => (
          <div className="col-4" style={{ marginBottom: "10px" }}>
            <div>
              <img src={getImage(item?.image)} style={{ marginBottom:"10px",width:"100%",height:"400px",borderRadius:"10px",objectFit:"cover" }} />
              <h4 className="title-color mb-3">
                {activetab === "english"
                  ? item?.title
                  : activetab === "simplified_chinese"
                  ? item?.simplified_chinese_title
                  : activetab === "arabic"
                  ? item?.arabic_title
                  : item?.traditional_chinese_title}
              </h4>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    activetab === "english"
                      ? item?.description
                      : activetab === "simplified_chinese"
                      ? item?.simplified_chinese_description
                      : activetab === "arabic"
                      ? item?.arabic_description
                      : item?.traditional_chinese_description,
                }}
              />{" "}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SafetyGuidsCard;
