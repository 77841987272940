import Api from "../../Api";

export const getLocations=async()=>{
    try {
        let res = await Api.get("/location");
       
        return (res?.data || [])
    }
    catch (e) {
        console.log(e);
        return ([])
    }
}

export const getAllLocations=async()=>{
    try {
        let res = await Api.get("/location/all");
       
        return (res?.data || [])
    }
    catch (e) {
        console.log(e);
        return ([])
    }
}