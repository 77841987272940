import React, { useEffect, useState } from "react";
import HeaderBread from "../../../components/HeaderBread";
import Layout from "../../../Layout";
import { useParams } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import LeadsInput from "../../../components/Leads/LeadsInputs";

import { getLeadsData } from "../../../utils/ApiCalls/Leads";
import { getPermission } from "../../../utils/ApiCalls/Permission";
import PermissionTable from "../MyProfile/PermissionTable";
import EditRoleInput from "../../../components/Role/EditRoleInput";

const EditRole = () => {
  const params = useParams();
  const [leads, setLeads] = useState({});

  const [{ userDetails }, dispatch] = useDataLayerValue();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Role List",
      route: "/profile/role",
    },
    {
      title: "Edit Role",
      route: "/profile/role/edit",
    },
  ];
  useEffect(() => {
    getData(params?.id);
  }, [params]);

  const getData = async (value) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getPermission({ search: value });

    console.log({ value });
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.count === 1) {
      setLeads(data.data[0]);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <Layout type={"update_per"} module_data={"Roles"}>
      <CustomHeaderTitle title={"View Role"} />
      <HeaderBread navigation={navigation} title={"View Role"}></HeaderBread>
      <div>{leads && <EditRoleInput roles={leads} />}</div>
    </Layout>
  );
};

export default EditRole;
