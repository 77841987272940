export const initialState = {
  userDetails: localStorage.getItem("userDetails")
    ? JSON.parse(localStorage.getItem("userDetails"))
    : {},
  permission: localStorage.getItem("permission")
    ? JSON.parse(localStorage.getItem("permission"))
    : {},
  token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
  ships: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER_DETAILS":
      return {
        ...state,
        userDetails: action.userDetails,
      };

    case "SET_PERMISSION":
      return {
        ...state,
        permission: action.permission,
      };

    case "SET_TOKEN":
      return {
        ...state,
        token: action.token,
      };

    case "SET_LOADING":
      return {
        ...state,
        loading: action.loading,
      };
    case "SET_ERROR_MESSAGE":
      return {
        ...state,
        errMess: action.errMess,
      };
    case "SET_SHIPS":
      return {
        ...state,
        ships: action.ships,
      };
    default:
      return state;
  }
};

export default reducer;
