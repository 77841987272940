import React from "react";
import { getLang } from "../lang/getlang";
import "./app.css";
const TablePreview6 = ({ cardData, activetab }) => {
  
  return (
    <div style={{ padding: "10px" }}>
      <div className="row mt-4 py-3">
        <div className="col-12 text-center">
          {cardData.dataArray?.map((item,index) => {
            return (
              <div className="container table-wrapper" key={index}>
                <table className="wifi">
                  <thead>
                    <tr>
                      <th id="th1" colspan="7" className="text-center" scope="col">
                        {" "}
                        {activetab === "english"
                          ? item?.subtitle
                          : activetab === "simplified_chinese"
                          ? item?.simplified_chinese_subtitle
                          : activetab === "arabic"
                          ? item?.arabic_subtitle
                          : item?.traditional_chinese_title}
                      </th>
                    </tr>
                    <tr>
                      <th
                        id="th3"
                        scope="col"
                        data-lang='{ "CN":"配套" , "AR": "الباقات"}'
                      >
                       {getLang("packages",activetab)}
                      </th>
                      <th
                        id="th2"
                        scope="col"
                        data-lang='{ "CN":"1晚" , "AR": "ليلة واحدة"}'
                      >
                       {getLang("one_nights",activetab)}
                      </th>
                      <th
                        id="th2"
                        scope="col"
                        data-lang='{ "CN":"2晚" , "AR": "ليلتان"}'
                      >
                          {getLang("two_nights",activetab)}
                  
                      </th>
                      <th
                        id="th2"
                        scope="col"
                        data-lang='{ "CN":"3晚" , "AR": "ثلاث ليالي"}'
                      >
                        {getLang("three_nights",activetab)}
                      </th>
                      <th
                        id="th2"
                        scope="col"
                        data-lang='{ "CN":"4晚" , "AR": "أربع ليالي"}'
                      >
                       {getLang("four_nights",activetab)}
                      </th>
                      <th
                        id="th2"
                        scope="col"
                        data-lang='{ "CN":"5晚" , "AR": "خمس ليالي"}'
                      >
                         {getLang("five_nights",activetab)}
                      </th>   <th
                        id="th2"
                        scope="col"
                        data-lang='{ "CN":"5晚" , "AR": "خمس ليالي"}'
                      >
                      {getLang("six_nights",activetab)}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {item?.dataArray?.map((item) => (
                      <tr>
                        <td data-lang='{ "CN":"標準（全程郵輪）- 1設備" , "AR": "عادية (كامل الرحلة)- جهاز واحد"}'>
                          {(activetab === "english"
                            ? item?.title
                            : activetab === "simplified_chinese"
                            ? item?.simplified_chinese_title
                            : activetab === "arabic"
                            ? item?.arabic_title
                            : item?.traditional_chinese_title)||"-"}
                        </td>
                        <td className="center">
                          <span className="price">
                            {" "}
                            {activetab === "english"
                              ? item?.one_night
                              : activetab === "simplified_chinese"
                              ? item?.simplified_chinese_one_night
                              : activetab === "arabic"
                              ? item?.arabic_one_night
                              : item?.traditional_chinese_one_night}
                          </span>
                        </td>
                        <td className="center">
                          <span className="price">
                            {" "}
                            {activetab === "english"
                              ? item?.two_night
                              : activetab === "simplified_chinese"
                              ? item?.simplified_chinese_two_night
                              : activetab === "arabic"
                              ? item?.arabic_two_night
                              : item?.traditional_chinese_two_night}
                          </span>
                        </td>
                        <td className="center">
                          <span className="price">
                            {" "}
                            {activetab === "english"
                              ? item?.three_night
                              : activetab === "simplified_chinese"
                              ? item?.simplified_chinese_three_night
                              : activetab === "arabic"
                              ? item?.arabic_three_night
                              : item?.traditional_chinese_three_night}
                          </span>
                        </td>
                        <td className="center">
                          <span className="price">
                            {" "}
                            {activetab === "english"
                              ? item?.four_night
                              : activetab === "simplified_chinese"
                              ? item?.simplified_chinese_four_night
                              : activetab === "arabic"
                              ? item?.arabic_four_night
                              : item?.traditional_chinese_four_night}
                          </span>
                        </td>
                        <td className="center">
                          <span className="price">
                            {" "}
                            {(activetab === "english"
                              ? item?.five_night
                              : activetab === "simplified_chinese"
                              ? item?.simplified_chinese_five_night
                              : activetab === "arabic"
                              ? item?.arabic_five_night
                              : item?.traditional_chinese_five_night)||"-"}
                          </span>
                        </td>
                        <td className="center">
                          <span className="price">
                            {" "}
                            {(activetab === "english"
                              ? item?.six_night
                              : activetab === "simplified_chinese"
                              ? item?.simplified_chinese_six_night
                              : activetab === "arabic"
                              ? item?.arabic_six_night
                              : item?.traditional_chinese_six_night)||"-"}
                          </span>
                        </td>
                      </tr>
                    ))}
                 
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
      </div>
   
    </div>
  );
};

export default TablePreview6;
