import React from "react";
import HeaderBread from "../../../components/HeaderBread";
import Layout from "../../../Layout";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import CabinMasterInput from "../../../components/CabinMaster/CabinMasterInputs";

const CreateCabinMaster = () => {
  
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Cabin Master",
      route: "/masters/cabin-master",
    },
    {
      title: "Add Cabin Master",
      route: "/masters/cabin-master/add",
    },
  ];

  return (
    <Layout type={"create_per"} module_data={"Cabin Master"}>
      <CustomHeaderTitle title={"Add Cabin Master"} />
      <HeaderBread
        navigation={navigation}
        title={"Add Cabin Master"}
      ></HeaderBread>
      <CabinMasterInput cabin={{}} />
    </Layout>
  );
};

export default CreateCabinMaster;
