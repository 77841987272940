import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";

const FareMarketCode = ({ data, getData, setMarketCode, marketCode, setPage, width }) => {
  const [selectedValue, setSelectedValue] = useState(marketCode);

  useEffect(() => {
    if (selectedValue) {
      getData(1);
      setPage(1);
    }
  }, [selectedValue]);

  console.log("market-code-data====>", data, marketCode);

  return (
    <Autocomplete
      options={data}
      size="small"
      getOptionLabel={(option) => option.market_code}
      value={data.find((item) => item.market_code === selectedValue) || null}
      onChange={(event, newValue) => {
        if (newValue) {
          console.log("val===>", newValue.market_code);
          setMarketCode(newValue.market_code);
          setSelectedValue(newValue.market_code);
        } else {
          setMarketCode("");
          setSelectedValue("");
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Market Code"
          variant="outlined"
          style={{width:"200px" }}
      
        />
      )}
    />
  );
};

export default React.memo(FareMarketCode);
