import { Chip, TextField, Button } from "@mui/material";
import React, { useState } from "react";

const RatePlanInput = ({chips,setChips,selectedOption}) => {
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleAddChip = () => {
    console.log(selectedOption)
    if(selectedOption=="specificRatePlans" && inputValue.trim() !==""){
      // setChips([inputValue.trim()]);
      setChips([...chips, inputValue.trim()]);
      setInputValue("");
    }
    else if (inputValue.trim() !== "") {
      // setChips([...chips, inputValue.trim()]);
      setChips([inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleDeleteChip = (index) => {
    const updatedChips = [...chips];
    updatedChips.splice(index, 1);
    setChips(updatedChips);
  };

  return (
    <div>
      {chips.map((chip, index) => (
        <Chip
          key={index}
          label={chip}
          variant="outlined"
          sx={{ borderRadius: "10px", margin: "0.5rem" }}
          color="error"
          onDelete={() => handleDeleteChip(index)}
        />
      ))}
      <div className="input_inner">
        <input
          type="text"
          placeholder=""
          value={inputValue}
          onChange={handleInputChange}
        />
        <button type="button" className="btn" onClick={handleAddChip}>
          Add More
        </button>
      </div>
    </div>
  );
};

export default RatePlanInput;
