import React, { useEffect, useState } from "react";
import { getImage } from "../../utils/getImages";

const UploadFileImage = ({
  selectedFile,
  setSelectedFile,
  image,
  className,
  label,
  setImageForPackages,
  mandatory,
  placeholder,
  width,
  accept,
  height,
}) => {
  const [remove, setRemove] = useState(false);
  const [datafile, setDataFiles] = useState();
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  

  

  const handleRemoveImage = () => {
    setRemove(true);
    // console.log("setImageForPackages====>", setImageForPackages)
    if (setImageForPackages) setImageForPackages(null);
    setSelectedFile();
  };
  return (
    <div className={className || "col-12"}>
      <div className="fieldrow">
        <label className="f_label">
          {label} <span style={{ color: "red" }}>{mandatory}</span>{" "}
        </label>
        <div className="upload_box">
          <input type="file" onChange={handleFileChange} accept={accept&&accept||"image/*"} />
          <i className="ri-upload-cloud-2-line"></i>
          <span>{placeholder}</span>
          {width && height && (
            <span>{`Height: ${height}, Width: ${width}`}</span>
          )}
        </div>
      </div>{" "}
      <div className="fieldrow uploaded_item">
        <ul>
          {selectedFile && (
            <li>
              <img src={getImage(selectedFile)} alt="" />

              <button onClick={handleRemoveImage} type="button">
                <i className="ri-close-line"></i>
              </button>
            </li>
          )}
          {image && !selectedFile && !remove && (
            <li>
              <img src={image} alt="" />

              <button onClick={handleRemoveImage} type="button">
                <i className="ri-close-line"></i>
              </button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default UploadFileImage;
