import React from "react";
import "./app.css";
import { getImage } from "../../../../../utils/getImages";
const PreviewPalace = ({ cardData, activetab }) => {
  
  return (
    <div>
      <div className="container-fluid py-3 py-md-5 ">
        <div className="container py-4">
          <div className="row align-items-center">
     
            <div className="col-md-6 mt-4 mt-md-0 px-3 px-md-5">
            <img
                src={
                  getImage(activetab === "english"
                    ? cardData?.logo
                    : activetab === "simplified_chinese"
                    ? cardData?.simplified_chinese_logo
                    : activetab === "arabic"
                    ? cardData?.arabic_logo
                    : cardData?.traditional_chinese_logo)
                  }
                alt="Genting Dream"
                // className="w-100 rounded"
              />
                <h1 className="title-color mb-4" style={{direction:activetab === "arabic"&&"rtl",color:"#E4BE83"}}>
               
                {activetab === "english"
                  ? cardData?.title
                  : activetab === "simplified_chinese"
                  ? cardData?.simplified_chinese_title
                  : activetab === "arabic"
                  ? cardData?.arabic_title
                  : cardData?.traditional_chinese_title}
              </h1>
              <p
              style={{direction:activetab === "arabic"&&"rtl"}}
                dangerouslySetInnerHTML={{
                  __html:
                    activetab === "english"
                      ? cardData?.description
                      : activetab === "simplified_chinese"
                      ? cardData?.simplified_chinese_description
                      : activetab === "arabic"
                      ? cardData?.arabic_description
                      : cardData?.traditional_chinese_description,
                }}
              />

              <p className="download-itinerary">
                {cardData?.action1 && (
                  <a
                    className="langEN"
                    href={cardData?.action1link}
                    target="_blank"
                  >
                    {cardData?.action1}
                  </a>
                )}
                {cardData?.action2 && (
                  <a
                    className="langEN"
                    href={cardData?.action2link}
                    target="_blank"
                  >
                    {cardData?.action2}
                  </a>
                )}
              </p>
            </div>
            <div className="col-md-6">
          
              <img
                src={
                  getImage(cardData?.image)
                  }
                alt="Genting Dream"
                className="w-100 rounded"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewPalace;
