import React from "react";
import "./app.css";
const TreditionalTerms = ({ cardData, activetab }) => {
  return (
    <div>
      <div className="card-body"></div>
      <div
        style={{
          border: "1px solid black",
          padding: "50px",
          margin: "10px",
          borderRadius: "10px",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: cardData?.traditional_chinese_description,
          }}
        />
      </div>
    </div>
  );
};

export default TreditionalTerms;
