import React from 'react'
import CustomTemplates from '../../Deals/CustomTemplate'
import { PassageTermsPage } from '../../../../components/template/CustomTemplate/Pages/PassageTerms'
import { CareerPages } from '../../../../components/template/CustomTemplate/Pages/CareerPage'

const Career = ({router}) => {
  return (
    <CustomTemplates slug={"career"} attributes={CareerPages}/>
  )
}

export default Career