import React from "react";
import HeaderBread from "../../../components/HeaderBread";
import Layout from "../../../Layout";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import CabinTypeInput from "../../../components/CabinTypeInput";
import CabinCategoryInput from "../../../components/CabinCategoryInput";

const CreateCabinCategory = () => {
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Cabin Category",
      route: "/masters/cabin-category",
    },
    {
      title: "Add Cabin Category",
      route: "/masters/cabin-category/add",
    },
  ];

  return (
    <Layout type={"create_per"} module_data={"Cabin Category"}>
      <CustomHeaderTitle title={"Create Cabin Category"} />
      <HeaderBread
        navigation={navigation}
        title={"Add Cabin Category"}
      ></HeaderBread>
      <CabinCategoryInput cabintypes={{}} />
    </Layout>
  );
};

export default CreateCabinCategory;
