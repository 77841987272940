import Card3Ship from "../../Previews/Card3Ship/Preview";
export const Component4 =  {
    type: 2,
    fields: [
      {
        title: "English Title",
        key: "title",
        className: "col-lg-3",
        type: "input",
        required: true,
      },
      {
        title: "Simplified Chinese Title",
        key: "simplified_chinese_title",
        className: "col-lg-3",
        type: "input",
        required: true,
      },
      {
        title: "Traditional Chinese Title",
        key: "traditional_chinese_title",
        className: "col-lg-3",
        type: "input",
        required: true,
      },
      {
        title: "Arabic Title",
        key: "arabic_title",
        className: "col-lg-3 arabic",
        type: "input",
        required: true,
      },


    ],
    level1: [
      {
        title: "Card Image",
        key: "image",
        className: "col-lg-12",
        placeholder: "Upload Card Image",
        type: "file",
        required: true
      },

      {
        title: "English Title",
        key: "title",
        className: "col-lg-3",
        type: "input",
        required: true,
      },
      {
        title: "Simplified Chinese Title",
        key: "simplified_chinese_title",
        className: "col-lg-3",
        type: "input",
        required: true,
      },
      {
        title: "Traditional Chinese Title",
        key: "traditional_chinese_title",
        className: "col-lg-3",
        type: "input",
        required: true,
      },
      {
        title: "Arabic Title",
        key: "arabic_title",
        className: "col-lg-3 arabic",
        type: "input",
        required: true,
      },

      {
        title: "English Description",
        key: "description",
        className: "col-lg-3",
        type: "html",
        required: true,
      },
      {
        title: "Simplified Chinese Description",
        key: "simplified_chinese_description",
        className: "col-lg-3",
        type: "html",
        required: true,
      },
      {
        title: "Traditional Chinese Description",
        key: "traditional_chinese_description",
        className: "col-lg-3",
        type: "html",
        required: true,
      },
      {
        title: "Arabic Description",
        key: "arabic_description",
        className: "col-lg-3",
        type: "html",
        required: true,
      },
      {
        title: "English Description 2",
        key: "description2",
        className: "col-lg-3",
        type: "html"
      },
      {
        title: "Simplified Chinese Description 2",
        key: "simplified_chinese_description2",
        className: "col-lg-3",
        type: "html"
      },
      {
        title: "Traditional Chinese Description 2",
        key: "traditional_chinese_description2",
        className: "col-lg-3",
        type: "html"
      },
      {
        title: "Arabic Description 2",
        key: "arabic_description2",
        className: "col-lg-3",
        type: "html",
      },
    ],
    preview: (activetab, cardData) => (
      <Card3Ship activetab={activetab} cardData={cardData} />
    ),
    sampleData:  {
        "id": 8,
        "component_name": 3,
        "logo": "",
        "image": "1724241385925pool.jpg",
        "title": "Our Staterooms",
        "image1": "",
        "image2": "",
        "image3": "",
        "image4": "",
        "image5": "",
        "dataArray": [
            {
                "logo": "",
                "image": "1725605536198oceanview-stateroom.jpg",
                "title": "Oceanview Stateroom",
                "image1": "",
                "image2": "",
                "image3": "",
                "image4": "",
                "image5": "",
                "dataArray": [
                    {
                        "logo": "",
                        "image": "1726501376712occan.jpg",
                        "title": "Card Title",
                        "image1": "",
                        "image2": "",
                        "image3": "",
                        "image4": "",
                        "image5": "",
                        "arabic_file": "",
                        "arabic_logo": "",
                        "arabic_image": "",
                        "english_file": "",
                        "arabic_file_1": "",
                        "arabic_file_2": "",
                        "english_file_1": "",
                        "english_file_2": "",
                        "simplified_chinese_file": "",
                        "simplified_chinese_logo": "",
                        "simplified_chinese_image": "",
                        "simplified_chinese_title": "",
                        "traditional_chinese_file": "",
                        "traditional_chinese_logo": "",
                        "simplified_chinese_file_1": "",
                        "simplified_chinese_file_2": "",
                        "traditional_chinese_image": "",
                        "traditional_chinese_title": "",
                        "traditional_chinese_file_1": "",
                        "traditional_chinese_file_2": ""
                    },
                    {
                        "logo": "",
                        "image": "17265550744892(1).jpg",
                        "title": "Card Title",
                        "image1": "",
                        "image2": "",
                        "image3": "",
                        "image4": "",
                        "image5": "",
                        "arabic_file": "",
                        "arabic_logo": "",
                        "arabic_image": "",
                        "english_file": "",
                        "arabic_file_1": "",
                        "arabic_file_2": "",
                        "english_file_1": "",
                        "english_file_2": "",
                        "simplified_chinese_file": "",
                        "simplified_chinese_logo": "",
                        "simplified_chinese_image": "",
                        "simplified_chinese_title": "",
                        "traditional_chinese_file": "",
                        "traditional_chinese_logo": "",
                        "simplified_chinese_file_1": "",
                        "simplified_chinese_file_2": "",
                        "traditional_chinese_image": "",
                        "traditional_chinese_title": "",
                        "traditional_chinese_file_1": "",
                        "traditional_chinese_file_2": ""
                    },
                    {
                        "logo": "",
                        "image": "17265550756633.jpg",
                        "title": "Card Title",
                        "image1": "",
                        "image2": "",
                        "image3": "",
                        "image4": "",
                        "image5": "",
                        "arabic_file": "",
                        "arabic_logo": "",
                        "arabic_image": "",
                        "english_file": "",
                        "arabic_file_1": "",
                        "arabic_file_2": "",
                        "english_file_1": "",
                        "english_file_2": "",
                        "simplified_chinese_file": "",
                        "simplified_chinese_logo": "",
                        "simplified_chinese_image": "",
                        "simplified_chinese_title": "",
                        "traditional_chinese_file": "",
                        "traditional_chinese_logo": "",
                        "simplified_chinese_file_1": "",
                        "simplified_chinese_file_2": "",
                        "traditional_chinese_image": "",
                        "traditional_chinese_title": "",
                        "traditional_chinese_file_1": "",
                        "traditional_chinese_file_2": ""
                    },
                    {
                        "logo": "",
                        "image": "17265550764384.jpg",
                        "title": "Card Title",
                        "image1": "",
                        "image2": "",
                        "image3": "",
                        "image4": "",
                        "image5": "",
                        "arabic_file": "",
                        "arabic_logo": "",
                        "arabic_image": "",
                        "english_file": "",
                        "arabic_file_1": "",
                        "arabic_file_2": "",
                        "english_file_1": "",
                        "english_file_2": "",
                        "simplified_chinese_file": "",
                        "simplified_chinese_logo": "",
                        "simplified_chinese_image": "",
                        "simplified_chinese_title": "",
                        "traditional_chinese_file": "",
                        "traditional_chinese_logo": "",
                        "simplified_chinese_file_1": "",
                        "simplified_chinese_file_2": "",
                        "traditional_chinese_image": "",
                        "traditional_chinese_title": "",
                        "traditional_chinese_file_1": "",
                        "traditional_chinese_file_2": ""
                    },
                    {
                        "logo": "",
                        "image": "17265550772685.jpg",
                        "title": "Card Title",
                        "image1": "",
                        "image2": "",
                        "image3": "",
                        "image4": "",
                        "image5": "",
                        "arabic_file": "",
                        "arabic_logo": "",
                        "arabic_image": "",
                        "english_file": "",
                        "arabic_file_1": "",
                        "arabic_file_2": "",
                        "english_file_1": "",
                        "english_file_2": "",
                        "simplified_chinese_file": "",
                        "simplified_chinese_logo": "",
                        "simplified_chinese_image": "",
                        "simplified_chinese_title": "",
                        "traditional_chinese_file": "",
                        "traditional_chinese_logo": "",
                        "simplified_chinese_file_1": "",
                        "simplified_chinese_file_2": "",
                        "traditional_chinese_image": "",
                        "traditional_chinese_title": "",
                        "traditional_chinese_file_1": "",
                        "traditional_chinese_file_2": ""
                    }
                ],
                "arabic_file": "",
                "arabic_logo": "",
                "description": "<p><span style=\"color: rgb(0, 0, 0);\">From 16 - 35 sq.m</span></p><p><span style=\"color: rgb(0, 0, 0);\">Max Occupancy 2</span></p><p><span style=\"color: rgb(0, 0, 0);\">Deck 5/9/10/11/12/13</span></p><p><span style=\"color: rgb(0, 0, 0);\">2 Single Beds</span></p>",
                "arabic_image": "",
                "arabic_title": "غرفة مطلة على المحيط",
                "english_file": "",
                "arabic_file_1": "",
                "arabic_file_2": "",
                "english_file_1": "",
                "english_file_2": "",
                "arabic_description": "<p class=\"ql-align-right ql-direction-rtl\"><span style=\"color: rgb(0, 0, 0);\">ابتداء من 20 متر مربع، الحد الأقصى للنزلاء 3- 4، الطوابق 8/ 9/ 10/ 11/ 12/ 13/ 15، سرير مزدوج صغير عدد 1+ سرير أريكة مزدوج عدد 1/ سرير مزدوج صغير عدد 1+ سرير أريكة مفرد عدد 1+ سرير سحب عدد 1</span></p>",
                "simplified_chinese_file": "",
                "simplified_chinese_logo": "",
                "simplified_chinese_image": "",
                "simplified_chinese_title": "海景客房",
                "traditional_chinese_file": "",
                "traditional_chinese_logo": "",
                "simplified_chinese_file_1": "",
                "simplified_chinese_file_2": "",
                "traditional_chinese_image": "",
                "traditional_chinese_title": "海景客房",
                "traditional_chinese_file_1": "",
                "traditional_chinese_file_2": "",
                "simplified_chinese_description": "<p><span style=\"color: rgb(0, 0, 0);\">面积16-35平方米，可容纳人数2人</span></p><p><span style=\"color: rgb(0, 0, 0);\">位于5/9/10/11/12/13层甲板</span></p><p><span style=\"color: rgb(0, 0, 0);\">两张单人床</span></p>",
                "traditional_chinese_description": "<p><span style=\"color: rgb(0, 0, 0);\">面積16-35平方米，可容納人數2人</span></p><p><span style=\"color: rgb(0, 0, 0);\">位於5/9/10/11/12/13層甲板</span></p><p><span style=\"color: rgb(0, 0, 0);\">兩張單人床</span></p>"
            },
            {
                "logo": "",
                "image": "1725605536817palace-deluxe-suite.jpg",
                "title": "Palace Deluxe Suite",
                "image1": "",
                "image2": "",
                "image3": "",
                "image4": "",
                "image5": "",
                "dataArray": [
                    {
                        "logo": "",
                        "image": "17265553888681.jpg",
                        "title": "Card Title",
                        "image1": "",
                        "image2": "",
                        "image3": "",
                        "image4": "",
                        "image5": "",
                        "arabic_file": "",
                        "arabic_logo": "",
                        "arabic_image": "",
                        "english_file": "",
                        "arabic_file_1": "",
                        "arabic_file_2": "",
                        "english_file_1": "",
                        "english_file_2": "",
                        "simplified_chinese_file": "",
                        "simplified_chinese_logo": "",
                        "simplified_chinese_image": "",
                        "simplified_chinese_title": "",
                        "traditional_chinese_file": "",
                        "traditional_chinese_logo": "",
                        "simplified_chinese_file_1": "",
                        "simplified_chinese_file_2": "",
                        "traditional_chinese_image": "",
                        "traditional_chinese_title": "",
                        "traditional_chinese_file_1": "",
                        "traditional_chinese_file_2": ""
                    },
                    {
                        "logo": "",
                        "image": "17265553899662.jpg",
                        "title": "Card Title",
                        "image1": "",
                        "image2": "",
                        "image3": "",
                        "image4": "",
                        "image5": "",
                        "arabic_file": "",
                        "arabic_logo": "",
                        "arabic_image": "",
                        "english_file": "",
                        "arabic_file_1": "",
                        "arabic_file_2": "",
                        "english_file_1": "",
                        "english_file_2": "",
                        "simplified_chinese_file": "",
                        "simplified_chinese_logo": "",
                        "simplified_chinese_image": "",
                        "simplified_chinese_title": "",
                        "traditional_chinese_file": "",
                        "traditional_chinese_logo": "",
                        "simplified_chinese_file_1": "",
                        "simplified_chinese_file_2": "",
                        "traditional_chinese_image": "",
                        "traditional_chinese_title": "",
                        "traditional_chinese_file_1": "",
                        "traditional_chinese_file_2": ""
                    },
                    {
                        "logo": "",
                        "image": "17265553908293.jpg",
                        "title": "Card Title",
                        "image1": "",
                        "image2": "",
                        "image3": "",
                        "image4": "",
                        "image5": "",
                        "arabic_file": "",
                        "arabic_logo": "",
                        "arabic_image": "",
                        "english_file": "",
                        "arabic_file_1": "",
                        "arabic_file_2": "",
                        "english_file_1": "",
                        "english_file_2": "",
                        "simplified_chinese_file": "",
                        "simplified_chinese_logo": "",
                        "simplified_chinese_image": "",
                        "simplified_chinese_title": "",
                        "traditional_chinese_file": "",
                        "traditional_chinese_logo": "",
                        "simplified_chinese_file_1": "",
                        "simplified_chinese_file_2": "",
                        "traditional_chinese_image": "",
                        "traditional_chinese_title": "",
                        "traditional_chinese_file_1": "",
                        "traditional_chinese_file_2": ""
                    },
                    {
                        "logo": "",
                        "image": "17265553916664.jpg",
                        "title": "Card Title",
                        "image1": "",
                        "image2": "",
                        "image3": "",
                        "image4": "",
                        "image5": "",
                        "arabic_file": "",
                        "arabic_logo": "",
                        "arabic_image": "",
                        "english_file": "",
                        "arabic_file_1": "",
                        "arabic_file_2": "",
                        "english_file_1": "",
                        "english_file_2": "",
                        "simplified_chinese_file": "",
                        "simplified_chinese_logo": "",
                        "simplified_chinese_image": "",
                        "simplified_chinese_title": "",
                        "traditional_chinese_file": "",
                        "traditional_chinese_logo": "",
                        "simplified_chinese_file_1": "",
                        "simplified_chinese_file_2": "",
                        "traditional_chinese_image": "",
                        "traditional_chinese_title": "",
                        "traditional_chinese_file_1": "",
                        "traditional_chinese_file_2": ""
                    },
                    {
                        "logo": "",
                        "image": "17265553927465.jpg",
                        "title": "Card Title",
                        "image1": "",
                        "image2": "",
                        "image3": "",
                        "image4": "",
                        "image5": "",
                        "arabic_file": "",
                        "arabic_logo": "",
                        "arabic_image": "",
                        "english_file": "",
                        "arabic_file_1": "",
                        "arabic_file_2": "",
                        "english_file_1": "",
                        "english_file_2": "",
                        "simplified_chinese_file": "",
                        "simplified_chinese_logo": "",
                        "simplified_chinese_image": "",
                        "simplified_chinese_title": "",
                        "traditional_chinese_file": "",
                        "traditional_chinese_logo": "",
                        "simplified_chinese_file_1": "",
                        "simplified_chinese_file_2": "",
                        "traditional_chinese_image": "",
                        "traditional_chinese_title": "",
                        "traditional_chinese_file_1": "",
                        "traditional_chinese_file_2": ""
                    }
                ],
                "arabic_file": "",
                "arabic_logo": "",
                "description": "<p><span style=\"color: rgb(0, 0, 0);\">From 41 sq.m</span></p><p><span style=\"color: rgb(0, 0, 0);\">Max Occupancy 4</span></p><p><span style=\"color: rgb(0, 0, 0);\">Deck 15</span></p><p><span style=\"color: rgb(0, 0, 0);\">1 Queen-size Bed + 1 Double Sofa Bed</span></p>",
                "arabic_image": "",
                "arabic_title": "جناح ذا بالاس ديلوكس",
                "english_file": "",
                "arabic_file_1": "",
                "arabic_file_2": "",
                "english_file_1": "",
                "english_file_2": "",
                "arabic_description": "<p class=\"ql-align-right ql-direction-rtl\"><span style=\"color: rgb(0, 0, 0);\">ابتداءً من 41 متر مربع، الحد الأقصى للنزلاء 4، الطابق 15، سرير مزدوج صغير عدد 1+ سرير أريكة مزدوج عدد 1</span></p>",
                "simplified_chinese_file": "",
                "simplified_chinese_logo": "",
                "simplified_chinese_image": "",
                "simplified_chinese_title": "皇宫豪华套房",
                "traditional_chinese_file": "",
                "traditional_chinese_logo": "",
                "simplified_chinese_file_1": "",
                "simplified_chinese_file_2": "",
                "traditional_chinese_image": "",
                "traditional_chinese_title": "皇宫豪華套房",
                "traditional_chinese_file_1": "",
                "traditional_chinese_file_2": "",
                "simplified_chinese_description": "<p><span style=\"color: rgb(0, 0, 0);\">面积41平方米</span></p><p><span style=\"color: rgb(0, 0, 0);\">可容纳人数4人</span></p><p><span style=\"color: rgb(0, 0, 0);\">位于15层甲板</span></p><p><span style=\"color: rgb(0, 0, 0);\">一张双人床 + 一张双人沙发床</span></p>",
                "traditional_chinese_description": "<p><span style=\"color: rgb(0, 0, 0);\">面積41平方米</span></p><p><span style=\"color: rgb(0, 0, 0);\">可容納人數4人</span></p><p><span style=\"color: rgb(0, 0, 0);\">位於15層甲板</span></p><p><span style=\"color: rgb(0, 0, 0);\">一張雙人床 + 一張雙人沙發床</span></p>"
            },
            {
                "logo": "",
                "image": "1725605537422palace-penthouse.jpg",
                "title": "Palace Penthouse",
                "image1": "",
                "image2": "",
                "image3": "",
                "image4": "",
                "image5": "",
                "dataArray": [
                    {
                        "logo": "",
                        "image": "17265556622501(1).jpg",
                        "title": "Card Title",
                        "image1": "",
                        "image2": "",
                        "image3": "",
                        "image4": "",
                        "image5": "",
                        "arabic_file": "",
                        "arabic_logo": "",
                        "arabic_image": "",
                        "english_file": "",
                        "arabic_file_1": "",
                        "arabic_file_2": "",
                        "english_file_1": "",
                        "english_file_2": "",
                        "simplified_chinese_file": "",
                        "simplified_chinese_logo": "",
                        "simplified_chinese_image": "",
                        "simplified_chinese_title": "",
                        "traditional_chinese_file": "",
                        "traditional_chinese_logo": "",
                        "simplified_chinese_file_1": "",
                        "simplified_chinese_file_2": "",
                        "traditional_chinese_image": "",
                        "traditional_chinese_title": "",
                        "traditional_chinese_file_1": "",
                        "traditional_chinese_file_2": ""
                    },
                    {
                        "logo": "",
                        "image": "17265556631462.jpg",
                        "title": "Card Title",
                        "image1": "",
                        "image2": "",
                        "image3": "",
                        "image4": "",
                        "image5": "",
                        "arabic_file": "",
                        "arabic_logo": "",
                        "arabic_image": "",
                        "english_file": "",
                        "arabic_file_1": "",
                        "arabic_file_2": "",
                        "english_file_1": "",
                        "english_file_2": "",
                        "simplified_chinese_file": "",
                        "simplified_chinese_logo": "",
                        "simplified_chinese_image": "",
                        "simplified_chinese_title": "",
                        "traditional_chinese_file": "",
                        "traditional_chinese_logo": "",
                        "simplified_chinese_file_1": "",
                        "simplified_chinese_file_2": "",
                        "traditional_chinese_image": "",
                        "traditional_chinese_title": "",
                        "traditional_chinese_file_1": "",
                        "traditional_chinese_file_2": ""
                    },
                    {
                        "logo": "",
                        "image": "17265556639813.jpg",
                        "title": "Card Title",
                        "image1": "",
                        "image2": "",
                        "image3": "",
                        "image4": "",
                        "image5": "",
                        "arabic_file": "",
                        "arabic_logo": "",
                        "arabic_image": "",
                        "english_file": "",
                        "arabic_file_1": "",
                        "arabic_file_2": "",
                        "english_file_1": "",
                        "english_file_2": "",
                        "simplified_chinese_file": "",
                        "simplified_chinese_logo": "",
                        "simplified_chinese_image": "",
                        "simplified_chinese_title": "",
                        "traditional_chinese_file": "",
                        "traditional_chinese_logo": "",
                        "simplified_chinese_file_1": "",
                        "simplified_chinese_file_2": "",
                        "traditional_chinese_image": "",
                        "traditional_chinese_title": "",
                        "traditional_chinese_file_1": "",
                        "traditional_chinese_file_2": ""
                    },
                    {
                        "logo": "",
                        "image": "17265556648534.jpg",
                        "title": "Card Title",
                        "image1": "",
                        "image2": "",
                        "image3": "",
                        "image4": "",
                        "image5": "",
                        "arabic_file": "",
                        "arabic_logo": "",
                        "arabic_image": "",
                        "english_file": "",
                        "arabic_file_1": "",
                        "arabic_file_2": "",
                        "english_file_1": "",
                        "english_file_2": "",
                        "simplified_chinese_file": "",
                        "simplified_chinese_logo": "",
                        "simplified_chinese_image": "",
                        "simplified_chinese_title": "",
                        "traditional_chinese_file": "",
                        "traditional_chinese_logo": "",
                        "simplified_chinese_file_1": "",
                        "simplified_chinese_file_2": "",
                        "traditional_chinese_image": "",
                        "traditional_chinese_title": "",
                        "traditional_chinese_file_1": "",
                        "traditional_chinese_file_2": ""
                    },
                    {
                        "logo": "",
                        "image": "17265556659635.jpg",
                        "title": "Card Title",
                        "image1": "",
                        "image2": "",
                        "image3": "",
                        "image4": "",
                        "image5": "",
                        "arabic_file": "",
                        "arabic_logo": "",
                        "arabic_image": "",
                        "english_file": "",
                        "arabic_file_1": "",
                        "arabic_file_2": "",
                        "english_file_1": "",
                        "english_file_2": "",
                        "simplified_chinese_file": "",
                        "simplified_chinese_logo": "",
                        "simplified_chinese_image": "",
                        "simplified_chinese_title": "",
                        "traditional_chinese_file": "",
                        "traditional_chinese_logo": "",
                        "simplified_chinese_file_1": "",
                        "simplified_chinese_file_2": "",
                        "traditional_chinese_image": "",
                        "traditional_chinese_title": "",
                        "traditional_chinese_file_1": "",
                        "traditional_chinese_file_2": ""
                    }
                ],
                "arabic_file": "",
                "arabic_logo": "",
                "description": "<p><span style=\"color: rgb(0, 0, 0);\">Approx. 56 - 115.4 sq.m</span></p><p><span style=\"color: rgb(0, 0, 0);\">Max Occupancy 4-6*</span></p><p><span style=\"color: rgb(0, 0, 0);\">Deck 13/16</span></p><p><span style=\"color: rgb(0, 0, 0);\">1 King-size Bed + 1 Double Sofa Bed / 1 Queen-size Bed + 2 Double Sofa Beds/ 1 Queen-size Bed + 1 Double Sofa Bed</span></p><p><br></p><p><span style=\"color: rgb(0, 0, 0);\" class=\"ql-size-small\">(* The maximum occupancy of designated Palace Penthouss can be up to 6 guests upon request, subject to stateroom availability)</span></p>",
                "arabic_image": "",
                "arabic_title": "غرفة الطابق العلوي في القصر ذا بالاس بينتهاوس",
                "english_file": "",
                "arabic_file_1": "",
                "arabic_file_2": "",
                "english_file_1": "",
                "english_file_2": "",
                "arabic_description": "<p class=\"ql-align-right ql-direction-rtl\"><span style=\"color: rgb(0, 0, 0);\">56-115.4متر مربع تقريباً، الحد الأقصى للنزلاء 4-6*، الطوابق 13/ 16، سرير مزدوج كبير عدد 1+ سرير أريكة مزدوج عدد 1/ سرير مزدوج صغير عدد 1+ سرير أريكة مزدوج عدد 2/ سرير مزدوج صغير عدد 1+ سرير أريكة مزدوج عدد 1 (*يمكن أن يصل الحد الأعلى للنزلاء في بينتهاوس القصر إلى 6 عند الطلب، يخضع لعدد الغرف المتوفرة)</span></p>",
                "simplified_chinese_file": "",
                "simplified_chinese_logo": "",
                "simplified_chinese_image": "",
                "simplified_chinese_title": "皇宫行政套房",
                "traditional_chinese_file": "",
                "traditional_chinese_logo": "",
                "simplified_chinese_file_1": "",
                "simplified_chinese_file_2": "",
                "traditional_chinese_image": "",
                "traditional_chinese_title": "皇宮行政套房",
                "traditional_chinese_file_1": "",
                "traditional_chinese_file_2": "",
                "simplified_chinese_description": "<p><span style=\"color: rgb(0, 0, 0);\">面积约56-115.4平方米</span></p><p><span style=\"color: rgb(0, 0, 0);\">可容纳人数4-6人*</span></p><p><span style=\"color: rgb(0, 0, 0);\">位于13/16层甲板</span></p><p><span style=\"color: rgb(0, 0, 0);\">一张特大双人床 + 一张双人沙发床 / 一张双人床 + 两张双人沙发床 / 一张双人床 + 一张双人沙发床</span></p><p><br></p><p><br></p><p><span style=\"color: rgb(0, 0, 0);\">(*如有特殊需求，指定皇宫行政套房的可容纳人数可增加至6人，视预定情况而定)</span></p>",
                "traditional_chinese_description": "<p><span style=\"color: rgb(0, 0, 0);\">面積約56-115.4平方米</span></p><p><span style=\"color: rgb(0, 0, 0);\">可容納人數4-6人*</span></p><p><span style=\"color: rgb(0, 0, 0);\">位於13/16層甲板</span></p><p><span style=\"color: rgb(0, 0, 0);\">一張特大雙人床 + 一張雙人沙發床 / 一張雙人床 + 兩張雙人沙發床 / 一張雙人床 + 一張雙人沙發床</span></p><p><br></p><p><br></p><p><span style=\"color: rgb(0, 0, 0);\">(*如有特殊需求，指定皇宮行政套房的可容納人數可增加至6人，視預定情況而定)</span></p>"
            },

        ],
        "arabic_file": "",
        "arabic_logo": "",
        "arabic_image": "",
        "arabic_title": "غرفنا الفاخرة",
        "english_file": "",
        "arabic_file_1": "",
        "arabic_file_2": "",
        "english_file_1": "",
        "english_file_2": "",
        "simplified_chinese_file": "",
        "simplified_chinese_logo": "",
        "simplified_chinese_image": "",
        "simplified_chinese_title": "客房",
        "traditional_chinese_file": "",
        "traditional_chinese_logo": "",
        "simplified_chinese_file_1": "",
        "simplified_chinese_file_2": "",
        "traditional_chinese_image": "",
        "traditional_chinese_title": "客房",
        "traditional_chinese_file_1": "",
        "traditional_chinese_file_2": ""
    }
  }
