import React from "react";
import { ThePalaceData } from "../../../components/template/CustomTemplate/Pages/ThePalace";
import CustomTemplates from "./CustomTemplate";

const WebPage = () => {
  return (
    <div>
      <CustomTemplates slug={"the-palace"} attributes={ThePalaceData} />
    </div>
  );
};

export default WebPage;
