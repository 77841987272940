import JobPostCard from "../Previews/JobPostCard/Preview";
import ShipBannerPreview from "../Previews/Ship/Banner/Preview";

export const CareerPages = {
  form: [
    {
      title: "Page Name",
      key: "page_name",
      className: "col-lg-12",
      type: "input",
    },
    {
      title: "Meta Description",
      key: "meta_description",
      className: "col-lg-12",
      type: "input",
    },
  ],
  component: [
    {
      type: 1,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
        },
        {
          title: "Page Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Page Image",
          type: "file",
        },
      ],
      preview: (activetab, cardData) => (
        <ShipBannerPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 2,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "English PDF",
          key: "english_file",
          className: "col-lg-3",
          type: "file",
          placeholder: "Upload English PDF",
          filetype: "pdf",
        },
        {
          title: "Simplified Chinese PDF",
          key: "simplified_chinese_file",
          className: "col-lg-3",
          placeholder: "Upload Simplified Chinese PDF ",
          type: "file",
          filetype: "pdf",
        },
        {
          title: "Traditional Chinese PDF",
          key: "traditional_chinese_file",
          className: "col-lg-3",
          placeholder: "Upload Traditional Chinese PDF ",
          type: "file",
          filetype: "pdf",
        },
        {
          title: "Arabic PDF",
          key: "arabic_file",
          className: "col-lg-3 arabic",
          type: "file",
          placeholder: "Upload Arabic PDF ",
          filetype: "pdf",
        },
      ],
      level1: [
        {
          title: "Job Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Job Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "Posting Date",
          key: "Date",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Job Simplified Chinese Posting Date",
          key: "simplified_chinese_date",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Posting Date",
          key: "traditional_chinese_date",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Posting Date",
          key: "arabic_date",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "Job location",
          key: "location",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Job Simplified Chinese Location",
          key: "simplified_chinese_location",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese location",
          key: "traditional_chinese_location",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic location",
          key: "arabic_location",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "English highlights",
          key: "highlights",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese highlights",
          key: "simplified_chinese_highlights",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese highlights",
          key: "traditional_chinese_highlights",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic highlights",
          key: "arabic_highlights",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "English responsibilities",
          key: "responsibilities",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese responsibilities",
          key: "simplified_chinese_responsibilities",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese responsibilities",
          key: "traditional_chinese_responsibilities",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic responsibilities",
          key: "arabic_responsibilities",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "English qualifications",
          key: "qualifications",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese qualifications",
          key: "simplified_chinese_qualifications",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese qualifications",
          key: "traditional_chinese_qualifications",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic qualifications",
          key: "arabic_qualifications",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "English note",
          key: "note",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese note",
          key: "simplified_chinese_note",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese note",
          key: "traditional_chinese_note",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic note",
          key: "arabic_note",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <JobPostCard activetab={activetab} cardData={cardData} />
      ),
    },
  ],
};
