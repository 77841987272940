import SafetyGuidsCard from "../Previews/Card3Ship/Preview";
import PreviewDeal3 from "../Previews/Deal/PreviewDeal3/Preview";
import PreviewFAQ from "../Previews/PreviewFAQ/Preview";
import ShipBannerPreview from "../Previews/Ship/Banner/Preview";
import ShipDealPreview from "../Previews/Ship/DealCard/Preview";

export const FAQPage = {
  form: [
    {
      title: "Page Name",
      key: "page_name",
      className: "col-lg-12",
      type: "input",
    },
    {
      title: "Meta Description",
      key: "meta_description",
      className: "col-lg-12",
      type: "input",
    },
  ],
  component: [
   
    {
      type: 1,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false
        },
        {
          title: "Page Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Page Image",
          type: "file",
          required: false
        },
      ],
      preview: (activetab, cardData) => (
        <ShipBannerPreview activetab={activetab} cardData={cardData} />
      ),
    },
  
    {
      type: 3,
      fields: [ {
        title: "English Title",
        key: "title",
        className: "col-lg-3",
        type: "input",
      },
      {
        title: "Simplified Chinese Title",
        key: "simplified_chinese_title",
        className: "col-lg-3",
        type: "input",
      },
      {
        title: "Traditional Chinese Title",
        key: "traditional_chinese_title",
        className: "col-lg-3",
        type: "input",
      },
      {
        title: "Arabic Title",
        key: "arabic_title",
        className: "col-lg-3 arabic",
        type: "input",
      },
],
      level1: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
        },

      ],
      level2: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
        },

        {
          title: "English Answer",
          key: "answer",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Simplified Chinese Answer",
          key: "simplified_chinese_answer",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Traditional Chinese Answer",
          key: "traditional_chinese_answer",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Arabic Answer",
          key: "arabic_answer",
          className: "col-lg-3",
          type: "html",
        },],
      preview: (activetab, cardData) => (
        <PreviewFAQ activetab={activetab} cardData={cardData} />
      ),
    },
  
  ],
  
};
