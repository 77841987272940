import React from "react";
import "./app.css";
import { constants } from "../../../../../constants/constants";
import { getImage } from "../../../../../utils/getImages";
const ImageGallary = ({ cardData, activetab }) => {
  return (
    <div style={{ margin: "10px" }}>
      <h1 className="title-color mb-4" style={{ textAlign: "center" }}>
        {" "}
        {activetab === "english"
          ? cardData?.title
          : activetab === "simplified_chinese"
          ? cardData?.simplified_chinese_title
          : activetab === "arabic"
          ? cardData?.arabic_title
          : cardData?.traditional_chinese_title}
      </h1>
      <div
        className="row"
        style={{
          display: "flex",
          // alignItems: "center",
          flexWrap: "wrap",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        {cardData?.dataArray?.map((item) => (
          <div className="col-3">
            <div>
              <img src={getImage(item?.image)} style={{ width: "100%",borderRadius:"10px",height:"300px",objectFit:"cover" }} />
          
             </div>
          </div>
        ))}
     
      </div>
    </div>
  );
};

export default ImageGallary;
