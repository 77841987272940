import React, { useEffect, useState } from 'react'
import { useDataLayerValue } from '../../../DataLayer/DataLayer';
import DeletePop from '../../../constants/ErrorPermissionModal';
import { message } from 'antd';
import { checkPermission } from '../../../utils/Validation/checkPermission';
import { deleteCabinCatergoryData } from '../../../utils/ApiCalls/CabinCategory';
import { useNavigate } from 'react-router-dom';
import { deleteWebpage, getAllWebpage, updateWebpage } from '../../../utils/ApiCalls/WebPages';
import CustomizedTable from '../../../components/UI/CustomizedTable';
import Swal from 'sweetalert2';
import { addlog } from '../../../utils/ApiCalls/Logs';
import { deleteMenuList } from '../../../utils/ApiCalls/MenuList';
import moment from 'moment';

const AllVersion = ({ slug, version, setVersion, setActiveTab }) => {
    const [rows, setRows] = useState([]);
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [pageSize, setPageSize] = useState(10);
    const [{ userDetails, permission }, dispatch] = useDataLayerValue();

    const handleUpdate = async (id, values, title, version) => {
        if (checkPermission(permission, "update_per", "Events")) {
            let data = await updateWebpage(id, { ...values, slug });
            if (data.error) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: data.error,
                });
            } else {
                addlog(
                    `Version ${version} ` + (" Webpage " + !values.valid_from
                        ? values.version_is_active === 1
                            ? "Actived"
                            : "Deactivated"
                        : "updated"),
                    slug,
                    { ...values, id, title },
                    userDetails.id
                );
            }
            await getData(page);
        } else {
            message.error("Update permission missing");
        }
    };
    const columns = [
        {
            title: "Sl",
            width: 30,
            dataIndex: "id",
            key: "id",
            fixed: "left",
            render: (position, record, index) => ((page - 1) * pageSize) + index + 1,
        },


        {
            title: "Slug",
            dataIndex: "slug",
            key: "slug",
            sorter: (a, b) => "",
            width: 50,
        },

        {
            title: "Versions",
            dataIndex: "version",
            key: "version",
            sorter: (a, b) => "",
            width: 50,
        },
        {
            title: "Updated at",
            dataIndex: "updated_at",
            key: "updated_at",
            sorter: (a, b) => "",
            render: (item, record) => <div>{moment(item).format("MMMM DD,YYYY hh:mm a")}</div>,
            width: 50,
        },
        {
            title: "Status",
            dataIndex: "version_is_active",
            key: "version_is_active",
            width: 30,
            sorter: (a, b) => "",
            render: (item, record, index) => (
                <div className="custom-control custom-switch">
                    <input
                        type="checkbox"
                        checked={item ? true : false}
                        onChange={(value) => {
                            try {
                                handleUpdate(
                                    record.id,
                                    {
                                        version_is_active: value.target.checked ? 1 : 0,
                                    },
                                    record.title,
                                    record?.version
                                );
                            } catch (error) {
                                dispatch({
                                    type: "SET_LOADING",
                                    loading: false,
                                });

                                Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: error.message || "Unknown error occurred",
                                });
                            }
                        }}
                        className="custom-control-input"
                        id={"customSwitch1" + index}
                    />
                    <label
                        className="custom-control-label"
                        style={{}}
                        for={"customSwitch1" + index}
                    ></label>
                </div>
            ),
        },
        {
            title: "Action",
            key: "operation",
            width: 40,
            render: (text, record) => (
                <div className="table_action_btn">
                    <div className="btn" onClick={() => handleEdit(record.version)}>
                        <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
                    </div>
                    <DeletePop onConfirm={() => handleDelete(record.id)}>
                        <div>
                            {" "}
                            <div className="btn delete_btn">
                                <i
                                    className="ri-delete-bin-line"
                                    data-toggle="tooltip"
                                    title="Delete"
                                ></i>
                            </div>
                        </div>
                    </DeletePop>
                </div>
            ),
        },
    ];
    useEffect(() => {
        getData(page);
    }, [page, pageSize, search]);


    const getData = async (value, sort, order) => {
        dispatch({
            type: "SET_LOADING",
            loading: true,
        });
        let args = { slug, search: search, page: value, limit: pageSize };
        if (order) {
            if (sort) {
                args = { ...args, sort };
            }
            args = { ...args, order };
        }

        let data = await getAllWebpage(args);
        if (data.error)
            dispatch({
                type: "SET_ERROR_MESSAGE",
                errMess: { message: data.error, type: "error" },
            });
        else if (data.data) {

            setRows(data.data);
            setCount(data.count);
        }
        dispatch({
            type: "SET_LOADING",
            loading: false,
        });
    };

    const handleEdit = async (id) => {
        if (checkPermission(permission, "update_per", "Ships")) {

            setVersion(id + "")
            setActiveTab("english")
        } else {
            message.error("Update permission missing");
        }
    };
    const handleDelete = async (id, url) => {
        if (checkPermission(permission, "delete_per", "Ships")) {
            deleteWebpage(id);
            deleteMenuList({ url })
            addlog("Webpage Deleted", slug, { id }, userDetails.id);
            getData(page);
        } else {
            message.error("Missing Permission for delete ");
        }
    };


    return (
        <div className={"card"}>
            <div
                style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-end",
                }}
            > <div className="card-body">
                    <div className="table_holder">
                        <div
                            style={{
                                display: "flex",
                                alignItems: "flex-end",
                                justifyContent: "flex-end",
                            }}
                        ></div>
                        {rows && (
                            <CustomizedTable
                                setRows={setRows}
                                setPageSize={setPageSize}
                                setPage={setPage}
                                pageSize={pageSize}
                                page={page}
                                count={count}
                                columns={columns}
                                rows={rows}
                                title={"Cabin Category"}
                                sortHandler={() => { }}
                            />
                        )}
                    </div>
                </div></div></div>
    )
}

export default AllVersion