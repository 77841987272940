import React from "react";
import "./app.css";
import { getImage } from "../../../../../utils/getImages";
const EventDetailsCard = ({ cardData, activetab }) => {
  return (
    <div>
      <div className="card-body"></div>
      <div>
        <div style={{ padding: "10px" }}>
          <img
            src={getImage(cardData?.image)}
            alt="Our Ship"
            // className="rounded"
            style={{ width: "100%", objectFit: "cover" }}
          />
          <div>
            <p
              style={{
                textAlign: "center",
                justifyContent: "center",
                marginTop: "10px",
                fontSize: "16px",
              }}
              dangerouslySetInnerHTML={{
                __html:
                  activetab === "english"
                    ? cardData?.description
                    : activetab === "simplified_chinese"
                    ? cardData?.simplified_chinese_description
                    : cardData?.traditional_chinese_description,
              }}
              data-lang='{"CN":"您的郵輪⾸選"}'
            ></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetailsCard;
