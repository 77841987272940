import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./dist/css/adminlte.min.css";

// import 'plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css';
import "./plugins/icheck-bootstrap/icheck-bootstrap.min.css";
// import 'plugins/jqvmap/jqvmap.min.css';
// import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700&display=fallback';
// import 'plugins/fontawesome-free/css/all.min.css';
import "./plugins/select2/css/select2.min.css";
import "./plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css";
// import 'plugins/datatables-buttons/css/buttons.bootstrap4.min.css';
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; 
import "@fontsource/roboto/400-italic.css";
import "./global/custom.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <App />
  // {/* </React.StrictMode> */}
);

reportWebVitals();
