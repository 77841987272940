import axios from "axios";
import { constants } from "../constants/constants";

const Api = axios.create({
  baseURL: constants.BASE_URL,
  headers: {
    "Content-Type": "application/json", // Add Content-Type header for JSON requests
  },
});

export const getToken = () => {
  try {
    const token = localStorage.getItem("token");
    if (token) {
      Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      return token;
    }
    return null;
  } catch (error) {
    console.error("Error retrieving token from localStorage:", error);
    return null;
  }
};

export const setToken = (token) => {
  try {
    if (token) {
      Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      localStorage.setItem("token", token);
    } else {
      delete Api.defaults.headers.common["Authorization"];
      localStorage.removeItem("token");
    }
  } catch (error) {
    console.error("Error setting/removing token in localStorage:", error);
    throw error;
  }
};

export default Api;
