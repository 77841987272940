import React from "react";
import "./app.css";
import { getImage } from "../../../../../../utils/getImages";
const ContactCard = ({ cardData, activetab }) => {
 
  return (
    <div>
    {cardData?.dataArray?.map(item=><div>
      <div className="container-fluid py-3 py-md-5 section-grey">
        <div className="container py-4">
          <div className="row align-items-center">
          
            <div className="col-md-6 mt-4 mt-md-0 px-3 px-md-5">
               <h3 className="title-color mb-4" style={{direction:activetab === "arabic"&&"rtl"}}>
                {activetab === "english"
                  ? item?.title
                  : activetab === "simplified_chinese"
                  ? item?.simplified_chinese_title
                  : activetab === "arabic"
                  ? item?.arabic_title
                  : item?.traditional_chinese_title}
              </h3>
              <p
              style={{direction:activetab === "arabic"&&"rtl"}}
                dangerouslySetInnerHTML={{
                  __html:
                    activetab === "english"
                      ? item?.description
                      : activetab === "simplified_chinese"
                      ? item?.simplified_chinese_description
                      : activetab === "arabic"
                      ? item?.arabic_description
                      : item?.traditional_chinese_description,
                }}
              />

              <p className="download-itinerary">
                {item?.action1 && (
                  <a
                    className="langEN"
                    href={item?.action1link}
                    target="_blank"
                  >
                    {item?.action1}
                  </a>
                )}
                {item?.action2 && (
                  <a
                    className="langEN"
                    href={item?.action2link}
                    target="_blank"
                  >
                    {item?.action2}
                  </a>
                )}
              </p>
            </div>
          
          </div>
        </div>
      </div>
    </div>)}</div>
  );
};

export default ContactCard;
