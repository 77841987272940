import React from "react";
import { getImage } from "../../../../../utils/getImages";
import "./app.css";
import Video from "./Video";
const DestinationPreview = ({ cardData, activetab }) => {
  return (
    <div>
      <div className="card-body"></div>
      <div className="generic-banner">
        <h3
          className=" title-color"
          style={{
            direction: activetab === "arabic" && "rtl",
            textAlign: "center",
          }}
        >
          {activetab === "english"
            ? cardData?.title
            : activetab === "simplified_chinese"
            ? cardData?.simplified_chinese_title
            : activetab === "arabic"
            ? cardData?.arabic_title
            : cardData?.traditional_chinese_title}
        </h3>
        <p
          style={{
            direction: activetab === "arabic" && "rtl",
            textAlign: "center",
            marginBottom: "10px",
          }}
          dangerouslySetInnerHTML={{
            __html:
              activetab === "english"
                ? cardData?.description
                : activetab === "simplified_chinese"
                ? cardData?.simplified_chinese_description
                : activetab === "arabic"
                ? cardData?.arabic_description
                : cardData?.traditional_chinese_description,
          }}
        />
        <Video url={getImage(
                activetab === "english"
                  ? cardData?.image
                  : activetab === "simplified_chinese"
                  ? cardData?.simplified_chinese_image
                  : activetab === "arabic"
                  ? cardData?.arabic_image
                  : cardData?.traditional_chinese_image
              )}/>
       
      </div>
    </div>
  );
};

export default DestinationPreview;
