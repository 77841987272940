import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Layout from "../../Layout";
import HeaderBread from "../../components/HeaderBread";
import OtherPagesInputs from "../../components/Pages/OtherPagesInputs";
import CustomHeaderTitle from "../../components/constants/CustomHeaderTitle";
import { getOtherPagesData } from "../../utils/ApiCalls/OtherPage";

const EditOtherPage = () => {
  const params = useParams();
  const [otherpages, setOtherPages] = useState({});
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Other Pages",
      route: "/cms/other-pages",
    },
    {
      title: "Edit Other Pages",
      route: "/cms/other-pages/edit",
    },
  ];
  useEffect(() => {
    getData(params?.id);
  }, [params]);

  const getData = async (value) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getOtherPagesData({ id: value });

    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.count === 1) {
      setOtherPages(data.data[0]);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <Layout>
       <CustomHeaderTitle title={"Edit Other Pages"}/>
      <HeaderBread
        navigation={navigation}
        title={"Edit Other Pages"}
      ></HeaderBread>
      {otherpages && <OtherPagesInputs pages={otherpages} />}
    </Layout>
  );
};

export default EditOtherPage;
