import React from "react";
import { eventsdata } from "../../../components/template/CustomTemplate/Pages/eventsdata";
import CustomTemplates from "../Deals/CustomTemplate";

const EventsPageMain= () => {

  return (
    <CustomTemplates slug={"events"}  attributes={eventsdata} />
  );
};

export default EventsPageMain;
