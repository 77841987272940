import React from "react";
import BannerInputs from "../../../components/Banner/BannerInputs";
import HeaderBread from "../../../components/HeaderBread";
import Layout from "../../../Layout";
import { useNavigate } from "react-router-dom";
import { createBanner } from "../../../utils/ApiCalls/Banner";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";

const CreateBanner = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Banner List",
      route: "/cms/homepage/banner",
    },
    {
      title: "Add Banner",
      route: "/cms/homepage/banner/add",
    },
  ];

  return (
    <Layout type={"create_per"} module_data={"banner"}>
        <CustomHeaderTitle title={"Create Banner"}/>
      <HeaderBread navigation={navigation} title={"Add Banner"}></HeaderBread>
      <BannerInputs banner={{}} />
    </Layout>
  );
};

export default CreateBanner;
