import React from "react";
import { KnowBeforeYouSailPage } from "../../../components/template/CustomTemplate/Pages/KnowBeforeYouSail";
import CustomTemplates from "../Deals/CustomTemplate";

const KnowBeforeYouSail = () => {

  return (
    <CustomTemplates
      slug={"know-before-you-sail"}
      attributes={KnowBeforeYouSailPage}
    />
  );
};

export default KnowBeforeYouSail;
