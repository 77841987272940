import React, { useEffect, useState } from 'react'
import HeaderBread from '../../components/HeaderBread';
import Layout from '../../Layout';
import { useParams } from 'react-router-dom';
import { getLocationDataById,  } from '../../utils/ApiCalls/Location';
import { useDataLayerValue } from '../../DataLayer/DataLayer';
import CustomHeaderTitle from '../../components/constants/CustomHeaderTitle';
import LocationInputs from '../../components/Location/LocationInput';

const EditLocation = () => {
    const params=useParams();
    const [location,setLocation]=useState({});
    const [{useDetails},dispatch]=useDataLayerValue();
   const navigation = [{
        title: "Home",
        route: "/"
    }, {
        title: "Location List",
        route: "/cms/homepage/location"
    }, {
        title: "Edit Location",
        route: "/cms/homepage/location/edit"
    }]
    useEffect(()=>{
        getData(params?.id)
    },[params])
    
    const getData = async (id) => {
        dispatch({
            type: "SET_LOADING",
            loading:true
          });
        let data = await getLocationDataById(id);

        if (data.error)
            dispatch({
                type: 'SET_ERROR_MESSAGE',
                errMess: { message: data.error, type: 'error' }
            });
        else
            setLocation(data);
            dispatch({
                type: "SET_LOADING",
                loading:false
              });
    }
    return (
        <Layout type={"update_per"} module_data={"Location"}>
              <CustomHeaderTitle title={"Edit Location"}/>
            <HeaderBread navigation={navigation} title={"Edit Location"}></HeaderBread>{location&&<LocationInputs location={location} />}</Layout>
    )
}

export default EditLocation