import { Box, Chip, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { getLocations } from "../../utils/ApiCalls/Locations";
import {
  createNewsLetter,
  updateNewsLetter,
} from "../../utils/ApiCalls/NewsLetter";
import { checkFields } from "../../utils/Validation/checkFields";

const NewsLetterInputs = ({ newsletter }) => {
  const [email, setEmail] = useState(newsletter?.email || "");
  const [displayLocation, setDisplayLocation] = useState([]);
  const [, dispatch] = useDataLayerValue();
  const [error, setError] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (newsletter) {
      setEmail(newsletter?.email);
      setDisplayLocation(newsletter.location_name);
    }
  }, [newsletter]);

  const addNewsLetter = async (e) => {
    e.preventDefault();
    let added = false;
    let dataValues = {
      email,
    };

    let valid = checkFields(dataValues);
    if (valid) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: valid, type: "error" },
      });
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });   
setError(valid);
    } else {
      setError();

      const values = {
        location_name: displayLocation,
        ...dataValues,
      };

      let data;
      if (newsletter?.id) {
        data = await updateNewsLetter(newsletter.id, values);
      } else {
        data = await createNewsLetter(values);
      }
      if (data?.error) {
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: data.error, type: "error" },
        });
        added = false;
      } else {
        added = true;
      }

      console.log({ added, newsletter });
      if (added && !newsletter.id) {
        navigate("/cms/news-letter");
      } else {
        navigate("/cms/news-letter");
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: "Values updated", type: "success" },
        });
      }
    }
  };
  return (
    <div>
      <section className="content">
        {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )}

        <div className="container-fluid">
          <div className="card">
            <form className="input_form" onSubmit={addNewsLetter}>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Email</label>
                      <input
                        required
                        className="f_input"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Location Name</label>
                      <input
                        required
                        className="f_input"
                        type="text"
                        value={displayLocation}
                        onChange={(e) => setDisplayLocation(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-right">
                <button className="btn main_submit">
                  {newsletter.id ? "Update" : "Add"} Newsletter
                </button>
              </div>
            </form>
          </div>
          <div className="pt-3"></div>
        </div>
      </section>
    </div>
  );
};

export default NewsLetterInputs;
