import { CircularProgress, styled } from '@mui/material';
import React from 'react';
import { useDataLayerValue } from '../DataLayer/DataLayer';

const LoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width:"100%",
  height:"100vh",
  position: 'fixed',
  zIndex: 100,
  
});

const Loading = ({loader}) => {
    const [{loading},]=useDataLayerValue();
  return (
    loading||loader?
    <LoadingContainer>
      <CircularProgress color='error' />
    </LoadingContainer>:null
  );
}

export default Loading;
