import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Pagination } from "antd";
import { useDataLayerValue } from "../../DataLayer/DataLayer";

const CustomizedDragNDrop = ({
  columns,
  rows,
  page,
  setPage,
  setCount,
  setPageSize,
  count,
  pageSize,
  sortHandler,
  setRows,
  updatePosition,
}) => {
  const [{ useDetails }, dispatch] = useDataLayerValue();

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  const onChange = (pageNumber) => {
    setPage(pageNumber);
    setRows([]);
  };

  const onSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };

  const [sortBy, setSortBy] = useState({
    column: null,
    order: "asc",
  });

  const handleSort = (column) => {
    sortHandler({
      columnKey: column,
      order: sortBy.column === column && sortBy.order === "asc" ? "descend" : "ascend",
    });

    setSortBy((prevSortBy) => ({
      column,
      order: prevSortBy.column === column && prevSortBy.order === "asc" ? "desc" : "asc",
    }));
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    // Clone rows to avoid direct state mutation
    const updatedRows = Array.from(rows);

    // Remove the element from the source index
    const [movedElement] = updatedRows.splice(result.source.index, 1);

    // Add the element to the destination index
    updatedRows.splice(result.destination.index, 0, movedElement);

    // Update positions
    const newRows = updatedRows.map((row, index) => ({
      ...row,
      position: (count - index) - (page - 1) * pageSize,
    }));

    setRows(newRows);

    dispatch({
      type: "SET_LOADING",
      loading: true,
    });

    try {
      await updatePosition(newRows);
    } catch (error) {
      console.error("Failed to update position:", error);
      // Handle the error appropriately (e.g., show a notification)
    } finally {
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%", overflow: "hidden" }}>
      <div className="table_holder">
        <DragDropContext onDragEnd={handleDragEnd}>
          <table id="voucher_list" className="table table-bordered table-striped">
            <thead>
              <tr>
                {columns.map((item, index) => (
                  <th
                    key={index}
                    onClick={() => item?.sorter && handleSort(item.key)}
                    style={{
                      width: item?.width + "px" || "auto",
                      cursor: "pointer",
                    }}
                  >
                    <span>{item.title}</span>
                    {item?.sorter && (
                      <span style={{ marginLeft: "10px" }}>
                        <span style={{ color: sortBy.column === item.key && sortBy.order === "asc" ? "red" : "grey" }}>↑</span>
                        <span style={{ color: sortBy.column === item.key && sortBy.order === "desc" ? "red" : "grey" }}>↓</span>
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <Droppable droppableId="droppable-1">
              {(provided) => (
                <tbody ref={provided.innerRef} {...provided.droppableProps}>
                  {rows?.map((row, index) => (
                    <Draggable draggableId={row.position.toString()} key={row.position} index={index}>
                      {(provided) => (
                        <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          {columns?.map((item) => (
                            <td key={item.id} style={{ minWidth: item?.width, height: "auto" }}>
                              <div style={{ display: "flex", alignItems: "center", justifyItems: "center", width: "100%", height: "100%" }}>
                                {item.render ? item.render(row[item.key], row, index) : row[item.key]}
                              </div>
                            </td>
                          ))}
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {rows?.length === 0 && (
                    <tr>
                      <td valign="top" colSpan={columns.length} className="dataTables_empty">
                        No matching records found
                      </td>
                    </tr>
                  )}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </table>
        </DragDropContext>
      </div>
      {count > 10 && (
        <Pagination
          showSizeChanger
          pageSizeOptions={['5', '10', '20', '50']}
          onShowSizeChange={onSizeChange}
          style={{ marginLeft: "auto", marginTop: "10px" }}
          defaultCurrent={page}
          current={page}
          onChange={onChange}
          total={count}
        />
      )}
    </div>
  );
};

export default CustomizedDragNDrop;
