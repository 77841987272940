import { Box, Chip, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { getLocations } from "../../utils/ApiCalls/Locations";

import { checkFields } from "../../utils/Validation/checkFields";
import { createCabinTypes, updateCabinTypes } from "../../utils/ApiCalls/CabinTypes";

const CabinTypeInput = ({ cabintypes }) => {
  const [name, setName] = useState("");

  const [isActive, setIsActive] = useState(true);
  const [display_name, setDisplayName] = useState([]);
  const [, dispatch] = useDataLayerValue();
  const [error, setError] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (cabintypes) {
      setName(cabintypes?.name);
      setDisplayName(cabintypes.display_name);
      setIsActive(cabintypes?.is_active === 1 ? true : false);
    }
  }, [cabintypes]);
  const handleCheckboxChange = () => {
    setIsActive(!isActive);
  };

  const addcabintypes = async (e) => {
    e.preventDefault();
    let added = false;
    let dataValues = {
      name,
    };

    let valid = checkFields(dataValues);
    if (valid) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: valid, type: "error" },
      });
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });   
setError(valid);
    } else {
      setError();

      const values = {
        display_name,
        is_active: isActive ? 1 : 0,
        ...dataValues,
      };

      let data;
      if (cabintypes?.id) {
        data = await updateCabinTypes(cabintypes.id, values);
      } else {
        data = await createCabinTypes(values);
      }
      if (data?.error) {
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: data.error, type: "error" },
        });
        added = false;
      } else {
        added = true;
      }

      if (added && !cabintypes.id) {
        navigate("/masters/cabin-types");
      } else {
        navigate("/masters/cabin-types");
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: "Values updated", type: "success" },
        });
      }
    }
  };
  return (
    <div>
      <section className="content">
        {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )}

        <div className="container-fluid">
          <div className="card">
            <form className="input_form" onSubmit={addcabintypes}>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Name</label>
                      <input
                        required
                        className="f_input"
                        type="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Display Name</label>
                      <input
                        required
                        className="f_input"
                        type="text"
                        value={display_name}
                        onChange={(e) => setDisplayName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                    <div className="iti_inner">
                      <div className="icheck-primary d-inline">
                        <input
                          type="checkbox"
                          id="customCheckbox4"
                          name="r10"
                          checked={isActive}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="customCheckbox4">
                          {isActive ? (
                            <p>
                              <span style={{ fontWeight: 600 }}>Active</span>
                            </p>
                          ) : (
                            <p>
                              <span style={{ fontWeight: 200 }}>Active</span>
                            </p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
              </div>
            
              <div className="card-footer text-right">
                <button className="btn main_submit">
                  {cabintypes?.id ? "Update" : "Add"} cabin type
                </button>
              </div>
            </form>
          </div>
          <div className="pt-3"></div>
        </div>
      </section>
    </div>
  );
};

export default CabinTypeInput;
