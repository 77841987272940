import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../Layout";
import HeaderBread from "../../../components/HeaderBread";
import CustomizedTables from "../../../components/Tables/BasicTable";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";

import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";

import CustomizedTable from "../../../components/UI/CustomizedTable";
import * as XLSX from "xlsx";
import moment from "moment";
import DeletePop from "../../../constants/ErrorPermissionModal";
import { checkPermission } from "../../../utils/Validation/checkPermission";
import { message } from "antd";
import { deleteAnnouncement, getAnnouncement, updateAnnouncement } from "../../../utils/ApiCalls/Announcement";
import Swal from "sweetalert2";
import { addlog } from "../../../utils/ApiCalls/Logs";

const Announcement = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Announcement",
      route: "/cms/announcement",
    },
  ];
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [{ userDetails, permission }, dispatch] = useDataLayerValue();
  const columns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (position, record, index) => ((page - 1) * pageSize) + index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 50,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: 30,
      sorter: (a, b) => "",
      render: (item, record, index) => (
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            checked={item ? true : false}
            onChange={(value) => {
              try {
                if (new Date(record.valid_from) > new Date()) {
                  throw new Error(`Banner is not yet started`);
                }

                if (new Date(record.valid_to) < new Date()) {
                  throw new Error(`Banner is expired`);
                }
                handleUpdate(
                  record.id,
                  {
                    is_active: value.target.checked ? 1 : 0,
                  },
                  record.title,
                  record.location_id
                );
              } catch (error) {
                dispatch({
                  type: "SET_LOADING",
                  loading: false,
                });

                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error.message || "Unknown error occurred",
                });
              }
            }}
            className="custom-control-input"
            id={"customSwitch1" + index}
          />
          <label
            className="custom-control-label"
            style={{}}
            for={"customSwitch1" + index}
          ></label>
        </div>
      ),
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (text, record) => (
        <div className="table_action_btn">
          <div className="btn" onClick={() => handleEdit(record.id)}>
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <DeletePop onConfirm={() => handleDelete(record.id)}>
            <div>
              {" "}
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>
        </div>
      ),
    },
  ];
  const handleUpdate = async (id, values, title) => {
    if (checkPermission(permission, "update_per", "Announcement")) {
      let data = await updateAnnouncement(id, values);
      if (data.error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.error,
        });
      } else {
        addlog(
          "Announcement " +
          (!values.valid_from
            ? values.is_active === 1
              ? "Actived"
              : "Deactivated"
            : "updated"),
          "Announcement",
          { ...values, id, title },
          userDetails.id
        );
      }
      getData(page);
    } else {
      message.error("Update permission missing");
    }
  };
  useEffect(() => {
    getData(page);
  }, [page, pageSize]);

  const getData = async (value) => {
    let data = await getAnnouncement({ page: value, limit: pageSize });
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.data) {
      setRows(data.data);
      setCount(data.count);
    }
  };
  const handleEdit = async (id) => {
    if (checkPermission(permission, "update_per", "Announcement")) {
      navigate("/cms/homepage/announcement/edit/" + id);
    } else {
      message.error("Update permission missing");
    }
  };
  const handleDelete = async (id) => {
    if (checkPermission(permission, "delete_per", "Announcement")) {
      deleteAnnouncement(id);
      getData();
    } else {
      message.error("Missing Permission for delete");
    }
  };



  return (
    <Layout type={"read_per"} module_data={"Announcement"}>
      <CustomHeaderTitle title={"Announcement"} />
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"Announcement"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <div className="card">
              <div className="card-header two_item">
                <h5 className="card-title">Announcement List</h5>
                <div className="two_item">
                  <Link to="/cms/homepage/announcement/add" className="btn add_v_btn mx-2">
                    Add Announcement
                  </Link>


                </div>
              </div>
              <div className="card-body">
                <div className="table_holder">
                  <CustomizedTable
                    setRows={setRows}
                    setPageSize={setPageSize}
                    setPage={setPage}
                    pageSize={pageSize}
                    page={page}
                    count={count}
                    columns={columns}
                    rows={rows}
                    title={"Announcement"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Announcement;
