import React from "react";
import { getImage } from "../../../../../utils/getImages";
import { getLang } from "../lang/getlang";
import "./app.css";
const TablePreview2 = ({ cardData, activetab }) => {

  return (
    <div style={{ padding: "10px" }}>
      <div className="col-md-12 box-dreamers-club">
        <div className="box-bring">
          <div className="box-header-cruise text-center p-3">
            <h2>
              {" "}
              {activetab === "english"
                ? cardData?.subtitle
                : activetab === "simplified_chinese"
                ? cardData?.simplified_chinese_subtitle
                : activetab === "arabic"
                ? cardData?.arabic_subtitle
                : cardData?.traditional_chinese_subtitle}
            </h2>
          </div>
          <div className="box-bring-content">
            <div className="box-dreamers-club-header d-flex">
              <div className="box-dreamers-club-description col-md-5">
                <p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        activetab === "english"
                          ? cardData?.description
                          : activetab === "simplified_chinese"
                          ? cardData?.simplified_chinese_description
                          : activetab === "arabic"
                          ? cardData?.arabic_description
                          : cardData?.traditional_chinese_description,
                    }}
                    data-lang='{"CN":"您的郵輪⾸選"}'
                    style={{
                      direction: activetab === "arabic" && "rtl",

                    }}
                  />
                </p>
              </div>
              <div className="box-dreamers-club-img col-md-7 pr-0">
                <img className="thumbnail-image" alt="" src={getImage(cardData?.image)} />
              </div>
            </div>

            <table className="table" sx={{ marginTop: "10px" }}>
              <tbody>
                <tr>
                  <th className="text-first">
                    <span>{getLang("age",activetab)}</span>
                  </th>
                  <th className="text-center">
                    <span>{getLang("price_genting",activetab)}</span>
                  </th>
                  <th className="text-center">
                    <span>{getLang("price_resort",activetab)}</span>
                  </th>
                </tr>
                {cardData?.dataArray?.map((item) => (
                  <tr>
                    <td className="">
                      <div className="title-text">
                        {" "}
                       
                          {" "}
                          {activetab === "english"
                            ? item?.title
                            : activetab === "simplified_chinese"
                            ? item?.simplified_chinese_title
                            : activetab === "arabic"
                            ? item?.arabic_title
                            : item?.traditional_chinese_title}
                   
                      </div>
                      <p className="description-text">
                      <p
                    dangerouslySetInnerHTML={{
                      __html:
                        activetab === "english"
                          ? item?.description
                          : activetab === "simplified_chinese"
                          ? item?.simplified_chinese_description
                          : activetab === "arabic"
                          ? item?.arabic_description
                          : item?.traditional_chinese_description,
                    }}
                    data-lang='{"CN":"您的郵輪⾸選"}'
                    style={{
                      direction: activetab === "arabic" && "rtl",

                    }}
                  />
                      </p>
                    </td>
                    <td className="vertical-align-center text-center">
                      <div className="title-text">   {activetab === "english"
                            ? item?.price_genting
                            : activetab === "simplified_chinese"
                            ? item?.simplified_chinese_price_genting
                            : activetab === "arabic"
                            ? item?.arabic_price_genting
                            : item?.traditional_chinese_price_genting}</div>
                    </td>
                    <td className="vertical-align-center text-center">
                      <div className="title-text">  {activetab === "english"
                            ? item?.price_resort
                            : activetab === "simplified_chinese"
                            ? item?.simplified_chinese_price_resort
                            : activetab === "arabic"
                            ? item?.arabic_price_resort
                            : item?.traditional_chinese_price_resort}</div>
                    </td>
                  </tr>
                ))}
          
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TablePreview2;
