import { Divider } from "@mui/material";
import React, { useState } from "react";
import DataCard from "../DataCard";
import FromData from "./FormCard";
import { message } from "antd";
import { gettitle } from "../../../../utils/getImages";

const Level2SubCard = ({ attributes,dataCard,setDataCard, pageData, setPageData }) => {
  
  const [activeSection,setActiveSection]=useState()
  const addData = (index, cardData, type) => {
    let array = [];
    if (type) {
      array = [...dataCard.dataArray];
      array[index] = cardData;
    } else if (dataCard.dataArray) array = [...dataCard.dataArray, cardData];
    else array = [cardData];
    setDataCard({ ...dataCard, dataArray: array });
    if (type) {
      message.success("data updated click on publish to publish changes");
    } else {
      message.success("Created");
    }
  };


  return (
    <div style={{ borderRadius: "5px", overflow: "hidden", padding: "10px" }}>
      <Divider sx={{ borderStyle: "dashed", marginBottom: "20px" }} />

      <div>
        <div>
          <div className="row">
            <div className="col-lg-12">
              {dataCard?.dataArray?.map((item, index) => {
                return (
                  <DataCard
                    color="#f7f7f7"
                    title={gettitle(item)}
                    activeDrop={activeSection}
                    setActiveDrop={setActiveSection}
                    index={index}
                    array={dataCard?.dataArray}
                    setArray={(e) => {
                      setDataCard({ ...dataCard, dataArray: e });
            
                    }}
                  >
                    <FromData
                    active={activeSection==index}
                    attributes={attributes}
                      data={item}
                      updateData={addData}
                      index={index + 1}
                    />
                  </DataCard>
                );
              })}
            </div>
            <div style={{ margin: "10px", width: "100%" }}>
              <FromData add updateData={addData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Level2SubCard;
