import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";

const FareCodeItineary = ({
  data,
  getData,
  setItinearyId,
  itinearyId,
  setPage,
  width,
}) => {
  const [selectedValue, setSelectedValue] = useState(itinearyId);

  useEffect(() => {
    if (selectedValue) {
      getData(1);
      setPage(1);
    }
  }, [selectedValue]);

  console.log("ititititi===>", data);
  return (
    <Autocomplete
      options={data}
      size="small"
      getOptionLabel={(option) => option.itinerary_id || ""}
      value={data.find((item) => item.itinerary_id === selectedValue) || null}
      onChange={(event, newValue) => {
        const selectedItineraryId = newValue ? newValue.itinerary_id : "";
        setItinearyId(selectedItineraryId);
        setSelectedValue(selectedItineraryId);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select Itinerary"
          variant="outlined"
          style={{
            width: "200px",
            height: "40px",
            marginRight: "10px",
          }}
        />
      )}
    />
  );
};

export default FareCodeItineary;
