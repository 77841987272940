import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";


export const getCabinCatergoryData = async (query) => {
    try {
      let res = await Api.get("/cabin-category/all", {
        params: query,
      });
    
      return res?.data || [];
    } catch (e) {
      let error = modifyError(e);
      return { error: error.message };
    }
  };

  export const getCabinCatergoryDataById = async (id) => {
    try {
      let res = await Api.get("/cabin-category/"+id)
    
      return res?.data || [];
    } catch (e) {
      return { error: "Something Wrong" };
    }
  };

  export const createCabinCatergoryData = async (data) => {

    try {
      let res = await Api.post("/cabin-category", data);
      return res?.data;
    } catch (e) {
      let error = modifyError(e);
      return { error: error.message };
    }
  };

  
  export const updateCabinCatergoryData = async (id, data) => {
    try {
      let res = await Api.patch("/cabin-category/" + id, data);
    
      return res?.data || [];
    } catch (e) {
      let error = modifyError(e);
      return { error: error.message };
    }
  };


export const deleteCabinCatergoryData = async (id) => {
  try {
    let res = await Api.delete("/cabin-category/" + id);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};
