import Card5Palace from "../Previews/Card5Palace/Preview";
import PreviewPalace from "../Previews/PreviewPalace/Preview";
import ShipBannerPreview from "../Previews/Ship/Banner/Preview";
import TermsPreview from "../Previews/Ship/Terms/Preview";
import TablePreview from "../Previews/TablePreview/Preview";
import TablePreview2 from "../Previews/TablePreview2/Preview";
import TablePreview3 from "../Previews/TablePreview3/Preview";
import TablePreview4 from "../Previews/TablePreview4/Preview";
import TablePreview5 from "../Previews/TablePreview5/Preview";

export const PrepareForYourCruisePage = {
  form: [
    {
      title: "Page Name",
      key: "page_name",
      className: "col-lg-12",
      type: "input",
    },
    {
      title: "Meta Description",
      key: "meta_description",
      className: "col-lg-12",
      type: "input",
    },
  ],
  component: [
    {
      type: 2,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "English Sub Title",
          key: "subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Sub Title",
          key: "simplified_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Sub Title",
          key: "traditional_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Sub Title",
          key: "arabic_subtitle",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
      ],
      level1: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false,
        },

        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <TablePreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 2,
      fields: [
        {
          title: "English Sub Title",
          key: "subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Sub Title",
          key: "simplified_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Sub Title",
          key: "traditional_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Sub Title",
          key: "arabic_subtitle",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
      ],
      level1: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false,
        },

        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <TablePreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 2,
      fields: [
        {
          title: "English Sub Title",
          key: "subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Sub Title",
          key: "simplified_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Sub Title",
          key: "traditional_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Sub Title",
          key: "arabic_subtitle",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Table Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Table Image",
          type: "file",
          required: false,
        },
      ],
      level1: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "English Price (Genting Dream)",
          key: "price_genting",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Price (Genting Dream)",
          key: "simplified_chinese_price_genting",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Price (Genting Dream)",
          key: "traditional_chinese_price_genting",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Price (Genting Dream)",
          key: "arabic_price_genting",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "English Price (Resorts World One)",
          key: "price_resort",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Price (Resorts World One)",
          key: "simplified_chinese_price_resort",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Price (Resorts World One)",
          key: "traditional_chinese_price_resort",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Price (Resorts World One)",
          key: "arabic_price_resort",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "English Price (Star Scorpio)",
          key: "price_star",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Price (Star Scorpio)",
          key: "simplified_chinese_price_star",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Price (Star Scorpio)",
          key: "traditional_chinese_price_star",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Price (Star Scorpio)",
          key: "arabic_price_star",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <TablePreview2 activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 2,
      fields: [
        {
          title: "English Sub Title",
          key: "subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Sub Title",
          key: "simplified_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Sub Title",
          key: "traditional_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Sub Title",
          key: "arabic_subtitle",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
      ],
      level1: [
        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <TablePreview3 activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 3,
      fields: [
        {
          title: "English Sub Title",
          key: "subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Sub Title",
          key: "simplified_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Sub Title",
          key: "traditional_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Sub Title",
          key: "arabic_subtitle",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
      ],
      level1: [
        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
      ],
      level2: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-3",
          placeholder: "Upload Card Image",
          type: "file",
          required: false,
        },

        {
          title: "Simplified Chinese Image",
          key: "simplified_chinese_image",
          placeholder: "Upload Card Image",
          className: "col-lg-3",
          type: "file",
        },
        {
          title: "Traditional Chinese Image",
          key: "traditional_chinese_image",
          className: "col-lg-3",
          type: "file",
          placeholder: "Upload Card Image",
        },
        {
          title: "Arabic Image",
          key: "arabic_image",
          className: "col-lg-3 arabic",
          type: "file",
          placeholder: "Upload Card Image",
        },
        {
          title: "English Sub Title",
          key: "subtitle",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Sub Title",
          key: "simplified_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Sub Title",
          key: "traditional_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Sub Title",
          key: "arabic_subtitle",
          className: "col-lg-3 arabic",
          type: "input",
        },
      ],
      preview: (activetab, cardData) => (
        <TablePreview4 activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 2,
      fields: [
        {
          title: "English Sub Title",
          key: "subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Sub Title",
          key: "simplified_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Sub Title",
          key: "traditional_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Sub Title",
          key: "arabic_subtitle",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
      ],
      level1: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false,
        },
        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <TablePreview5 activetab={activetab} cardData={cardData} />
      ),
    },
  ],
};
