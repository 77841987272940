import React from "react";
import "./app.css";
import { getImage } from "../../../../../../utils/getImages";
const Blogview = ({ cardData, activetab }) => {
  return (
    <div>
      <div
        style={{
          padding: "10px 50px",

          borderRadius: "10px",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: cardData?.description,
          }}
        />
      </div>
      <div
        style={{
          padding: "10px 50px",
        }}
      >
        <img
          src={getImage(cardData.image)}
          style={{ width: "100%", padding: "10px" }}
        />
      </div>
      <div
        style={{
          padding: "10px 50px",
          borderRadius: "10px",
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: cardData?.description1,
          }}
        />
      </div>
    </div>
  );
};

export default Blogview;
