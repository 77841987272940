import ShipDealPreview from "../../Previews/Ship/DealCard/Preview";

export const Component3 = {
    type: 1,
    fields: [
        {
            title: "Page Image",
            key: "image",
            className: "col-lg-12",
            placeholder: "Upload Page Image",
            type: "file",
            required: true,
        },

        {
            title: "English Title",
            key: "title",
            className: "col-lg-3",
            type: "input",
            required: true,
        },
        {
            title: "Simplified Chinese Title",
            key: "simplified_chinese_title",
            className: "col-lg-3",
            type: "input",
            required: true,
        },
        {
            title: "Traditional Chinese Title",
            key: "traditional_chinese_title",
            className: "col-lg-3",
            type: "input",
            required: true,
        },
        {
            title: "Arabic Title",
            key: "arabic_title",
            className: "col-lg-3 arabic",
            type: "input",
            required: true,
        },

        {
            title: "English Description",
            key: "description",
            className: "col-lg-3",
            type: "html",
            required: true,
        },
        {
            title: "Simplified Chinese Description",
            key: "simplified_chinese_description",
            className: "col-lg-3",
            type: "html",
            required: true,
        },
        {
            title: "Traditional Chinese Description",
            key: "traditional_chinese_description",
            className: "col-lg-3",
            type: "html",
            required: true,
        },
        {
            title: "Arabic Description",
            key: "arabic_description",
            className: "col-lg-3",
            type: "html",
            required: true,
        },
        {
            title: "Button 1 English Title",
            key: "title_button_1",
            className: "col-lg-3",
            type: "input",
            required: true,
        },
        {
            title: "Button 1 Simplified Chinese Title",
            key: "simplified_chinese_title_button_1",
            className: "col-lg-3",
            type: "input",
            required: true,
        },
        {
            title: "Button 1 Traditional Chinese Title",
            key: "traditional_chinese_title_button_1",
            className: "col-lg-3",
            type: "input",
            required: true,
        },
        {
            title: "Button 1 Arabic Title",
            key: "arabic_title_button_1",
            className: "col-lg-3 arabic",
            type: "input",
            required: true,
        },
        {
            title: "English Button 1 file",
            key: "english_file_1",
            className: "col-lg-3",
            type: "file",

            placeholder: "Upload English Button 1 file",
            filetype: "pdf",
            required: true,
        },
        {
            title: "Simplified Chinese Button 1 file",
            key: "simplified_chinese_file_1",
            className: "col-lg-3",
            placeholder: "Upload Simplified Chinese Button 1 file ",
            type: "file",
            filetype: "pdf",
            required: true,
        },
        {
            title: "Traditional Chinese Button 1 file",
            key: "traditional_chinese_file_1",
            className: "col-lg-3",
            placeholder: "Upload Traditional Chinese Button 1 file ",
            type: "file",
            filetype: "pdf",
            required: true,
        },
        {
            title: "Arabic Button 1 file",
            key: "arabic_file_1",
            className: "col-lg-3 arabic",
            type: "file",
            placeholder: "Upload Arabic Button 1 file ",
            filetype: "pdf",
            required: true,
        },
        {
            title: "Button 2 English Title",
            key: "title_button_2",
            className: "col-lg-3",
            type: "input",
            required: true,
        },
        {
            title: "Button 2 Simplified Chinese Title",
            key: "simplified_chinese_title_button_2",
            className: "col-lg-3",
            type: "input",
            required: true,
        },
        {
            title: "Button 2 Traditional Chinese Title",
            key: "traditional_chinese_title_button_2",
            className: "col-lg-3",
            type: "input",
            required: true,
        },
        {
            title: "Button 2 Arabic Title",
            key: "arabic_title_button_2",
            className: "col-lg-3 arabic",
            type: "input",
            required: true,
        },
        {
            title: "English Button 2 file",
            key: "english_file_2",
            className: "col-lg-3",
            type: "file",
            placeholder: "Upload English Button 2 file",
            filetype: "pdf",
            required: true,
        },
        {
            title: "Simplified Chinese Button 2 file",
            key: "simplified_chinese_file_2",
            className: "col-lg-3",
            placeholder: "Upload Simplified Chinese Button 2 file ",
            type: "file",
            filetype: "pdf",
            required: true,
        },
        {
            title: "Traditional Chinese Button 2 file",
            key: "traditional_chinese_file_2",
            className: "col-lg-3",
            placeholder: "Upload Traditional Chinese Button 2 file ",
            type: "file",
            filetype: "pdf",
            required: true,
        },
        {
            title: "Arabic Button 2 file",
            key: "arabic_file_2",
            className: "col-lg-3 arabic",
            type: "file",
            placeholder: "Upload Arabic Button 1 file ",
            filetype: "pdf",
            required: true,
        },
    ],
    preview: (activetab, cardData) => (
        <ShipDealPreview activetab={activetab} cardData={cardData} />
    ),
    sampleData:{
        "id": 7,
        "component_name": 1,
        "logo": "",
        "image": "1724241276922boat.jpg",
        "title": "Experience luxury cruising with the Genting Dream.",
        "image1": "",
        "image2": "",
        "image3": "",
        "image4": "",
        "image5": "",
        "arabic_file": "",
        "arabic_logo": "",
        "description": "<p><span style=\"background-color: rgb(233, 233, 233); color: rgb(0, 0, 0);\">Welcome aboard the Genting Dream, the flagship of Resorts World Cruises. We’ve been a pioneer and a trendsetter in hospitality for 50 years and we know luxury cruising like no other. We’ll be bringing you fresh, novel experiences and an array of cuisine, entertainment and themes from across the globe for a cruise that’s limited only by your imagination.</span></p>",
        "arabic_image": "",
        "arabic_title": "اختبر الفخامة في الرحلات البحرية مع جنتنج دريم.",
        "english_file": "",
        "templateType": "deal_card",
        "arabic_file_1": "GDR_Ship-Brochure_EN.pdf",
        "arabic_file_2": "GDR_Ship-Brochure_EN.pdf",
        "english_file_1": "GDR_Ship-Brochure_EN_(3).pdf",
        "english_file_2": "Ship-Brochure_EN.pdf",
        "title_button_1": "View Brochure",
        "title_button_2": "View Cruise Itinerary",
        "arabic_description": "<p class=\"ql-align-right ql-direction-rtl\"><span style=\"color: rgb(0, 0, 0); background-color: rgb(233, 233, 233);\">مرحباً بكم على متن سفينة \"جينتنغ دريم\"، السفينة الرائدة لشركة \"ريزورتس ورلد كروزيس\". على مدار 50 عاماً، كنا الرواد في مجال الضيافة، ونحن خبراء في تقديم رحلات بحرية لا مثيل لها. استعدوا لتجارب جديدة ومبتكرة، مع مجموعة متنوعة من المأكولات، ووسائل الترفيه، والأنشطة التي تجوب العالم كله، كل ذلك في رحلة بحرية لا يحدها سوى خيالك.</span></p>",
        "arabic_title_button_1": "مشاهدة الكتيب البروشور",
        "arabic_title_button_2": "عرض خط سير الرحلة",
        "simplified_chinese_file": "",
        "simplified_chinese_logo": "",
        "simplified_chinese_image": "",
        "simplified_chinese_title": "体验梦幻豪华游轮之旅。",
        "traditional_chinese_file": "",
        "traditional_chinese_logo": "",
        "simplified_chinese_file_1": "Ship-Brochure_EN.pdf",
        "simplified_chinese_file_2": "Ship-Brochure_EN.pdf",
        "traditional_chinese_image": "",
        "traditional_chinese_title": "體驗夢幻豪華遊輪之旅。",
        "traditional_chinese_file_1": "GDR_Ship-Brochure_TC.pdf",
        "traditional_chinese_file_2": "GDR_Ship-Brochure_TC.pdf",
        "simplified_chinese_description": "<p><span style=\"background-color: rgb(233, 233, 233); color: rgb(0, 0, 0);\">欢迎登上云顶梦号，名胜世界邮轮的旗舰。 50 年来，我们⼀直都是酒店业的先驱和引领者，⽽且对豪华游轮⾮常在⾏。我们将为您带来⼀些前所未有的新颖体验，包括有来⾃世界各地的美⾷、将让您留下精彩回忆的娱乐，还有意想不到的各类主题。期待您的加⼊！</span></p>",
        "traditional_chinese_description": "<p><span style=\"background-color: rgb(233, 233, 233); color: rgb(0, 0, 0);\">歡迎登上雲頂夢號，名勝世界郵輪的旗艦。 50 年來，我們⼀直都是酒店業的先驅和引領者，⽽且對豪華郵輪⾮常在⾏。我們將為您帶來⼀些前所未有的新穎體驗，包括有來⾃世界各地的美⾷、將讓您留下精彩回憶的娛樂，還有意想不到的各類主題。期待您的加⼊！</span></p>",
        "simplified_chinese_title_button_1": "查看邮轮手册",
        "simplified_chinese_title_button_2": "查看邮轮行程",
        "traditional_chinese_title_button_1": "查看郵輪手冊",
        "traditional_chinese_title_button_2": "查看郵輪⾏程"
    }
}