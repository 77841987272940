import React from "react";
import CustomTemplates from "../../Deals/CustomTemplate";
import { entertainmentpages } from "../../../../components/template/CustomTemplate/Pages/Entertainment";

const Entertainment = () => {
  return (
    <div>
      {" "}
      <CustomTemplates slug={"entertainment"} attributes={entertainmentpages} />
    </div>
  );
};

export default Entertainment;
