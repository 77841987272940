import DestinationPreview from "../Previews/DestinationPreview/DestinationPreview";
import StoryCard from "../Previews/Mice/StoryCard/Preview";
import ShipBannerPreview from "../Previews/Ship/Banner/Preview";
import ShipDealPreview from "../Previews/Ship/DealCard/Preview";

export const OurStoryPagesData = {
  form: [
    {
      title: "Page Name",
      key: "page_name",
      className: "col-lg-12",
      type: "input",
    },
    {
      title: "Meta Description",
      key: "meta_description",
      className: "col-lg-12",
      type: "input",
    },
  ],
  component: [
   
    {
      type: 1,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false
        },
        {
          title: "Page Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Page Image",
          type: "file",
          required: false
        },
      ],
      preview: (activetab, cardData) => (
        <ShipBannerPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 1,
      fields: [
        {
          title: "Page Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Page Image",
          type: "file",required: false
        },
        

{
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",required: false
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",required: false
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",required: false
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",required: false
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",required: false
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",required: false
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",required: false
        },
      ],
      preview: (activetab, cardData) => (
        <ShipDealPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 1,
      fields: [
        {
          title: "Page Video",
          key: "image",
          className: "col-lg-3",
          placeholder: "Upload Page Video",
          type: "file",
          filetype:"video",required: false
        },
        {
          title: "Simplified Chinese Video",
          key: "simplified_chinese_image",
          filetype: "video",
          className: "col-lg-3",
          placeholder: "Upload Page Video",
          type: "file",
          required: false,
        },
        {
          title: "Traditional Chinese Video",
          key: "traditional_chinese_image",
          filetype: "video",
          className: "col-lg-3",
          placeholder: "Upload Page Video",
          type: "file",
          required: false,
        },
        {
          title: "Arabic Video",
          key: "arabic_image",
          filetype: "video",
          className: "col-lg-3",
          placeholder: "Upload Page Video",
          type: "file",
          required: false,
        },

{
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",required: false
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",required: false
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",required: false
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",required: false
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",required: false
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",required: false
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",required: false
        },
      ],
      preview: (activetab, cardData) => (
        <DestinationPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 2,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",required: false
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",required: false
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",required: false
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",required: false
        }
      ],
      level1: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false
        },
        {
          title: "Simplified Chinese Card Image",
          key: "simplified_chinese_image",
          className: "col-lg-3",
          placeholder: "Upload Simplified Chinese Card Image ",
          type: "file",
        },
        {
          title: "Traditional Chinese Card Image",
          key: "traditional_chinese_image",
          className: "col-lg-3",
          placeholder: "Upload Traditional Chinese Card Image ",
          type: "file",
        },
        {
          title: "Arabic Card Image",
          key: "arabic_image",
          className: "col-lg-3 arabic",
          type: "file",
          placeholder: "Upload Arabic Card Image ",
        },
      ],
      preview: (activetab, cardData) => (
        <StoryCard activetab={activetab} cardData={cardData} />
      ),
    },
    
   
  ],
  
};
