import React from "react";
import { useParams } from "react-router-dom";
import CustomTemplates from "../WebPages/Deals/CustomTemplate";
import { blogDetails } from "../../components/template/CustomTemplate/Pages/blogDetails";

const AddBlog = () => {
  const params = useParams();

  return (
    <CustomTemplates page_type={"blog_item"} slug={params.id}  attributes={blogDetails} />
  );
};

export default AddBlog;
