import Brightness1OutlinedIcon from "@mui/icons-material/Brightness1Outlined";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import { checkPermission } from "../../../utils/Validation/checkPermission";
const MenuItems = ({ item, open, setOpen, route, main }) => {
  const [{ permission }, dispatch] = useDataLayerValue();
  const [active, setActive] = useState(false);
  let link = route + item.link;
  let location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname.includes(item.link)) {
      setActive(true);
    }
  }, [location.pathname, item.link]);
  const checkMenu = (item) => {
    let data = false;
    item?.subMenu?.map((item) => {
      if (item?.subMenu) {
        data = checkMenu(item);
      } else if (checkPermission(permission, "read_per", item?.title))
        data = true;
    });
    return data;
  };
  return (
    <div>
      {permission &&
        ((item?.subMenu && checkMenu(item)) ||
          checkPermission(permission, "read_per", item?.title)) && (
          <div>
            <ListItem
              key={item.title}
              onClick={() => {
                setOpen(true);
                if (item.subMenu) {
                  setActive(!active);
                } else {
                  navigate(link);
                }
                if (item?.onClick) {
                  item?.onClick();
                  navigate("/login");
                }
              }}
              disablePadding
              sx={{
                display: "block",
                background: main
                  ? "#262626"
                  : !item.subMenu && location.pathname.includes(item.link)
                  ? "red"
                  : "black",
                borderRadius: "5px",
                marginBottom: "5px",
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "white",
                  }}
                >
                  {item.icon ? (
                    item.icon
                  ) : (
                    <Brightness1OutlinedIcon sx={{ color: "white" }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={item?.title.length>15?item.title.slice(0,15)+"...":item.title}
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {item.subMenu && (
                  <Box
                    sx={{
                      minWidth: 0,
                      opacity: open ? 1 : 0,
                      justifyContent: "center",
                      color: "white",
                      marginLeft: "auto",
                    }}
                  >
                    {active ? (
                      <ExpandMoreIcon sx={{ color: "white" }} />
                    ) : (
                      <ChevronLeftIcon sx={{ color: "white" }} />
                    )}
                  </Box>
                )}
              </ListItemButton>
            </ListItem>{" "}
            {active &&
              item?.subMenu?.map((item) => (
                <MenuItems
                  setOpen={setOpen}
                  route={link}
                  open={open}
                  item={item}
                />
              ))}
          </div>
        )}
    </div>
  );
};

export default MenuItems;
