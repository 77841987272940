import { Button, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../Layout";
import HeaderBread from "../../../components/HeaderBread";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";

import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import CustomizedTable from "../../../components/UI/CustomizedTable";
import { getPermission } from "../../../utils/ApiCalls/Permission";
import DeletePop from "../../../constants/ErrorPermissionModal";
import { checkPermission } from "../../../utils/Validation/checkPermission";
import { message } from "antd";
import { deleteWebpage, getAllWebpage, updateWebpage } from "../../../utils/ApiCalls/WebPages";
import { constants } from "../../../constants/constants";
import SwitchBar from "../../../components/Pages/SwitchBar";
import { addlog } from "../../../utils/ApiCalls/Logs";
import Swal from "sweetalert2";
import ValidDateUI from "../../../constants/validDateUI";
const EventsListPage = () => {
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Event List",
      route: "/profile/user-management",
    },
  ];
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [{ userDetails,permission }, dispatch] = useDataLayerValue();
  const [regionalActive, setRegionalActive] = useState("SG")
  const navigate = useNavigate()
  
  const handleUpdate = async (id, values, title, location_id) => {
    if (checkPermission(permission, "update_per", "Events")) {
      let data = await updateWebpage(id, values);
      if (data.error) {
        // dispatch({
        //   type: "SET_ERROR_MESSAGE",
        //   errMess: { message: data.error, type: "error" },
        // });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.error,
        });
      } else {
        addlog(
          "Webpage " + !values.valid_from
            ? values.is_active === 1
              ? "Actived"
              : "Deactivated"
            : "updated",
          "Webpage",
          { ...values, id, title, location_id },
          userDetails.id
        );
      }
      await getData(page);
    } else {
      message.error("Update permission missing");
    }
  };
  const columns = [
    {
      title: "No",
      width: 2,
      dataIndex: "id",
      key: "position",
      fixed: "left",
      render: (position, record, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Image",
      width: 30,
      dataIndex: "image",
      key: "image",
      render: (image, record) => {

        return (
          <img
            src={`${constants.MEDIAURL}/${image}`}
            style={{ width: "100px", height: "80px" }}
          />
        );

      },
    },
    {
      title: "Title",
      width: 400,
      dataIndex: "page_name",
      key: "page_name",
      fixed: "left",
    },
    {
      title: "Valid date",
      dataIndex: "valid_date",
      key: "valid_till",

      render: (text, record, index) => {
        return <ValidDateUI record={record} handleUpdate={handleUpdate} />;
      },

    }, {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      sorter: (a, b) => "",

      render: (item, record, index) => {
        return (
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              checked={!(new Date(record.valid_to) <
                new Date(new Date()))&&item ? true : false}
              onChange={(value) => {
                   try {
                  if (
                    new Date(record.valid_from) >
                    new Date(new Date())
                  ) {
                    throw new Error(`Webpage is not yet started`);
                  }

                  if (
                    new Date(record.valid_to) <
                    new Date(new Date())
                  ) {
                    throw new Error(`Webpage is expired`);
                  }
                  handleUpdate(
                    record.id,
                    {
                      is_active: value.target.checked ? 1 : 0,
                    },
                    record.title
                  );
                } catch (error) {
                  dispatch({
                    type: "SET_LOADING",
                    loading: false,
                  });

                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.message,
                  });
                }
              }}
              className="custom-control-input"
              id={"customSwitch1" + index}
            />
            <label
              className="custom-control-label"
              style={{}}
              for={"customSwitch1" + index}
            ></label>
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "operation",
      width: 30,
      render: (text, record) => (
        <div className="table_action_btn">
          <div
            onClick={() =>
              navigate("/webpages/experiences/events/edit/" + record.slug)
            }
            className="btn"
          >
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <DeletePop
            onConfirm={() =>
              handleDelete(record.id, record.title, record.location_id)
            }
          >
            <div>
              {" "}
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getData(page);
  }, [page, pageSize,regionalActive]);
  const handleDelete = async (id) => {
    if (checkPermission(permission, "delete_per", "Events")) {
      await deleteWebpage(id);
      await getData(page);
    } else {
      message.error("Missing Permission for delete");
    }
  };

  const getData = async (value) => {
    let data = await getAllWebpage({ page_type: "events_item", page: value, limit: pageSize,regionalActive });
    const per = await getPermission();
    setRoles(per?.data?.map((item) => item.name));
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.data) {
      setRows(data.data);
      setCount(data.count);
    }
  };
  return (
    <Layout type={"read_per"} module_data={"events"}>
      <CustomHeaderTitle title={"Event List"} />
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"Event List"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <div className="card">
              <div className="card-header two_item">
                <h5 className="card-title">Events List</h5>
                <div className="two_item">
                  <Link style={{ marginRight: "10px" }}
                    to="/webpages/experiences/events/home"
                    className="btn add_v_btn"
                  >
                    Add Events Home
                  </Link>
                  <Link
                    to="/webpages/experiences/events/add"
                    className="btn add_v_btn"
                  >
                    Add Events
                  </Link>

                </div>
              </div>
              <div className="card-body">
                <div className="table_holder">
                  <SwitchBar
                    tabs={[
                      { title: "Singapore", value: "SG" },
                      { value: "MY", title: "Malaysia" },
                      { value: "ID", title: "Indonesia" },
                      { value: "IN", title: "India" },
                      { value: "CN", title: "China" },
                      { value: "TW", title: "Taiwan" },
                      { value: "HK", title: "Hong Kong" },
                      { value: "PH", title: "Philippines" },
                      { value: "AE", title: "UAE" },

                      { value: "OTHER", title: "All Others" },
                    ]}

                    activeTab={regionalActive}
                    setActiveTab={setRegionalActive}
                  />
                  {roles?.length > 0 && (
                    <CustomizedTable
                      setRows={setRows}
                      setPageSize={setPageSize}
                      setPage={setPage}
                      pageSize={pageSize}
                      page={page}
                      count={count}
                      columns={columns}
                      rows={rows}
                      title={"NewsLetter"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default EventsListPage;
