import React from "react";
import "./app.css";
import { getImage } from "../../../../../../utils/getImages";
const ShipDealPreview = ({ cardData, activetab }) => {
 
  return (
   
    <div>
      <div className="container-fluid py-3 py-md-5 section-grey">
        <div className="container py-4">
          <div className="row align-items-center">
          <div className="col-md-6">
              <img
                src={
                  getImage(cardData?.image)
                  }
                alt="Genting Dream"
                className="w-100 rounded"
              />
            </div>
            <div className="col-md-6 mt-4 mt-md-0 px-3 px-md-5">
               <h1 className="title-color mb-4" style={{direction:activetab === "arabic"&&"rtl"}}>
                {activetab === "english"
                  ? cardData?.title
                  : activetab === "simplified_chinese"
                  ? cardData?.simplified_chinese_title
                  : activetab === "arabic"
                  ? cardData?.arabic_title
                  : cardData?.traditional_chinese_title}
              </h1>
              <p
              style={{direction:activetab === "arabic"&&"rtl"}}
                dangerouslySetInnerHTML={{
                  __html:
                    activetab === "english"
                      ? cardData?.description
                      : activetab === "simplified_chinese"
                      ? cardData?.simplified_chinese_description
                      : activetab === "arabic"
                      ? cardData?.arabic_description
                      : cardData?.traditional_chinese_description,
                }}
              />

              <p className="download-itinerary">
                {cardData?.action1 && (
                  <a
                    className="langEN"
                    href={cardData?.action1link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {cardData?.action1}
                  </a>
                )}
                {cardData?.action2 && (
                  <a
                    className="langEN"
                    href={cardData?.action2link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {cardData?.action2}
                  </a>
                )}
              </p>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipDealPreview;
