import React from "react";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import FareCodeInputs from "../../../components/FareCode/FareCodeInputs";
import HeaderBread from "../../../components/HeaderBread";
import Layout from "../../../Layout";

const CreateFareCode = () => {
  
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Market",
      route: "/masters/markets",
    },
    {
      title: "Add Market",
      route: "/masters/markets/add",
    },
  ];

  return (
    <Layout type={"create_per"} module_data={"Markets"}>
      <CustomHeaderTitle title={"Add Market"} />
      <HeaderBread
        navigation={navigation}
        title={"Add Market"}
      ></HeaderBread>
      <FareCodeInputs banner={{}} />
    </Layout>
  );
};

export default CreateFareCode;
