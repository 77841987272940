import React from "react";
import "./app.css";
import { getImage } from "../../../../../utils/getImages";
const Life3Card = ({ cardData, activetab }) => {
  
  return (
    <div style={{ marginLeft: "10px" }}>
      <h1 className="title-color mb-4">
        {" "}
        {activetab === "english"
          ? cardData?.title
          : activetab === "simplified_chinese"
          ? cardData?.simplified_chinese_title
          : activetab === "arabic"
          ? cardData?.arabic_title
          : cardData?.traditional_chinese_title}
          
      </h1>
      <p className="title-color mb-4">
        {" "}
        {activetab === "english"
          ? cardData?.description
          : activetab === "simplified_chinese"
          ? cardData?.simplified_chinese_description
          : activetab === "arabic"
          ? cardData?.arabic_description
          : cardData?.traditional_chinese_description}
          
      </p>
      <div
        className="col-12 text-center mb-5"
        style={{ display: "flex", alignItems: "center", width: "100%" }}
      >
        <ul
          className="page-tab scroll-tab"
          style={{
            display: "flex",
            gap: "10px",

            listStyleType: "none",
            padding: 0,
            alignItems: "center",
            justifyContent: "center",
            margin: 0,
            width: "100%",
          }}
        >
          {cardData?.dataArray?.map((item, index) => (
            <li data-scroll-tab-seq={index + 1 + ""} className="">
              {activetab === "english"
                ? item?.title
                : activetab === "simplified_chinese"
                ? item?.simplified_chinese_title
                : activetab === "arabic"
                ? item?.arabic_title
                : item?.traditional_chinese_title}
            </li>
          ))}
        </ul>
      </div>
      <div
        style={{
          display: "flex",
          // alignItems: "center",
          flexWrap: "wrap",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <div className="row" style={{ width: "100%" }}>
          {cardData?.dataArray?.map((item) => (
            <div style={{ width: "100%" }}>
              <div className="col-12 text-center scroll-content-1">
                <h2 className="mb-5 title-grey"  style={{direction:activetab === "arabic"&&"rtl"}}>
                  {activetab === "english"
                    ? item?.title
                    : activetab === "simplified_chinese"
                    ? item?.simplified_chinese_title
                    : activetab === "arabic"
                    ? item?.arabic_title
                    : item?.traditional_chinese_title}
                        <div
                            style={{direction:activetab === "arabic"&&"rtl",fontSize:"15px",fontWeight:"400",marginTop:10}}
                              dangerouslySetInnerHTML={{
                                __html:
                                  activetab === "english"
                                    ? item?.description
                                    : activetab === "simplified_chinese"
                                    ? item?.simplified_chinese_description
                                    : activetab === "arabic"
                                    ? item?.arabic_description
                                    : item?.traditional_chinese_description,
                              }}
                            />
                </h2>
            
              </div>
              
              <div
                className="row"
                style={{ width: "100%"}}
              >
                {item?.dataArray?.map((item) => (
               
                    <div className="col-md-3">
                      <div className="destination-card">
              <img alt=""   src={getImage(item?.image)} className="w-100 d-block"/>
              <div className="info">
                <h5 className="title-color" data-lang="{ &quot;CN&quot;:&quot;馬斯喀特市導覽&quot; }"> {activetab === "english"
                                ? item?.title
                                : activetab === "simplified_chinese"
                                ? item?.simplified_chinese_title
                                : activetab === "arabic"
                                ? item?.arabic_title
                                : item?.traditional_chinese_title}</h5>
                <p > <p
                            style={{direction:activetab === "arabic"&&"rtl"}}
                              dangerouslySetInnerHTML={{
                                __html:
                                  activetab === "english"
                                    ? item?.description
                                    : activetab === "simplified_chinese"
                                    ? item?.simplified_chinese_description
                                    : activetab === "arabic"
                                    ? item?.arabic_description
                                    : item?.traditional_chinese_description,
                              }}
                            /></p>
                  </div>
            </div>
                     
                    </div>
                  
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Life3Card;
