import { CircularProgress } from "@mui/material";
import { Button, Result } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../DataLayer/DataLayer";
import { getToken } from "../utils/Api";
import { getPermission } from "../utils/ApiCalls/Permission";
import { validateUserToken } from "../utils/ApiCalls/UserCalls";
import { checkPermission } from "../utils/Validation/checkPermission";
import MiniDrawer from "./MuiDrawer";

const Layout = ({ children, type, module_data }) => {
  const navigate = useNavigate();
  const [{ token, userDetails, permission, permission_error }, dispatch] =
    useDataLayerValue();
  
  const [loading, setLoading] = useState(true);  // New loading state
  
  useEffect(() => {
    getToken();
    getData();
  }, []);

  const getData = async () => {
    let data = await validateUserToken();

    dispatch({
      type: "SET_USER_DETAILS",
      userDetails: data,
    });
    
    let value = await getPermission({ search: data?.role });
    dispatch({
      type: "SET_PERMISSION",
      permission: value?.data?.[0],
    });

    setLoading(false);  // Set loading to false when permission data is fetched
  };

  if (loading) {
    // Show loading spinner while data is being fetched
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <MiniDrawer>
        <div
          style={{
            padding: "10px",
            minHeight: "600px",
            background: "#f5f3f2",
            width: "100%",
            flex: 1,
          }}
        >
          {checkPermission(permission, type, module_data) ? (
            children
          ) : (
            <Result
              status="403"
              title="403"
              subTitle="Sorry, you are not authorized to access this page."
              extra={
                <Button
                  onClick={() => navigate("/profile/my-profile")}
                  type="primary"
                >
                  Back Home
                </Button>
              }
            />
          )}
        </div>
        <footer
          style={{
            width: "100%",
            backgroundColor: "#b3b3b3",
            border: "none",
            textAlign: "center",
            color: "#000",
            padding: "1rem",
          }}
        >
          Copyright &copy; 2023 All rights reserved.
        </footer>{" "}
      </MiniDrawer>
    </div>
  );
};

export default Layout;
