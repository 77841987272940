import React, { useState, useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";

const CabinCategory = ({ cabinCategoryData, getData, setCabinCategoryId, cabinCategoryId, setPage }) => {
  const [selectedValue, setSelectedValue] = useState(
    cabinCategoryData.find((item) => item.cabin_category_id === cabinCategoryId) || null
  );

  useEffect(() => {
    getData(1);
    setPage(1);
  }, [selectedValue]);

  return (
    <Autocomplete
      options={cabinCategoryData}
      getOptionLabel={(option) => 
        `${option.cabin_category_display_name} ${option.cabin_type_display_name ? `(${option.cabin_type_display_name})` : ""}`
      }
      value={selectedValue}
      onChange={(event, newValue) => {
        setSelectedValue(newValue);
        setCabinCategoryId(newValue ? newValue.cabin_category_id : "");
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="Select Cabin Categories"
          variant="outlined"
          style={{ width: "200px", height: "40px", marginRight: "10px" }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.cabin_category_id === value.cabin_category_id}
    />
  );
};

export default CabinCategory;
