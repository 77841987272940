import { common } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PageNotFound from "./404-page";
import "./App.css";
import { DataLayer } from "./DataLayer/DataLayer";
import ProtectedRoute from "./DataLayer/ProtectedRoute";
import reducer, { initialState } from "./DataLayer/reducer";
import Booking from "./Pages/Bookings/CabinBookings";
import EditBookings from "./Pages/Bookings/CabinBookings/EditBooking";
import Leads from "./Pages/Bookings/Leads";
import EditLeads from "./Pages/Bookings/Leads/EditLeads";
import ChangePassword from "./Pages/ChangePassword";
import Dashboard from "./Pages/Dashboard";
import ForgetPassword from "./Pages/ForgetPassword";
import Banner from "./Pages/Homepage/Banner/Banner";
import CreateBanner from "./Pages/Homepage/Banner/CreateBanner";
import EditBanner from "./Pages/Homepage/Banner/EditBanner";
import DealDetails from "./Pages/Homepage/DealDetails";
import CreateDealDetails from "./Pages/Homepage/DealDetails/CreateDealDetails";
import EditDealDetails from "./Pages/Homepage/DealDetails/EditDealDetails";
import CreateDeal from "./Pages/Homepage/Deals/CreateDeal";
import EditDeal from "./Pages/Homepage/Deals/EditDeal";
import CreateDestination from "./Pages/Homepage/Destination/CreateDestination";
import Destination from "./Pages/Homepage/Destination/Destinations";
import EditDestination from "./Pages/Homepage/Destination/EditDestination";
import Experience from "./Pages/Homepage/Experience";
import CreateExperience from "./Pages/Homepage/Experience/CreateExperience";
import EditExperience from "./Pages/Homepage/Experience/EditExperience";
import NewsLetter from "./Pages/Homepage/NewsLetter";
import EditNewsLetter from "./Pages/Homepage/NewsLetter/EditNewsLetter";
import CreateLocation from "./Pages/Location/CreateLocation";
import EditLocation from "./Pages/Location/EditLocation";
import Location from "./Pages/Location/Location";
import Login from "./Pages/Login";
import CabinCategory from "./Pages/Master/CabinCategory";
import CreateCabinCategory from "./Pages/Master/CabinCategory/CreateCabinCategory";
import EditCabinCategory from "./Pages/Master/CabinCategory/EditCabinCategory";
import CabinMaster from "./Pages/Master/CabinModule";
import CreateCabinMaster from "./Pages/Master/CabinModule/CreateCabinMaster";
import EditCabinMaster from "./Pages/Master/CabinModule/EditCabinMaster";
import CabinTypes from "./Pages/Master/CabinTypes";
import {
  default as CreateCabinType,
  default as CreateNewsLetter,
} from "./Pages/Master/CabinTypes/CreateCabinType";
import EditCabinType from "./Pages/Master/CabinTypes/EditCabinType";
import FareCode from "./Pages/Master/FareCode";
import CreateFareCode from "./Pages/Master/FareCode/CreateFareCode";
import EditFareCode from "./Pages/Master/FareCode/EditFareCode";
import OtherPage from "./Pages/OtherPages";
import CreateOtherPages from "./Pages/OtherPages/CreateOtherPage";
import EditOtherPage from "./Pages/OtherPages/EditOtherPage";
import EditPackage from "./Pages/Packages/EditPackages";
import Packages from "./Pages/Packages/Packages";
import Promos from "./Pages/PromoIndex/PromoIndex";
import MyProfile from "./Pages/Profile/MyProfile";
import Permission from "./Pages/Profile/Permisson";
import Roles from "./Pages/Profile/Role";
import EditRole from "./Pages/Profile/Role/EditRole";
import UserManagment from "./Pages/Profile/UserMangment";
import EditUser from "./Pages/Profile/UserMangment/EditUser";
import Register from "./Pages/Register";
import ResetPassword from "./Pages/ResetPassword";
import SpecialPages from "./Pages/SpecialPages";
import CreateSpecialPages from "./Pages/SpecialPages/CreateSpecialPage";
import EditSpecialPage from "./Pages/SpecialPages/EditSpecialPage";
import OurStoryPage from "./Pages/WebPages/AboutUs/OurStory";
import {
  default as FAQ,
} from "./Pages/WebPages/AboutUs/SafetyGuidsPage";
import {
  default as ThePalacePage,
} from "./Pages/WebPages/AboutUs/ThePalace";
import BrochuresGuides from "./Pages/WebPages/BrochuresGuides";
import ContactUs from "./Pages/WebPages/Contact";
import DealsPage from "./Pages/WebPages/Deals";
import AddDealsPage from "./Pages/WebPages/Deals/AddDealsPage";
import Promotions from "./Pages/WebPages/Deals/Promotions";
import WebPage from "./Pages/WebPages/Deals/WebPage";
import DestinationPage from "./Pages/WebPages/DestinationsPage";
import EditDestinationPage from "./Pages/WebPages/DestinationsPage/EditDestinationPage";
import EventsPageMain from "./Pages/WebPages/Events";
import EventsListPage from "./Pages/WebPages/EventsPage";
import AddEventsPage from "./Pages/WebPages/EventsPage/AddEventsPage";
import EditEventsPage from "./Pages/WebPages/EventsPage/EditEventsPage";
import EditEntertainmentPage from "./Pages/WebPages/Experiences/Entertainment";
import LifeatRWC from "./Pages/WebPages/Experiences/LifeatRWC";
import MICEPage from "./Pages/WebPages/Experiences/MICE";
import ExperiencePage from "./Pages/WebPages/ExperiencesPage";
import BillofRights from "./Pages/WebPages/Footer/Bill of Rights";
import Career from "./Pages/WebPages/Footer/Career";
import { Ships } from "./Pages/WebPages/Ships";
import MediaAndResource from "./Pages/WebPages/Ships copy/MediaAndResource";
import PrepareForCruise from "./Pages/WebPages/Ships copy/PrepareForCruise";
import EditShips from "./Pages/WebPages/Ships/Editships";
import ErrorHandler from "./constants/ErrorHandler";
import Loading from "./constants/Loading";
import "./dist/css/adminlte.min.css";
import "./global/custom.css";
import { getToken } from "./utils/Api";
import AddBlog from "./Pages/AddBlog/AddBlog";
import Blogs from "./Pages/WebPages/Blogs";
import KnowBeforeYouSail from "./Pages/WebPages/Ships copy/KnowBeforeYouSail";
import { HelmetProvider } from "react-helmet-async";
import BalconyClassMain from "./Pages/WebPages/AboutUs/BalconyClassMain";
import SafetyGuidsPages from "./Pages/WebPages/AboutUs/SafetyGuidsPages";
import EditGroupedPackages from "./Pages/Packages/EditGroupedPackages";
import WebPageTeamplate from "./Pages/Homepage/WebPageTeamplate";
import CreateWebpageTeamplate from "./Pages/Homepage/WebPageTeamplate/CreateWebPageTeamplate";
import Announcement from "./Pages/Homepage/Announcement";
import CreateAnnouncement from "./Pages/Homepage/Announcement/CreateAnnouncement";
import EditAnnouncement from "./Pages/Homepage/Announcement/EditAnnouncement";


function App() {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/profile/user-management/add",
      element: (
        <ProtectedRoute>
          <Register />
        </ProtectedRoute>
      ),
    },
    {
      path: "/profile/user-management/edit/:id",
      element: (
        <ProtectedRoute>
          <EditUser />
        </ProtectedRoute>
      ),
    },
    {
      path: "/profile/reset-password",
      element: (
        <ProtectedRoute>
          <ChangePassword />
        </ProtectedRoute>
      ),
    },
    {
      path: "/forget-password",
      element: <ForgetPassword />,
    },
    {
      path: "/reset-password",
      element: <ResetPassword />,
    },
    {
      path: "/",
      element: (
        <ProtectedRoute>
          {" "}
          <Dashboard />{" "}
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/banner",
      element: (
        <ProtectedRoute>
          {" "}
          <Banner />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/announcement",
      element: (
        <ProtectedRoute>
          {" "}
          <Announcement />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/announcement/add",
      element: (
        <ProtectedRoute>
          {" "}
          <CreateAnnouncement />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/announcement/edit/:id",
      element: (
        <ProtectedRoute>
          <EditAnnouncement />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/banner/add",
      element: (
        <ProtectedRoute>
          {" "}
          <CreateBanner />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/banner/edit/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditBanner />
        </ProtectedRoute>
      ),
    },
    // {
    //   path: "/cms/homepage/deals",
    //   element: (
    //     <ProtectedRoute>
    //       {" "}
    //       <Deals />
    //     </ProtectedRoute>
    //   ),
    // },
    {
      path: "/cms/homepage/deals/add",
      element: (
        <ProtectedRoute>
          {" "}
          <CreateDeal />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/deals/edit/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditDeal />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/destinations",
      element: (
        <ProtectedRoute>
          <Destination />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/destinations/add",
      element: (
        <ProtectedRoute>
          {" "}
          <CreateDestination />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/destinations/edit/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditDestination />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/packages",
      element: (
        <ProtectedRoute>
          {" "}
          <Packages />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/promo-index",
      element: (
        <ProtectedRoute>
          {" "}
          <Promos />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/experience",
      element: (
        <ProtectedRoute>
          {" "}
          <Experience />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/experience/add",
      element: (
        <ProtectedRoute>
          {" "}
          <CreateExperience />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/experience/edit/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditExperience />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/packages",
      element: (
        <ProtectedRoute>
          {" "}
          <Packages />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/packages/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditPackage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/grouppackages/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditGroupedPackages />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/other-pages",
      element: (
        <ProtectedRoute>
          {" "}
          <OtherPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/other-pages/add",
      element: (
        <ProtectedRoute>
          <CreateOtherPages />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/other-pages/edit/:id",
      element: (
        <ProtectedRoute>
          <EditOtherPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/special-pages",
      element: (
        <ProtectedRoute>
          {" "}
          <SpecialPages />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/special-pages/add",
      element: (
        <ProtectedRoute>
          <CreateSpecialPages />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/special-pages/edit/:id",
      element: (
        <ProtectedRoute>
          <EditSpecialPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/news-letter",
      element: (
        <ProtectedRoute>
          {" "}
          <NewsLetter />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/news-letter/add",
      element: (
        <ProtectedRoute>
          {" "}
          <CreateNewsLetter />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/news-letter/edit/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditNewsLetter />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/deal-details",
      element: (
        <ProtectedRoute>
          {" "}
          <DealDetails />
        </ProtectedRoute>
      ),
    },

    {
      path: "/cms/homepage/deal-details/add",
      element: (
        <ProtectedRoute>
          {" "}
          <CreateDealDetails />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/deal-details/edit/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditDealDetails />
        </ProtectedRoute>
      ),
    },
    {
      path: "/masters/cabin-types",
      element: (
        <ProtectedRoute>
          {" "}
          <CabinTypes />
        </ProtectedRoute>
      ),
    },
    {
      path: "/masters/cabin-types/add",
      element: (
        <ProtectedRoute>
          {" "}
          <CreateCabinType />
        </ProtectedRoute>
      ),
    },
    {
      path: "/masters/cabin-types/edit/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditCabinType />
        </ProtectedRoute>
      ),
    },
    {
      path: "/masters/cabin-category",
      element: (
        <ProtectedRoute>
          {" "}
          <CabinCategory />
        </ProtectedRoute>
      ),
    },
    {
      path: "/masters/cabin-category/add",
      element: (
        <ProtectedRoute>
          {" "}
          <CreateCabinCategory />
        </ProtectedRoute>
      ),
    },
    {
      path: "/masters/cabin-category/edit/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditCabinCategory />
        </ProtectedRoute>
      ),
    },
    {
      path: "/masters/markets",
      element: (
        <ProtectedRoute>
          {" "}
          <FareCode />
        </ProtectedRoute>
      ),
    },
    {
      path: "/masters/markets/add",
      element: (
        <ProtectedRoute>
          {" "}
          <CreateFareCode />
        </ProtectedRoute>
      ),
    },
    {
      path: "/masters/markets/edit/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditFareCode />
        </ProtectedRoute>
      ),
    },
    {
      path: "/masters/cabin-master",
      element: (
        <ProtectedRoute>
          {" "}
          <CabinMaster />
        </ProtectedRoute>
      ),
    },
    {
      path: "/masters/cabin-master/add",
      element: (
        <ProtectedRoute>
          {" "}
          <CreateCabinMaster />
        </ProtectedRoute>
      ),
    },
    {
      path: "/masters/cabin-master/edit/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditCabinMaster />
        </ProtectedRoute>
      ),
    },

    {
      path: "*",
      element: <PageNotFound />,
    },
    {
      path: "/cms/homepage/location",
      element: (
        <ProtectedRoute>
          {" "}
          <Location />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/location/add",
      element: (
        <ProtectedRoute>
          {" "}
          <CreateLocation />
        </ProtectedRoute>
      ),
    },
    {
      path: "/cms/homepage/location/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditLocation />
        </ProtectedRoute>
      ),
    },
    {
      path: "/bookings/leads",
      element: (
        <ProtectedRoute>
          {" "}
          <Leads />
        </ProtectedRoute>
      ),
    },
    {
      path: "/bookings/leads/edit/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditLeads />
        </ProtectedRoute>
      ),
    },
    {
      path: "/bookings/cabin-booking",
      element: (
        <ProtectedRoute>
          {" "}
          <Booking />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/deals",
      element: (
        <ProtectedRoute>
          {" "}
          <DealsPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/deals/celebration-packages",
      element: (
        <ProtectedRoute>
          <AddDealsPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/deals/onboard-promotions",
      element: (
        <ProtectedRoute>
          <Promotions />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/about-us/our-story",
      element: (
        <ProtectedRoute>
          <OurStoryPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/about-us/the-palace",
      element: (
        <ProtectedRoute>
          <ThePalacePage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/about-us/balcony-class",
      element: (
        <ProtectedRoute>
          <BalconyClassMain />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/header/prepare-for-your-cruise",
      element: (
        <ProtectedRoute>
          <PrepareForCruise />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/header/know-before-you-sail",
      element: (
        <ProtectedRoute>
          <KnowBeforeYouSail />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/about-us/brochures-guides",
      element: (
        <ProtectedRoute>
          <BrochuresGuides />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/footer/privacy-policy",
      element: (
        <ProtectedRoute>
          <BillofRights router={"privacy-policy"} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/footer/bill-of-rights",
      element: (
        <ProtectedRoute>
          <BillofRights router={"bill-of-rights"} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/footer/career",
      element: (
        <ProtectedRoute>
          <Career />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/footer/terms-of-usage",
      element: (
        <ProtectedRoute>
          <BillofRights router={"terms-of-usage"} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/footer/terms-and-conditions",
      element: (
        <ProtectedRoute>
          <BillofRights router={"terms-and-conditions"} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/footer/cookie-policy",
      element: (
        <ProtectedRoute>
          <BillofRights router={"cookie-policy"} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/footer/passager-terms",
      element: (
        <ProtectedRoute>
          <BillofRights router={"passage-terms"} />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/about-us/safety-guides",
      element: (
        <ProtectedRoute>
          <SafetyGuidsPages />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/footer/press-releases",
      element: (
        <ProtectedRoute>
          <MediaAndResource />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/footer/contact-us",
      element: (
        <ProtectedRoute>
          <ContactUs />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/about-us/faq",
      element: (
        <ProtectedRoute>
          <FAQ />
        </ProtectedRoute>
      ),
    },
    {
      path: "/bookings/cabin-booking/edit/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditBookings />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/templates",
      element: (
        <ProtectedRoute>
          {" "}
          <WebPageTeamplate />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/templates/add",
      element: (
        <ProtectedRoute>
          {" "}
          <CreateWebpageTeamplate />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/ships",
      element: (
        <ProtectedRoute>
          {" "}
          <Ships />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/ships/edit/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditShips />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/ships/add",
      element: (
        <ProtectedRoute>
          {" "}
          <EditShips />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/destinations",
      element: (
        <ProtectedRoute>
          {" "}
          <DestinationPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/destinations/edit/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditDestinationPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/destinations/add",
      element: (
        <ProtectedRoute>
          {" "}
          <EditDestinationPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/experiencePage",
      element: (
        <ProtectedRoute>
          {" "}
          <ExperiencePage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/experiences/entertainment",
      element: (
        <ProtectedRoute>
          {" "}
          <EditEntertainmentPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/experiences/events",
      element: (
        <ProtectedRoute>
          {" "}
          <EventsListPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/experiences/events/edit/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <EditEventsPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/experiences/events/add",
      element: (
        <ProtectedRoute>
          {" "}
          <AddEventsPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/experiences/events/home",
      element: (
        <ProtectedRoute>
          {" "}
          <EventsPageMain />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/blog/add",
      element: (
        <ProtectedRoute>
          {" "}
          <AddBlog />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/blog/edit/:id",
      element: (
        <ProtectedRoute>
          {" "}
          <AddBlog />
        </ProtectedRoute>
      ),
    },

    {
      path: "/webpages/experiences/mice",
      element: (
        <ProtectedRoute>
          {" "}
          <MICEPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/experiences/life-at-rwc",
      element: (
        <ProtectedRoute>
          {" "}
          <LifeatRWC />
        </ProtectedRoute>
      ),
    },
    {
      path: "/webpages/blog",
      element: (
        <ProtectedRoute>
          {" "}
          <Blogs />
        </ProtectedRoute>
      ),
    },
    {
      path: "/profile/my-profile",
      element: (
        <ProtectedRoute>
          {" "}
          <MyProfile />
        </ProtectedRoute>
      ),
    },
    {
      path: "/profile/user-management",
      element: (
        <ProtectedRoute>
          {" "}
          <UserManagment />
        </ProtectedRoute>
      ),
    },
    {
      path: "/profile/role/add",
      element: (
        <ProtectedRoute>
          <Permission />
        </ProtectedRoute>
      ),
    },
    {
      path: "/profile/role/edit/:id",
      element: (
        <ProtectedRoute>
          <EditRole />
        </ProtectedRoute>
      ),
    },
    {
      path: "/template",
      element: (
        <ProtectedRoute>
          <WebPage />
        </ProtectedRoute>
      ),
    },
    {
      path: "/profile/role",
      element: (
        <ProtectedRoute>
          {" "}
          <Roles />
        </ProtectedRoute>
      ),
    },
  ]);

  useEffect(() => {
    getToken();
  }, []);
  const customTheme = (theme) =>
    createTheme({
      ...theme,
      palette: {
        ...theme.palette,
        primary: {
          main: common.black,
        },
      },
    });
  return (
    <HelmetProvider>
      <ThemeProvider theme={customTheme}>
        <DataLayer initialState={initialState} reducer={reducer}>
          <ErrorHandler />
          <Loading />
          <RouterProvider router={router} />
        </DataLayer>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
