import React from "react";
import { OnboradingPromotion } from "../../../components/template/CustomTemplate/Pages/OnboradingPromotion";
import CustomTemplates from "./CustomTemplate";

const Promotions = () => {
 
  return ( 
    <CustomTemplates slug={"onboard-promotions"} attributes={OnboradingPromotion} />
  );
};

export default Promotions;
