import React, { useState, useEffect } from "react";
import { Box, Chip, MenuItem, Select } from "@mui/material";

const Vessel = ({ getData, setVesselId, vesselData, vesselId, width, setPage }) => {
//   const [options, setOption] = useState();
  const [selectedValue, setSelectedValue] = useState(vesselId);

  useEffect(() => {
    console.log("selected Value ====>", selectedValue);
      getData(1);
      setPage(1);
  }, [selectedValue]);
  console.log("vesselFilter====>", vesselData)
  return (
    <Select
      style={{ width: width || "22%", height: "40px", marginRight:"10px" }}
      required
      displayEmpty
      renderValue={vesselId !== "" ? undefined : () => "Select Vessel"}
      value={selectedValue}
      onChange={(e) => {
        setVesselId(e.target.value);
        setSelectedValue(e.target.value);
      }}
    >
      {vesselData.map((item, index) => (
        <MenuItem key={index} value={item.id}>
          {`${item.name}`}
        </MenuItem>
      ))}
    </Select>
  );
};

export default Vessel;
