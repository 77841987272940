import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../Layout";
import HeaderBread from "../../components/HeaderBread";
import {
  deleteLocationData,
  getLocationData,
  updateLocationStatus,
} from "../../utils/ApiCalls/Location/index";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import SearchField from "../../components/SearchField";
import CustomizedTable from "../../components/UI/CustomizedTable";
import { addlog } from "../../utils/ApiCalls/Logs";
import SwitchBar from "../../components/Pages/SwitchBar";
import Logs from "../../components/Logs";
import ActiveInactive from "../../components/UI/ActiveInactive";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DeletePop from "../../constants/ErrorPermissionModal";
import { message } from "antd";
import { checkPermission } from "../../utils/Validation/checkPermission";

const MySwal = withReactContent(Swal);

const Locations = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Location List",
      route: "/cms/homepage/locations",
    },
  ];

  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState("listing");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState("");
  const [{ userDetails, permission }, dispatch] = useDataLayerValue();
  const sortHandler = async (options) => {
    if (Object.keys(options).length > 0) {
      let sort, order;
      sort = options.columnKey;
      order =
        options.order === "ascend"
          ? "asc"
          : options.order === "descend"
          ? "desc"
          : undefined;

      await getData(1, sort, order);
    }
  };

  const locationColumns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (text, record, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Continent",
      dataIndex: "continent",
      key: "continent",
      width: 80,
      sorter: (a, b) => "",
      render: (continent) => <p>{continent}</p>,
    },
    {
      title: "Agency",
      dataIndex: "agency",
      key: "agency",
      width: 80,
      sorter: (a, b) => "",
      render: (agency) => <p>{agency?.agencyCode}</p>,
    },
    {
      title: "Country",
      width: 80,
      dataIndex: "country",
      key: "location_name",
      sorter: (a, b) => "",
      render: (location_name, record) => <p>{location_name}</p>,
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
      sorter: (a, b) => "",
      width: 100,
      render: (language) => <p>{language.english_name}</p>,
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: 100,
      sorter: (a, b) => "",
      render: (is_active, record, index) => (
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            checked={is_active ? true : false}
            onChange={(value) => {
              handleUpdate(
                record.id,
                {
                  is_active: value.target.checked ? 1 : 0,
                },
                record.continent
              );
            }}
            className="custom-control-input"
            id={"customSwitch1" + index}
          />
          <label
            className="custom-control-label"
            style={{}}
            for={"customSwitch1" + index}
          ></label>
        </div>
      ),
    },

    {
      title: "Action",
      key: "operation",

      width: 100,
      render: (text, record, index) => (
        <div className="table_action_btn">
          <div className="btn" onClick={() => handleEdit(record.id)}>
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <DeletePop
            onConfirm={() => handleDelete(record.id, record.continent)}
          >
            <div>
              {" "}
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getData(page);
  }, [page, pageSize, search]);

  const handleUpdate = async (id, values, title) => {
    if (checkPermission(permission, "update_per", "Location")) {
      let data = await updateLocationStatus(id, values);
      if (data.error) {
        // dispatch({
        //   type: "SET_ERROR_MESSAGE",
        //   errMess: { message: data.error, type: "error" },
        // });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.error,
        });
      } else {
        addlog(
          "Location  " + !values.valid_from
            ? values.is_active === 1
              ? "Actived"
              : "Deactivated"
            : "updated",
          "Location",
          { ...values, id, title },
          userDetails.id
        );
      }
      await getData(page);
    } else {
      message.error("Update permission missing");
    }
  };

  const handleEdit = async (id) => {
    if (checkPermission(permission, "update_per", "Location")) {
      navigate("/cms/homepage/location/" + id);
    } else {
      message.error("Update permission missing");
    }
  };

  const handleDelete = async (id, title) => {
    if (checkPermission(permission, "delete_per", "Location")) {
      await deleteLocationData(id);
      addlog("Location deleted", "Location", { id, title }, userDetails.id);
      await getData();
    } else {
      message.error("Missing Permission for delete Location");
    }
  };

  const getData = async (value, sort, order) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let args = { search: search, page: value, limit: pageSize };
    if (status !== "") {
      args = { ...args, is_active: status };
    }
    if (order) {
      if (sort) {
        args = { ...args, sort };
      }
      args = { ...args, order };
    }
    console.log("args==>", args);
    let data = await getLocationData(args);
    console.log("data=>", data);
    if (data.error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.error,
      });
    } else if (data.data) {
      setRows(data.data);
      setCount(data.count);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  return (
    <Layout type={"read_per"} module_data={"Location"}>
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"Locations"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <SwitchBar
              tabs={[
                { title: "Location List", value: "listing" },
                { value: "logs", title: " History Logs" },
              ]}
              activeTab={active}
              setActiveTab={setActive}
            />
            {active === "listing" && (
              <div className="card">
                <div className="card-header two_item">
                  <h5 className="card-title">Location List</h5>
                  <div className="two_item" style={{ width: "350px" }}>
                    <ActiveInactive
                      getData={getData}
                      setStatus={setStatus}
                      status={status}
                    />
                    <Link to="/cms/homepage/location/add" className="btn add_v_btn">
                      Add Location
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table_holder">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div style={{ maxWidth: 200 }}>
                        <SearchField
                          value={search}
                          SearchValue={() => {
                            setPage(1);
                          }}
                          setValue={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {rows && (
                      <CustomizedTable
                        setRows={setRows}
                        setPageSize={setPageSize}
                        setPage={setPage}
                        pageSize={pageSize}
                        page={page}
                        count={count}
                        columns={locationColumns}
                        rows={rows}
                        title={"Locations"}
                        sortHandler={sortHandler}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>
      {active === "logs" && (
        <Logs
          type={"Location"}
          slug={"/cms/homepage/location/edit/"}
          refreshlog={active}
        />
      )}
    </Layout>
  );
};

export default Locations;
