import { Checkbox } from "@mui/material";
import { Divider } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomizedTable from "../UI/CustomizedTable";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import {
  addModule,
  addPermission,
  getModule,
  updatePermission,
} from "../../utils/ApiCalls/Permission";
const EditRoleInput = ({ roles }) => {
  const navigate = useNavigate();

  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [module_name, setModule_Name] = useState("");
  const [roleData, setRoleData] = useState(
    roles || {
      name: "",
      read_per: [],
      create_per: [],
      update_per: [],
      delete_per: [],
      download_per: [],
    }
  );
  const [, dispatch] = useDataLayerValue();
  const columns = [
    {
      title: "Module",
      dataIndex: "name",
      key: "name",
      width: 50,
      render: (item, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          {" "}
          <div
            className="iti_inner"
            style={{ marginLeft: "20px", marginTop: "-10px" }}
          >
            <div className="icheck-primary d-inline">
              <input
                type="checkbox"
                id={"customCheckbox4allcreate_per" + record.module_key}
                name="r10"
                onChange={(e) => {
                  if (e.target.checked) {
                    checkModule(record.module_key);
                    // addData("create_per", record?.module_key, "add");
                  } else {
                    checkModule(record.module_key, "remove");
                    // addData("create_per", record?.module_key, "remove");
                  }
                }}
              />
              <label
                htmlFor={"customCheckbox4allcreate_per" + record.module_key}
              ></label>
            </div>
          </div>
          <p style={{ fontWeight: "bold" }}>{item}</p>
        </div>
      ),
    },

    {
      title: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {" "}
          <div className="iti_inner" style={{ marginTop: "5px" }}>
            <div className="icheck-primary d-inline">
              <input
                type="checkbox"
                id={"customCheckbox4_read_per"}
                name="r10"
                onChange={(e) => {
                  if (e.target.checked) {
                    checkSinglePer("read_per");
                  } else {
                    checkSinglePer("read_per", "remove");
                  }
                }}
              />
              <label htmlFor={"customCheckbox4_read_per"}></label>
            </div>
          </div>
          <div>View</div>{" "}
        </div>
      ),
      dataIndex: "read",
      key: "read",
      width: 50,
      render: (item, record) => (
        <p>
          <div className="iti_inner">
            <div className="icheck-primary d-inline">
              <input
                type="checkbox"
                id={"customCheckbox4" + record?.module_key + "read_per"}
                name="r10"
                checked={roleData?.read_per?.includes(record?.module_key)}
                onChange={(e) => {
                  if (e.target.checked) {
                    addData("read_per", record?.module_key, "add");
                  } else {
                    addData("read_per", record?.module_key, "remove");
                  }
                }}
              />
              <label
                htmlFor={"customCheckbox4" + record?.module_key + "read_per"}
              ></label>
            </div>
          </div>
        </p>
      ),
    },

    {
      title: <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {" "}
          <div className="iti_inner" style={{ marginTop: "5px" }}>
            <div className="icheck-primary d-inline">
              <input
                type="checkbox"
                id={"customCheckbox4_create_per"}
                name="r10"
                onChange={(e) => {
                  if (e.target.checked) {
                    checkSinglePer("create_per");
                  } else {
                    checkSinglePer("create_per", "remove");
                  }
                }}
              />
              <label htmlFor={"customCheckbox4_create_per"}></label>
            </div>
          </div>
          <div>Create</div>{" "}
        </div>,
      dataIndex: "create",
      key: "create",
      width: 50,
      render: (item, record) => (
        <p>
          <div className="iti_inner">
            <div className="icheck-primary d-inline">
              <input
                type="checkbox"
                id={"customCheckbox4" + record?.module_key + "create_per"}
                name="r10"
                checked={roleData?.create_per?.includes(record?.module_key)}
                onChange={(e) => {
                  if (e.target.checked) {
                    addData("create_per", record?.module_key, "add");
                  } else {
                    addData("create_per", record?.module_key, "remove");
                  }
                }}
              />
              <label
                htmlFor={"customCheckbox4" + record?.module_key + "create_per"}
              ></label>
            </div>
          </div>
        </p>
      ),
    },
    {
      title: <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {" "}
          <div className="iti_inner" style={{ marginTop: "5px" }}>
            <div className="icheck-primary d-inline">
              <input
                type="checkbox"
                id={"customCheckbox4_update_per"}
                name="r10"
                onChange={(e) => {
                  if (e.target.checked) {
                    checkSinglePer("update_per");
                  } else {
                    checkSinglePer("update_per", "remove");
                  }
                }}
              />
              <label htmlFor={"customCheckbox4_update_per"}></label>
            </div>
          </div>
          <div>Update</div>{" "}
        </div>,
      dataIndex: "update",
      key: "update",
      width: 50,
      render: (item, record) => (
        <p>
          <div className="iti_inner">
            <div className="icheck-primary d-inline">
              <input
                type="checkbox"
                id={"customCheckbox4" + record?.module_key + "update_per"}
                name="r10"
                checked={roleData?.update_per?.includes(record?.module_key)}
                onChange={(e) => {
                  if (e.target.checked) {
                    addData("update_per", record?.module_key, "add");
                  } else {
                    addData("update_per", record?.module_key, "remove");
                  }
                }}
              />
              <label
                htmlFor={"customCheckbox4" + record?.module_key + "update_per"}
              ></label>
            </div>
          </div>
        </p>
      ),
    },

    {
      title:<div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {" "}
          <div className="iti_inner" style={{ marginTop: "5px" }}>
            <div className="icheck-primary d-inline">
              <input
                type="checkbox"
                id={"customCheckbox4_delete_per"}
                name="r10"
                onChange={(e) => {
                  if (e.target.checked) {
                    checkSinglePer("delete_per");
                  } else {
                    checkSinglePer("delete_per", "remove");
                  }
                }}
              />
              <label htmlFor={"customCheckbox4_delete_per"}></label>
            </div>
          </div>
          <div>Delete</div>{" "}
        </div>,
      dataIndex: "delete",
      key: "delete",
      width: 50,
      render: (item, record) => (
        <p>
          <div className="iti_inner">
            <div className="icheck-primary d-inline">
              <input
                type="checkbox"
                id={"customCheckbox4" + record?.module_key + "delete_per"}
                name="r10"
                checked={roleData?.delete_per?.includes(record?.module_key)}
                onChange={(e) => {
                  if (e.target.checked) {
                    addData("delete_per", record?.module_key, "add");
                  } else {
                    addData("delete_per", record?.module_key, "remove");
                  }
                }}
              />
              <label
                htmlFor={"customCheckbox4" + record?.module_key + "delete_per"}
              ></label>
            </div>
          </div>
        </p>
      ),
    },
    {
      title: <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {" "}
          <div className="iti_inner" style={{ marginTop: "5px" }}>
            <div className="icheck-primary d-inline">
              <input
                type="checkbox"
                id={"customCheckbox4_download_per"}
                name="r10"
                onChange={(e) => {
                  if (e.target.checked) {
                    checkSinglePer("download_per");
                  } else {
                    checkSinglePer("download_per", "remove");
                  }
                }}
              />
              <label htmlFor={"customCheckbox4_download_per"}></label>
            </div>
          </div>
          <div>Download</div>{" "}
        </div>,
      dataIndex: "download",
      key: "download",
      width: 50,
      render: (item, record) => (
        <p>
          <div className="iti_inner">
            <div className="icheck-primary d-inline">
              <input
                type="checkbox"
                id={"customCheckbox4" + record?.module_key + "remove_per"}
                name="r10"
                checked={roleData?.download_per?.includes(record?.module_key)}
                onChange={(e) => {
                  if (e.target.checked) {
                    addData("download_per", record?.module_key, "add");
                  } else {
                    addData("download_per", record?.module_key, "remove");
                  }
                }}
              />
              <label
                htmlFor={"customCheckbox4" + record?.module_key + "remove_per"}
              ></label>
            </div>
          </div>
        </p>
      ),
    },
  ];
  useEffect(() => {
    getData();
  }, []);
  let checkall = (type) => {
    let rowsvalue = rows.map((item) => item.module_key);
    let data = {
      update_per: type ? [] : rowsvalue,
      read_per: type ? [] : rowsvalue,
      create_per: type ? [] : rowsvalue,
      delete_per: type ? [] : rowsvalue,
      download_per: type ? [] : rowsvalue,
    };
    setRoleData((item) => ({ ...item, ...data }));
  };
  let checkModule = (module_key, type) => {
    let data = {
      update_per: type
        ? roleData.update_per.filter((item) => item != module_key)
        : [...roleData.update_per, module_key],
      read_per: type
        ? roleData.read_per.filter((item) => item != module_key)
        : [...roleData.read_per, module_key],
      create_per: type
        ? roleData.create_per.filter((item) => item != module_key)
        : [...roleData.create_per, module_key],
      delete_per: type
        ? roleData.delete_per.filter((item) => item != module_key)
        : [...roleData.delete_per, module_key],
      download_per: type
        ? roleData.download_per.filter((item) => item != module_key)
        : [...roleData.download_per, module_key],
    };
    setRoleData((item) => ({ ...item, ...data }));
  };
  let checkSinglePer = (permission, type) => {
    let data = {
      [permission]: type ? [] : rows.map((item) => item.module_key),
    };
    setRoleData((item) => ({ ...item, ...data }));
  };
  useEffect(() => {
    if (roles?.name) {
      setRoleData(roles);
    }
  }, [roles?.name]);
  const addData = (type, value, action) => {
    if (action === "add") {
      let data = roleData[type];
      data.push(value);
      setRoleData((item) => ({
        ...item,
        [type]: data,
      }));
    } else {
      let data = roleData[type].filter((item) => item != value);

      setRoleData((item) => ({
        ...item,
        [type]: data,
      }));
    }
  };
  const getData = async () => {
    const data = await getModule();
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data) {
      setRows(data);
      setCount(10);
    }
  };
  const addModulesData = async (e) => {
    e.preventDefault();
    const module_key = module_name?.replaceAll(" ", "_").toLowerCase();
    if (module_key) {
      let data = await addModule({ name: module_name, module_key });
      getData();
      setModule_Name("");
    }
  };
  const addRoleData = async (e) => {
    e.preventDefault();
    let data;
    if (roleData?.id) data = await updatePermission(roleData?.id, roleData);
    else data = await addPermission(roleData);
    navigate("/profile/role");
  };
  return (
    <section className="content" style={{ marginTop: "20px" }}>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header two_item">
            <h5 className="card-title">Add Roles</h5>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              {" "}
              <div className="iti_inner" style={{ marginLeft: "20px" }}>
                <div className="icheck-primary d-inline">
                  <input
                    type="checkbox"
                    id={"customCheckbox4allcreate_per2"}
                    name="r10"
                    onChange={(e) => {
                      if (e.target.checked) {
                        checkall();
                        // addData("create_per", record?.module_key, "add");
                      } else {
                        checkall("remove");
                        // addData("create_per", record?.module_key, "remove");
                      }
                    }}
                  />
                  <label
                    style={{ marginTop: "-10px" }}
                    htmlFor={"customCheckbox4allcreate_per2"}
                  >
                    <p>Check All</p>{" "}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="card-body col-lg-12">
            <CustomizedTable
              setRows={setRows}
              setPageSize={setPageSize}
              setPage={setPage}
              pageSize={pageSize}
              page={page}
              count={count}
              columns={columns}
              rows={rows}
              title={"Permission"}
            />
          </div>
          <form className="input_form" onSubmit={addRoleData}>
            <div className="card-body">
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-lg-4 ">
                  <div className="fieldrow">
                    <label className="f_label">Role Name</label>
                    <input
                      className="f_input"
                      required
                      type="text"
                      value={roleData?.name}
                      onChange={(e) =>
                        setRoleData((item) => ({
                          ...item,
                          name: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>{" "}
              </div>
              <div className=" text-right">
                <button className="btn main_submit">
                  {" "}
                  {roles?.id ? "Update" : "Add"} Role
                </button>
              </div>
            </div>
          </form>
          <Divider style={{ color: "black", border: "1px solid grey" }} />
          <form className="input_form" onSubmit={addModulesData}>
            <div className="card-body">
              <div className="row" style={{ alignItems: "center" }}>
                <div className="col-lg-4 ">
                  <div className="fieldrow">
                    <label className="f_label">Module Name</label>
                    <input
                      className="f_input"
                      required
                      type="text"
                      value={module_name}
                      onChange={(e) => setModule_Name(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 ">
                  <button className="btn main_submit">Add Module</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default EditRoleInput;
