import React from "react";
import { brochures_and_guide } from "../../../components/template/CustomTemplate/Pages/brochures_and_guide copy";
import CustomTemplates from "../Deals/CustomTemplate";

const BrochuresGuides= () => {

  return (
    <CustomTemplates slug={"brochures-guides"}  attributes={brochures_and_guide} />
  );
};

export default BrochuresGuides;
