import { Box, Modal } from '@mui/material';
import React from 'react'

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    border: "none",
    boxShadow: 24,
    p: 4,
};

const FormPopup = ({ children, open, setOpen, width, height,sx }) => {
    const handleClose = () => {
        setOpen(false)
    }
    return (

        <Modal
            open={open}
            onClose={(e) => {
                handleClose();

            }}
            sx={{ border: "none" }}
        >
            <Box sx={{ ...style, width, height,...sx }}>{children}</Box></Modal>
    )
}

export default FormPopup