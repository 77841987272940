import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";

export const createFareCode = async (data) => {
  try {
    let res = await Api.post("/agency-master", data);
    return res?.data;
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getFareCodeData = async (query) => {
  try {
    let res = await Api.get("/agency-master/all", {
      params: query,
    });
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const getCountries = async (query) => {
  try {
    let res = await Api.get("/countries", {
      params: query,
    });
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const deleteFareCodeData = async (id) => {
  try {
    let res = await Api.delete("/agency-master/" + id);
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updateFareCodeData = async (id, data) => {
  try {
    let res = await Api.patch("/agency-master/" + id, data);
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to Update" };
  }
};
