import React, { useEffect, useState } from 'react';

const YouTubeEmbed = ({ videoUrl,height,width }) => {
  const [videoId, setVideoId] = useState(null);

  useEffect(() => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = videoUrl.match(regex);

    if (match && match[1]) {
      setVideoId(match[1]);
    } else {
      console.error('Invalid YouTube URL');
    }
  }, [videoUrl]);

  if (!videoId) {
    return null; // or render a placeholder, error message, etc.
  }

  return (
    <div>
      {videoId?<iframe
        width={width||"560"}
        height={height||"315"}
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allowFullScreen
      ></iframe>:<p>Not valid youtube link</p>}
    </div>
  );
};

export default YouTubeEmbed;
