import React from "react";
import HeaderBread from "../../components/HeaderBread";
import Layout from "../../Layout";
import { useNavigate } from "react-router-dom";
import CustomHeaderTitle from "../../components/constants/CustomHeaderTitle";
import OtherPagesInputs from "../../components/Pages/OtherPagesInputs";

const CreateOtherPages = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Other Pages",
      route: "/cms/other-pages",
    },
    {
      title: "Add Other Pages",
      route: "/cms/other-pages/add",
    },
  ];

  return (
    <Layout>
      <CustomHeaderTitle title={"Create Other Pages"} />
      <HeaderBread
        navigation={navigation}
        title={"Create Other Pages"}
      ></HeaderBread>
      <OtherPagesInputs banner={{}} />
    </Layout>
  );
};

export default CreateOtherPages;
