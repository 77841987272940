import Api from "../../Api";

export const createAnnouncement = async (data) => {

  try {
    let res = await Api.post("/announcement", data);
    return res?.data;
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const getAnnouncement = async (query) => {
  try {
    let res = await Api.get("/announcement", {
      params: query,
    });

    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const exportAnnouncement = async (query) => {
  try {
    let res = await Api.get("/announcement/export", {
      responseType: "arraybuffer"
    });

    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const deleteAnnouncement = async (id) => {
  try {
    let res = await Api.delete("/announcement/" + id);

    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updateAnnouncement = async (id, data) => {
  try {
    let res = await Api.patch("/announcement/" + id, data);

    return res?.data || [];
  } catch (e) {
    return { error: "Failed to Update" };
  }
};
