import Card5Palace from "../Previews/Card5Palace/Preview";
import PalceCard5 from "../Previews/Card5Ship/PalceCard5";
import DestinationPreview from "../Previews/DestinationPreview/DestinationPreview";
import PreviewPalace from "../Previews/PreviewPalace/Preview";
import BalconyClassBannerPreview from "../Previews/Ship/Banner/BalconyClassBannerPreview";

export const BalconyClassPage = {
  form: [
    {
      title: "Page Name",
      key: "page_name",
      className: "col-lg-12",
      type: "input",
    },
    {
      title: "Meta Description",
      key: "meta_description",
      className: "col-lg-12",
      type: "input",
    },
  ],
  component: [
    {
      type: 1,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "Page Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Page Image",
          type: "file",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <BalconyClassBannerPreview activetab={activetab} cardData={cardData} />
      ),
    },

    {
      type: 1,
      fields: [
        {
          title: "Page Logo",
          key: "logo",
          className: "col-lg-3",
          placeholder: "Upload Page Logo",
          type: "file",
        },
        {
          title: "Simplified Chinese Logo",
          key: "simplified_chinese_logo",
          placeholder: "Upload Simplified Chinese Page Logo",
          className: "col-lg-3",
          type: "file",
          required: false,
        },
        {
          title: "Traditional Chinese Logo",
          key: "traditional_chinese_logo",
          className: "col-lg-3",
          placeholder: "Upload Traditional Chinese Page Logo",
          type: "file",
          required: false,
        },
        {
          title: "Arabic Logo",
          key: "arabic_logo",
          placeholder: "Upload Arabic Page Logo",
          className: "col-lg-3 arabic",
          type: "file",
          required: false,
        },

        {
          title: "Page Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Page Image",
          type: "file",
        },

        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <PreviewPalace activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 1,
      fields: [
        {
          title: "Page Video",
          key: "image",
          filetype: "video",
          className: "col-lg-3",
          placeholder: "Upload Page Video",
          type: "file",
          required: false,
        },
        {
          title: "Simplified Chinese Video",
          key: "simplified_chinese_image",
          filetype: "video",
          className: "col-lg-3",
          placeholder: "Upload Page Video",
          type: "file",
          required: false,
        },
        {
          title: "Traditional Chinese Video",
          key: "traditional_chinese_image",
          filetype: "video",
          className: "col-lg-3",
          placeholder: "Upload Page Video",
          type: "file",
          required: false,
        },
        {
          title: "Arabic Video",
          key: "arabic_image",
          filetype: "video",
          className: "col-lg-3",
          placeholder: "Upload Page Video",
          type: "file",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <DestinationPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 2,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
      ],
      level1: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false,
        },
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "html",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <PalceCard5 activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 2,
      fields: [
        {
          title: "English Note",
          key: "note",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Note",
          key: "simplified_chinese_note",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Note",
          key: "traditional_chinese_note",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Note",
          key: "arabic_note",
          className: "col-lg-3 arabic",
          type: "input",
        },
      ],
      level1: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false,
        },
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "html",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <Card5Palace activetab={activetab} cardData={cardData} />
      ),
    },
  ],
};
