import Api from "../../Api";

export const getlanguages=async()=>{
    try {
        let res = await Api.get("/language");
        //
        return (res?.data || [])
    }
    catch (e) {
        console.log(e);
        return ([])
    }
}