import DestinationPreview from "../Previews/DestinationPreview/DestinationPreview";
import Life3Card from "../Previews/lifeCard3/Preview";
import ShipBannerPreview from "../Previews/Ship/Banner/Preview";

export const LifeAtRwc = {
  form: [
    {
      title: "Page Name",
      key: "page_name",
      className: "col-lg-12",
      type: "input",
      required: false,
    },
    {
      title: "Meta Description",
      key: "meta_description",
      className: "col-lg-12",
      type: "input",
      required: false,
    },
  ],
  component: [
    {
      type: 1,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "Page Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Page Image",
          type: "file",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <ShipBannerPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 1,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Page Video",
          key: "image",
          filetype: "video",
          className: "col-lg-12",
          placeholder: "Upload Page Video",
          type: "file",
          required: false,
        },
        {
          title: "Simplified Chinese Video",
          key: "simplified_chinese_image",
          filetype: "video",
          className: "col-lg-12",
          placeholder: "Upload Page Video",
          type: "file",
          required: false,
        },
        {
          title: "Traditional Chinese Video",
          key: "traditional_chinese_image",
          filetype: "video",
          className: "col-lg-12",
          placeholder: "Upload Page Video",
          type: "file",
          required: false,
        },
        {
          title: "Arabic Video",
          key: "arabic_image",
          filetype: "video",
          className: "col-lg-12",
          placeholder: "Upload Page Video",
          type: "file",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <DestinationPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 3,
      fields: [],
      level1: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
      ],
      level2: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false,
        },
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
        },
        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
        },
      ],
      preview: (activetab, cardData) => (
        <Life3Card activetab={activetab} cardData={cardData} />
      ),
    },
  ],
};
