import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../Layout";
import HeaderBread from "../../../components/HeaderBread";

import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import SearchField from "../../../components/SearchField";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";

import Logs from "../../../components/Logs";
import SwitchBar from "../../../components/Pages/SwitchBar";
import ActiveInactive from "../../../components/UI/ActiveInactive";
import CabinCategory from "../../../components/UI/CabinCategory";
import CustomizedTable from "../../../components/UI/CustomizedTable";
import Vessel from "../../../components/UI/Vessel";
import { constants } from "../../../constants/constants";
import {
  getCabin,
  getCabinCategoryByVessel,
  updateCabin,
} from "../../../utils/ApiCalls/Cabin";
import { addlog } from "../../../utils/ApiCalls/Logs";
import { getVessels } from "../../../utils/ApiCalls/SpecialPage/index";

import { message } from "antd";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { checkPermission } from "../../../utils/Validation/checkPermission";

const MySwal = withReactContent(Swal);

const CabinMaster = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("listing");
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Cabin Master",
      route: "/masters/cabin-master",
    },
  ];
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState("");
  const [vesselId, setVesselId] = useState("");
  const [{ userDetails, permission }, dispatch] = useDataLayerValue();
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [vessels, setVessels] = useState([]);
  const [cabinCategory, setCabinCategory] = useState([]);
  const [cabinCategoryId, setCabinCategoryId] = useState("");

  const sortHandler = async (options) => {
    if (Object.keys(options).length > 0) {
      let sort, order;
      sort = options.columnKey;
      order =
        options.order === "ascend"
          ? "asc"
          : options.order === "descend"
          ? "desc"
          : undefined;
      setSort(sort);
      setOrder(order);
      await getData(1, sort, order);
    }
  };

  const columns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (text, record, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Image",
      width: 100,
      dataIndex: "image",
      key: "image",
      render: (image) => (
        <img
          src={`${constants.MEDIAURL}/${image}`}
          style={{ width: "100px", height: "80px" }}
        />
      ),
    },
    {
      title: "Vessel",
      dataIndex: "vessel_name",
      key: "vessel_name",
      sorter: (a, b) => "",
      width: 50,
    },
    {
      title: "Cabin Type",
      dataIndex: "cabin_type_name",
      key: "cabin_type_name",
      sorter: (a, b) => "",
      width: 50,
    },
    {
      title: "Cabin Category",
      dataIndex: "cabin_category_name",
      key: "cabin_category_name",
      sorter: (a, b) => "",
      width: 50,
    },
    {
      title: "Cabin Code",
      dataIndex: "cabin_code",
      key: "cabin_code",
      sorter: (a, b) => "",
      width: 50,
    },
    {
      title: "Variant",
      dataIndex: "varient",
      key: "varient",
      sorter: (a, b) => "",
      width: 50,
    },

    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: 100,
      sorter: (a, b) => "",
      render: (item, record, index) => (
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            checked={item ? true : false}
            onChange={(value) => {
              handleUpdate(
                record.id,
                {
                  is_active: value.target.checked ? 1 : 0,
                },
                record.cabin_code
              );
            }}
            className="custom-control-input"
            id={"customSwitch1" + index}
          />
          <label
            className="custom-control-label"
            style={{}}
            for={"customSwitch1" + index}
          ></label>
        </div>
      ),
    },

    {
      title: "Action",
      key: "operation",
      width: 40,
      render: (text, record) => (
        <div className="table_action_btn">
          <div className="btn" onClick={() => handleEdit(record.id)}>
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
        
        </div>
      ),
    },
  ];
  useEffect(() => {
    getData(page, sort, order);
  }, [page, pageSize, search]);

  useEffect(() => {
    getVesselsData();
    // setVessels(vessels);
  }, []);

  useEffect(() => {
    console.log("vesselId===>", vesselId);
    if (vesselId) {
      getCabinCategoryByVesselData(vesselId);
    }
  }, [vesselId]);

  const getVesselsData = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });

    let vessels = await getVessels();
    if (vessels.error) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: vessels.error, type: "error" },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: vessels.error,
      });
    } else {
      vessels.push({ id: null, name: "All" });
      setVessels(vessels);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  const getCabinCategoryByVesselData = async (vesselId) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });

    let cabin_categoryByVessel = await getCabinCategoryByVessel(vesselId);
    if (cabin_categoryByVessel.error) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: cabin_categoryByVessel.error, type: "error" },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: cabin_categoryByVessel.error,
      });
    } else {
      console.log("cabin_categoryByVessel===>", cabin_categoryByVessel);
      // setVessels(vessels);
      cabin_categoryByVessel.push({
        cabin_category_id: null,
        cabin_category_display_name: "All",
      });
      setCabinCategory(cabin_categoryByVessel);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  const getData = async (value, sort, order) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let args = { search: search, page: value, limit: pageSize };
    if (cabinCategoryId) {
      args = { ...args, cabin_category_id: cabinCategoryId };
    }
    if (vesselId) {
      args = { ...args, vessel_id: vesselId };
    }
    if (status !== "") {
      args = { ...args, is_active: status };
    }
    if (order) {
      if (sort) {
        args = { ...args, sort };
      }
      args = { ...args, order };
    }

    let data = await getCabin(args);
    if (data.error) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: data.error, type: "error" },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.error,
      });
    } else if (data.data) {
      console.log({ data });
      setRows(data.data);
      setCount(data.count);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  const handleEdit = async (id) => {
    if (checkPermission(permission, "update_per", "Cabin Master")) {
      navigate("/masters/cabin-master/edit/" + id);
    } else {
      message.error("Update permission missing");
    }
  };

  const handleUpdate = async (id, values, title) => {
    let data = await updateCabin(id, values);
    if (data.error) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: data.error, type: "error" },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.error,
      });
    } else {
      addlog(
        "Cabin " + !values.valid_from
          ? values.is_active === 1
            ? "Actived"
            : "Deactivated"
          : "updated",
        "Cabin_Master",
        { ...values, id, title },
        userDetails.id
      );
    }

    getData(page, sort, order);
  };

  console.log("cabinCategoryId=====>", cabinCategoryId);
  return (
    <Layout type={"read_per"} module_data={"Cabin Master"}>
      <CustomHeaderTitle title={"Cabin Master"} />
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"Cabin Master"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <SwitchBar
              tabs={[
                { title: "Cabin Master List", value: "listing" },
                { value: "logs", title: " History Logs" },
              ]}
              activeTab={active}
              setActiveTab={setActive}
            />
            {active === "listing" && (
              <div className="card">
                <div className="card-header two_item">
                  <h5 className="card-title">Cabin Master List</h5>
                  <div
                    style={{
                      width: "1050px",
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <ActiveInactive
                      getData={getData}
                      setStatus={setStatus}
                      status={status}
                      width={"15%"}
                      setPage={setPage}
                    />
                    <Vessel
                      vesselData={vessels}
                      getData={getData}
                      setVesselId={setVesselId}
                      setCabinCategoryId={setCabinCategoryId}
                    />
                    <CabinCategory
                      cabinCategoryData={cabinCategory}
                      getData={getData}
                      setPage={setPage}
                      setCabinCategoryId={setCabinCategoryId}
                      cabinCategoryId={cabinCategoryId}
                    />
                    <Link
                      to="/masters/cabin-master/add"
                      className="btn add_v_btn"
                      style={{ display: "inline-block" }}
                    >
                      Add Cabin Master
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table_holder">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div style={{ maxWidth: 200 }}>
                        <SearchField
                          value={search}
                          SearchValue={() => {
                            setPage(1);
                          }}
                          setValue={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {rows && (
                      <CustomizedTable
                        setRows={setRows}
                        setPageSize={setPageSize}
                        setPage={setPage}
                        pageSize={pageSize}
                        page={page}
                        count={count}
                        columns={columns}
                        rows={rows}
                        title={"Cabin Master"}
                        sortHandler={sortHandler}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        {active === "logs" && (
          <Logs
            type={"Cabin_Master"}
            slug={"/masters/cabin-master/edit/"}
            refreshlog={active}
          />
        )}
      </div>
    </Layout>
  );
};

export default CabinMaster;
