import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";

export const addLeads = async (data) => {
  try {
    let res = await Api.post("/leads", data);
    return res?.data;
  } catch (e) {
    console.log(e);
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getLeadsData = async (query) => {
  try {
    let res = await Api.get("/leads", {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};
export const getLeadsInfo = async (id) => {
  try {
    let res = await Api.get("/leads/" + id);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};
export const getBookings = async (query) => {
  console.log(query);
  try {
    let res = await Api.get("/booking", {
      params: query,
    });

    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};
export const getSingleBookings = async (id) => {
  try {
    let res = await Api.get("/booking/" + id);

    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const getGuestData = async (query) => {
  try {
    let res = await Api.get("/guest", {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const getPaymentData = async (query) => {
  try {
    let res = await Api.get("/payment", {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const getEmergencyData = async (query) => {
  try {
    let res = await Api.get("/emergency-contact", {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};
export const getancillary = async (query) => {
  try {
    let res = await Api.get("/guest/ancillary", {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const deleteLeadsData = async (id) => {
  try {
    let res = await Api.delete("/leads/" + id);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updateLeadsData = async (id, data) => {
  try {
    let res = await Api.patch("/leads/" + id, data);
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};
