import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";

export const createSpecialPages = async (data) => {
  try {
    let res = await Api.post("/special-pages", data);
    return res?.data;
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

// export const getPageDataLocation = async (query) => {
//   try {
//     let res = await Api.get("/special-pages/location", {
//       params: query,
//       headers:{
//         "Authorization": `Bearer ${localStorage.getItem("token")}`
//       }
//     });
//   
//     return res?.data || [];
//   } catch (e) {
//     return { error: "Something Wrong" };
//   }
// };

export const getLocationSpecialPageData = async(query)=>{
  try {
    let res = await Api.get("/special-pages/location", {
      params: query,
      headers:{
        "Authorization": `Bearer ${localStorage.getItem("token")}`
      }
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
}

export const createSpecialPagesPackage = async (data) => {
  try {
    let res = await Api.post("/special-page-itinerary", data);
    return res?.data;
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};


export const getSpecialPagesPackageData = async (query) => {
  try {
    let res = await Api.get("/special-page-itinerary", {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};


export const getSpecialPagesData = async (query) => {
  try {
    let res = await Api.get("/special-pages", {
      params: query,
    });
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const deleteSpecialPagesData = async (id) => {
  try {
    let res = await Api.delete("/special-pages/" + id);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updateSpecialPagesData = async (id, data) => {
  try {
    let res = await Api.patch("/special-pages/" + id, data);
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getAgencies = async () => {
  try {
    let res = await Api.get("/agency-master", {
      params: { fields: "agencyCode" },
    });
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getAgencyById = async (id) => {
  try {
    let res = await Api.get("/agency-master/"+ id);
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getMarketCodeByAgency = async (agencyCode) => {
  try {
    let res = await Api.get("/agency-master/agency/"+ agencyCode);
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};



export const getLocationByAgency = async (query) => {
  try {
    console.log("query=====>", query)
    let res = await Api.get("/location/agency", {
      params: query,
    });
    return res?.data || [];
  } catch (e) {
    console.log(e);
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getVessels = async () => {
  try {
    let res = await Api.get("/vessels", {
      params: { fields: "id,name" },
    });
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getSpecialPagePackages = async (data) => {
  try {
    let res = await Api.post("/fare-code/codes", data);
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getSpecialPageLocation = async (data) => {
  try {
    let res = await Api.post("/fare-code/location", data);
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const updatePosition = async(deals, query)=>{
  try {
    let res = await Api.patch("/special-pages/relocate-position", deals, {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
}


export const getItineary = async()=>{
  try {
    let res = await Api.get("/itineraries/all");
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
}