import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Layout from "../../../Layout";
import HeaderBread from "../../../components/HeaderBread";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import { getCabin, getSingleCabin, getCabinVarientByVarientId } from "../../../utils/ApiCalls/Cabin";
import CabinMasterInput from "../../../components/CabinMaster/CabinMasterInputs";

const EditCabinMaster = () => {
  const params = useParams();
  const [cabin, setCabin] = useState({});
  const [{}, dispatch] = useDataLayerValue();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Cabin Master",
      route: "/masters/cabin-master",
    },
    {
      title: "Edit Cabin Master",
      route: "/masters/cabin-master/edit",
    },
  ];
  useEffect(() => {
    getData(params?.id);
  }, [params]);

  const getData = async (value) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getSingleCabin(value);
    let cabinVarientData = await getCabinVarientByVarientId(data?.cabin_variant_id);

    if (data.error || cabinVarientData.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data) {
      setCabin({...data, size: cabinVarientData?.size, deck_no: cabinVarientData?.deck_no});
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <Layout type={"update_per"} module_data={"Cabin Master"}>
      <CustomHeaderTitle title={"Edit Cabin Master"} />
      <HeaderBread
        navigation={navigation}
        title={"Edit Cabin Master"}
      ></HeaderBread>
      {cabin && <CabinMasterInput cabin={cabin} />}
    </Layout>
  );
};

export default EditCabinMaster;
