import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderBread from "../../../components/HeaderBread";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Layout from "../../../Layout";

import { message } from "antd";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import CustomizedTable from "../../../components/UI/CustomizedTable";
import DeletePop from "../../../constants/ErrorPermissionModal";
import { deleteTemplates, getTemplates } from "../../../utils/ApiCalls/Templates";
import { checkPermission } from "../../../utils/Validation/checkPermission";

const WebPageTeamplate = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Templates",
      route: "/cms/templates",
    },
  ];
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [{ permission }, dispatch] = useDataLayerValue();
  const columns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (position, record, index) => ((page - 1) * pageSize) + index + 1,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 50,
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (text, record) => (
        <div className="table_action_btn">
          <div className="btn" onClick={() => handleEdit(record.id)}>
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <DeletePop onConfirm={() => handleDelete(record.id)}>
            <div>
              {" "}
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getData(page);
  }, [page, pageSize]);

  const getData = async (value) => {
    let data = await getTemplates({ page: value, limit: pageSize });
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.data) {
      setRows(data.data);
      setCount(data.count);
    }
  };
  const handleEdit = async (id) => {
    if (checkPermission(permission, "update_per", "Templates")) {
      navigate("/webpages/templates/edit/" + id);
    } else {
      message.error("Update permission missing");
    }
  };
  const handleDelete = async (id) => {
    if (checkPermission(permission, "delete_per", "Templates")) {
      deleteTemplates(id);
      getData();
    } else {
      message.error("Missing Permission for delete");
    }
  };



  return (
    <Layout type={"read_per"} module_data={"Templates"}>
      <CustomHeaderTitle title={"Templates"} />
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"Templates"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <div className="card">
              <div className="card-header two_item">
                <h5 className="card-title">Templates List</h5>
                <div className="two_item">
                  <Link to="/webpages/templates/add" className="btn add_v_btn mx-2">
                    Add Teamplate
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <div className="table_holder">
                  <CustomizedTable
                    setRows={setRows}
                    setPageSize={setPageSize}
                    setPage={setPage}
                    pageSize={pageSize}
                    page={page}
                    count={count}
                    columns={columns}
                    rows={rows}
                    title={"Templates"}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default WebPageTeamplate;
