import React, { useEffect, useState } from "react";
import YouTubeEmbed from "./YouTubeEmbed";

const UploadFileImageBanner = ({
  selectedFile,
  setSelectedFile,
  className,
  image,
  mandatory,
  mediaType,
  label,
  placeholder,
}) => {
  const [remove, setRemove] = useState(false);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  useEffect(() => {
    if (mediaType != "link") setSelectedFile();
    else {
      setSelectedFile(image);
    }
  }, [mediaType]);
  const handleRemoveImage = () => {
    setRemove(true);
    setSelectedFile();
  };
  return (
    <div className={className||"col-12"}>
      <div className="fieldrow">
        <label className="f_label">{label}{<span style={{color: "red"}}> {mandatory}</span>}</label>
        {mediaType === "link" ? (
          <input
            required
            className="f_input"
            type="text"
            value={selectedFile}
            onChange={(e) => setSelectedFile(e.target.value)}
          />
        ) : (
          <div className="upload_box">
            <input
              type="file"
              onChange={handleFileChange}
              accept={mediaType === "image" ? "image/*" : "video/*"}
            />
            <i className="ri-upload-cloud-2-line"></i>
            <span>{placeholder}</span>
            <span>{"Width: 1920,Height: 826"}</span>
          </div>
        )}
      </div>{" "}
      <div className="fieldrow uploaded_item">
        <ul>
          {selectedFile && typeof selectedFile !== "string" && (
            <li>
              {mediaType === "image" ? (
                <img src={URL.createObjectURL(selectedFile)} alt="" />
              ) : (
                <video
                  controls
                  src={URL.createObjectURL(selectedFile)}
                  style={{ height: 100, width: 200 }}
                ></video>
              )}
              <button onClick={handleRemoveImage} type="button">
                <i className="ri-close-line"></i>
              </button>
            </li>
          )}
          {selectedFile && typeof selectedFile === "string" && (
            <YouTubeEmbed
              height={"100"}
              width={"200"}
              videoUrl={selectedFile}
            />
          )}
          {image && !selectedFile && !remove && (
            <li style={{ width: mediaType === "link" && "100%" }}>
              {mediaType === "image" ? (
                <img src={image} alt="" />
              ) : mediaType === "link" ? (
                <YouTubeEmbed height={"100"} width={"200"} videoUrl={image} />
              ) : (
                <video
                  controls
                  src={image}
                  style={{ height: 100, width: 200 }}
                ></video>
              )}
              <button onClick={handleRemoveImage} type="button">
                <i className="ri-close-line"></i>
              </button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default UploadFileImageBanner;
