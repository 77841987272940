import React, { useEffect, useRef } from 'react';

const Video = ({ url }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      // Reload the video and play it when the URL changes
      videoRef.current.load();
    //   videoRef.current.play();
    }
  }, [url]);

  return (
    <div className="info">
      <video
        ref={videoRef}
        controls
        loop
        muted
        playsInline
        className="w-100 mt-4 mx-auto rounded"
      >
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div></div>
    </div>
  );
};

export default Video;
