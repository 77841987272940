import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";

const ReactQuillData2 = ({ data, setData, level, key }) => {
  const [value, setValue] = useState(data || "");
  const [trigger,setTrigger]=useState(true)
  useEffect(() => {
    if(data&&trigger){
    setValue(data)
      setTrigger(true)
  }
 
  }, [data]);
  useEffect(() => {
    setData(value);
  }, [value]);

  // Define the toolbar and other modules for ReactQuill
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [
        {
          color: ["red", "blue", "yellow","#E5BF83","gray","#dc3545"],
        },
      ],
      ["link", "image", "video"],
      ["clean"],
      [{ direction: "rtl" }],
    ],
    clipboard: {
      matchVisual: true,
    },
  };

  return (
    <div>
      <ReactQuill
        key={key}
        modules={modules}
        theme="snow"
        style={{ height: "150px" }}
        value={value} // Controlled value
        onChange={setValue} // Update value when content changes
      />
    </div>
  );
};

export default ReactQuillData2;
