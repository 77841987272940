import { Box, Chip, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill"; // Import React Quill
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../DataLayer/DataLayer";

import { uploadFile } from "../../utils/ApiCalls/Document";

import { checkFields } from "../../utils/Validation/checkFields";
import UploadFileImage from "../imageUpload/uploadFileImage";
import { getVessels } from "../../utils/ApiCalls/SpecialPage";
import {
  createCabin,
  getCabinCategory,
  getCabinTypes,
  getCabinVarient,
  updateCabin,
} from "../../utils/ApiCalls/Cabin";
import { constants } from "../../constants/constants";
import { addlog } from "../../utils/ApiCalls/Logs";
import { IMAGE_SUB_TYPE, IMAGE_TYPE } from "../../constants/ImageConstant";



import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const CabinMasterInput = ({ cabin }) => {
  const [traditional_chinese_description, settraditional_chinese_description] =
    useState(cabin?.traditional_chinese_description || "");
  const [englishDescription, setEnglishDescription] = useState(
    cabin?.description || ""
  );
  const [simplified_chinese_description, setsimplified_chinese_description] =
    useState(cabin?.description || "");
    const [arabic_description, setarabic_description] =
    useState(cabin?.description || "");
  const [simplifiedChineseTitle, setSimplifiedChineseTitle] = useState(
    cabin?.simplifiedChineseTitle || ""
  );
  const [traditionalChineseTitle, setTraditionalChineseTitle] = useState(
    cabin?.traditionalChineseTitle || ""
  );
  const [isActive, setIsActive] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [traditionalChineseFile, setTraditionalChineseFile] = useState(null);
  const [arabicFile, setarabicFile] = useState(null);
  const [arabicChineseFile, setarabicChineseFile] = useState(null);
  const [simplifiedChineseFile, setSimplifiedChineseFile] = useState(null);
  const [traditional_chinese_image, setTraditional_chinese_image] =
    useState(null);
    const [arabic_image, setarabic_image] =
    useState(null);
  const [simplified_chinese_image, setSimplified_chinese_image] =
    useState(null);
    const [image, setImage] = useState(cabin?.image || null);

  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [error, setError] = useState();
  const navigate = useNavigate();
  const [vesselOptions, setVesselOptions] = useState([]);
  const [vessel_id, setVessel_id] = useState("");
  const [cabinTypesOptions, setCabinTypesOptions] = useState([]);
  const [cabin_types, setcabin_types] = useState("");
  const [cabinCategoryOption, setCabinCategoryOption] = useState([]);
  const [cabin_category, setcabin_category] = useState("");
  const [
    simplified_chinese_cabin_category,
    setsimplified_chinese_cabin_category,
  ] = useState("");
  const [
    traditional_chinese_cabin_category,
    settraditional_chinese_cabin_cabin_category,
  ] = useState("");
  const [
    arabic_cabin_category,
    setarabic_cabin_cabin_category,
  ] = useState("");
  const [cabinCategoryVarientOption, setCabinCategoryVarientOption] = useState(
    []
  );
  const [cabin_varient, setcabin_varient] = useState("");
  const [cabin_code, setcabin_code] = useState("");
  const [size, setSize] = useState(cabin.size || "");
  const [deckNo, setDeckNo] = useState(cabin.deck_no || "");

  useEffect(() => {
    if (cabin) {
      setVessel_id(cabin?.vessel_id);
      setcabin_types(cabin?.cabin_type_id);
      setImage(cabin?.image);
      setSimplified_chinese_image(cabin?.simplified_chinese_image);
      setTraditional_chinese_image(cabin?.traditional_chinese_image);
      setcabin_category(cabin?.cabin_category_id);
      setarabic_image(cabin?.arabic_image);
      setarabic_description(cabin?.arabic_description )
      setcabin_code(cabin?.cabin_code);
      setcabin_varient(cabin?.cabin_variant_id);
      setEnglishDescription(cabin?.english_description);
      setsimplified_chinese_description(cabin?.simplified_chinese_description);
      settraditional_chinese_description(
        cabin?.traditional_chinese_description
      );
      settraditional_chinese_cabin_cabin_category(cabin?.cabin_category_id);
      setsimplified_chinese_cabin_category(cabin?.cabin_category_id);
      setDeckNo(cabin?.deck_no);
      setSize(cabin?.size);
      setIsActive(cabin?.is_active === 1 ? true : false);
    }
  }, [cabin]);
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handletraditional_chinese_descriptionChange = (content) => {
    settraditional_chinese_description(content);
  };

  const handleCheckboxChange = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    getOptionLocation();
  }, []);

  useEffect(() => {
    if (vessel_id && cabin_types) {
      getCabinCategoryOption();
    }
  }, [vessel_id, cabin_types]);

  useEffect(() => {
    if (cabin_category) {
      if (
        cabinCategoryOption.filter((item) => item.id === cabin_category)
          .length === 1
      ) {
        setcabin_code(
          cabinCategoryOption.filter((item) => item.id === cabin_category)[0]
            ?.category_code
        );
      }
      getCabinVarientOption();
    }
  }, [cabin_category]);

  const getCabinVarientOption = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });

    let cabin_varient = await getCabinVarient(cabin_category);
    if (cabin_varient.error) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: cabin_varient.error, type: "error" },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: cabin_varient.error,
      });
    } else {
      setCabinCategoryVarientOption(cabin_varient);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const getCabinCategoryOption = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });

    let cabin_category = await getCabinCategory(vessel_id, cabin_types);
    if (cabin_category.error) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: cabin_category.error, type: "error" },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: cabin_category.error,
      });
    } else {
      setCabinCategoryOption(cabin_category);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const getOptionLocation = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });

    let vessels = await getVessels();
    let cabin_types = await getCabinTypes();
    if (vessels.error) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: vessels.error, type: "error" },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: vessels.error,
      });
    } else {
      setVesselOptions(vessels);
      setCabinTypesOptions(cabin_types);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const addcabin = async (e) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    e.preventDefault();
    let dataValues = {
      vessel_id,
      cabin_type_id: cabin_types,
      cabin_category_id: cabin_category,
      cabin_code: cabin_code,
      deck_no: deckNo,
      image: image,
      simplified_chinese_image: simplified_chinese_image,
      arabic_image:arabic_image,
      arabic_description:arabic_description,
      traditional_chinese_image: traditional_chinese_image,
      size: size,
      cabin_variant_id: cabin_varient,
      english_description: englishDescription,
      simplified_chinese_description: simplified_chinese_description,
      traditional_chinese_description: traditional_chinese_description,
      is_active: isActive ? 1 : 0,
    };
    try {
      
      if (selectedFile) {
        let imageType = {
          type: IMAGE_TYPE.CABIN_IMG,
          subType: IMAGE_SUB_TYPE.ENGLISH_IMG
        }
        let image = await uploadFile(selectedFile, imageType);
        if(image?.error) throw new Error(image.error);
  
        dataValues.image = image?.url;
      }
      if (traditionalChineseFile) {
        let imageType = {
          type: IMAGE_TYPE.CABIN_IMG,
          subType: IMAGE_SUB_TYPE.ENGLISH_IMG
        }
        let image = await uploadFile(traditionalChineseFile, imageType);
        if(image?.error) throw new Error(image.error);
  
        dataValues.traditional_chinese_image = image?.url;
      }
      if (simplifiedChineseFile) {
        let imageType = {
          type: IMAGE_TYPE.CABIN_IMG,
          subType: IMAGE_SUB_TYPE.ENGLISH_IMG
        }
        let image = await uploadFile(simplifiedChineseFile, imageType);
        if(image?.error) throw new Error(image.error);
  
        dataValues.simplified_chinese_image = image?.url;
      }
      if (arabicFile) {
        let imageType = {
          type: IMAGE_TYPE.CABIN_IMG,
          subType: IMAGE_SUB_TYPE.ENGLISH_IMG
        }
        let image = await uploadFile(arabicFile, imageType);
        if(image?.error) throw new Error(image.error);
  
        dataValues.arabic_image = image?.url;
      }
      let valid = checkFields(dataValues);
      if (valid) {
        // dispatch({
        //   type: "SET_ERROR_MESSAGE",
        //   errMess: { message: valid, type: "error" },
        // });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: valid,
        });
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
        // setError(valid);
      } else {
        try {
          const values = {
            ...dataValues,
          };
          let data;
          if (cabin?.id) {
            data = await updateCabin(cabin.id, values);
          } else {
            data = await createCabin(values);
          }
  
          console.log(data);
  
          if (data?.error) {
            throw new Error(data.error);
          }
  
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
  
          if (cabin?.id) {
            addlog(
              "Cabin Updated",
              "Cabin_Master",
              {
                id: cabin.id,
                title: cabin.cabin_code,
              },
              userDetails.id
            );
          } else {
            addlog(
              "Special Pages Created",
              "Cabin_Master",
              {
                id: data.id,
                title: dataValues.cabin_code,
              },
              userDetails.id
            );
          }
          if (!cabin.id) {
            navigate("/masters/cabin-master");
          } else {
            navigate("/masters/cabin-master");
            dispatch({
              type: "SET_ERROR_MESSAGE",
              errMess: { message: "Values updated", type: "success" },
            });
          }
        } catch (error) {
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
          // dispatch({
          //   type: "SET_ERROR_MESSAGE",
          //   errMess: {
          //     message: error.message || "Unknown error occurred",
          //     type: "error",
          //   },
          // });
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message || "Unknown error occurred",
          });
        }
      }
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: {
      //     message: error.message || "Unknown error occurred",
      //     type: "error",
      //   },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message || "Unknown error occurred",
      }); 
    }
  };
  return (
    <div>
      <section className="content">
        {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )}

        <div className="container-fluid">
          <div className="card">
            <form className="input_form" onSubmit={addcabin}>
              <div className="card-body">
                <div className="row">
                  <UploadFileImage
                    className={"col-lg-3"}
                    mandatory={"*"}
                    width={427}
                    height={314}
                   
                    image={
                      cabin?.image
                        ? `${constants.MEDIAURL}/${cabin?.image}`
                        : cabin?.image
                    }
                    label={"English Cabin Image"}
                    placeholder={"Upload english cabin Image"}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                  />
                
                  <UploadFileImage
                    className={"col-lg-3"}
                    mandatory={"*"}
                    width={427}
                    height={314}
                    image={
                      cabin?.simplified_chinese_image
                        ? `${constants.MEDIAURL}/${cabin?.simplified_chinese_image}`
                        : cabin?.simplified_chinese_image
                    }
                    label={"Simplified Chinese Cabin Image"}
                    placeholder={"Upload Simplified Chinese Cabin Image "}
                    selectedFile={simplifiedChineseFile}
                    setSelectedFile={setSimplifiedChineseFile}
                  />
                    <UploadFileImage
                    mandatory={"*"}
                    width={427}
                    height={314}
                    className={"col-lg-3"}
                    image={
                      cabin?.traditional_chinese_image
                        ? `${constants.MEDIAURL}/${cabin?.traditional_chinese_image}`
                        : cabin?.traditional_chinese_image
                    }
                    label={"Traditional Chinese Cabin Image "}
                    placeholder={
                      "Upload Traditional Chinese Cabin Image "
                    }
                    selectedFile={traditionalChineseFile}
                    setSelectedFile={setTraditionalChineseFile}
                  />
                    <UploadFileImage
                    mandatory={"*"}
                    width={427}
                    height={314}
                    className={"col-lg-3"}
                    image={
                      cabin?.arabic_image
                        ? `${constants.MEDIAURL}/${cabin?.arabic_image}`
                        : cabin?.arabic_image
                    }
                    label={"Arabic Cabin Image "}
                    placeholder={
                      "Upload Arabic Cabin Image "
                    }
                    selectedFile={arabicFile}
                    setSelectedFile={setarabicFile}
                  />

                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Vessel <span style={{color: "red"}}> *</span></label>
                      <Select
                        required
                        style={{ width: "100%", height: "45px" }}
                        value={vessel_id}
                        onChange={(e) => setVessel_id(e.target.value)}
                      >
                        {vesselOptions.map((item, index) => (
                          <MenuItem key={index} value={item?.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Cabin Type <span style={{color: "red"}}> *</span></label>
                      <Select
                        required
                        style={{ width: "100%", height: "45px" }}
                        value={cabin_types}
                        onChange={(e) => setcabin_types(e.target.value)}
                      >
                        {cabinTypesOptions.map((item, index) => (
                          <MenuItem key={index} value={item?.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">English Cabin Category <span style={{color: "red"}}> *</span></label>
                      <Select
                        required
                        style={{ width: "100%", height: "45px" }}
                        value={cabin_category}
                        disabled={!vessel_id && !cabin_types}
                        onChange={(e) => {
                          setcabin_category(e.target.value);
                          setsimplified_chinese_cabin_category(e.target.value);
                          settraditional_chinese_cabin_cabin_category(
                            e.target.value
                          );
                        }}
                      >
                        {cabinCategoryOption.map((item, index) => (
                          <MenuItem key={index} value={item?.id}>
                            {item.display_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Simplified Chineese Cabin Category
                      </label>
                      <Select
                        required
                        style={{ width: "100%", height: "45px" }}
                        value={simplified_chinese_cabin_category}
                        disabled={!vessel_id && !cabin_types}
                        onChange={(e) => {
                          setcabin_category(e.target.value);
                          setsimplified_chinese_cabin_category(e.target.value);
                          settraditional_chinese_cabin_cabin_category(
                            e.target.value
                          );
                        }}
                      >
                        {cabinCategoryOption.map((item, index) => (
                          <MenuItem key={index} value={item?.id}>
                            {item.simplified_chinese_display_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Traditional Chineese Cabin Category
                      </label>
                      <Select
                        required
                        style={{ width: "100%", height: "45px" }}
                        value={traditional_chinese_cabin_category}
                        disabled={!vessel_id && !cabin_types}
                        onChange={(e) => {
                          setcabin_category(e.target.value);
                          setsimplified_chinese_cabin_category(e.target.value);
                          settraditional_chinese_cabin_cabin_category(
                            e.target.value
                          );
                        }}
                      >
                        {cabinCategoryOption.map((item, index) => (
                          <MenuItem key={index} value={item?.id}>
                            {item.traditional_chinese_display_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Cabin Code</label>
                      <input
                        className="f_input"
                        required
                        type="text"
                        disabled
                        value={cabin_code}
                        // onChange={(e) => setcabin_code(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Variant <span style={{color: "red"}}> *</span></label>
                      <Select
                        required
                        style={{ width: "100%", height: "45px" }}
                        value={cabin_varient}
                        disabled={!cabin_category}
                        onChange={(e) => setcabin_varient(e.target.value)}
                      >
                        {cabinCategoryVarientOption.map((item, index) => (
                          <MenuItem key={index} value={item?.id}>
                            {item.varient_code}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12"></div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Size</label>
                      <input
                        className="f_input"
                        // required
                        type="text"
                        value={size}
                        onChange={(e) => setSize(e.target.value)}
                      />
                     
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Deck No.</label>
                      <input
                        className="f_input"
                        // required
                        type="text"
                        value={deckNo}
                        onChange={(e) => setDeckNo(e.target.value)}
                      />
                     
                    </div>
                  </div>
                  <div className="col-4"></div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">English Description</label>
                      <ReactQuill
                        value={englishDescription}
                        onChange={setEnglishDescription}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                        Simplified Chinese Description
                      </label>
                      <ReactQuill
                        value={simplified_chinese_description}
                        onChange={setsimplified_chinese_description}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                        Traditional Chinese Description
                      </label>
                      <ReactQuill
                        value={traditional_chinese_description}
                        onChange={handletraditional_chinese_descriptionChange}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                    <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                       Arabic Description
                      </label>
                      <ReactQuill
                        value={arabic_description}
                        onChange={(e)=>setarabic_description(e)}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="iti_inner">
                      <div className="icheck-primary d-inline">
                        <input
                          type="checkbox"
                          id="customCheckbox4"
                          name="r10"
                          checked={isActive}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="customCheckbox4">
                          {isActive ? (
                            <p>
                              <span style={{ fontWeight: 600 }}>Active</span>
                            </p>
                          ) : (
                            <p>
                              <span style={{ fontWeight: 200 }}>Active</span>
                            </p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-right">
                <button className="btn main_submit">
                  {cabin.id ? "Update" : "Add"} Cabin Master
                </button>
              </div>
            </form>
          </div>
          <div className="pt-3"></div>
        </div>
      </section>
    </div>
  );
};

export default CabinMasterInput;
