import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Button } from "@mui/material";
import RichEditor from "../components/DyanmicForm/RichTextEditor";
const Dashboard = () => {
  const handleSubmit = async () => {
    try {
      const response = await fetch("http://localhost:4000/payment", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          token:
            "U2FsdGVkX19fjVPaPE4Ofonfx9uA/qnW3TPV+hEbkfTBLic1yQWfsuUcVBE1orsFHqcxIi6gsl4nLR9ZkCzLrDCADidaBLkjC4aNzosGu8kyvzZnWLtU+DS/VU5jUJ+RjwpD8C8QxxudzmawOH85aM4Lf395vEKle3iFjjCvps2X3hFy4Oyf5yvAYIPumXhiSQXA54ysuJqx7lZmo4mUteYefwxLE9ELRKTh/jL9VCozcOwrT0vuc6XnCAomjuR4E3mAZolMt+WkDECJ9ncP/Q3bAaH8/5ne1jDpAi/Lmc1q2dChfrgTo3EYit7Cs6w5+71PPKq5LzV8Yo+KvS+fpRv55guOc0ZqZ446xCChYPO0prfs42l9if/xmgT2+TmGY7HMtsJfqNX47Exvrr1omSjuzpC+DzzJFn/LXAoOZFaPQlPv3tXyGf4IZA6pAfeDKYAmwcSRk57b95GogXA6x28231isFbRYmLY/KbmIQsB3MEKRQDUX3yfgUa51XLW3MvZv/0T/D8fkbuA+3hx+0Q==",
        },
      });

      if (response.ok) {
        console.log(response);
      } else {
        // Handle error if needed
        console.error("Fetch request failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  return (
    <Layout type={"read_per"} module_data={"dashboard"}>
   Dashboard
   {/* <RichEditor/> */}
    </Layout>
  );
};

export default Dashboard;
