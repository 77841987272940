import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDataLayerValue } from "./DataLayer";
import { getToken, setToken } from "../utils/Api";
import { getUser, validateUserToken } from "../utils/ApiCalls/UserCalls";

const ProtectedRoute = ({ children }) => {
  const [{ userDetails, token }, dispatch] = useDataLayerValue();
  let location = useLocation();
  const [authenticated, setAuthenticated] = useState(false);
  const navigate = useNavigate();
  const validateUser = async () => {
    let data = await validateUserToken();
    if (data.message) {
      navigate("/login");
    } else {
      getToken();
      dispatch({
        type: "SET_USER_DETAILS",
        userDetails: data,
      });
    }
  };
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      validateUser();
      setAuthenticated(true);
      setToken(token);
    } else {
      setAuthenticated(false);
      navigate("/login");
    }
  }, [token]);

  return children;
};

export default ProtectedRoute;
