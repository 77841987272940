import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css"; // Import Quill styl
import { useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { IMAGE_SUB_TYPE, IMAGE_TYPE } from "../../constants/ImageConstant";
import LocationAndExpcept from "../../constants/LocationAndExpcept";
import { constants } from "../../constants/constants";
import {
  createDestination,
  deleteDestinationData,
  getDestinatinDataLocation,
  updateDestination,
} from "../../utils/ApiCalls/Destination";
import { getAllLocations } from "../../utils/ApiCalls/Locations";
import { addlog } from "../../utils/ApiCalls/Logs";
import { uploadData } from "../../utils/ApiCalls/Upload";
import { checkFields } from "../../utils/Validation/checkFields";
import UploadFileImage from "../imageUpload/uploadFileImage";


import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import UploadFileImageBanner2 from "../Banner/uploadFileImageBanner copy";

const MySwal = withReactContent(Swal)

const DestinationInputs = ({ destination }) => {
  const [englishTitle, setEnglishTitle] = useState(
    destination?.englishTitle || ""
  );
  const [simplifiedChineseTitle, setSimplifiedChineseTitle] = useState(
    destination?.simplifiedChineseTitle || ""
  );
  const [traditionalChineseTitle, setTraditionalChineseTitle] = useState(
    destination?.traditionalChineseTitle || ""
  );
  const [ArabicTitle, setArabicTitle] = useState(
    destination?.traditionalChineseTitle || ""
  );

  const [currentLocation, setCurrentLocation] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [traditionalChineseFile, setTraditionalChineseFile] = useState(null);
  const [simplifiedChineseFile, setSimplifiedChineseFile] = useState(null);
  const [ArabicFile, setArabicFile] = useState(null);
  const [updateSelectedFile, setUpdateSelectedFile] = useState(null);
  const [displayLocation, setDisplayLocation] = useState([]);
  const [url, setUrl] = useState(destination?.url || "");
  const [image, setImage] = useState(destination?.image || null);
  const [submitBtnState, setSubmitBtnState] = useState(false);
  const [traditional_chinese_image, setTraditional_chinese_image] =
    useState(null);
    const [arabic_image, setarabic_image] =
    useState(null);
  const [valid_from, setValidFrom] = useState();
  const [valid_to, setValidTo] = useState();
  const [simplified_chinese_image, setSimplified_chinese_image] =
    useState(null);
  const [locationOption, setLocationOption] = useState([]);
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [error, setError] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (destination) {
      if (destination.image) {
        getLocationData();
      }
      setImage(destination?.image);
      setSimplified_chinese_image(destination?.simplified_chinese_image);
      setTraditional_chinese_image(destination?.traditional_chinese_image);
      setEnglishTitle(destination?.title);
      setSimplifiedChineseTitle(destination?.simplified_chinese_title);
      setTraditionalChineseTitle(destination?.traditional_chinese_title);
      setArabicTitle(destination?.arabic_title)
      setarabic_image(destination?.arabic_image)
      setUrl(destination?.url);
      if (destination.location_id)
        setDisplayLocation([destination.location_id]);
      setIsActive(destination?.is_active === 1 ? true : false);
      setValidFrom(destination?.valid_from);
      console.log({destination:destination?.valid_from})
      setValidTo(destination?.valid_to)
    }
  }, [destination]);


 
  const handleCheckboxChange = () => {
    setIsActive(!isActive);
  };



  const getLocationData = async () => {
    let data = await getDestinatinDataLocation({ image: destination.image });

    if (data.error) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: data.error, type: "error" },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.error,
      });
    } else {
      setCurrentLocation(data);
      setDisplayLocation(data?.map((item) => item.location_id) || []);
    }
  };

  const handleDisplayLocationChange = (selectedOptions) => {
    setDisplayLocation(selectedOptions.target.value);
  };
  useEffect(() => {
    getOptionLocation();
  }, []);

  const getOptionLocation = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getAllLocations();

    if (data.error) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: data.error, type: "error" },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.error,
      });
    } else {
      setLocationOption(data?.data || []);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const addDestination = async (e) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });

    e.preventDefault();
    let added = false;
    let dataValues = {
      image,
      traditional_chinese_image,
      simplified_chinese_image,
      arabic_image,
      title: englishTitle,
      traditional_chinese_title: traditionalChineseTitle,
      simplified_chinese_title: simplifiedChineseTitle,
      valid_from: valid_from,
      valid_to: valid_to,
      arabic_title:ArabicTitle,
      url,
      is_active: isActive ? 1 : 0,
    };

    try {
      if (selectedFile) {
        if (typeof selectedFile === "string") {
          dataValues.image = selectedFile.replace(constants.MEDIAURL + "/", "");
        }else{
        let imageType = {
          type: IMAGE_TYPE.DESTINATION_IMG,
          subType: IMAGE_SUB_TYPE.ENGLISH_IMG,
        };
        let image = await uploadData(selectedFile, imageType);
        if (image?.error) throw new Error(image.error);

        dataValues.image = image?.url;}
      }else{
        dataValues.image=""
      }
      if (traditionalChineseFile) {
        if (typeof traditionalChineseFile === "string") {
          dataValues.traditional_chinese_image = traditionalChineseFile.replace(constants.MEDIAURL + "/", "");
        }else{
        let imageType = {
          type: IMAGE_TYPE.DESTINATION_IMG,
          subType: IMAGE_SUB_TYPE.TRADITIONAL_CHINESE_IMG,
        };
        let image = await uploadData(traditionalChineseFile, imageType);
        if (image?.error) throw new Error(image.error);

        dataValues.traditional_chinese_image = image?.url;}
      }else{
        dataValues.traditional_chinese_image =""
      }
      if (simplifiedChineseFile) {
        if (typeof simplifiedChineseFile === "string") {
          dataValues.simplified_chinese_image = simplifiedChineseFile.replace(constants.MEDIAURL + "/", "");
        }else{
        let imageType = {
          type: IMAGE_TYPE.DESTINATION_IMG,
          subType: IMAGE_SUB_TYPE.TRADITIONAL_CHINESE_IMG,
        };
        let image = await uploadData(simplifiedChineseFile);
        if (image?.error) throw new Error(image.error);

        dataValues.simplified_chinese_image = image?.url;}
      }
        else{
          dataValues.simplified_chinese_image =""
        }
      
      if (ArabicFile) {
        if (typeof ArabicFile === "string") {
          dataValues.arabic_image = ArabicFile.replace(constants.MEDIAURL + "/", "");
        }else{
        let imageType = {
          type: IMAGE_TYPE.DESTINATION_IMG,
          subType: IMAGE_SUB_TYPE.TRADITIONAL_CHINESE_IMG,
        };
        let image = await uploadData(ArabicFile);
        if (image?.error) throw new Error(image.error);

        dataValues.arabic_image = image?.url;}
      }   else{
        dataValues.arabic_image =""
      }
      console.log("dataValues===>", dataValues);
      // let requiredFields=["english_image"];
      // const isChinaSelecetd = displayLocation.find(location=> locationOption.find(l=> location === l.id)?.location_code==="CN");
      // if(isChinaSelecetd){
      //     requiredFields.push("traditional_chinese_image", "simplified_chinese_image");
      // }
      let valid = checkFields(dataValues);
      if (valid) {
        // dispatch({
        //   type: "SET_ERROR_MESSAGE",
        //   errMess: { message: valid, type: "error" },
        // });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: valid,
        });
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
        // setError(valid);
      } else {
        try {
          setError();
          await Promise.all( currentLocation.map(async item=>{
        
            if(!displayLocation.includes(item.location_id)){
              await deleteDestinationData(item.id)
            }
          }))
          await Promise.all(
            displayLocation.map(async (item, index) => {
              let values = {
                location_id: item,
                ...dataValues
              };
  
              let data;
              if (destination?.id) {
                let locationValue = currentLocation.filter(
                  (data) => data.location_id === item
                );
                if (locationValue.length > 0) {
                  let { ...rest } = values;
                  if (item === destination.location_id) {
                    data = await updateDestination(locationValue[0].id, values);
                  } else {
                    data = await updateDestination(locationValue[0].id, rest);
                  }
                } else {
                  await createDestination({ ...values, recordIteration: index + 1 });
                }
              } else {
                values = {
                  ...values,
                  recordIteration: index + 1
                }
                data = await createDestination(values);
              }
              if (data?.error) {
                throw new Error(data.error);
              } else {
                if (destination?.id) {
                  addlog(
                    "Destination Updated",
                    "Destination",
                    {
                      id: destination.id,
                      title: values.title || destination.title,
                      url: url || destination.url,
                      location_id: destination.location_id,
                    },
                    userDetails.id
                  );
                } else {
                  addlog(
                    "Destination Created",
                    "Destination",
                    {
                      id: data.id,
                      title: data.title,
                      url: data.url,
                      location_id: item,
                    },
                    userDetails.id
                  );
                }
              }
            })
          );
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
  
          if (!destination.id) {
            navigate("/cms/homepage/destinations");
          } else {
            navigate("/cms/homepage/destinations");
            dispatch({
              type: "SET_ERROR_MESSAGE",
              errMess: { message: "Values updated", type: "success" },
            });
          }
        } catch (error) {
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
          // dispatch({
          //   type: "SET_ERROR_MESSAGE",
          //   errMess: {
          //     message: error.message || "Unknown error occurred",
          //     type: "error",
          //   },
          // });
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message || "Unknown error occurred",
          });
        }
      }
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: {
      //     message: error.message || "Unknown error occurred",
      //     type: "error",
      //   },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message || "Unknown error occurred",
      }); 
    }
  };
  return (
    <div>
      <section className="content">
        {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )}

        <div className="container-fluid">
          <div className="card">
            <form className="input_form" onSubmit={addDestination}>
              <div className="card-body">
                <div className="row">
                <LocationAndExpcept
                       displayLocation={displayLocation}
                       banner={destination}
                       locationOption={locationOption}
                       nondisplay
                       options={locationOption}
                       setDisplayLocation={setDisplayLocation}
                       handleDisplayLocationChange={handleDisplayLocationChange}
                      />
                 
                  <div className="w-100"></div>
                  <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">English Title</label>
                      <input
                        className="f_input"
                        // required
                        type="text"
                        value={englishTitle}
                        onChange={(e) => setEnglishTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Simplified Chinese Title
                      </label>
                      <input
                        className="f_input"
                        // required
                        type="text"
                        value={simplifiedChineseTitle}
                        onChange={(e) =>
                          setSimplifiedChineseTitle(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Traditional Chinese Title
                      </label>
                      <input
                        className="f_input"
                        // required
                        type="text"
                        value={traditionalChineseTitle}
                        onChange={(e) =>
                          setTraditionalChineseTitle(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Arabic Title
                      </label>
                      <input
                        className="f_input"
                        // required
                        style={{direction:"rtl"}}
                        type="text"
                        value={ArabicTitle}
                        onChange={(e) =>
                          setArabicTitle(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <UploadFileImageBanner2
                      mediaType={"image"}
                       className="col-lg-3"
                      mandatory={"*"}
                      // mandatory={displayLocation.find(location=>locationOption.find(l=> l.id===location)?.location_code === "CN") ? "*": ""}

                    image={
                      destination?.image
                        ? `${constants.MEDIAURL}/${destination?.image}`
                        : destination?.image
                    }
                    label={"English Destination Image"}
                    width={431}
                    height={431}
                    placeholder={"Upload English Destination Image"}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                  />
                <UploadFileImageBanner2
                      mediaType={"image"}
                       className="col-lg-3"
                     
                      // mandatory={displayLocation.find(location=>locationOption.find(l=> l.id===location)?.location_code === "CN") ? "*": ""}

                    width={431}
                    height={431}
                    image={
                      destination?.simplified_chinese_image
                        ? `${constants.MEDIAURL}/${destination?.simplified_chinese_image}`
                        : destination?.simplified_chinese_image
                    }
                    label={"Simplified Chinese Destination Image"}
                    placeholder={"Upload Simplified Chinese Destination Image "}
                    selectedFile={simplifiedChineseFile}
                    setSelectedFile={setSimplifiedChineseFile}
                  />

<UploadFileImageBanner2
                      mediaType={"image"}
                       className="col-lg-3"
                     
                      // mandatory={displayLocation.find(location=>locationOption.find(l=> l.id===location)?.location_code === "CN") ? "*": ""}
                    width={431}
                    height={431}
                    image={
                      destination?.traditional_chinese_image
                        ? `${constants.MEDIAURL}/${destination?.traditional_chinese_image}`
                        : destination?.traditional_chinese_image
                    }
                    label={"Traditional Chinese Destination Image "}
                    placeholder={
                      "Upload Traditional Chinese Destination Image "
                    }
                    selectedFile={traditionalChineseFile}
                    setSelectedFile={setTraditionalChineseFile}
                  />
                  <UploadFileImageBanner2
                      mediaType={"image"}
                       className="col-lg-3"
                    
                      // mandatory={displayLocation.find(location=>locationOption.find(l=> l.id===location)?.location_code === "CN") ? "*": ""}
                    width={431}
                    height={431}
                    image={
                      destination?.arabic_image
                        ? `${constants.MEDIAURL}/${destination?.arabic_image}`
                        : destination?.arabic_image
                    }
                    label={"Arabic Destination Image "}
                    placeholder={
                      "Upload Arabic Destination Image "
                    }
                    selectedFile={ArabicFile}
                    setSelectedFile={setArabicFile}
                  />
                 
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Url</label>
                      <input
                        className="f_input"
                        // required
                        type="text"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6 col-6">
                    {/* <div className="sailing_date "> */}
                    {/* <h4 className="sub_title2">Valid Date</h4> */}
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="fieldrow">
                          <label className="f_label"> Valid From <span style={{color: "red"}}> *</span></label>
                          <input
                            required
                            value={valid_from&&moment(valid_from).format("YYYY-MM-DD")}
                            onChange={(e) => {
                              setValidFrom(e.target.value)
                              setValidTo("")
                            }}
                            className="f_input"
                            type="date"
                          />
                          
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="fieldrow">
                          <label className="f_label">Valid To <span style={{color: "red"}}> *</span></label>
                          <input
                            required
                            className="f_input"
                            value={valid_to&&moment(valid_to).format("YYYY-MM-DD")}
                            onChange={(e) => {
                              setValidTo(e.target.value);
                            }}
                            min={moment(valid_from)
                              .format("YYYY-MM-DD")}
                            type="date"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="iti_inner">
                      <div className="icheck-primary d-inline">
                        <input
                          type="checkbox"
                          id="customCheckbox4"
                          name="r10"
                          checked={isActive}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="customCheckbox4">
                          {isActive ? (
                            <p>
                              <span style={{ fontWeight: 600 }}>Active</span>
                            </p>
                          ) : (
                            <p>
                              <span style={{ fontWeight: 200 }}>Active</span>
                            </p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-right">
                <button disabled={submitBtnState} className="btn main_submit">
                  {destination?.id ? "Update" : "Add"} Destination
                </button>
              </div>
            </form>
          </div>
          <div className="pt-3"></div>
        </div>
      </section>
    </div>
  );
};

export default DestinationInputs;
