import React, { useState } from "react";

const SwitchBar = ({ activeTab, setActiveTab, tabs, setSearch, setPage, setRows, setCount ,module, setPageSize }) => {
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    if(module==="country-tab"){
      setPage(1);
      setRows([]);
      setCount(0);
      setPageSize(10);
    }
    if(setSearch)
      setSearch("")
  };

  return (
    <ul className="nav voucher_nav" role="tablist">
      {tabs?.map((item) => {
        return (
          <li role="presentation">
            <button
              className={`nav_btn ${activeTab === item.value ? "active" : ""}`}
              id={item.value}
              onClick={() => handleTabClick(item.value)}
              type="button"
              role="tab"
              aria-controls="page_setup"
              aria-selected={activeTab === item.value}
            >
              {item.title}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default SwitchBar;
