import ShipBannerPreview from "../../Previews/Ship/Banner/Preview";

export const Component2 = {
    type: 1,
    fields: [
        {
            title: "English Title",
            key: "title",
            className: "col-lg-3",
            type: "input",
            required: true
        },
        {
            title: "Simplified Chinese Title",
            key: "simplified_chinese_title",
            className: "col-lg-3",
            type: "input",
            required: true
        },
        {
            title: "Traditional Chinese Title",
            key: "traditional_chinese_title",
            className: "col-lg-3",
            type: "input",
            required: true
        },
        {
            title: "Arabic Title",
            key: "arabic_title",
            className: "col-lg-3 arabic",
            type: "input",
            required: true
        },
        {
            title: "Page Image",
            key: "image",
            className: "col-lg-12",
            placeholder: "Upload Page Image",
            type: "file",
            required: true
        },
        {
            title: "English Page Title",
            key: "page_title",
            className: "col-lg-3",
            type: "input",
            required: true
        },
        {
            title: "Simplified Chinese Page Title",
            key: "simplified_chinese_page_title",
            className: "col-lg-3",
            type: "input",
            required: true
        },
        {
            title: "Traditional Chinese Page Title",
            key: "traditional_chinese_page_title",
            className: "col-lg-3",
            type: "input",
            required: true
        },
        {
            title: "Arabic Page Title",
            key: "arabic_page_title",
            className: "col-lg-3 arabic",
            type: "input",
            required: true
        },

        {
            title: "English Description",
            key: "description",
            className: "col-lg-3",
            type: "html", required: true
        },
        {
            title: "Simplified Chinese Description",
            key: "simplified_chinese_description",
            className: "col-lg-3",
            type: "html", required: true
        },
        {
            title: "Traditional Chinese Description",
            key: "traditional_chinese_description",
            className: "col-lg-3",
            type: "html", required: true
        },
        {
            title: "Arabic Description",
            key: "arabic_description",
            className: "col-lg-3",
            type: "html", required: true
        },

    ],
    preview: (activetab, cardData) => (
        <ShipBannerPreview activetab={activetab} cardData={cardData} />
    ),
    sampleData: { "ship": "genting_dream", "image": "17346663468061725270174217our-ship-page-banner.jpg", "title": " Cruise Your Christmas with Dick Lee", "end_date": "2024-12-10", "start_date": "2024-12-08", "arabic_image": "1728917887203Thumbnail_416x416_EN.jpg", "arabic_title": "استمتع برحلة عيد الميلاد مع ديك لي", "simplified_chinese_image": "1729021719340Thumbnail_416x416_SC.jpg", "simplified_chinese_title": "与李炳文一起邮轮过圣诞", "traditional_chinese_image": "1729021719525Thumbnail_416x416_TC.jpg", "traditional_chinese_title": "與李炳文一起郵輪過聖誕" }
}