import React, { useEffect, useState } from "react";
import Layout from "../../../Layout";
import { useNavigate } from "react-router-dom";
import HeaderBread from "../../../components/HeaderBread";
import { Avatar } from "@mui/material";
import Divider from "@mui/material/Divider";
import EmailIcon from "@mui/icons-material/Email";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import { validateUserToken } from "../../../utils/ApiCalls/UserCalls";
import PermissionTable from "./PermissionTable";
import { getPermission } from "../../../utils/ApiCalls/Permission";
const MyProfile = () => {
  const [profileData, setProfileData] = useState({});
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [myPermission, setMyPermission] = useState();
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "My Profile",
      route: "/cms/packages",
    },
  ];

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let data = await validateUserToken();
    setProfileData(data);
    console.log({ data });
    let value = await getPermission({ search: data?.role });
    setMyPermission(value?.data?.[0]);
  };
  return (
    <Layout type={"read_per"} module_data={"My Profile"}>
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"My Profile"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid" style={{ position: "relative" }}>
            <div className="card">
              <img
                src={"https://cms.rwcruisesuat.com/dist/img/login_bg.jpg"}
                alt="Our Ship"
                className=" rounded"
                style={{
                  marginBottom: "-70px",
                  maxHeight: "100px",
                  objectFit: "cover",
                }}
              />
              <div className="card-body">
                <div className="table_holder">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png"
                        sx={{
                          width: 100,
                          height: 100,
                          border: "5px solid white",
                          zIndex: "3",
                        }}
                      />
                      <h3 style={{ marginTop: "10px", fontWeight: "bold" }}>
                      {profileData?.firstName?.split("")[0].toUpperCase()+profileData?.firstName?.slice(1,profileData?.firstName?.length)}           {profileData?.lastName?.split("")[0].toUpperCase()+profileData?.lastName?.slice(1,profileData?.lastName?.length)} 
                      </h3>
                      <p style={{ color: "red" }}>
                        {profileData?.role || "User"}
                      </p>
                    </div>
                    <Divider
                      sx={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    />
                    <div style={{ display: "flex" }}>
                      <EmailIcon sx={{ marginRight: "10px" }} />
                      <p>{profileData?.email}</p>
                    </div>
                    {myPermission && (
                      <PermissionTable myPermission={myPermission} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default MyProfile;
