import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";


export const getCabinTypes = async (query) => {
    try {
      let res = await Api.get("/cabin-types/all", {
        params: query,
      });
    
      return res?.data || [];
    } catch (e) {
      let error = modifyError(e);
      return { error: error.message };
    }
  };

  export const getCabinTypesById = async (id) => {
    try {
      let res = await Api.get("/cabin-types/"+id)
    
      return res?.data || [];
    } catch (e) {
      return { error: "Something Wrong" };
    }
  };

  export const createCabinTypes = async (data) => {

    try {
      let res = await Api.post("/cabin-types", data);
      return res?.data;
    } catch (e) {
      let error = modifyError(e);
      return { error: error.message };
    }
  };

  
  export const updateCabinTypes = async (id, data) => {
    try {
      let res = await Api.patch("/cabin-types/" + id, data);
    
      return res?.data || [];
    } catch (e) {
      let error = modifyError(e);
      return { error: error.message };
    }
  };


export const deleteCabinTypes = async (id) => {
  try {
    let res = await Api.delete("/cabin-types/" + id);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};
