import React, { useState } from "react";
import Layout from "../../../Layout";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import HeaderBread from "../../../components/HeaderBread";
import { Link, useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import CustomizedTable from "../../../components/UI/CustomizedTable";
import Logs from "../../../components/Logs";

const DealsPage = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState([
    {
      title: "Genting Dreams",
      slug: "genting-dreams",
      template: "ship",
    },
    {
      title: "Resort World One",
      slug: "genting-dreams",
      template: "ship",
    },
  ]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [active, setActive] = useState("listing");
  const [{ userDetails }, dispatch] = useDataLayerValue();

  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Ships List",
      route: "/cms/webpages/our-ship",
    },
  ];

  const columns = [
    {
      title: "No",
      width: 10,
      dataIndex: "id",
      key: "position",
      fixed: "left",
      render: (position, record, index) => index + 1,
    },
    {
      title: "Title",
      width: 10,
      dataIndex: "title",
      key: "title",
      fixed: "left",
    },

    {
      title: "Action",
      key: "operation",
      width: 30,
      render: (text, record) => (
        <div className="table_action_btn">
          <div onClick={() => navigate("/template")} className="btn">
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <div onClick={() => navigate("/template")} className="btn delete_btn">
            <i
              className="ri-delete-bin-line"
              data-toggle="tooltip"
              title="Delete"
            ></i>
          </div>
        </div>
      ),
    },
  ];
  return (
    <Layout type={"read_per"} module_data={"deals"}>
      <CustomHeaderTitle title={"Deals"} />
      <div>
        <div>
          <HeaderBread navigation={navigation} title={"Deals"}></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            {active === "listing" && (
              <div className="card">
                <div className="card-header two_item">
                  <h5 className="card-title">Deals List</h5>
                </div>
                <div className="card-body">
                  <div className="table_holder">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    ></div>
                    {rows && (
                      <CustomizedTable
                        setRows={setRows}
                        setPageSize={setPageSize}
                        setPage={setPage}
                        pageSize={pageSize}
                        page={page}
                        count={count}
                        columns={columns}
                        rows={rows}
                        title={"Cabin Category"}
                        sortHandler={() => {}}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>{" "}
        {active === "logs" && (
          <Logs
            type={"Page"}
            slug={"/webpages/our-ship/edit/"}
            refreshlog={active}
          />
        )}
      </div>
    </Layout>
  );
};

export default DealsPage;
