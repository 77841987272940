import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../Layout";
import HeaderBread from "../../components/HeaderBread";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { addlog } from "../../utils/ApiCalls/Logs";
import CustomHeaderTitle from "../../components/constants/CustomHeaderTitle";
import {
  getPromoIndex,
  updatePromoIndex
} from "../../utils/ApiCalls/PromoIndex";
import CustomizedTable from "../../components/UI/CustomizedTable";
import SwitchBar from "../../components/Pages/SwitchBar";
import { checkPermission } from "../../utils/Validation/checkPermission";
import { message } from "antd";
import PromoIndexEdit from "../../constants/PromoIndexEdit";
import Logs from "../../components/Logs";
import PromoIndex from "../../components/UI/PromoIndex";

const Promos = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Promo Index",
      route: "/cms/promo-index",
    },
  ];
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [active, setActive] = useState("listing");
  const [{ userDetails,permission }, dispatch] = useDataLayerValue();
  const [promoIndexs, setPromoIndexs] = useState([]);
  const [promoIndex, setPromoIndex] = useState("");
  const columns = [
    {
      title: "Sl",
      width: 10,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (position, record, index) =>((page - 1) * pageSize) + index + 1,
    },
    {
      title: "Promo Index",
      dataIndex: "promo_index",
      key: "promo_index",
      width: 50,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 300,
      render: (item) => <p>{item}</p>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 500,
      render: (item) => <p>{item}</p>,
    },
    {
      title: "Action",
      key: "operation",
      width: 50,
      render: (item, record) => {
        return <PromoIndexEdit record={record} handleUpdate={handleUpdate} />;
      }
    },
  ];
  useEffect(() => {
    getData(page);
  }, [page, pageSize]);

  const getData = async (value) => {
    let args = { page: value, limit: pageSize };
    if (promoIndex) {
      args = { ...args, promoIndex };
    }
    let data = await getPromoIndex(args);
    if (data.error){
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    }else if (data.data) {
      setRows(data.data);
      setCount(data.count);
      if (data?.promoIndex) {
        data.promoIndex.unshift({ promo_index: "All" });
        setPromoIndexs(data.promoIndex);
      }
    }
  };

  const handleUpdate = async (id, values) => {
    if (checkPermission(permission, "update_per", "Promo Index")) {
      let data = await updatePromoIndex(id, values);
      if (data.error) {
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: data.error, type: "error" },
        });
      } else {
        addlog("Promo index updated", "Promo_Index", { id }, userDetails.id);
        message.success("Updated Successfully!");
      }
      getData(page);
    } else {
      message.error("Update permission missing");
    }
  };

  return (
    <Layout type={"read_per"} module_data={"Promo Index"}>
      <CustomHeaderTitle title={"Promo Index"} />
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"Promo Index"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <SwitchBar
              tabs={[
                { title: "Promo Index", value: "listing" },
                { value: "logs", title: " History Logs" },
              ]}
              activeTab={active}
              setActiveTab={setActive}
            />
            {(active === "listing") && (
            <div className="card">
              <div className="card-header two_item">
                <h5 className="card-title">Promo Index List</h5>
                <div className="two_item">
                  <PromoIndex
                      width={"150px"}
                      data={promoIndexs}
                      getData={getData}
                      promoIndex={promoIndex}
                      setPromoIndex={setPromoIndex}
                      setPage={setPage}
                    />
                </div>
              </div>
              <div className="card-body">
                <div className="table_holder">
                  <CustomizedTable
                    setRows={setRows}
                    setPageSize={setPageSize}
                    setPage={setPage}
                    pageSize={pageSize}
                    page={page}
                    count={count}
                    columns={columns}
                    rows={rows}
                    title={"Promo Index"}
                  />
                </div>
              </div>
            </div>
            )}
          </div>
        </section>
        {active === "logs" && (
          <Logs
            type={"Promo_Index"}
            slug={"/cms/packages/edit/"}
            refreshlog={active}
          />
        )}
      </div>
    </Layout>
  );
};

export default Promos;
