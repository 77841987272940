import { Button, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../Layout";
import HeaderBread from "../../../components/HeaderBread";
import CustomizedTables from "../../../components/Tables/BasicTable";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";

import SearchField from "../../../components/SearchField";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import CustomizedTable from "../../../components/UI/CustomizedTable";
import { constants } from "../../../constants/constants";
import { addlog } from "../../../utils/ApiCalls/Logs";
import SwitchBar from "../../../components/Pages/SwitchBar";
import Logs from "../../../components/Logs";
import moment from "moment";
import ValidDateUI from "../../../constants/validDateUI";
import Location from "../../../components/UI/Location";
import dayjs from 'dayjs';
import Vessel from "../../../components/UI/VesselFilter";
import { getVessels } from "../../../utils/ApiCalls/SpecialPage/index";
import { checkPermission } from "../../../utils/Validation/checkPermission";
import { createDownloads } from "../../../utils/ApiCalls/Downloads";
import Download from "../../../components/Downloads";
import {
  deleteLeadsData,
  getLeadsData,
  updateLeadsData,
} from "../../../utils/ApiCalls/Leads";
import { DatePicker, message } from "antd";
import Ancillary from "../../../components/Booking/Ancillary";
const Leads = () => {
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Failed Booking List",
      route: "/bookings/leads",
    },
  ];
  const [active, setActive] = useState("listing");
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [positionVal, setPositionVal] = useState([]);
  const [sailing_date, setSaling_date] = useState();

  const [created_at,setCreatedAt]=useState(JSON.stringify([moment().subtract(3, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]));
  const [search, setSearch] = useState("");
  const [{ userDetails,permission }, dispatch] = useDataLayerValue();
  const [locationData, setLocationData] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [disableSpecificDate, setDisableSpecificDate] = useState([]);
  const [vessel, setVessel] = useState([]);
  const [vesselId, setVesselId] = useState("");

  const sortHandler = async (options) => {
    if (Object.keys(options).length > 0) {
      let sort, order;
      sort = options.columnKey;
      order =
        options.order === "ascend"
          ? "asc"
          : options.order === "descend"
          ? "desc"
          : undefined;
      await getData(1, sort, order);
    }
  };


  const dateRender = (current) => {
    const isDisabled = !disableSpecificDate.filter(item=>(item.format('YYYY-MM-DD')===current.format('YYYY-MM-DD'))).length>0;
    return (
      <div
        className="ant-picker-cell-inner"
        style={{
          color: isDisabled ? 'inherit' : 'white',
          background:isDisabled?"inherit":"blue",
          borderRadius:"100px"
        }}
      >
        {current.date()}
      </div>
    );
  };

  const setPositionalValue = (data) => {
    const mappedPositionVal = data.map((d) => ({
      id: d.id,
      pos: d.position,
    }));
    setPositionVal(mappedPositionVal);
  };
  const columns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (position, record, index) =>((page - 1) * pageSize) + index + 1,
    },
    {
      title: "Package Id",
      dataIndex: "package_id",
      key: "package_id",
      sorter: (a, b) => "",
      width: 50,
    },
    {
      title: "Embarkation Port",
      dataIndex: "port",
      key: "port",
      width: 10
    },
    {
      title: "Booking No",
      dataIndex: "booking_no",
      key: "booking_no",
      sorter: (a, b) => "",
    },
    {
      title: "Cabin no",
      dataIndex: "cabin_no",
      key: "cabin_no",
      sorter: (a, b) => "",
      width: 10,
      render: (item, booking) => <div>{booking?.cabin_no?? "--"}</div>,
    },
    {
      title: "Booking Date",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => "",
      width: 150,
      render: (item, record) => <div>{moment(item).format("DD-MM-YYYY")}</div>,
    },
    {
      title: "Sailing Date",
      dataIndex: "sailing_date",
      key: "sailing_date",
      sorter: (a, b) => "",
      width: 150,
      render: (item, record) => <div>{moment(item).format("DD-MM-YYYY")}</div>,
    },
    {
      title: "Cabin Category",
      dataIndex: "cabin_category",
      key: "cabin_category",
      sorter: (a, b) => "",
      width: 10,
      render: (item, booking) => <div>{(booking?.cabin_category) ?? "--"}</div>,
    },
  
    {
      title: "Passengers",
      dataIndex: "first_name",
      key: "first_name",
      // sorter: (a, b) => "",
      width: 50,
      render: (item, record) => (
        <div>
          {record.first_name ? record.first_name + " " + record.last_name : "-"}
        </div>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      // sorter: (a, b) => "",
      width: 10,
    },
    {
      title: "Amount",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => "",
      width: 10,
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
      sorter: (a, b) => "",
      width: 10,
    },
    {
      title: "Rate Plan",
      dataIndex: "fare_code_name",
      key: "fare_code_name",
      sorter: (a, b) => "",
      width: 200,
      render: (item, booking) => <div>{(booking?.fare_code_name||booking?.leadDetails?.map(item=>item.fare_code_name)?.join(",")) ?? "--"}</div>,
    },
    {
      title: "Reason",
      dataIndex: "payment_status",
      key: "payment_status",
      sorter: (a, b) => "",
      width: 200,
      render: (text, record) => (
        <div>
          {text?text=="Request was processed successfully."?"Payment Completed Booking failed":"Payment Failed":"Payment not initialized"}
        </div>
      )
    },
    {
      title: "Vessel",
      dataIndex: "vessel_name",
      key: "vessel_name",
      // sorter: (a, b) => "",
      width: 150,
    },
    {
      title: "No Of Pax",
      dataIndex: "no_of_pax",
      key: "no_of_pax",
      // sorter: (a, b) => "",
      width: 100,
      render: (item, record) => (
        <div>{Number(record.adult)+Number(record.child)+Number(record.infant)}</div>
      ),
    },
    {
      title: "Ancillary (PREPAID GRATUITY)",
      dataIndex: "prepaid_gratuity_count",
      key: "prepaid_gratuity_count",
      sorter: (a, b) => "",
      width: 10,
    },
    {
      title: "Ancillary (PSTANDARD 3N - 2 DEVICE)",
      dataIndex: "pstandard_3n_2_device",
      key: "pstandard_3n_2_device",
      sorter: (a, b) => "",
      width: 10,
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (text, record) => (
        <div className="table_action_btn">
          <div className="btn delete_btn" onClick={() => handleEdit(record.id,record.cabin_no)}>
            <i className="ri-eye-line" data-toggle="tooltip" title="View"></i>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (active === "listing") {
      getVesselData();
      getData(page);
    }
  }, [page, pageSize, search,sailing_date,created_at]);

  // useEffect(() => {    
  //   if (active === "listing") {
  //     setCreatedAt(JSON.stringify([moment().subtract(3, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]));
  //   }
  // }, []);

  const getVesselData = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });

    let vessels = await getVessels();
    console.log("vessel in pack===>", vessel);
    if (vessels.error) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: vessels.error, type: "error" },
      });
    } else {
      vessels.push({ id: null, name: "All" });
      setVessel(vessels);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  const getData = async (value, sort, order) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let args = { search: search, page: value, limit: pageSize,sailing_date,created_at };
    if(locationId){
      args = { ...args, location_id: locationId===-1?undefined: locationId };
    }
    if (order) {
      if (sort) {
        args = { ...args, sort };
      }
      args = { ...args, order };
    }
    if (vesselId) {
      var selectedVessel = vessel.find(function (element) {
        return element.id === vesselId;
      });
      args = { ...args, vessel_name: selectedVessel.name };
    }

    let data = await getLeadsData(args);
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.data) {
      setRows(data.data);
      setCount(data.count);
      setPositionalValue(data.data);
      if(data.sailingDates){
        const sailingDates = data.sailingDates.map(e=>moment(e.sailing_date));
        setDisableSpecificDate(sailingDates)
      }
      if(locationData.length === 0){
        data.locations.push({location_id:-1, location: 'ALL'})
        setLocationData(data.locations)
      }

    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const handleEdit = async (id, cabin_no) => {
    navigate("/bookings/leads/edit/" + id+"?cabin_no="+cabin_no);
  };
  const saveDownloads = async () => {
    if (checkPermission(permission, "download_per", "Failed Bookings")) {
      if(count>0){
        let args = { search: search,sailing_date,created_at };
        if(locationId){
          args = { ...args, location_id: locationId===-1?undefined: locationId };
        }
        if (vesselId) {
          var selectedVessel = vessel.find(function (element) {
            return element.id === vesselId;
          });
          args = { ...args, vessel_name: selectedVessel.name };
        }
        let request = {
          module_name: 'failed_booking',
          created_by: userDetails?.id,
          search_params: args
        };
        let data = await createDownloads(request);
        if (data.error) {
          message.error("An error occurs when you try to download");
        } else {
          addlog("Bookings Downloaded", "Leads", args, userDetails.id);
          message.success("Download request submitted");
        }
      }else{
        message.error("There are no bookings to download");
      }
    } else {
      message.error("Download permission missing");
    }
  };

  return (
    <Layout type={"read_per"} module_data={"Failed Bookings"}>
      <CustomHeaderTitle title={"Failed Bookings"} />
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"Failed Bookings"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <SwitchBar
              tabs={[
                { title: "Failed Bookings List", value: "listing" },
                { value: "downloads", title: "Downloads" },
                { value: "logs", title: " History Logs" },
              ]}
              activeTab={active}
              setActiveTab={setActive}
            />
            {active === "listing" && (
              <div className="card">
                <div className="card-header two_item">
                  {/* <h5 className="card-title">Failed Bookings List</h5> */}
                  <div style={{display:"flex",gap:"10px"}}>
                    <Location
                      width="150px"
                      setPage={setPage}
                      getData={getData}
                      locationData={locationData}
                      setLocationId={setLocationId}
                      locationId={locationId}
                    />
                    <Vessel
                      setVesselId={setVesselId}
                      vesselData={vessel}
                      vesselId={vesselId}
                      getData={getData}
                      setPage={setPage}
                      width={150}
                    />
                    <DatePicker
                     style={{
                      width:"150px"
                    }}
                      placeholder="Sailing Date"
                      label="Sailing Date"
                      onChange={(date, dateString) => {
                       setSaling_date(dateString);
                       setPage(1);
                      }}
                      dateRender={dateRender}
                      disabledDate={(current)=>{
                        return !disableSpecificDate.some(date => current.isSame(date, 'day'));
                      }}
                    />
                      {/* <DatePicker
                       style={{
                        width:"200px"
                      }}
                      placeholder="Booking Date"
                      label="Booking Date"
                      onChange={(date, dateString) => {
                       setCreatedAt(dateString);
                       setPage(1);
                      }}
                      disabledDate={(current) => {
                        return moment()  <= current
                      }}
                    /> */}
                    <RangePicker
                      style={{
                        width:"250px"
                      }}
                      onChange={(date, dateString) => {
                        setCreatedAt(JSON.stringify(dateString));
                        setPage(1);
                      }}
                      defaultValue={[dayjs(dayjs().subtract(3, 'month').format('YYYY-MM-DD'), 'YYYY-MM-DD'), dayjs(dayjs().format('YYYY-MM-DD'), 'YYYY-MM-DD')]}
                      format={'YYYY-MM-DD'}
                      disabledDate={(current) => {
                        return moment() <= current;
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                          variant="contained"
                          onClick={() => saveDownloads()}
                          style={{
                            marginRight: "10px",
                            background: "#0ab402",
                            fontWeight: "500",
                          }}
                        >
                          Download
                    </Button>
                  </div>
                </div>
               
                <div className="card-body">
                  <div className="table_holder">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{ marginRight: "auto", marginBottom: "10px" }}
                      >
                      <Button
                            variant="contained"
                            style={{
                              marginRight: "10px",
                              background: "Black",
                              fontWeight: "500",
                            }}
                          >
                            Number Of Bookings: {count}
                      </Button>
                      </div>
                      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <SearchField
                          value={search}
                          SearchValue={() => {
                            setPage(1);
                          }}
                          setValue={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <CustomizedTable
                      setRows={setRows}
                      setPageSize={setPageSize}
                      setPage={setPage}
                      pageSize={pageSize}
                      page={page}
                      count={count}
                      columns={columns}
                      rows={rows}
                      sortHandler={sortHandler}
                      title={"Failed Bookings"}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        {active === "downloads" && (
          <Download
            type={"Leads"}
            slug={"/bookings/leads/edit/"}
            refreshlog={active}
            module={'failed_booking'}
          />
        )}
        {active === "logs" && (
          <Logs
            type={"Leads"}
            slug={"/bookings/leads/edit/"}
            refreshlog={active}
          />
        )}
      </div>
    </Layout>
  );
};

export default Leads;
