import React, { useEffect, useState } from 'react'
import BannerInputs from '../../../components/Banner/BannerInputs'
import HeaderBread from '../../../components/HeaderBread';
import Layout from '../../../Layout';
import { useParams } from 'react-router-dom';
import { getBannerData } from '../../../utils/ApiCalls/Banner';
import { useDataLayerValue } from '../../../DataLayer/DataLayer';
import CustomHeaderTitle from '../../../components/constants/CustomHeaderTitle';

const EditBanner = () => {
    const params = useParams();
    const [banner, setBanner] = useState({});
    const [{ useDetails }, dispatch] = useDataLayerValue();
    const navigation = [{
        title: "Home",
        route: "/"
    }, {
        title: "Banner List",
        route: "/cms/homepage/banner"
    }, {
        title: "Edit Banner",
        route: "/cms/homepage/banner/edit"
    }]
    useEffect(() => {
        getData(params?.id)
    }, [params])

    const getData = async (value) => {
        dispatch({
            type: "SET_LOADING",
            loading: true
        });
        let data = await getBannerData({ id: value });

        if (data.error)
            dispatch({
                type: 'SET_ERROR_MESSAGE',
                errMess: { message: data.error, type: 'error' }
            });
        else
            if (data.count === 1) {

                setBanner(data.data[0])

            }
        dispatch({
            type: "SET_LOADING",
            loading: false
        });
    }
    return (
        <Layout type={"update_per"} module_data={"banner"}>
            <CustomHeaderTitle title={"Edit Banner"} />
            <HeaderBread navigation={navigation} title={"Edit Banner"}></HeaderBread>{banner && <BannerInputs banner={banner} />}</Layout>
    )
}

export default EditBanner