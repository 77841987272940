import React, { useEffect, useState } from "react";
import { getPaymentLogData } from "../../utils/ApiCalls/PaymentLogs";
import { JsonViewer } from "@textea/json-viewer";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const BookingLogs = ({ booking_no, datastep }) => {
  const [paymentLog, setPaymentLog] = useState();
  const [bookingLog, setBookingLog] = useState();
  const [step, setStep] = useState(datastep || 1);
  const steps = ["Lead Created", "Payment Completed", "Booking Completed"];
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let data = await getPaymentLogData({ booking_no });

      setPaymentLog(data?.payment[0]);
 
      if (data?.booking?.length > 0) {
        setBookingLog(data?.booking[0]);
        setStep(3);
      } else {
        
         if(data?.payment?.length > 0)
        setStep(2);
      else
      setStep(0)
      }
    }
  return (
    <div>
      <div className="container-fluid">
        <form className="input_form">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Logs</h3>
            </div>
            <Box sx={{ width: "100%", marginTop: "20px" }}>
              <Stepper activeStep={step} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            {paymentLog && (
              <div className="card-body pb-2">
               
                <div className="card-header">
                  <h3 className="card-title">Cybersource Payment Response</h3>
                </div>
                {paymentLog && (
                  <JsonViewer
                    defaultExpandDepth={0}
                    value={JSON.parse(paymentLog?.response)}
                  />
                )}
              </div>
            )}
            <div className="card-body pb-2">
              {bookingLog && (
                <div>
                  <div className="card-header">
                    <h3 className="card-title">Itravel Booking Request</h3>
                  </div>
                  {bookingLog && (
                    <JsonViewer
                      defaultExpandDepth={0}
                      value={JSON.parse(bookingLog?.request)}
                    />
                  )}
                  <div className="card-header">
                    <h3 className="card-title">Itravel Booking Response</h3>
                  </div>
                  {bookingLog && (
                    <JsonViewer
                      defaultExpandDepth={0}
                      value={JSON.parse(bookingLog?.response)}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingLogs;
