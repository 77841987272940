import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";

export const addMenuList = async (data) => {
  try {
    let res = await Api.post("/menu-list", data);
    return res?.data;
  } catch (e) {
    console.log(e);
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getMenuList = async (query) => {
  try {
    let res = await Api.get("/menu-list", {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const deleteMenuList = async (data) => {
  try {
    let res = await Api.post("/menu-list/delete", data);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updateMenuList = async (id, data) => {
  try {
    let res = await Api.patch("/menu-list/" + id, data);
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};


export const updateManyMenuList = async (data) => {
  try {
    let res = await Api.post("/menu-list/update" , data);
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};
