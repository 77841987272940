export const IMAGE_TYPE = {
    BANNER_IMG: "BANNER_IMG",
    DEAL_IMG: "DEAL_IMG",
    PACKAGE_MAP_IMG: "PACKAGE_MAP_IMG",
    DESTINATION_IMG : "DESTINATION_IMG",
    CABIN_IMG: "CABIN_IMG"
}

export const IMAGE_SUB_TYPE = {
    ENGLISH_IMG : "ENGLISH_IMG",
    TRADITIONAL_CHINESE_IMG: "TRADITIONAL_CHINESE_IMG",
    SIMPLIFIED_CHINESE_IMG: "SIMPLIFIED_CHINESE_IMG"
}
