import React from "react";
import "./app.css";
import { getImage } from "../../../../../../utils/getImages";
const DealCards = ({ cardData, activetab }) => {
 
  return (
    <div style={{ marginLeft: "10px" }}>
      <h1 className="title-color mb-4 " style={{ textAlign: "center" }}>
        {" "}
        {activetab === "english"
                ? cardData?.title
                : activetab === "simplified_chinese"
                ? cardData?.simplified_chinese_title
                : activetab === "arabic"
                ? cardData?.arabic_title
                : cardData?.traditional_chinese_title}
      </h1>
      <p dangerouslySetInnerHTML={{
                              __html:
                                activetab === "english"
                                  ? cardData?.description
                                  : activetab === "simplified_chinese"
                                  ? cardData?.simplified_chinese_description
                                  : activetab === "arabic"
                                  ? cardData?.arabic_description
                                  : cardData?.traditional_chinese_description,
                            }} style={{ textAlign: "center" }}/>
    
      <div
        className="col-12 text-center mb-5"
        style={{ display: "flex", alignItems: "center", width: "100%" }}
      >
        <ul
          className="page-tab scroll-tab"
          style={{
            display: "flex",
            gap: "10px",

            listStyleType: "none",
            padding: 0,
            alignItems: "center",
            justifyContent: "center",
            margin: 0,
            width: "100%",
          }}
        >
          {cardData?.dataArray?.map((item, index) => (
            <li data-scroll-tab-seq={index + 1 + ""} className="">
              {activetab === "english"
                ? item?.title
                : activetab === "simplified_chinese"
                ? item?.simplified_chinese_title
                : activetab === "arabic"
                ? item?.arabic_title
                : item?.traditional_chinese_title}
            </li>
          ))}
        </ul>
      </div>
      <div
        style={{
          display: "flex",
          // alignItems: "center",
          flexWrap: "wrap",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <div className="row" style={{ width: "100%" }}>
          {cardData?.dataArray?.map((item) => (
            <div style={{ width: "100%" }}>
              <div className="col-12 text-center scroll-content-1">
                <h2
                  className="mb-5 title-grey"
                  style={{ direction: activetab === "arabic" && "rtl" }}
                >
                 {activetab === "english"
                              ? item?.subtitle
                              : activetab === "simplified_chinese"
                              ? item?.simplified_chinese_subtitle
                              : activetab === "arabic"
                              ? item?.arabic_subtitle
                              : item?.traditional_chinese_title}
                </h2>
              </div>

              <div className="row" style={{ width: "100%" }}>
                {item?.dataArray?.map((item) => (
                  <div className="col-md-12" style={{ marginBottom: "10px" }}>
                    <div className="event-holder">
                      <a className="rounded gallery-item">
                        <img src={getImage(item?.image)} alt="" className="w-100" />
                        <div className="info">
                          <h3 className="title" data-lang='{"CN":"紳士盛典"}'>
                            {activetab === "english"
                              ? item?.title
                              : activetab === "simplified_chinese"
                              ? item?.simplified_chinese_title
                              : activetab === "arabic"
                              ? item?.arabic_title
                              : item?.traditional_chinese_title}
                                 <p
                            
                            style={{
                              direction: activetab === "arabic" && "rtl",
                              maxHeight: "100px",
                              overflow: "hidden",
                              fontWeight:"400",
                              fontSize:"14px",
                              color:"white"
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                activetab === "english"
                                  ? item?.description
                                  : activetab === "simplified_chinese"
                                  ? item?.simplified_chinese_description
                                  : activetab === "arabic"
                                  ? item?.arabic_description
                                  : item?.traditional_chinese_description,
                            }}
                          />
                          </h3>
                       
                        </div>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DealCards;
