import { message } from "antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import Quill from "quill";
import TableUI from "quill-table-ui";
import "quill-table-ui/dist/index.css"; // Optional if the module comes with specific CSS
import RichEditor from "./RichTextEditor";

Quill.register({
  "modules/tableUI": TableUI,
});

const ReactQuillData = ({ data, setData, key, active,level }) => {
  const [value, setValue] = useState(data);
 
  useEffect(() => {

    if (active)
    setData(value);
  }, [value]);

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [
        {
          color: ["red", "blue", "yellow", "#E5BF83", "gray","#dc3545"],
        },
      ],
      ["link", "image", "video"],
      ["clean"],
      [{ direction: "rtl" }],
      ["table"], // Add the table option
    ],
    clipboard: {
      matchVisual: true,
    },
    tableUI: true, // Enable table module
  };

  return (
    <div key={key}>
      <ReactQuill
        modules={modules}
        theme="snow"
        style={{ height: "100px" }}
        value={value}
        onChange={(e) => {
          setValue(e);
        }}
      />
    </div>
  );
};

export default ReactQuillData;
