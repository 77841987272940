import AcUnitIcon from "@mui/icons-material/AcUnit";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArticleIcon from "@mui/icons-material/Article";
import BookIcon from "@mui/icons-material/Book";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import HomeIcon from "@mui/icons-material/Home";
import LanguageIcon from "@mui/icons-material/Language";
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import MovieIcon from "@mui/icons-material/Movie";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SailingIcon from "@mui/icons-material/Sailing";
import TuneIcon from "@mui/icons-material/Tune";
const sideMenu = [
  {
    title: "CMS",
    icon: <NewspaperIcon />,
    link: "/cms",
    subMenu: [
      {
        title: "Homepage",
        icon: <HomeIcon />,
        link: "/homepage",
        subMenu: [
          {
            title: "Announcement",
            link: "/announcement",
          },
          {
            title: "Banner",
            link: "/banner",
          },
          {
            title: "Destinations",
            link: "/destinations",
          },
          // {
          //     title: "SEO",
          //     link: "/seo",
          // },
          {
            title: "Experience",
            link: "/experience",
          },
          {
            title: "Location",
            link: "/location",
          },
          // {
          //     title: "Deal Details",
          //     link: "/deal-details",
          // },
        ],
      },
      {
        title: "Packages",
        icon: <SailingIcon />,
        link: "/packages",
      },
      {
        title: "Promo Index",
        icon: <ArticleIcon />,
        link: "/promo-index",
      },
      // {
      //     title: "Other Pages",
      //     icon: <ArticleIcon />,
      //     link: "/other-pages",
      // },
      {
        title: "Special Pages",
        icon: <BookIcon />,
        link: "/special-pages",
      },
      {
        title: "Newsletter",
        icon: <ArticleIcon />,
        link: "/news-letter",
      },
    ],
  },
  {
    title: "Masters",
    icon: <TuneIcon />,
    link: "/masters",
    subMenu: [
      {
        title: "Markets",
        link: "/markets",
      },
      {
        title: "Cabin Master",
        link: "/cabin-master",
      },
      {
        title: "Cabin Types",
        link: "/cabin-types",
      },
      {
        title: "Cabin Category",
        link: "/cabin-category",
      },
    ],
  },
  {
    title: "Bookings",
    icon: <CalendarMonthIcon />,
    link: "/bookings",
    subMenu: [
      {
        title: "Cabin Bookings",
        link: "/cabin-booking",
      },
      {
        title: "Failed Bookings",
        link: "/leads",
      },
    ],
  },
  {
    title: "Webpages",
    icon: <LanguageIcon />,
    link: "/webpages",
    subMenu: [
      {
        icon: <LaptopChromebookIcon />,
        title: "Templates",
        link: "/templates",
      },
      {
        icon: <AcUnitIcon />,
        title: "Deals",
        link: "/deals",
        subMenu: [
          {
            title: "Celebration Packages",
            link: "/celebration-packages",
          },
          {
            title: "Onboard Promotions",
            link: "/onboard-promotions",
          },
        ],
      },
      {
        icon: <DirectionsBoatIcon />,
        title: "Ships",
        link: "/ships",
      },
      {
        icon: <DirectionsBoatIcon />,
        title: "Destinations",
        link: "/destinations",
      },
      {
        icon: <MovieIcon />,
        title: "Experiences",
        link: "/experiences",
        subMenu: [
          {
            title: "Entertainment",
            link: "/entertainment",
          },
          {
            title: "Life at RWC",
            link: "/life-at-rwc",
          },
          {
            title: "Events",
            link: "/events",
          },
          {
            title: "MICE",
            link: "/mice",
          },
        ],
      },
      {
        icon: <MovieIcon />,
        title: "About Us",
        link: "/about-us",
        subMenu: [
          {
            title: "Our Story",
            link: "/our-story",
          },
          {
            title: "The Palace",
            link: "/the-palace",
          },
          {
            title: "Balcony Class",
            link: "/balcony-class",
          },
          {
            title: "Safety Guides",
            link: "/safety-guides",
          },
          {
            title: "Brochures and Guides",
            link: "/brochures-guides",
          },
          {
            title: "FAQ",
            link: "/faq",
          },
        ],
      },
      {
        icon: <MovieIcon />,
        title: "Footer",
        link: "/footer",
        subMenu: [
          {
            title: "Bill of Rights",
            link: "/bill-of-rights",
          },
          {
            title: "Privacy Policy",
            link: "/privacy-policy",
          },
          {
            title: "Cookie Policy",
            link: "/cookie-policy",
          },
          {
            title: "Terms of Usage",
            link: "/terms-of-usage",
          },
          {
            title: "Passage Terms",
            link: "/passager-terms",
          },
          {
            title: "Terms And Conditions",
            link: "/terms-and-conditions",
          },
          {
            title: "Career",
            link: "/career",
          },
          {
            title: "Media & Resources",
            link: "/press-releases",
          },
          {
            title: "Contact Us",
            link: "/contact-us",
          },
        ],
      },
      {
        icon: <MovieIcon />,
        title: "Header",
        link: "/header",
        subMenu: [
          {
            title: "Prepare For Your Cruise",
            link: "/prepare-for-your-cruise",
          },
          {
            title: "Know Before You Sail",
            link: "/know-before-you-sail",
          },
        ],
      },
      {
        title: "Blog",
        icon: <LaptopChromebookIcon />,
        link: "/blog",
      },
    ],
  },
  {
    title: "Profile",
    icon: <AccountCircleIcon />,
    link: "/profile",
    subMenu: [
      {
        title: "My Profile",
        link: "/my-profile",
      },
      {
        title: "Roles",
        link: "/role",
      },
      {
        title: "User Management",
        link: "/user-management",
      },
      {
        title: "Reset Password",
        link: "/reset-password",
      },
    ],
  },
  {
    title: "Logout",
    onClick: () => {
      localStorage.removeItem("token");
    },
    icon: <PowerSettingsNewIcon />,
  },
];

export const menus = { sideMenu };
