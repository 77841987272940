import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";

export const createDownloads = async (request) => {
  try {
    let res = await Api.post("/downloads", request);
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const getDownloadsData = async (query) => {
  try {
    let res = await Api.get("/downloads", {
      params: query,
    });
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};