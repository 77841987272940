import React from "react";
import CustomTemplates from "../../Deals/CustomTemplate";
import { MicePage } from "../../../../components/template/CustomTemplate/Pages/MicePage";

const MICEPage = () => {
  
  return (
    <CustomTemplates slug={"mice"} attributes={MicePage} />
  );
};

export default MICEPage;
