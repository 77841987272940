import React, { useEffect } from "react";
import "./app.css";
import { getImage } from "../../../../../../utils/getImages";
import { getLang } from "../../lang/getlang";
const PreviewPay = ({ cardData, activetab }) => {
  return (
    <div>
      {cardData?.dataArray.map((value) => (
        <div style={{ marginLeft: "10px" }}>
          <h1 className="title-gray center" style={{ textAlign: "center" }}>
            {" "}
            {activetab === "english"
              ? value?.title
              : activetab === "simplified_chinese"
              ? value?.simplified_chinese_title
              : activetab === "arabic"
              ? value?.arabic_title
              : value?.traditional_chinese_title}
          </h1>
          <div className="col-12 " style={{ marginBottom: "20px" }}>
            <div className="langEN">
              <div className="container  table-wrapper">
                <table className="pay-as-you-go text-center">
                  <colgroup>
                    <col style={{ width: "50%" }} />
                    <col style={{ width: "50%" }} />
                  </colgroup>
                  <tbody>
                    {value?.dataArray?.map((item, index) =>
                      index === 0 ? (
                        <tr>
                          <td>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  activetab === "english"
                                    ? item?.price
                                    : activetab === "simplified_chinese"
                                    ? item?.simplified_chinese_price
                                    : activetab === "arabic"
                                    ? item?.arabic_price
                                    : item?.traditional_chinese_price,
                              }}
                            />
                          </td>
                          <td rowspan="5" className="img-cell">
                            <img
                              src={getImage(value?.image)}
                              style={{ width: "100%" }}
                              height="453"
                            />
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td>
                            {" "}
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  activetab === "english"
                                    ? item?.price
                                    : activetab === "simplified_chinese"
                                    ? item?.simplified_chinese_price
                                    : activetab === "arabic"
                                    ? item?.arabic_price
                                    : item?.traditional_chinese_price,
                              }}
                            />
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
                <div>
              <div
                style={{
                  marginLeft: "10px",
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                {" "}
                {getLang("note", activetab)}
              </div>
              <div
                style={{
                  marginLeft: "10px",
                  fontSize: 14,
                  marginRight: "auto",
                }}
                dangerouslySetInnerHTML={{
                  __html:
                    activetab === "english"
                      ? value?.note
                      : activetab === "simplified_chinese"
                      ? value?.simplified_chinese_note
                      : activetab === "arabic"
                      ? value?.arabic_note
                      : value?.traditional_chinese_note,
                }}
              />
            </div>
              </div>{" "}
             </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PreviewPay;
