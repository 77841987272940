import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import HeaderBread from "../../../components/HeaderBread";
import EditRoleInput from "../../../components/Role/EditRoleInput";
import Layout from "../../../Layout";
const Permission = () => {
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Role",
      route: "/profile/role/add",
    },
  ];
  return (
    <Layout type={"create_per"} module_data={"Roles"}>
      <CustomHeaderTitle title={"Add Roles"} />
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"Add Roles"}
          ></HeaderBread>
        </div>
        <EditRoleInput />
      </div>
    </Layout>
  );
};

export default Permission;
