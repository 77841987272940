import React, { useEffect, useState } from "react";
import { Pagination } from "antd";
import { Button } from "@mui/material";
const CustomizedTable = ({
  columns,
  rows,
  page,
  setPage,
  count,
  pageSize,
  sortHandler,
  setRows,
  setPageSize
}) => {

  useEffect(()=>{
    if(setPage)
    setPage(1)
  },[pageSize])
  const onChange = (pageNumber) => {
    setPage(pageNumber);
    setRows([]);
  };
  const onSizeChange = (current, pageSize)=>{
    console.log("hii",pageSize)
    setPageSize(pageSize);
  }

  const [sortBy, setSortBy] = useState({
    column: null,
    order: "asc",
  });
  const handleSort = (column) => {
    sortHandler({
      columnKey: column,
      order:
        sortBy.column === column && sortBy.order === "asc"
          ? "descend"
          : "ascend",
    });

    setSortBy((prevSortBy) => ({
      column,
      order:
        prevSortBy.column === column && prevSortBy.order === "asc"
          ? "desc"
          : "asc",
    }));
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden"
      }}
    >
    
      <div className="table_holder divcontainer">
        <table id="voucher_list" className="table table-bordered table-striped">
          <thead>
            <tr>
              {columns.map((item, index) => (
                <th
                  onClick={() => item?.sorter && handleSort(item.key)}
                  style={{ width: item?.width+"px !impotant"||"auto" +"!impotant", cursor: "pointer" }}
                >
                  <k>{item.title}</k>
                  {item?.sorter && (
                    <k style={{ marginLeft: "10px" }}>
                      <k
                        style={{
                          color:
                            sortBy.column === item.key && sortBy.order === "asc"
                              ? "red"
                              : "grey",
                        }}
                      >
                        ↑
                      </k>
                      <k
                        style={{
                          color:
                            sortBy.column === item.key &&
                            sortBy.order === "desc"
                              ? "red"
                              : "grey",
                        }}
                      >
                        ↓
                      </k>
                    </k>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows?.map((row, index) => {
          
              return (
                <tr>
                  {columns?.map((item) => {
                    return (
                      <td key={item.id} style={{ minWidth: item?.width,height:"auto"}}>
                        <div style={{display:"flex",alignItems:"center",justifyItems:"center",width:"100%",height:"100%"}}>
                        {item.render
                          ? item.render(row[item.key], row, index)
                          : row[item.key]}</div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {rows?.length === 0 && (
              <td valign="top" colspan="8" className="dataTables_empty">
                No matching records found
              </td>
            )}
          </tbody>
        </table>
      </div>
     {count > 10 && <Pagination
        showSizeChanger
        pageSizeOptions={['5', '10', '20', '50']}
        onShowSizeChange={onSizeChange}
        style={{ marginLeft: "auto", marginTop: "10px" }}
        defaultCurrent={page}
        current={page}
        onChange={onChange}
        total={count}
      />}
    </div>
  );
};

export default CustomizedTable;
