import PreviewPay from "../Previews/Deal/PreviewPay/PreviewPay";
import Destination3 from "../Previews/Destination/3/Preview";
import OnboardPromtion4 from "../Previews/Destination/5/Preview";
import OnboardPreview from "../Previews/OnboardPreview/Preview";
import ShipBannerPreview from "../Previews/Ship/Banner/Preview";
import ShipDealPreview from "../Previews/Ship/DealCard/Preview";

export const OnboradingPromotion = {
  form: [
    {
      title: "Page Name",
      key: "page_name",
      className: "col-lg-12",
      type: "input",
    },
    {
      title: "Meta Description",
      key: "meta_description",
      className: "col-lg-12",
      type: "input",
    },
  ],
  component: [
    {
      type: 1,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "Page Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Page Image",
          type: "file",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <ShipBannerPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 1,
      fields: [
        {
          title: "Page Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Page Image",
          type: "file",
          required: false,
        },

        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <ShipDealPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 3,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
      ],
      level1: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "html",
          required: false,
        },

        {
          title: "English note",
          key: "note",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese note",
          key: "simplified_chinese_note",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_note",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic note",
          key: "arabic_note",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Ship",
          key: "ship",
          className: "col-lg-3",
          type: "ship",
          required: false,
        },
      ],
      level2: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false,
        },
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "1 Night",
          key: "one_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese 1 Night",
          key: "simplified_chinese_one_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese 1 Night",
          key: "traditional_chinese_one_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic 1 Night",
          key: "arabic_one_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "2 Nights",
          key: "two_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese 2 Night",
          key: "simplified_chinese_two_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese 2 Night",
          key: "traditional_chinese_two_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic 2 Night",
          key: "arabic_two_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "3 Nights",
          key: "three_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese 3 Nights",
          key: "simplified_chinese_three_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese 3 Nights",
          key: "traditional_chinese_three_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic 3 Nights",
          key: "arabic_three_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "4 Nights",
          key: "four_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese 4 Nights",
          key: "simplified_chinese_four_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese 4 Nights",
          key: "traditional_chinese_four_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic 4 Nights",
          key: "arabic_four_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "5 Nights",
          key: "five_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese 5 Nights",
          key: "simplified_chinese_five_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese 5 Nights",
          key: "traditional_chinese_five_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic 5 Nights",
          key: "arabic_five_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "6 Nights",
          key: "six_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese 6 Nights",
          key: "simplified_chinese_six_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese 6 Nights",
          key: "traditional_chinese_six_nights",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic 6 Nights",
          key: "arabic_six_nights",
          className: "col-lg-3",
          type: "input",
        },
      ],
      preview: (activetab, cardData) => (
        <OnboardPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 3,
      fields: [],
      level1: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "Ship",
          key: "ship",
          className: "col-lg-3",
          type: "ship",
          required: false,
        },
      ],
      level2: [
        {
          title: "Popup Image",
          key: "image2",
          className: "col-lg-6",
          placeholder: "Upload Popup Image",
          type: "file",
          required: false,
        },
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-6",
          placeholder: "Upload Card Image",
          type: "file",
          required: false,
        },
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "English Subtitle",
          key: "sub_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Subtitle",
          key: "simplified_chinese_sub_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Subtitle",
          key: "traditional_chinese_sub_title",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Subtitle",
          key: "arabic_sub_title",
          className: "col-lg-3 arabic",
          type: "input",
        },
        {
          title: "English type",
          key: "type",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese type",
          key: "simplified_chinese_type",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese type",
          key: "traditional_chinese_type",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic type",
          key: "arabic_type",
          className: "col-lg-3 arabic",
          type: "input",
        },
        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "English Filter Text",
          key: "filter_text",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Filter Text",
          key: "simplified_chinese_filter_text",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Filter Text",
          key: "traditional_chinese_filter_text",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Filter Text",
          key: "arabic_filter_text",
          className: "col-lg-3 arabic",
          type: "input",
        },
        {
          title: "English tag",
          key: "tag",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese tag",
          key: "simplified_chinese_tag",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese tag",
          key: "traditional_chinese_tag",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic tag",
          key: "arabic_tag",
          className: "col-lg-3 arabic",
          type: "input",
        },
        {
          title: "Show Popup",
          key: "popup",
          className: "col-lg-3",
          type: "select",
          option: [
            {
              id: "show",
              name: "Show",
            },
            {
              id: "hide",
              name: "Hide",
            },
          ],
        },
      ],
      preview: (activetab, cardData) => (
        <OnboardPromtion4 activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 3,
      fields: [],

      level1: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "Poster Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Poster Image",
          type: "file",
          required: false,
        },
        {
          title: "English Note",
          key: "note",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Note",
          key: "simplified_chinese_note",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Note",
          key: "traditional_chinese_note",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Note",
          key: "arabic_note",
          className: "col-lg-3 arabic",
          type: "html",
          required: false,
        },
        {
          title: "Ship",
          key: "ship",
          className: "col-lg-3",
          type: "ship",
          required: false,
        },
      ],
      level2: [
        {
          title: "Price",
          key: "price",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese  Price",
          key: "simplified_chinese_price",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese  Price",
          key: "traditional_chinese_price",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic  Price",
          key: "arabic_price",
          className: "col-lg-3 arabic",
          type: "html",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <PreviewPay activetab={activetab} cardData={cardData} />
      ),
    },
  ],
};
