import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ResetPassword } from "../utils/ApiCalls/UserCalls";
import { useDataLayerValue } from "../DataLayer/DataLayer";
import CustomHeaderTitle from "../components/constants/CustomHeaderTitle";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [modal, setModal] = useState(false);
  const [_, dispatch] = useDataLayerValue();
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await ResetPassword({ email });
    if (data.message)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.message, type: "success" },
      });
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });

    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <div className="wrapper login_wrap">
      <CustomHeaderTitle title={"Forget Password"} />
      <img className="bg_img" src="./dist/img/login_bg.jpg" alt="" />
      <div className="login_inner">
        <div className="login-box">
          <div className="box_inner">
            <img className="box_img" src="./dist/img/logo_login.png" alt="" />
            <h4 className="login_title">Forgot Password</h4>
            <p className="login-box-msg">
              You forgot your password? Here you can easily retrieve a new
              password.
            </p>

            <form onSubmit={handleResetPassword}>
              <div className="input-group mb-4">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={email}
                  required
                  onChange={handleEmailChange}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <i className="ri-mail-line"></i>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn_sign">
                    Reset Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        {modal && (
          <div
            className="modal fadeIn theme_modal"
            style={{ display: "block" }}
            id="success_reset_password"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body success_body">
                  <figure className="suc_img">
                    <img src="./dist/img/password_reset.png" alt="" />
                    <h3>Your password updated successfully.</h3>
                  </figure>
                </div>
                <div className="modal-footer justify-content-center">
                  <Link to="/" className="btn modal_submit">
                    Back to Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgetPassword;
