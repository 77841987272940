const { constants } = require("../constants/constants");
const getImage = (image) => {
  if (typeof image === "string" && image.trim() !== "") {
    // Handle string path
    return `${constants.MEDIAURL}/${image}`;
  } else if (image instanceof Blob || image instanceof File) {
    // Handle File/Blob object
    return URL.createObjectURL(image);
  } else {
    // Return empty string for invalid image
    return "";
  }
};

const gettitle=(item)=>{
  if(item.venue_title)
    return item.venue_title
  if(item.sub_title)
    return item.sub_title;
  if(item.subtitle)
    return item.subtitle;
  if(item.title)
    return item.title

}
module.exports = { getImage,gettitle };
