import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BookingInput from "../../../components/Booking/BookingInputs";
import BookingInputs2 from "../../../components/Booking/BookingInputs2";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import HeaderBread from "../../../components/HeaderBread";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Layout from "../../../Layout";
import { getBookings } from "../../../utils/ApiCalls/Leads";


const EditBookings = () => {
  const params = useParams();
  const [bookings, setBooking] = useState({});
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
   
  
    {
      title: "View Cabin Booking",
      route: "/bookings/cabin-booking/edit",
    },
  ];
  useEffect(() => {
   
    getData(params?.id);
  }, [params]);

  const getData = async (value) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getBookings( {booking_no:value} );
    console.log(data)
    
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });

    else if (data) {
      setBooking(data.data?.[0]);

    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <Layout type={"read_per"} module_data={"Cabin Bookings"}>
      <CustomHeaderTitle title={"View Cabin Booking"} />
      <HeaderBread navigation={navigation} title={"View Cabin Booking"}></HeaderBread>
      {bookings &&bookings?.leadDetails?.length>1?<BookingInputs2 booking={bookings} />: <BookingInput booking={bookings} />}

    
    </Layout>
  );
};

export default EditBookings;