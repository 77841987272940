let BASE_URL;
let PRODUCTION_URL;
let BUCKET_NAME;
let REGION;
let MEDIAURL;

const env = process.env;

console.log({env})
BASE_URL = process.env.REACT_APP_API_BASE_URL||"http://localhost:5000/";
// BASE_URL="https://cms-api.rwcruisesuat.com"
PRODUCTION_URL = process.env.REACT_APP_PRODUCTION_URL || "https://rwcruisesuat.com"
MEDIAURL=process.env.REACT_APP_MEDIAURL||'https://media.rwcruisesuat.com'
BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME || 'rwdc-b2c-prd-media';
// BUCKET_NAME='rwcb2c-images'
REGION = process.env.REACT_APP_REGION || 'ap-southeast-1';
module.exports.constants = { BASE_URL,PRODUCTION_URL, BUCKET_NAME, REGION,MEDIAURL };