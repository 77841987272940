import React, { useEffect, useState } from "react";
import CustomTemplates from "../../Deals/CustomTemplate";
import { PassageTermsPage } from "../../../../components/template/CustomTemplate/Pages/PassageTerms";

const BillofRights = ({ router }) => {
  const [slug, setSlug] = useState(null);

  useEffect(() => {
    if (router) {
      setSlug(router);
    }
  }, [router]);

  // Render nothing if slug is not set yet
  if (!slug) return null;

  // The key prop will force the component to reload whenever the slug changes
  return <CustomTemplates key={slug} slug={slug} attributes={PassageTermsPage} />;
};

export default BillofRights;
