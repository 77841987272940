import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import React, { useState } from "react";
import Swal from 'sweetalert2';
import { useDataLayerValue } from "../DataLayer/DataLayer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  border: "none",
  boxShadow: 24,
  p: 4,
};

function isHTML(str) {
  var doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
}

const PromoIndexEdit = ({ record, handleUpdate }) => {

  const [title, setTitle] = useState(record?.title);
  const [description, setDescription] = useState(record?.description);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [, dispatch] = useDataLayerValue();


  return (
    <>
      {/* <form onSubmit={(e)=> console.log("hiii")}> */}
      {/* {!editValidDate ? ( */}
      <div className="table_action_btn" style={{display:"flex"}}>
        <div
          className="btn"
          onClick={() => {
            handleOpen();
          }}
        >
          <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
        </div>
      </div>

      <Modal
        open={open}
        onClose={(e) => {
          handleClose();
         
        }}
        sx={{ border: "none" }}
      >
        <Box sx={style}>
          {isHTML() ? <h4 style={{ fontWeight: "bold" }}  dangerouslySetInnerHTML={{__html: "Edit Promo Index"}}/> : <h4 style={{ fontWeight: "bold" }}>Edit Promo Index</h4> } 
          <form className="input_form">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="fieldrow">
                  <label className="f_label">Title</label>
                  <input
                    required
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    type="text"
                    className="f_input"
                    // type="date"
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="fieldrow">
                  <label className="f_label">Description</label>
                  <textarea
                    required
                    className="f_input"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: "10%",
              }}
            >
              <Button
                variant="contained"
                onClick={(e) => {
                  handleClose();
                  try {
                    if(!title){
                      throw new Error("Please provide title")
                    } 
                    if(!description){
                      throw new Error("Please provide description");
                    }

                  handleUpdate(record.promo_index, { title, description });
                 

                  } catch (error) {
                    dispatch({
                      type: "SET_LOADING",
                      loading: false,
                    });
                    // dispatch({
                    //   type: "SET_ERROR_MESSAGE",
                    //   errMess: {
                    //     message: error.message || "Unknown error occurred",
                    //     type: "error",
                    //   },
                    // });
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: error.message,
                    });
                  }
                }}
              >
                Save
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
      {/* </form> */}
    </>
  );
};

export default PromoIndexEdit;
