import { FormControlLabel, Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Logs from "../../../components/Logs";
import CustomizedTable from "../../../components/UI/CustomizedTable";
import { getAllWebpage } from "../../../utils/ApiCalls/WebPages";
import Preview from "./Preview";

const EventAddCard = ({ oldData, setDataCard,activetab,preview }) => {
  const navigate = useNavigate();
  const [activeData, setActiveData] = useState(false);
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [active, setActive] = useState("listing");
  const [{ userDetails, permission }, dispatch] = useDataLayerValue();

  const [roleData, setRoleData] = useState({
    name: "",
    show: [],
  });
  useEffect(() => {
    console.log({oldData})
    if (oldData) setRoleData(oldData );
  }, [oldData]);
  useEffect(() => {
    setActiveData(preview);
  }, [preview]);
  useEffect(() => {
    console.log({roleData})
    setDataCard(roleData);
  }, [roleData]);
  const addData = (type, value, action) => {
    if (action === "add") {
      let data = roleData[type];
      data.push(value);
      setRoleData((item) => ({
        ...item,
        [type]: data,
      }));
    } else {
      let data = roleData[type].filter((item) => item != value);

      setRoleData((item) => ({
        ...item,
        [type]: data,
      }));
    }
  };
  const columns = [
    {
      title: "No",
      width: 10,
      dataIndex: "id",
      key: "position",
      fixed: "left",
      render: (position, record, index) =>((page - 1) * pageSize) + index + 1,
    },
    {
      title: "Title",
      width: 10,
      dataIndex: "page_name",
      key: "page_name",
      fixed: "left",
    },
    {
        title: "Ship",
        width: 10,
        dataIndex: "ship",
        key: "ship",
        fixed: "left",
        render: (text, record) => (<div>{text=="genting_dream"?"Genting Dream":"Resorts World one"}</div>)
      },

    {
      title: "Action",
      key: "operation",
      width: 30,
      render: (text, record) => (
        <div className="table_action_btn">
          <div className="icheck-primary d-inline">
            <input
              type="checkbox"
              id={"customCheckbox4" + record?.id + "read_per"}
              name="r10"
              checked={roleData?.show?.includes(record?.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  addData("show", record?.id, "add");
                } else {
                  addData("show", record?.id, "remove");
                }
              }}
            />
            <label
              htmlFor={"customCheckbox4" + record?.id + "read_per"}
            ></label>
          </div>
        </div>
      ),
    },
  
  ];
  const handleChange = (event) => {
    setActiveData(event.target.checked);
  };
  const getData = async (value) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getAllWebpage({ page_type: "events_item" ,page: value, limit: pageSize});
    console.log(data);
    setRows(data.data);
    setCount(data?.count)
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  useEffect(() => {
     getData(page);
   }, [page, pageSize]);
  return (
    <div >
      <div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div>
            {active === "listing" && (
              <div className="card">
                <div className="card-header two_item">
                  <h5 className="card-title">Select Other Events</h5>
                  <FormControlLabel
          control={<Switch checked={activeData} onChange={handleChange} />}
          label="Preview"
        />
                </div>
                <div className="card-body">
                {activeData &&  <Preview activetab={activetab} cardData={roleData} />}
                {!activeData&&  <div className="table_holder">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    ></div>
                    {rows && (
                      <CustomizedTable
                        setRows={setRows}
                        setPageSize={setPageSize}
                        setPage={setPage}
                        pageSize={pageSize}
                        page={page}
                        count={count}
                        columns={columns}
                        rows={rows}
                        title={"Cabin Category"}
                        sortHandler={() => {}}
                      />
                    )}
                  </div>}
                </div>
              </div>
            )}
          </div>
        </section>{" "}
        {active === "logs" && (
          <Logs
            type={"Page"}
            slug={"/webpages/our-ship/edit/"}
            refreshlog={active}
          />
        )}
      </div>
    </div>
  );
};

export default EventAddCard;
