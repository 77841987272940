import React from "react";
import HeaderBread from "../../../components/HeaderBread";
import Layout from "../../../Layout";
import { useNavigate } from "react-router-dom";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import DealDetailsInputs from "../../../components/DealDetails/DealDetailsInputs";

const CreateDealDetails = () => {
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Deal Details",
      route: "/cms/homepage/deal-details",
    },
    {
      title: "Add Deal Details",
      route: "/cms/homepage/deal-details/add",
    },
  ];

  return (
    <Layout>
      <CustomHeaderTitle title={"Create Deal Details"} />
      <HeaderBread
        navigation={navigation}
        title={"Add Deal Details"}
      ></HeaderBread>
      <DealDetailsInputs details={{}} />
    </Layout>
  );
};

export default CreateDealDetails;
