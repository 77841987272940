import Api from "../../Api";

export const createTemplates = async (data) => {

  try {
    let res = await Api.post("/webpage-template", data);
    return res?.data;
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const getTemplates = async (query) => {
  try {
    let res = await Api.get("/webpage-template", {
      params: query,
    });

    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const exportTemplates = async (query) => {
  try {
    let res = await Api.get("/webpage-template/export", {
      responseType: "arraybuffer"
    });

    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const deleteTemplates = async (id) => {
  try {
    let res = await Api.delete("/webpage-template/" + id);

    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updateTemplates = async (id, data) => {
  try {
    let res = await Api.patch("/webpage-template/" + id, data);

    return res?.data || [];
  } catch (e) {
    return { error: "Failed to Update" };
  }
};
