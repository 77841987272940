import { Box, Chip, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../DataLayer/DataLayer";

import { checkFields } from "../../utils/Validation/checkFields";
import { createCabinCatergoryData, updateCabinCatergoryData } from "../../utils/ApiCalls/CabinCategory";
import { getVessels } from "../../utils/ApiCalls/SpecialPage";


const CabinCategoryInput = ({ cabintypes }) => {
  const [name, setName] = useState("");

  const [isActive, setIsActive] = useState(true);
  const [display_name, setDisplayName] = useState([]);
  const [arabic_display_name, setArabicDisplayName] = useState([]);
  const [category_code, setCabinCategoryCode] = useState([]);
  const [, dispatch] = useDataLayerValue();
  const [vesselOptions, setVesselOptions] = useState([]);
  const [vessel, setVessel] = useState("");
  const [error, setError] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (cabintypes) {
      setName(cabintypes?.display_name);
      setVessel(cabintypes?.vessel?.id)
      setDisplayName(cabintypes.display_name);
      setIsActive(cabintypes?.is_active === 1 ? true : false);
      setArabicDisplayName(cabintypes?.arabic_display_name)
    }
  }, [cabintypes]);
  const getOptionLocation = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let vessels = await getVessels();
    if (vessel.error) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: vessel.error, type: "error" },
      });
    } else {

      setVesselOptions(vessels);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  useEffect(() => { getOptionLocation() }, []);
  const addcabintypes = async (e) => {
    e.preventDefault();
    let added = false;
    let dataValues = {
      display_name: name,
      arabic_display_name,
      vessel_id: vessel
    };

    let valid = checkFields(dataValues);
    if (valid) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: valid, type: "error" },
      });
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
      setError(valid);
    } else {
      setError();

      const values = {
        display_name,
        category_code,
        ...dataValues,

      };

      let data;
      if (cabintypes?.id) {
        data = await updateCabinCatergoryData(cabintypes.id, values);
      } else {
        data = await createCabinCatergoryData(values);
      }
      if (data?.error) {
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: data.error, type: "error" },
        });
        added = false;
      } else {
        added = true;
      }

      if (added && !cabintypes.id) {
        navigate("/masters/cabin-category");
      } else {
        navigate("/masters/cabin-category");
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: "Values updated", type: "success" },
        });
      }
    }
  };
  return (
    <div>
      <section className="content">
        {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )}

        <div className="container-fluid">
          <div className="card">
            <form className="input_form" onSubmit={addcabintypes}>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Name</label>
                      <input
                        required
                        className="f_input"
                        type="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Display Name</label>
                      <input
                        required
                        className="f_input"
                        type="text"
                        value={display_name}
                        onChange={(e) => setDisplayName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Arabic Display Name</label>
                      <input
                        required
                        className="f_input"
                        type="text"
                        style={{ direction: "rtl" }}
                        value={arabic_display_name}
                        onChange={(e) => setArabicDisplayName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Category Code</label>
                      <input
                        required
                        className="f_input"
                        type="text"
                        value={category_code}
                        onChange={(e) => setCabinCategoryCode(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                  <div className="fieldrow">
                    <label className="f_label">Cruise Name <span style={{ color: "red" }}> *</span></label>
                    <Select
                      required
                      style={{ width: "100%", height: "45px" }}
                      value={vessel}
                      onChange={(e) => setVessel(e.target.value)}
                    >
                      {vesselOptions.map((item, index) => (
                        <MenuItem key={index} value={item?.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>

              <div className="card-footer text-right">
                <button className="btn main_submit">
                  {cabintypes?.id ? "Update" : "Add"} Cabin Category
                </button>
              </div>
            </form>
          </div>
          <div className="pt-3"></div>
        </div>
      </section>
    </div>
  );
};

export default CabinCategoryInput;
