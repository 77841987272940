import arabic from "./arabic.json";
import simplified_chinese from "./simplified_chinese.json";
import treditional_chinese from "./treditional_chinese.json";
import english from "./english.json";
export const getLang = (key, activetab) => {
  return activetab === "english"
    ? english[key]
    : activetab === "simplified_chinese"
    ? simplified_chinese[key]
    : activetab === "arabic"
    ? arabic[key]
    : treditional_chinese[key];
};
