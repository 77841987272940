import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactQuillData from "./ReactQuillData";
import ReactQuillData2 from "./ReactQuillData2";
import UploadFileForm from "./UploadFileForm";
import RichEditor from "./RichTextEditor";
import { useDataLayerValue } from "../../DataLayer/DataLayer";

const DyanmicFrom = ({
  attributes,
  data,
  rootData,
  setData,
  level,
  active,
  error = [],
}) => {
  let [root, setRoot] = useState();
  let [{ ships }, dispatch] = useDataLayerValue();
  useEffect(() => {
    setRoot(rootData)
  }, [rootData])

  const getMenu = (item) => {
    if (item?.menuOption && root) {
      let res = item.menuOption(root)
      return res.map((item, index) => (
        <MenuItem key={index} value={item?.id}>
          {item.name}
        </MenuItem>
      ))
    }
    else if (item.option) {
      return item.option.map((item, index) => (
        <MenuItem key={index} value={item?.id}>
          {item.name}
        </MenuItem>
      ))
    }
  }
  return (
    <div className="row" style={{ width: "100%", marginLeft: "2px" }}>
      {attributes?.map((item, index) => {
        if (item.type === "date") {
          return (
            <div key={index} className={` ${item.className}`}>
              <div className="fieldrow">
                <label className="f_label">
                  {" "}
                  {item.title}{" "}
                  {item?.required && <span style={{ color: "red" }}>*</span>}
                </label>
                <input
                  required
                  value={data[item.key]}
                  onChange={(e) => {
                    setData({
                      ...data,
                      [item.key]: e.target.value,
                    });
                  }}
                  className="f_input"
                  type="date"
                />
                {error?.includes(item.key) && (
                  <span
                    style={{
                      color: "red",
                      marginTop: "5px",
                      fontWeight: "bold",
                      fontSize: 13,
                    }}
                  >
                    {item.title} is required
                  </span>
                )}
              </div>
            </div>
          );
        }
        if (item?.type === "input") {
          return (
            <div key={index} className={` ${item.className}`}>
              <div className="fieldrow">
                <label className="f_label">
                  {item.title}{" "}
                  {item?.required && <span style={{ color: "red" }}>*</span>}
                </label>
                <input
                  className="f_input"
                  // required
                  type="text"
                  value={data[item.key]}
                  onChange={(e) => {

                    setData({
                      ...data,
                      [item.key]: e.target.value,
                    });
                  }}
                />
                {error?.includes(item.key) && (
                  <span
                    style={{
                      color: "red",
                      marginTop: "5px",
                      fontWeight: "bold",
                      fontSize: 13,
                    }}
                  >
                    {item.title} is required
                  </span>
                )}
              </div>
            </div>
          );
        } else if (item?.type === "html") {
          return (
            <div key={index} className={` ${item.className}`}>
              <div className="fieldrow">
                <label className="f_label">
                  {item.title}{" "}
                  {item?.required && <span style={{ color: "red" }}>*</span>}
                </label>
                <div style={{ height: "300px" }}>
                  {level === 1 ? (
                    <ReactQuillData2
                      level={level}
                      key={index}
                      theme="snow"
                      style={{ height: "200px" }}
                      data={data[item.key]}
                      setData={(e) => {

                        setData({
                          ...data,
                          [item.key]: e,
                        });
                      }}
                    />
                    // <div></div>
                  ) : (
                    <ReactQuillData
                      level={level}
                      active={active}
                      key={index}
                      theme="snow"
                      style={{ height: "200px" }}
                      data={data[item.key]}
                      setData={(e) => {
                        setData({
                          ...data,
                          [item.key]: e,
                        });
                      }}
                    />
                  )}

                </div> {error?.includes(item.key) && (
                  <span style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}>
                    {item.title} is required
                  </span>
                )}
              </div>
            </div>
          );
        } else if (item?.type === "editor") {
          return (
            <div key={index} className={` ${item.className}`}>
              <div className="fieldrow">
                <label className="f_label">
                  {item.title}{" "}
                  {item?.required && <span style={{ color: "red" }}>*</span>}
                </label>
                <div style={{ height: item.height || "300px", paddingBottom: "70px" }}>

                  <RichEditor
                    level={level}
                    key={index}
                    theme="snow"
                    style={{ height: "200px" }}
                    data={data[item.key]}
                    setData={(e) => {

                      setData({
                        ...data,
                        [item.key]: e,
                      });
                    }}
                  />



                </div> {error?.includes(item.key) && (
                  <span style={{ color: "red", fontWeight: "bold", fontSize: "14px" }}>
                    {item.title} is required
                  </span>
                )}
              </div>
            </div>
          );
        } else if (item?.type === "select") {
          return (
            <div key={index} className={` ${item.className}`}>
              <div className="fieldrow">
                <label className="f_label">
                  {item.title}{" "}
                  {item?.required && <span style={{ color: "red" }}>*</span>}
                </label>
                <Select
                  required
                  style={{ width: "100%", height: "45px" }}
                  value={data[item.key]}
                  onChange={(e) => {

                    setData({
                      ...data,
                      [item.key]: e.target.value,
                    });
                  }}
                >
                  {getMenu(item)}

                </Select>
                {error?.includes(item.key) && (
                  <span
                    style={{
                      color: "red",
                      marginTop: "5px",
                      fontWeight: "bold",
                      fontSize: 13,
                    }}
                  >
                    {item.title} is required
                  </span>
                )}
              </div>
            </div>
          );
        } else if (item?.type === "ship") {
          return (
            <div key={index} className={` ${item.className}`}>
              <div className="fieldrow">
                <label className="f_label">
                  {item.title}{" "}
                  {item?.required && <span style={{ color: "red" }}>*</span>}
                </label>
                <Select
                  required
                  style={{ width: "100%", height: "45px" }}
                  value={data[item.key] || ""}
                  onChange={(e) => {
                    setData({
                      ...data,
                      [item.key]: e.target.value,
                    });
                  }}
                >
                  <MenuItem key={"all"} value={""}>
                    All
                  </MenuItem>
                  {getMenu({ ...item, option: ships })}

                </Select>
                {error?.includes(item.key) && (
                  <span
                    style={{
                      color: "red",
                      marginTop: "5px",
                      fontWeight: "bold",
                      fontSize: 13,
                    }}
                  >
                    {item.title} is required
                  </span>
                )}
              </div>
            </div>
          );
        } else if (item?.type === "file") {
          return (
            <div className={item.className}>
              <UploadFileForm
                required={item.required}
                type={item?.filetype || "image"}
                key={index}
                label={item.title}
                placeholder={item.placeholder}
                image={data[item.key]}
                setImage={(e) => {

                  setData({
                    ...data,
                    [item.key]: e,
                  });
                }}
              />
              {error?.includes(item.key) && (
                <span
                  style={{
                    color: "red",
                    marginTop: "5px",
                    fontWeight: "bold",
                    fontSize: 13,
                  }}
                >
                  {item.title} is required
                </span>
              )}
            </div>
          );
        }
      })}
    </div>
  );
};

export default DyanmicFrom;
