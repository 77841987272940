import KnowPreview from "../Previews/KnowPreview/Preview";
import TablePreview from "../Previews/TablePreview/Preview";
import TablePreview6 from "../Previews/TablePreview6/Preview";
import TablePreview7 from "../Previews/TablePreview7/Preview";

export const KnowBeforeYouSailPage = {
  form: [
    {
      title: "Page Name",
      key: "page_name",
      className: "col-lg-12",
      type: "input",
    },
    {
      title: "Meta Description",
      key: "meta_description",
      className: "col-lg-12",
      type: "input",
    },
  ],
  component: [
  
   
    {
      type: 2,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "English Sub Title",
          key: "subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Sub Title",
          key: "simplified_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Sub Title",
          key: "traditional_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Sub Title",
          key: "arabic_subtitle",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
      ],
      level1: [
        {
          title: "Card Image",
          key: "logo",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false,
        },

        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html"
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html"
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html"
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html"
        },
        {
          title: "Card QR",
          key: "image",
          className: "col-lg-3",
          placeholder: "Upload Card QR",
          type: "file",
          required: false,
        },
       
        
        {
          title: "Simplified Chinese QR",
          key: "simplified_chinese_image",
          placeholder: "Upload Card QR",
          className: "col-lg-3",
          type: "file",
        },
        {
          title: "Traditional Chinese QR",
          key: "traditional_chinese_image",
          className: "col-lg-3",
          type: "file",
          placeholder: "Upload Card QR",
        },
        {
          title: "Arabic QR",
          key: "arabic_image",
          className: "col-lg-3 arabic",
          type: "file",
          placeholder: "Upload Card QR",
        },
        {
          title: "Ship",
          key: "ship",
          required: false,
          className: "col-lg-3",
          type: "ship",
     
        },
      ],
      preview: (activetab, cardData) => (
        <KnowPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 3,
      fields:[],
      level1: [
        {
          title: "English Sub Title",
          key: "subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Sub Title",
          key: "simplified_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Sub Title",
          key: "traditional_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Sub Title",
          key: "arabic_subtitle",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "English Standard Note",
          key: "standard_note",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Standard Note",
          key: "simplified_chinese_standard_note",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Standard Note",
          key: "traditional_chinese_standard_note",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Standard Note",
          key: "arabic_standard_note",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "English Premium Note",
          key: "premium_note",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Premium Note",
          key: "simplified_chinese_premium_note",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Premium Note",
          key: "traditional_chinese_premium_note",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Premium Note",
          key: "arabic_premium_note",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "Ship",
          key: "ship",
          className: "col-lg-3",
          type: "ship",
        },
      ],
      level2: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

     
        {
          title: "English 1 Night",
          key: "one_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Simplified Chinese 1 Night",
          key: "simplified_chinese_one_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Traditional Chinese 1 Night",
          key: "traditional_chinese_one_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Arabic 1 Night",
          key: "arabic_one_night",
          className: "col-lg-3 arabic",
          type: "input",
          
        },
        {
          title: "English 2 Night",
          key: "two_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Simplified Chinese 2 Night",
          key: "simplified_chinese_two_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Traditional Chinese 2 Night",
          key: "traditional_chinese_two_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Arabic 2 Night",
          key: "arabic_two_night",
          className: "col-lg-3 arabic",
          type: "input",
          
        },
        {
          title: "English 3 Night",
          key: "three_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Simplified Chinese 3 Night",
          key: "simplified_chinese_three_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Traditional Chinese 3 Night",
          key: "traditional_chinese_three_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Arabic 3 Night",
          key: "arabic_three_night",
          className: "col-lg-3 arabic",
          type: "input",
          
        },
        {
          title: "English 4 Night",
          key: "four_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Simplified Chinese 4 Night",
          key: "simplified_chinese_four_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Traditional Chinese 4 Night",
          key: "traditional_chinese_four_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Arabic 4 Night",
          key: "arabic_four_night",
          className: "col-lg-3 arabic",
          type: "input",
          
        },
        {
          title: "English 5 Night",
          key: "five_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Simplified Chinese 5 Night",
          key: "simplified_chinese_five_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Traditional Chinese 5 Night",
          key: "traditional_chinese_five_night",
          className: "col-lg-3",
          type: "input",
          
        },

        {
          title: "Arabic 5 Night",
          key: "arabic_five_night",
          className: "col-lg-3 arabic",
          type: "input",
          
        },
        {
          title: "English 6 Night",
          key: "six_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Simplified Chinese 6 Night",
          key: "simplified_chinese_six_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Traditional Chinese 6 Night",
          key: "traditional_chinese_six_night",
          className: "col-lg-3",
          type: "input",
          
        },
        {
          title: "Arabic 6 Night",
          key: "arabic_six_night",
          className: "col-lg-3 arabic",
          type: "input",
          
        },
     
      ],
      preview: (activetab, cardData) => (
        <TablePreview6 activetab={activetab} cardData={cardData} />
      ),
    },
  
    {
      type: 3,
      fields:[],
      level1: [
        {
          title: "English Sub Title",
          key: "subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Sub Title",
          key: "simplified_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Sub Title",
          key: "traditional_chinese_subtitle",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Sub Title",
          key: "arabic_subtitle",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "Ship",
          key: "ship",
          required: false,
          className: "col-lg-3",
          type: "ship",
        },
      ],
      level2: [
        {
          title: "English Venue Title",
          key: "venue_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Venue Title",
          key: "simplified_chinese_venue_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Venue Title",
          key: "traditional_chinese_venue_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Venue Title",
          key: "arabic_venue_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "English Port Name",
          key: "port_name",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Port Name",
          key: "simplified_chinese_port_name",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Port Name",
          key: "traditional_chinese_port_name",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Port Name",
          key: "arabic_port_name",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "English Address",
          key: "address",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Address",
          key: "simplified_chinese_address",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Address",
          key: "traditional_chinese_address",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Address",
          key: "arabic_address",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <TablePreview7 activetab={activetab} cardData={cardData} />
      ),
    },
    
  ],
};
