import React from "react";
import "./app.css";
import { getImage } from "../../../../../utils/getImages";
const Card4Ship = ({ cardData, activetab }) => {
  return (
    <div style={{ padding: "10px", background: "#CB001F" }}>
      <div>
        <h1
          className="title-color mb-4"
          style={{ color: "#FFFFFF" }}
          data-lang='{ "CN":"客房" }'
        >
            {activetab === "english"
          ? cardData?.title
          : activetab === "simplified_chinese"
          ? cardData?.simplified_chinese_title
          : activetab === "arabic"
          ? cardData?.arabic_title
          : cardData?.traditional_chinese_title}
        </h1>
      </div>
      <div
      className="row"
        style={{
          display: "flex",
          // alignItems: "center",
          flexWrap: "wrap",
          flexDirection:"row",
        }}
      >
      {cardData?.dataArray?.map((item) => (<div className="destination-package col-3" style={{marginBottom:"10px"}}>
        <img
         src={
          getImage(item?.image)
             }
         className="w-100 rounded"
        />

        <a href="">
          <div>
            <div className="info">
              <p className="place" data-lang='{ "CN":"皇宫庭苑别墅" }'>
              {activetab === "english"
                  ? item?.title
                  : activetab === "simplified_chinese"
                  ? item?.simplified_chinese_title
                  : activetab === "arabic"
                  ? item?.arabic_title
                  : item?.traditional_chinese_title}
              </p>
            </div>

          </div>
        </a>
      </div>))}
    </div>
    </div>
  );
};

export default Card4Ship;
