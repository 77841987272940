import React from "react";
import "./app.css";
import { getImage } from "../../../../../../utils/getImages";
const ThumbnailPreview = ({ cardData, activetab }) => {
  return (
    <div>
      <div className="card-body" style={{margin:"20px"}}></div>
      <div className="destination-package" style={{ width: "200px",margin:"20px" }}>
        <img
        alt=""
         src={
          getImage(cardData?.image)
             }
         className="w-100 rounded"
        />

          <div>
            <div className="info">
              <p className="place" >
              {activetab === "english"
                  ? cardData?.title
                  : activetab === "simplified_chinese"
                  ? cardData?.simplified_chinese_title
                  : activetab === "arabic"
                  ? cardData?.arabic_title
                  : cardData?.traditional_chinese_title}
              </p>
            </div>

          </div>
     
      </div>
    </div>
  );
};

export default ThumbnailPreview;
