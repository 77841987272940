import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../Layout";
import HeaderBread from "../../../components/HeaderBread";

import { message } from "antd";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Logs from "../../../components/Logs";
import SwitchBar from "../../../components/Pages/SwitchBar";
import SearchField from "../../../components/SearchField";
import CustomizedTable from "../../../components/UI/CustomizedTable";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import DeletePop from "../../../constants/ErrorPermissionModal";
import {
  deleteFareCodeData,
  getFareCodeData,
} from "../../../utils/ApiCalls/FareCode";
import { checkPermission } from "../../../utils/Validation/checkPermission";

const FareCode = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Market",
      route: "/masters/markets",
    },
  ];
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [active, setActive] = useState("listing");
  const [{ permission }, dispatch] = useDataLayerValue();

  const sortHandler = async (options) => {
    if (Object.keys(options).length > 0) {
      let sort, order;
      sort = options.columnKey;
      order =
        options.order === "ascend"
          ? "asc"
          : options.order === "descend"
          ? "desc"
          : undefined;
      await getData(1, sort, order);
    }
  };

  const columns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (position, record, index) =>((page - 1) * pageSize) + index + 1,
    },

    {
      title: "Itravel Country",
      dataIndex: "itravel_country",
      key: "itravel_country",
      sorter: (a, b) => "",
      width: 50,
    },

    {
      title: "Itravel Market Code",
      dataIndex: "marketCode",
      key: "marketCode",
      sorter: (a, b) => "",
      width: 50,
    },
    {
      title: "Itravel Agency Code",
      dataIndex: "agencyCode",
      key: "agencyCode",
      sorter: (a, b) => "",
      width: 50,
    },
    {
      title: "Currency Code",
      dataIndex: "currencyCode",
      key: "currencyCode",
      sorter: (a, b) => "",
      width: 50,
    },
    {
      title: "Detected Country",
      dataIndex: "detected_country",
      key: "detected_country",
      sorter: (a, b) => "",
      width: 50,
    },
    {
      title: "Assigned Market code",
      dataIndex: "assigned_market_code",
      key: "assigned_market_code",
      width: 50,
    },

    {
      title: "Action",
      key: "operation",
      width: 40,
      render: (text, record) => (
        <div className="table_action_btn">
          <div className="btn" onClick={() => handleEdit(record.id)}>
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <DeletePop onConfirm={() => handleDelete(record.id)}>
            <div>
              {" "}
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getData(page);
  }, [page, pageSize, search]);

  const getData = async (value, sort, order) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let args = { search: search, page: value, limit: pageSize };
    if (order) {
      if (sort) {
        args = { ...args, sort };
      }
      args = { ...args, order };
    }

    let data = await getFareCodeData(args);
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.data) {
      console.log({ data });
      setRows(data.data);
      setCount(data.count);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  const handleEdit = async (id) => {
    if (checkPermission(permission, "update_per", "Markets")) {
      navigate("/masters/markets/edit/" + id);
    } else {
      message.error("Update permission missing");
    }
  };
  const handleDelete = async (id) => {
    if (checkPermission(permission, "delete_per", "Markets")) {
      deleteFareCodeData(id);
      getData();
    } else {
      message.error("Missing Permission for delete ");
    }
  };

  return (
    <Layout type={"read_per"} module_data={"Markets"}>
      <CustomHeaderTitle title={"Markets"} />
      <div>
        <div>
          <HeaderBread navigation={navigation} title={"Markets"}></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <SwitchBar
              tabs={[
                { title: "Market List", value: "listing" },
                { value: "logs", title: " History Logs" },
              ]}
              activeTab={active}
              setActiveTab={setActive}
            />
            {active === "listing" && (
              <div className="card">
                <div className="card-header two_item">
                  <h5 className="card-title">Market List</h5>
                  <Link to="/masters/markets/add" className="btn add_v_btn">
                    Add Market
                  </Link>
                </div>
                <div className="card-body">
                  <div className="table_holder">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div style={{ maxWidth: 200 }}>
                        <SearchField
                          value={search}
                          SearchValue={() => {
                            setPage(1);
                          }}
                          setValue={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    {rows && (
                      <CustomizedTable
                        setRows={setRows}
                        setPageSize={setPageSize}
                        setPage={setPage}
                        pageSize={pageSize}
                        page={page}
                        count={count}
                        columns={columns}
                        rows={rows}
                        title={"Markets"}
                        sortHandler={sortHandler}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        {active === "logs" && (
          <Logs
            type={"Markets"}
            slug={"/masters/markets/edit/"}
            refreshlog={active}
          />
        )}
      </div>
    </Layout>
  );
};

export default FareCode;
