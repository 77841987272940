import React from "react";
import BannerInputs from "../../../components/Banner/BannerInputs";
import HeaderBread from "../../../components/HeaderBread";
import Layout from "../../../Layout";
import { useNavigate } from "react-router-dom";
import ExperienceInputs from "../../../components/Experience/ExperienceInputs";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";

const CreateExperience = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Experience",
      route: "/cms/homepage/experience",
    },
    {
      title: "Add Experience",
      route: "/cms/homepage/experience/add",
    },
  ];

  return (
    <Layout type={"create_per"} module_data={"Experience"}>
        <CustomHeaderTitle title={"Create Experience"}/>
      <HeaderBread navigation={navigation} title={"Add Experience"}></HeaderBread>
      <ExperienceInputs experience={{}} />
    </Layout>
  );
};

export default CreateExperience;
