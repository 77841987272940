import React from "react";
import DestinationInput from "../../../components/Destination/DestinationInput";
import HeaderBread from "../../../components/HeaderBread";
import Layout from "../../../Layout";
import { useNavigate } from "react-router-dom";
import { createBanner } from "../../../utils/ApiCalls/Banner";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";

const CreateDestination = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Destination List",
      route: "/cms/homepage/destinations",
    },
    {
      title: "Add Destination",
      route: "/cms/homepage/destinations/add",
    },
  ];

  return (
    <Layout type={"create_per"} module_data={"destinations"}>
        <CustomHeaderTitle title={"Create Destination"}/>
      <HeaderBread navigation={navigation} title={"Add Destination"}></HeaderBread>
      <DestinationInput destination={{}} />
    </Layout>
  );
};

export default CreateDestination;
