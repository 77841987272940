import { Box, Chip, MenuItem, Select } from "@mui/material";
import React from "react";

const SelectInputs = ({
  modules,
  style,
  options,
  locationType,
  selectedValue,
  setSelectedValue,
  disabled,
}) => {
  const handleDelete = (value) => {
    setSelectedValue(selectedValue.filter((item) => item !== value));
  };
  return (
    <Select
      required={locationType==="DisplayLocation" ? true: false}
      style={{ ...style }}
      multiple
      disabled={disabled}
      renderValue={(selected) => (
        <Box
          sx={{ display: "flex", gap: 0.5, overflow: "scroll" }}
          className="noScrollBar"
        >
          {selected.map((value, index) => (
            <Chip
              key={value}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
              color="error"
              onDelete={() => !disabled && handleDelete(value)}
              sx={{
                borderRadius: "4px",
                "& .MuiChip-label": {
                  color: "white",
                  fontWeight: "bold",
                },
              }}
              label={options.find((item) => item.id === value)?.location_name}
            ></Chip>
          ))}
        </Box>
      )}
      value={selectedValue}
      onChange={(e) => setSelectedValue(e.target.value)}
    >
      {options.map((item, index) => (
        <MenuItem key={index} value={item.id}>
          {item.location_name} {modules? `(${item.agency.marketCode})`: ''}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectInputs;
