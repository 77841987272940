import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  LoginUser,
  RegisterUser,
  updateConfirmPassword,
} from "../utils/ApiCalls/UserCalls";
import { useDataLayerValue } from "../DataLayer/DataLayer";
import CustomHeaderTitle from "../components/constants/CustomHeaderTitle";
import Layout from "../Layout";

const ChangePassword = () => {
  const [firstName, setFirstName] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const [{ userDetails }, dispatch] = useDataLayerValue();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  useEffect(() => {
    if (userDetails?.email) {
      setEmail(userDetails?.email);
    }
  }, [userDetails]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let values = {
      email,
      password,
      confirmPassword,
      oldpassword,
    };
    let data = await updateConfirmPassword(values);
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.message)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.message, type: "error" },
      });
    else if (data.data) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.message, type: "error" },
      });
      console.log(data?.data);
      localStorage.setItem("token", data.token);

      dispatch({
        type: "SET_USER_DETAILS",
        userDetails: data.data,
      });
    }

    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <Layout type={"create_per"} module_data={"reset_password"}>
      <div className="wrapper login_wrap">
        <img
          className="bg_img"
          src="https://t4.ftcdn.net/jpg/05/62/16/01/360_F_562160112_92yO0mjcQbSCYoLGeT3i2ieTP9WF55le.jpg"
          alt=""
        />
        <CustomHeaderTitle title={"Register"} />

        <div className="login_inner">
          <div className="login-box">
            <div className="box_inner">
              <img className="box_img" src="./dist/img/logo_login.png" alt="" />
              <h4 className="login_title">Reset Password</h4>
              <form onSubmit={handleSubmit}>
                <div className="input-group mb-4">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Old Password"
                    value={oldpassword}
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$"
                    title="Password must be at least 8 characters long and include at least one letter and one number"
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                  />

                  <div className="input-group-append">
                    <div className="input-group-text">
                      <i className="ri-lock-password-line"></i>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-4">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="New Password"
                    value={password}
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$"
                    title="Password must be at least 8 characters long and include at least one letter and one number"
                    onChange={handlePasswordChange}
                    required
                  />

                  <div className="input-group-append">
                    <div className="input-group-text">
                      <i className="ri-lock-password-line"></i>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-4">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$"
                    title="Password must be at least 8 characters long and include at least one letter and one number"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />

                  <div className="input-group-append">
                    <div className="input-group-text">
                      <i className="ri-lock-password-line"></i>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <button type="submit" className="btn btn_sign">
                      Reset Password
                    </button>
                  </div>
                </div>
              </form>

              {/* <div className="social-auth-links text-center mb-3">
              <p>- OR -</p>
            </div> */}

              {/* <p className="mb-1">
              <Link to="/login">Already have account ?</Link>
            </p> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ChangePassword;
