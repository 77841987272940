import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import * as React from "react";

export default function DateAndTimePicker({ value,disabled, setValue, min, max }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        minDateTime={min&&dayjs(min)}
        disabled={disabled}
        maxDateTime={max&&dayjs(max)}
        size="small"
        value={dayjs(value)}
        onChange={(newValue) => setValue(newValue)}
      />
    </LocalizationProvider>
  );
}
