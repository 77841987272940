import * as React from "react";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
// import moment from "moment";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
const LogItem = ({ item, slug, location, hidetitle }) => {
  let data = JSON.parse(item.data);
  console.log(location.filter((item) => item.id === data.location_id));

  const [dotColor, setDotColor] = React.useState("red");

  React.useEffect(() => {
    if (item.actions.includes("Created")) {
      setDotColor("blue");
    } else if (item.actions.includes("Actived")) {
      setDotColor("#a88332");
    } else if (item.actions.includes("Deactivated")) {
      setDotColor("black");
    } else if (item.actions.includes("Updated")) {
      setDotColor("green");
    }
  }, [item]);
  return (
    <TimelineItem>
      <TimelineOppositeContent color="text.secondary">
        {/* {moment(item?.created_at).local().format("MMMM DD,YYYY hh:mm a")} */}
        {moment(item?.created_at).tz("Asia/Kuala_Lumpur").format("MMMM DD,YYYY hh:mm a")}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot style={{ background: dotColor }} />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ display: "flex", gap: "5px" }}>
        {slug ? <Link to={slug + data.id} style={{ display: "flex", gap: "5px" }}>
          {" "}
          <div style={{ color: "blue" }}>
            {(!hidetitle && data?.title) ? data?.title + " " : ""}{" "}
          </div>
          <div>{item.actions}</div>
        </Link> : <div style={{ display: "flex", gap: "5px" }}>
          {" "}
          <div style={{ color: "blue" }}>
            {(!hidetitle && data?.title) ? data?.title + " " : ""}{" "}
          </div>
          <div  style={{ color:dotColor }}>{item.actions}</div>
        </div>}
        {item.user && (
          <div>
            {" by " + item?.user?.firstName + " " + item?.user?.lastName}
          </div>
        )}
        {data?.location_id && (
          <div style={{ display: "flex", gap: "5px" }}>
            <div>for </div>
            <div style={{ color: "green" }}>
              {
                location.filter((item) => item.id === data.location_id)?.[0]
                  ?.location_name
              }
            </div>
          </div>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};

export default LogItem;
