import React, { useEffect, useState } from "react";
import { getSpecialPagePackages, getItineary } from "../../utils/ApiCalls/SpecialPage";
import { getLocationDataById } from "../../utils/ApiCalls/Location/index";
import { getAllLocations } from "../../utils/ApiCalls/Locations/index"
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { checkFields } from "../../utils/Validation/checkFields";
import CustomizedTable from "../UI/CustomizedTable";
import SearchField from "../SearchField";
import FareCodeItineary from "../../components/UI/FareCodeitineary";
import MarketCode from "../../components/UI/MarketCode";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)


const FareCodeList = ({
  displayLocation,
  locationOption,
  itineraries,
  from_date,
  to_date,
  valid_from,
  valid_to,
  activeTab,
  agency,
  vessel,
  selectedOption,
}) => {
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [iti, setITI] = useState([]);
  const [page, setPage] = useState(1);
  const [itinearyId, setItinearyId] = useState("");
  const [marketCode, setMarketCode] = useState("");
  const [locationId, setLocationId] = useState("");
  const [location, setLocation] = useState([])
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [, dispatch] = useDataLayerValue();

  const sortHandler = async (options) => {
    if (Object.keys(options).length > 0) {
      let sort, order;
      sort = options.columnKey;
      setSort(sort);
      order =
        options.order === "ascend"
          ? "asc"
          : options.order === "descend"
          ? "desc"
          : undefined;
      setOrder(order)
      await getData(page, sort, order);
    }
  };

  useEffect(()=>{
    console.log("this")
    console.log({pageSize})
  },[pageSize])
  const columns = [
    {
      title: "Sl",
      dataIndex: "id",
      key: "id",
      render: (position, record, index) =>((page - 1) * pageSize) + index + 1,
    },

    {
      title: "Cruise Id",
      dataIndex: "package_id",
      key: "package_id",
      sorter: (a, b) => "",
    },
    {
      title: "Itinerary Id",
      dataIndex: "itinerary_id",
      key: "itinerary_id",
      sorter: (a, b) => "",
    },
    {
      title: "Itravel Itinerary Title",
      dataIndex: "package_name",
      key: "package_name",
      width:"200px",
      sorter: (a, b) => "",
    },
    {
      title: "Fare Code",
      dataIndex: "fare_code",
      key: "fare_code",
      sorter: (a, b) => "",
    },
    {
      title: "Fare Code Description",
      dataIndex: "fare_code_name",
      key: "fare_code_name",
      sorter: (a, b) => "",
    },
    {
      title: "Departure Port",
      dataIndex: "departure_port",
      key: "departure_port",
      sorter: (a, b) => "",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => "",
    },
    {
      title: "Market Code",
      dataIndex: "market_code",
      key: "market_code",
      sorter: (a, b) => "",
    },
    {
      title: "Vessel",
      dataIndex: "vessel_name",
      key: "vessel_name",
      sorter: (a, b) => "",
    },
    {
      title: "Rate Plan Type",
      dataIndex: "fare_code_type",
      key: "fare_code_type",
      sorter: (a, b) => "",
    },
    {
      title: "Promo Index",
      dataIndex: "promo_index",
      key: "promo_index",
      width: 80,
      sorter: (a, b) => "",
    },
    {
      title: "Promo Title",
      dataIndex: "promo_title",
      key: "promo_title",
      width: 80,
      sorter: (a, b) => "",
    },
    {
      title: "Promo Description",
      dataIndex: "promo_description",
      key: "promo_description",
      width: 80,
      sorter: (a, b) => "",
    },
  ];
  useEffect(() => {
    if (activeTab === "iti-tab") {
        getData(page, sort, order);
    }
  }, [page, pageSize, search, activeTab]);

  
  const getData = async (value, sort, order) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let args = {
      search: search || "",
      page: value,
      limit: pageSize,
      id: itineraries,
      agencyId: agency,
      vessel_id: vessel,
      selected_type: selectedOption,
      itinearyId
    };
    let valid=[];
    if (itineraries?.length < 1 || !itineraries) {
      valid.push(" Add Fare Code");
    }
    if(!from_date || !to_date){
      valid.push(" Add sailing_from and sailing_to date")
    }
    if(!valid_from || !valid_to){
      valid.push(" Add valid_from and valid_to date");
    }
    args.fromDate = from_date;
    args.toDate = to_date;
    if(itinearyId){
      args = {...args, itinearyId}
    }
    if(locationId){
     const locationData = await getLocationDataById(locationId);
    //  locationData.agency. 
    args = { ...args, marketCode: locationData?.agency?.marketCode }
    }
    if (order) {
      if (sort) {
        args = { ...args, sort };
      }
      args = { ...args, order };
    }
    if (valid.length > 0) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: valid.length > 0 ? valid.join(","): valid, type: "error" },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: valid.length > 0 ? valid.join(","): valid,
      });
    } else {
      let data = await getSpecialPagePackages(args);
      if (data.error)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.error,
        });

      else if (data.data) {
        if(data.itinearies && data.itinearies.length > 0){
          data.itinearies.unshift({itinerary_id:"All"})
          setITI(data?.itinearies);
        }
        if(data.locations && data.locations.length > 0){
          let locations = displayLocation.map(l=>locationOption.find(loc=>loc.id===l));
          const loc = locations.filter(loc=>data.locations.find(l=>l.market_code === loc.agency.marketCode));

          loc.unshift({id: null , location_name:"All"})
          setLocation(loc);
        }
        if(data.count > 0){
          setRows(data.data);
          setCount(parseInt(data.count));
        }else {
          setRows(data.data);
          setCount(parseInt(data.count));
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "No matching itinearies found",
          });
          // dispatch({
          //   type: "SET_ERROR_MESSAGE",
          //   errMess: { message: "No matching itinearies found", type: "error" },
          // });
        }
      }
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  return (
    <div className="card-body">
      <div className="table_holder">
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <FareCodeItineary data={iti} getData={getData} setItinearyId={setItinearyId} itinearyId={itinearyId} setPage={setPage}/>
          <MarketCode data={location} getData={getData} setLocationId={setLocationId} locationId={locationId} setPage={setPage} />
          <div style={{ maxWidth: 200 }}>
            <SearchField
              value={search}
              SearchValue={() => {
                setPage(1);
              }}
              setValue={(e) => {
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>
        {rows && (
          <CustomizedTable
            setRows={setRows}
            setPageSize={setPageSize}
            setPage={setPage}
            pageSize={pageSize}
            page={page}
            count={count}
            columns={columns}
            rows={rows}
            title={"Banner"}
            sortHandler={sortHandler}
          />
        )}
      </div>
    </div>
  );
};

export default FareCodeList;
