import Destination2 from "../Previews/Destination/2/Preview";
import Destination3 from "../Previews/Destination/3/Preview";
import ShipBannerPreview from "../Previews/Ship/Banner/Preview";

export const destinationpages = {
  form: [
    {
      title: "Page Name",
      key: "page_name",
      className: "col-lg-12",
      type: "input",
    },
    {
      title: "Meta Description",
      key: "meta_description",
      className: "col-lg-12",
      type: "input",
    },
  ],
  component: [
    {
      type: 1,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "Page Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Page Image",
          type: "file",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <ShipBannerPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 1,
      fields: [
        {
          title: "Page Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Page Image",
          type: "file",
          required: false,
        },
        {
          title: "English Underlined Text",
          key: "underlined_text",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Underlined Text",
          key: "simplified_chinese_underlined_text",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Underlined Text",
          key: "traditional_chinese_underlined_text",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Underlined Text",
          key: "arabic_underlined_text",
          className: "col-lg-3 arabic",
          type: "input",
        },

        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <Destination2 activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 3,
      fields: [
        {
          title: "English filter text",
          key: "filter_text",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese filter text",
          key: "simplified_chinese_filter_text",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese filter text",
          key: "traditional_chinese_filter_text",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic filter text",
          key: "arabic_filter_text",
          className: "col-lg-3 arabic",
          type: "input",
        },
      ],
      level1: [
        {
          title: "Show Description",
          key: "show_desc",
          className: "col-lg-6",
          type: "select",
          option: [
            {
              id: "yes",
              name: "Yes",
            },
            { id: "no", name: "No" },
          ],
        },
        {
          title: "Show Menu",
          key: "show_menu",
          className: "col-lg-6",
          type: "select",
          option: [
            {
              id: "yes",
              name: "Yes",
            },
            { id: "no", name: "No" },
          ],
        },

        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
        },
      ],
      level2: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false,
        },
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "English Subdescription",
          key: "subdescription",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Simplified Chinese Subdescription",
          key: "simplified_chinese_subdescription",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Traditional Chinese Subdescription",
          key: "traditional_chinese_subdescription",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Arabic Subdescription",
          key: "arabic_subdescription",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "English Filter Name",
          key: "filter_name",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Filter Name",
          key: "simplified_chinese_filter_name",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Filter Name",
          key: "traditional_chinese_filter_name",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Filter Name",
          key: "arabic_filter_name",
          className: "col-lg-3 arabic",
          type: "input",
        },
      ],
      preview: (activetab, cardData) => (
        <Destination3 activetab={activetab} cardData={cardData} />
      ),
    },
  ],
};
