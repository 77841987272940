import React, { useEffect, useState } from "react";
import PackageInput from "../../components/Packages/PackageInput";
import HeaderBread from "../../components/HeaderBread";
import Layout from "../../Layout";
import { useParams } from "react-router-dom";
import {
  getPackageDataById,
  getNoteData,
  getPackageGroupDataById,
} from "../../utils/ApiCalls/Package/index";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import CustomHeaderTitle from "../../components/constants/CustomHeaderTitle";
import PackageInput2 from "../../components/Packages/PackageInput2";

const EditGroupedPackages = () => {
  const params = useParams();
  const [packages, setPackage] = useState();
  const [{ useDetails }, dispatch] = useDataLayerValue();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "All Packages",
      route: "/cms/packages",
    },
    {
      title: "Edit Package",
      route: "/cms/packages/:id",
    },
  ];
  useEffect(() => {
    getData(params?.id);
  }, [params]);

  const getData = async (id) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getPackageGroupDataById(id);
    let noteData = await getNoteData("Packages");
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else {
      if (data.data.itinearies && data.data.itinearies.length > 0) {
        data.data.itinearies.map((d) => {
          if (!d.display_port_name) d["display_port_name"] = d.port_name;
          if (!d.display_arrival_time)
            d["display_arrival_time"] = d.arrival_time;
          if (!d.display_departur_time)
            d["display_departur_time"] = d.departur_time;
        });
      }
      setPackage({ ...data.data, note: noteData });
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
    }
  };
  return (
    <Layout type={"create_per"} module_data={"Packages"}>
      <CustomHeaderTitle title={"Edit Package"} />
      <HeaderBread navigation={navigation} title={"Edit Package"}></HeaderBread>
      {packages && <PackageInput2 packages={packages} />}
    </Layout>
  );
};

export default EditGroupedPackages;
