import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LoginUser, RegisterUser } from "../utils/ApiCalls/UserCalls";
import { useDataLayerValue } from "../DataLayer/DataLayer";
import CustomHeaderTitle from "../components/constants/CustomHeaderTitle";
import Layout from "../Layout";
import SelectInputs from "../constants/SelectInputs";
import { getPermission } from "../utils/ApiCalls/Permission";
import { MenuItem, Select } from "@mui/material";
import { updateUsers } from "../utils/ApiCalls/Users";

const Register = ({user}) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [, dispatch] = useDataLayerValue();
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };


  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(()=>{
    if(user){
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setPassword(user.password)
      setRole(user.role)
    }
  },[user])
  const getData = async () => {
    const data = await getPermission();
    setRoles(data?.data?.map((item) => item.name));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let values = {
      firstName,
      lastName,
      email,
      role,
   
      isActive: 1,
    };
    let data
    
    if(user){
      
      data= await updateUsers(user.id,values);}
      else{
        values.password=password
    data= await RegisterUser(values);}
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data) {
      navigate("/profile/user-management");
    }

    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <Layout type={"create_per"} module_data={"user management"}>
      <div className="wrapper login_wrap">
        <img
          className="bg_img"
          src="https://t4.ftcdn.net/jpg/05/62/16/01/360_F_562160112_92yO0mjcQbSCYoLGeT3i2ieTP9WF55le.jpg"
          alt=""
        />
        <CustomHeaderTitle title={"Register"} />

        <div className="login_inner">
          <div className="login-box">
            <div className="box_inner">
              <img className="box_img" src="./dist/img/logo_login.png" alt="" />
              <h4 className="login_title">{user?"Edit":"Add"} User</h4>
              <form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    placeholder="First Name"
                    required
                    value={firstName}
                    onChange={(e) => {
                      const value = e.target.value;
                        setFirstName(value);
                      
                    }}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <i className="ri-user-line"></i>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    placeholder="Last Name"
                    value={lastName}
                    required
                    onChange={(e) => {
                      const value = e.target.value;
                    
                        setLastName(value);
                    
                    }}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <i className="ri-user-line"></i>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    required
                    placeholder="Email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <i className="ri-mail-line"></i>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-4">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$"
                    title="Password must be at least 8 characters long and include at least one letter and one number"
                    onChange={handlePasswordChange}
                    required
                  />

                  <div className="input-group-append">
                    <div className="input-group-text">
                      <i className="ri-lock-password-line"></i>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-4">
                  <Select
                    style={{
                      width: "100%",
                      height: "45px",
                      background: "white",
                    }}
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    {roles?.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div className="row">
                  <div className="col-4">
                    <button type="submit" className="btn btn_sign">
                      {user?"Update":"Create"}
                    </button>
                  </div>
                </div>
              </form>

              {/* <div className="social-auth-links text-center mb-3">
              <p>- OR -</p>
            </div> */}

              {/* <p className="mb-1">
              <Link to="/login">Already have account ?</Link>
            </p> */}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
