import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React, { useEffect, useState } from "react";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";

import CancelIcon from "@mui/icons-material/Cancel";
import CustomizedTable from "../../../components/UI/CustomizedTable";
import { getModule } from "../../../utils/ApiCalls/Permission";
const PermissionTable = ({ myPermission }) => {
  const [rows, setRows] = useState([
  
  ]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [, dispatch] = useDataLayerValue();
  const columns = [
    {
      title: "Module",
      dataIndex: "name",
      key: "name",
      width: 50,
      render: (item) => <p style={{ fontWeight: "bold" }}>{item}</p>,
    },

    {
      title: "View",
      dataIndex: "read",
      key: "read",
      width: 50,
      render: (item, record) => (
        <p>
          {myPermission?.read_per?.includes(record?.module_key) ? (
            <CheckCircleIcon style={{ color: "green" }} />
          ) : (
            <CancelIcon style={{ color: "red" }} />
          )}

          {/* <Checkbox style={{ color: "blue" }} defaultChecked /> */}
        </p>
      ),
    },

    {
      title: "Create",
      dataIndex: "create",
      key: "create",
      width: 50,
      render: (item, record) => (
        <p>
          {myPermission?.create_per?.includes(record?.module_key) ? (
            <CheckCircleIcon style={{ color: "green" }} />
          ) : (
            <CancelIcon style={{ color: "red" }} />
          )}
          {/* <Checkbox style={{ color: "blue" }} defaultChecked /> */}
        </p>
      ),
    },
    {
      title: "Update",
      dataIndex: "update",
      key: "update",
      width: 50,
      render: (item, record) => (
        <p>
          {myPermission?.update_per?.includes(record?.module_key) ? (
            <CheckCircleIcon style={{ color: "green" }} />
          ) : (
            <CancelIcon style={{ color: "red" }} />
          )}
          {/* <Checkbox style={{ color: "blue" }} defaultChecked /> */}
        </p>
      ),
    },

    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      width: 50,
      render: (item, record) => (
        <p>
          {myPermission?.delete_per?.includes(record?.module_key) ? (
            <CheckCircleIcon style={{ color: "green" }} />
          ) : (
            <CancelIcon style={{ color: "red" }} />
          )}
          {/* <Checkbox style={{ color: "blue" }} defaultChecked /> */}
        </p>
      ),
    },
    {
      title: "Download",
      dataIndex: "download",
      key: "download",
      width: 50,
      render: (item, record) => (
        <p>
          {myPermission?.download_per?.includes(record?.module_key) ? (
            <CheckCircleIcon style={{ color: "green" }} />
          ) : (
            <CancelIcon style={{ color: "red" }} />
          )}
          {/* <Checkbox style={{ color: "blue" }} defaultChecked /> */}
        </p>
      ),
    },
  ];
  useEffect(() => {
    getData();
  }, []);
 
  const getData = async () => {
    const data = await getModule();
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data) {
      setRows(data);
      setCount(10);
    }
  };

  return (
    <CustomizedTable
      setRows={setRows}
      setPageSize={setPageSize}
      setPage={setPage}
      pageSize={pageSize}
      page={page}
      count={count}
      columns={columns}
      rows={rows}
      title={"Permission"}
    />
  );
};

export default PermissionTable;
