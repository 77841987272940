import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import { Paper, Popper, Chip } from "@mui/material";

const StyledAutocomplete = styled(Autocomplete)({
  width: 250,
  marginRight: "10px",
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CustomPaper = (props) => {
  return <Paper {...props} style={MenuProps.PaperProps.style} />;
};

const CustomPopper = (props) => {
  return <Popper {...props} />;
};

const StyledChip = styled(Chip)({

});

export default function MultipleSelectData({
  getData,
  data,
  value,
  setValue,
  label,
  onChange,
}) {
  const handleChange = (event, newValue) => {
    // Filter out the disabled item if it's selected
    const filteredValue = newValue.filter((item) => item !== "");
    setValue(filteredValue);
    if (onChange) {
      onChange();
    }
  };

  // Add the disabled item to the options list
  const optionsWithDisabled = [...data];

  return (
    <StyledAutocomplete
      multiple
      options={optionsWithDisabled}
      value={value}
      onChange={handleChange}
      sx={{ width: 400 }}
      limitTags={1}
      disableCloseOnSelect
      PaperComponent={CustomPaper}
      PopperComponent={CustomPopper}
      inputProps={{ "aria-label": "Without label" }}
      size="small"
      getOptionLabel={(option) => (option === "" ? label : option)}
      renderOption={(props, option, { selected }) =>
        <li {...props}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          {option}
        </li>
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          sx={{ width: 250 }}
          placeholder={label}
          
        />
      )}
      renderTags={(value, getTagProps) =>
     
       value.map((option, index) => (
          <p
            {...getTagProps({ index })}
            key={option}
          >{option}</p>
        ))
      }
    />
  );
}
