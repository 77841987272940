import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import ReactQuill from "react-quill"; // Import React Quill
import { Box, Chip, MenuItem, Select } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { getLocations, getAllLocations } from "../../utils/ApiCalls/Locations";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { createDeal, deleteDealerData, getDealDataLocation, updateDeal } from "../../utils/ApiCalls/Dealer";
import { uploadData } from "../../utils/ApiCalls/Upload";
import { checkFields } from "../../utils/Validation/checkFields";
import UploadFileImage from "../imageUpload/uploadFileImage";
import SelectInputs from "../../constants/SelectInputs";
import { constants } from "../../constants/constants";
import LocationAndExpcept from "../../constants/LocationAndExpcept";
import { addlog } from "../../utils/ApiCalls/Logs";
import { IMAGE_SUB_TYPE, IMAGE_TYPE } from "../../constants/ImageConstant";
import DateAndTimePicker from "../DateAndTimePicker";

const DealInputs = ({ deal }) => {
  const [englishTitle, setEnglishTitle] = useState(deal?.englishTitle || "");
  const [englishName, setEnglishName] = useState(deal?.englishName || "");
  const [englishSubTitle, setEnglishSubTitle] = useState(
    deal?.englishTitle || ""
  );
  const [simplifiedChineseTitle, setSimplifiedChineseTitle] = useState(
    deal?.simplifiedChineseTitle || ""
  );
  const [simplifiedChineseName, setSimplifiedChineeseName] = useState(
    deal?.simplifiedChineeseName || ""
  );
  const [simplifiedChineseSubTitle, setSimplifiedChineeseSubTitle] = useState(
    deal?.simplifiedChineeseSubTitle || ""
  );
  //   const [description, setDescription] = useState(deal?.description || "");
  const [traditionalChineseTitle, setTraditionalChineseTitle] = useState(
    deal?.traditionalChineseTitle || ""
  );
  const [traditionalChineseName, setTraditionalChineseName] = useState(
    deal?.traditionalChineseTitle || ""
  );
  const [traditionalChineseSubTitle, setTraditionalChineseSubTitle] = useState(
    deal?.traditionalChineseTitle || ""
  );
  const [currentLocation, setCurrentLocation] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [traditionalChineseFile, setTraditionalChineseFile] = useState(null);
  const [simplifiedChineseFile, setSimplifiedChineseFile] = useState(null);
  const [updateSelectedFile, setUpdateSelectedFile] = useState(null);
  const [displayLocation, setDisplayLocation] = useState([]);
  const [url, setUrl] = useState(deal?.url || "");
  const [image, setImage] = useState(deal?.image || null);
  const [submitBtnState, setSubmitBtnState] = useState(false);
  const [traditional_chinese_image, setTraditional_chinese_image] =
    useState(null);
  const [simplified_chinese_image, setSimplified_chinese_image] =
    useState(null);
  const [valid_from, setValidFrom] = useState();
  const [valid_to, setValidTo] = useState();
  const [from_date, setFormDate] = useState();
  const [to_date, setToDate] = useState();
  const [locationOption, setLocationOption] = useState([]);
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [error, setError] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (deal) {
      if (deal.image) {
        getLocationData();
      }
      setEnglishName(deal?.name);
      setImage(deal?.image);
      setSimplified_chinese_image(deal?.simplified_chinese_image);
      setTraditional_chinese_image(deal?.traditional_chinese_image);
      setEnglishTitle(deal?.title);
      setEnglishSubTitle(deal?.sub_title);
      setSimplifiedChineseTitle(deal?.simplified_chinese_title);
      setSimplifiedChineeseName(deal?.simplified_chinese_name);
      setSimplifiedChineeseSubTitle(deal?.simplified_chinese_sub_title);
      setTraditionalChineseTitle(deal?.traditional_chinese_title);
      setTraditionalChineseName(deal?.traditional_chinese_name);
      setTraditionalChineseSubTitle(deal?.traditional_chinese_sub_title);
      setUrl(deal?.url || "");
      if (deal.location_id) setDisplayLocation([deal.location_id]);
      setIsActive(deal?.is_active === 1 ? true : false);

      setValidFrom(deal?.valid_from);
      setValidTo(deal?.valid_to);
    }
  }, [deal]);
  const getLocationData = async () => {
    let data = await getDealDataLocation({ image: deal.image });

    if (data.error) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    } else {
      setCurrentLocation(data);
      setDisplayLocation(data?.map((item) => item.location_id) || []);
    }
  };


  const handleCheckboxChange = () => {
    setIsActive(!isActive);
  };

  const handleDisplayLocationChange = (selectedOptions) => {
    setDisplayLocation(selectedOptions.target.value);
  };
  useEffect(() => {
    getOptionLocation();
  }, []);

  const getOptionLocation = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getAllLocations();
    if (data.error) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    } else {
      setLocationOption(data?.data || []);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const addDeal = async (e) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    e.preventDefault();
    let dataValues = {
      image,
      traditional_chinese_image,
      simplified_chinese_image,
      title: englishTitle,
      name: englishName,
      sub_title: englishSubTitle,
      traditional_chinese_name: traditionalChineseName,
      traditional_chinese_sub_title: traditionalChineseSubTitle,
      traditional_chinese_title: traditionalChineseTitle,
      simplified_chinese_name: simplifiedChineseName,
      simplified_chinese_title: simplifiedChineseTitle,
      simplified_chinese_sub_title: simplifiedChineseSubTitle,
      valid_from: valid_from,
      valid_to: valid_to,
      url,
      order_by: 1,
      is_active: isActive ? 1 : 0,
    };
    
    try {
      if (selectedFile) {
        let imageType = {
            type: IMAGE_TYPE.DEAL_IMG,
            subType: IMAGE_SUB_TYPE.ENGLISH_IMG
          }
        let image = await uploadData(selectedFile, imageType);
        if(image?.error) throw new Error(image.error);

        dataValues.image = image?.url;
      }
      if (traditionalChineseFile) {
        let imageType = {
          type: IMAGE_TYPE.DEAL_IMG,
          subType: IMAGE_SUB_TYPE.TRADITIONAL_CHINESE_IMG
        }
        let image = await uploadData(traditionalChineseFile, imageType);
        if(image?.error) throw new Error(image.error);

        dataValues.traditional_chinese_image = image?.url;
      }
      if (simplifiedChineseFile) {
        let imageType = {
          type: IMAGE_TYPE.DEAL_IMG,
          subType: IMAGE_SUB_TYPE.TRADITIONAL_CHINESE_IMG
        }
        let image = await uploadData(simplifiedChineseFile);
        if(image?.error) throw new Error(image.error);

        dataValues.simplified_chinese_image = image?.url;
      }
  
      let requiredFields = ["english_image"];
      
      const isChinaSelecetd = displayLocation.find(location=> locationOption.find(l=> location === l.id)?.location_code==="CN");
      console.log("selectedCountry====>", isChinaSelecetd);
      
      if(isChinaSelecetd){
        requiredFields.push("traditional_chinese_image", "simplified_chinese_image");
      }
      console.log("datavalues===>", dataValues)
      let valid = checkFields(dataValues, "deals", requiredFields);
      if (valid) {
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: valid, type: "error" },
        });
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
        setError(valid);
      } else {
        try {
          setError();
        await Promise.all( currentLocation.map(async item=>{
        
            if(!displayLocation.includes(item.location_id)){
              await deleteDealerData(item.id)
            }
          }))
          await Promise.all(
            displayLocation.map(async (item, index) => {
              let values = {
                location_id: item,
                ...dataValues,
              };
  
              let data;
              if (deal?.id) {
                let locationValue = currentLocation.filter(
                  (data) => data.location_id === item
                );
                if (locationValue.length > 0) {
                  let { ...rest } = values;
                  if (item === deal.location_id) {
                    data = await updateDeal(locationValue[0].id, values);
                  } else {
                    data = await updateDeal(locationValue[0].id, rest);
                  }
                } else {
                  await createDeal({ ...values, recordIteration: index + 1 });
                }
              }  else {
                values = {
                  ...values,
                  recordIteration: index + 1,
                };
                data = await createDeal(values);
              }
              if (data?.error) {
                throw new Error(data.error);
              } else {
                if (deal?.id) {
                  addlog(
                    "Deal Updated",
                    "Deal",
                    {
                      id: deal.id,
                      title: values.title || deal.title,
                      url: url || deal.url,
                      location_id: item,
                    },
                    userDetails.id
                  );
                } else {
                  addlog(
                    "Deal Created",
                    "Deal",
                    {
                      id: data.id,
                      title: data.title,
                      url: data.url,
                      location_id: item,
                    },
                    userDetails.id
                  );
                }
              }
            })
          );
  
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
          if (!deal.id) {
            navigate("/cms/homepage/deals");
          } else {
            navigate("/cms/homepage/deals");
            dispatch({
              type: "SET_ERROR_MESSAGE",
              errMess: { message: "Values updated", type: "success" },
            });
          }
        } catch (error) {
          dispatch({
            type: "SET_LOADING",
            loading: false,
          });
          dispatch({
            type: "SET_ERROR_MESSAGE",
            errMess: {
              message: error.message || "Unknown error occurred",
              type: "error",
            },
          });
        }
      }
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: {
          message: error.message || "Unknown error occurred",
          type: "error",
        },
      });
    }
  };
  console.log(
    "hello===>",
    `${constants.MEDIAURL}/${deal?.image}`
  );
  return (
    <div>
      <section className="content">
        {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )}

        <div className="container-fluid">
          <div className="card">
            <form className="input_form" onSubmit={addDeal}>
              <div className="card-body">
                <div className="row">
                  <LocationAndExpcept
                    displayLocation={displayLocation}
                    banner={deal}
                    locationOption={locationOption}
                    options={locationOption}
                    setDisplayLocation={setDisplayLocation}
                    handleDisplayLocationChange={handleDisplayLocationChange}
                  />

                  <div className="w-100"></div>
                  {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">English Name</label>
                      <input
                        // required
                        className="f_input"
                        type="text"
                        value={englishName}
                        onChange={(e) => setEnglishName(e.target.value)}
                      />
                    </div>
                  </div> */}
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">English Title</label>
                      <input
                        className="f_input"
                        type="text"
                        value={englishTitle}
                        onChange={(e) => setEnglishTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Simplified Chinese Title
                      </label>
                      <input
                        className="f_input"
                        type="text"
                        value={simplifiedChineseTitle}
                        onChange={(e) =>
                          setSimplifiedChineseTitle(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Traditional Chinese Title
                      </label>
                      <input
                        className="f_input"
                        type="text"
                        value={traditionalChineseTitle}
                        onChange={(e) =>
                          setTraditionalChineseTitle(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">English Subtitle</label>
                      <input
                        className="f_input"
                        // required
                        type="text"
                        value={englishSubTitle}
                        onChange={(e) => setEnglishSubTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Simplified Chinese Name</label>
                      <input
                        // required
                        className="f_input"
                        type="text"
                        value={simplifiedChineseName}
                        onChange={(e) =>
                          setSimplifiedChineeseName(e.target.value)
                        }
                      />
                    </div>
                  </div> */}

                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Simplified Chinese Subtitle
                      </label>
                      <input
                        className="f_input"
                        // required
                        type="text"
                        value={simplifiedChineseSubTitle}
                        onChange={(e) =>
                          setSimplifiedChineeseSubTitle(e.target.value)
                        }
                      />
                    </div>
                  </div>

                  {/* <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Traditional Chinese Name
                      </label>
                      <input
                        // required
                        className="f_input"
                        type="text"
                        value={traditionalChineseName}
                        onChange={(e) =>
                          setTraditionalChineseName(e.target.value)
                        }
                      />
                    </div>
                  </div> */}
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Traditional Chinese Subtitle
                      </label>
                      <input
                        className="f_input"
                        // required
                        type="text"
                        value={traditionalChineseSubTitle}
                        onChange={(e) =>
                          setTraditionalChineseSubTitle(e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <UploadFileImage
                    className="col-lg-4"
                    width={778}
                    height={542}
                    image={
                      deal?.image
                        ? `${constants.MEDIAURL}/${deal?.image}`
                        : deal?.image
                    }
                    label={"English Deal Image"}
                    mandatory={"*"} 
                    placeholder={"Upload Deal Image "}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                  />

                  <UploadFileImage
                    className="col-lg-4"
                    width={778}
                    height={542}
                    image={
                      deal?.simplified_chinese_image
                        ? `${constants.MEDIAURL}/${deal?.simplified_chinese_image}`
                        : deal?.simplified_chinese_image
                    }
                    mandatory={displayLocation.find(location=> locationOption.find(l=> location === l.id)?.location_code==="CN")? "*": ""}
                    label={"Simplified Chinese Deal Image"}
                    placeholder={"Upload Simplified Chinese Deal Image"}
                    selectedFile={simplifiedChineseFile}
                    setSelectedFile={setSimplifiedChineseFile}
                  />
                  <UploadFileImage
                    mandatory={displayLocation.find(location=> locationOption.find(l=> location === l.id)?.location_code==="CN")? "*":""}
                    className="col-lg-4"
                    width={778}
                    height={542}
                    image={
                      deal?.traditional_chinese_image
                        ? `${constants.MEDIAURL}/${deal?.traditional_chinese_image}`
                        : deal?.traditional_chinese_image
                    }
                    label={"Traditional Chinese Deal Image "}
                    placeholder={"Upload Traditional Chinese Deal Image "}
                    selectedFile={traditionalChineseFile}
                    setSelectedFile={setTraditionalChineseFile}
                  />
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Itinerary / Url <span style={{color: "red"}}> *</span></label>
                      <input
                        className="f_input"
                        // required
                        type="text"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                      />
                     
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6 col-6">
                    {/* <div className="sailing_date "> */}
                    {/* <h4 className="sub_title2">Valid Date</h4> */}
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="fieldrow">
                          <label className="f_label"> Valid From <span style={{color:"red", }}>*</span></label>
                          <input
                            required
                            value={valid_from}
                            onChange={(e) => setValidFrom(e.target.value)}
                            className="f_input"
                            type="date"
                          />
                        
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="fieldrow">
                          <label className="f_label">Valid To <span style={{color:"red", }}>*</span></label>
                          <input
                            required
                            className="f_input"
                            value={valid_to}
                            onChange={(e) => {
                              setValidTo(e.target.value);
                            }}
                            min={moment(valid_from)
                              .add(1, "days")
                              .format("YYYY-MM-DD")}
                            type="date"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                  <div className="col-12">
                    <div className="iti_inner">
                      <div className="icheck-primary d-inline">
                        <input
                          type="checkbox"
                          id="customCheckbox4"
                          name="r10"
                          checked={isActive}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="customCheckbox4">
                          {isActive ? (
                            <p>
                              <span style={{ fontWeight: 600 }}>Active</span>
                            </p>
                          ) : (
                            <p>
                              <span style={{ fontWeight: 200 }}>Active</span>
                            </p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-right">
                <button disabled={submitBtnState} className="btn main_submit">
                  {deal?.id ? "Update" : "Add"} Deal
                </button>
              </div>
            </form>
          </div>
          <div className="pt-3"></div>
        </div>
      </section>
    </div>
  );
};

export default DealInputs;
