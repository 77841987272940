import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";


export const getDealerData = async (query) => {
    try {
      let res = await Api.get("/deals", {
        params: query,
      });
    
      return res?.data || [];
    } catch (e) {
      let error = modifyError(e);
      return { error: error.message };
    }
  };

  export const getDealerDataById = async (id) => {
    try {
      let res = await Api.get("/deals/"+id)
    
      return res?.data || [];
    } catch (e) {
      return { error: "Something Wrong" };
    }
  };

  export const createDeal = async (data) => {

    try {
      let res = await Api.post("/deals", data);
      return res?.data;
    } catch (e) {
      let error = modifyError(e);
      return { error: error.message };
    }
  };

  export const getDealDataLocation = async (query) => {
    try {
      let res = await Api.get("/deals/location", {
        params: query,
        headers:{
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });
    
      return res?.data || [];
    } catch (e) {
      return { error: "Something Wrong" };
    }
  };
  export const updateDeal = async (id, data) => {
    try {
      let res = await Api.patch("/deals/" + id, data);
    
      return res?.data || [];
    } catch (e) {
      let error = modifyError(e);
      return { error: error.message };
    }
  };


export const deleteDealerData = async (id) => {
  try {
    let res = await Api.delete("/deals/" + id);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updatePosition = async(deals, query)=>{
  try {
    let res = await Api.patch("/deals/relocate-position", deals, {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
}