import React from "react";
import CustomHeaderTitle from "./components/constants/CustomHeaderTitle";
import Layout from "./Layout";

const PageNotFound = () => {
  return (
    <Layout>
    <div id="oopss">
      <CustomHeaderTitle title={"404"} />
      <div id="error-text">
        <img
          src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg"
          alt="404"
        />
        <span>404 PAGE</span>
        <p className="p-a">. The page you were looking for could not be found</p>
        <p className="p-b">... Back to previous page</p>
       
      </div>
    </div>
    </Layout>
  );
};

export default PageNotFound;
