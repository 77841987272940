import React from "react";
import "./app.css";
const TablePreview3 = ({ cardData, activetab }) => {
 
  return (
    <div style={{ padding: "10px" }}>
      <div className="col-md-12 box-dreamers-club">
        <div className="box-bring">
          <div className="box-header-cruise text-center p-3">
            <h2>
              {" "}
              {activetab === "english"
                ? cardData?.subtitle
                : activetab === "simplified_chinese"
                ? cardData?.simplified_chinese_subtitle
                : activetab === "arabic"
                ? cardData?.arabic_subtitle
                : cardData?.traditional_chinese_subtitle}
            </h2>
          </div>

          <div className="row box-bring-content">
            
            {cardData?.dataArray?.map((item) => (
              <div className="col-md-6 special-care-item">
                <div className="">
                 
                  <span>
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          activetab === "english"
                            ? item?.description
                            : activetab === "simplified_chinese"
                            ? item?.simplified_chinese_description
                            : activetab === "arabic"
                            ? item?.arabic_description
                            : item?.traditional_chinese_description,
                      }}
                      data-lang='{"CN":"您的郵輪⾸選"}'
                      style={{
                        direction: activetab === "arabic" && "rtl",
                      }}
                    />
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
       
      </div>
    </div>
  );
};

export default TablePreview3;
