import React, { useState } from "react";
import ReactQuill from "react-quill"; // Import React Quill
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { useNavigate } from "react-router-dom";
import { constants } from "../../constants/constants";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { uploadFile } from "../../utils/ApiCalls/Document";
import { addlog } from "../../utils/ApiCalls/Logs";
import { updatePackageData } from "../../utils/ApiCalls/Package/index";
import { checkFields } from "../../utils/Validation/checkFields";
import UploadFileImage from "../imageUpload/uploadFileImage";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { IMAGE_SUB_TYPE, IMAGE_TYPE } from "../../constants/ImageConstant";

const MySwal = withReactContent(Swal)

const PackageInputs = ({ packages }) => {
  const [englishTitle, setEnglishTitle] = useState(
    packages.package?.title || ""
  );
  const [NOTE, setNOTE] = useState(packages.note.note || "");
  const [ArabicNOTE, setArabicNOTE] = useState(packages.note.note || "");
  const [simplifiedNote, setSimplifiedNote] = useState(packages.note.simplified_chinese_note || "");
  const [traditionalNote, setTraditionalNote] = useState(packages.note.traditional_chinese_note || "");
  const [simplifiedChineseTitle, setSimplifiedChineseTitle] = useState(
    packages.package?.simplified_chinese_title || ""
  );
  const [traditionalChineseTitle, setTraditionalChineseTitle] = useState(
    packages.package?.traditional_chinese_title || ""
  );
  const [arabicTitle, setarabicTitle] = useState(
    packages.package?.arabic_title || ""
  );
  const [englishSubTitle, setEnglishSubTitle] = useState(
    packages.package?.sub_title || ""
  );
  const [simplifiedChineseSubTitle, setSimplifiedChineseSubTitle] = useState(
    packages.package?.simplified_chinese_sub_title || ""
  );
  const [traditionalChineseSubTitle, setTraditionalChineseSubTitle] = useState(
    packages.package?.traditional_chinese_sub_title || ""
  );
  const [arabicSubTitle, setarabicSubTitle] = useState(
    packages.package?.arabic_sub_title || ""
  );
  const [englishDescription, setEnglishDescription] = useState(
    packages.package?.description || ""
  );
  const [simplifiedChineseDescription, setSimplifiedChineseDescription] =
    useState(packages.package?.simplified_chinese_description || "");
  const [traditionalChineseDescription, setTraditionalChineseDescription] =
    useState(packages.package?.traditional_chinese_description || "");
    const [arabicDescription, setarabicDescription] =
    useState(packages.package?.arabic_description || "");
  const [englishNote, setEnglishNote] = useState(packages.note?.note || "");
  const [simplifiedChinesNote, setSimplifiedChineseNote] = useState(
    packages.note?.simplified_chinese_note || ""
  );
  const [traditionalChineseNote, setTraditionalChineseNote] = useState(
    packages.note?.traditional_chinese_note || ""
  );
 
  const [arabicNote, setarabicNote] = useState(
    packages.package?.arabic_note || ""
  );
  
  const [itinearies, setItinearies] = useState(
    packages.itinearies && packages.itinearies.length > 0
      ? packages.itinearies
      : []
  );

  const [isActive, setIsActive] = useState(packages.package?.is_active);
  const [selectedFile, setSelectedFile] = useState(null);
  const [traditionalChineseFile, setTraditionalChineseFile] = useState(null);
  const [simplifiedChineseFile, setSimplifiedChineseFile] = useState(null);

  const [arabicFile, setarabicFile] = useState(null);
  const [thumbnailTraditionalChineseFile, setThumbnailTraditionalChineseFile] =
    useState(null);
  const [thumbnailSimplifiedChineseFile, setThumbnailSimplifiedChineseFile] =
    useState(null);
    const [thumbnailarabicFile, setThumbnailarabicFile] =
    useState(null);
  const [mainTraditionalChineseFile, setMainTraditionalChineseFile] =
    useState(null);
  const [mainSimplifiedChineseFile, setMainSimplifiedChineseFile] =
    useState(null);
  const [traditional_chinese_image, setTraditional_chinese_image] =
    useState(null);
  const [simplified_chinese_image, setSimplified_chinese_image] =
    useState(null);
  const [thumbailSelectedFile, setThumbailSelectedFile] = useState(null);
  const [mainSelectedFile, setMainSelectedFile] = useState(null);
  const [itineary_image, setItinearyImage] = useState(packages.package?.itineary_image);
  const [itineary_simplified_chinese_image, setItinearySimplifiedChineseImage] = useState(packages.package?.itineary_simplified_chinese_image);
  const [itineary_traditional_chinese_image, setItinearyTraditionalChineseImage] = useState(packages.package?.itineary_traditional_chinese_image);
  const [itineary_arabic_image, setItinearyarabicImage] = useState(packages.package?.itineary_arabic_image);
 
   const [thumbnail_image, setThumbnailImage] = useState(packages.package?.thumbnail_image);
  const [thumbnail_simplified_chinese_image, setThumbnailSimplifiedChineseImage] = useState(packages.package?.thumbnail_simplified_chinese_image);
  const [thumbnail_traditional_chinese_image, setThumbnailTraditionalChineseImage] = useState(packages.package?.thumbnail_traditional_chinese_image);
  const [thumbnail_arabic_image, setThumbnailarabicImage] = useState(packages.package?.thumbnail_arabic_image);
  
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [error, setError] = useState();

  const navigate = useNavigate();

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
      [{ direction: "rtl" }],
    
    ],
    
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const updatePackage = async (e) => {
    e.preventDefault();
    let packageDataValues = {
      title: englishTitle,
      arabic_title:arabicTitle,
      traditional_chinese_title: traditionalChineseTitle,
      simplified_chinese_title: simplifiedChineseTitle,
      sub_title: englishSubTitle,
      simplified_chinese_sub_title: simplifiedChineseSubTitle,
      traditional_chinese_sub_title: traditionalChineseSubTitle,
      arabic_sub_title:arabicSubTitle,
      
      description: englishDescription,
      arabic_description:arabicDescription,
      arabic_note:arabicNote,
      
      itineary_image,
      itineary_simplified_chinese_image,
      itineary_traditional_chinese_image,
      itineary_arabic_image,
      thumbnail_image,
      thumbnail_simplified_chinese_image,
      thumbnail_traditional_chinese_image,
      thumbnail_arabic_image,
      simplified_chinese_description: simplifiedChineseDescription,
      traditional_chinese_description: traditionalChineseDescription,
      note: englishNote,
      simplified_chinese_note: simplifiedChinesNote,
      traditional_chinese_note: traditionalChineseNote,
      is_active: isActive ? 1 : 0,
    };

    let noteDataValues = {
      module: "Packages",
      note: englishNote,
      simplified_chinese_note: simplifiedChinesNote,
      traditional_chinese_note: traditionalChineseNote,
      arabic_note:arabicNote,
    }

    console.log("selectedFile===>", selectedFile);
    try {
      
      if (selectedFile) {
        let imageType = {
          type: IMAGE_TYPE.PACKAGE_MAP_IMG,
          subType: IMAGE_SUB_TYPE.ENGLISH_IMG
        }
        let image = await uploadFile(selectedFile, imageType);
        if(image?.error) throw new Error(image.error);

        const value = image?.url ? image.url : "";
        console.log(value);
        packageDataValues["itineary_image"] = value;
      }
      // else {
      //   packageDataValues["itineary_image"] = "";
      // }
      if (thumbailSelectedFile) {
        let image = await uploadFile(thumbailSelectedFile);
        const value = image?.url ? image.url : "";
        console.log(value);
        packageDataValues["thumbnail_image"] = value;
      }
     
      if (traditionalChineseFile) {
        let imageType = {
          type: IMAGE_TYPE.PACKAGE_MAP_IMG,
          subType: IMAGE_SUB_TYPE.TRADITIONAL_CHINESE_IMG
        }
        let image = await uploadFile(traditionalChineseFile, imageType);
        if(image?.error) throw new Error(image.error);
        packageDataValues.itineary_traditional_chinese_image = image?.url;
      }
      if (arabicFile) {
        let imageType = {
          type: IMAGE_TYPE.PACKAGE_MAP_IMG,
          subType: IMAGE_SUB_TYPE.TRADITIONAL_CHINESE_IMG
        }
        let image = await uploadFile(arabicFile, imageType);
        if(image?.error) throw new Error(image.error);
        packageDataValues.itineary_arabic_image = image?.url;
      }
      // else {
      //   packageDataValues["itineary_traditional_chinese_image"] = ""; 
      // }
      if (simplifiedChineseFile) {
        let imageType = {
          type: IMAGE_TYPE.PACKAGE_MAP_IMG,
          subType: IMAGE_SUB_TYPE.SIMPLIFIED_CHINESE_IMG
        }
        let image = await uploadFile(simplifiedChineseFile, imageType);
        if(image?.error) throw new Error(image.error);
        packageDataValues.itineary_simplified_chinese_image = image?.url;
      }
      // else {
      //   packageDataValues["itineary_simplified_chinese_image"] = "";
      // }
  
      if (thumbnailTraditionalChineseFile) {
        let image = await uploadFile(thumbnailTraditionalChineseFile);
        packageDataValues.thumbnail_traditional_chinese_image = image?.url;
      }
      if (thumbnailarabicFile) {
        let image = await uploadFile(thumbnailarabicFile);
        packageDataValues.thumbnail_arabic_image = image?.url;
      }
      // else {
      //   packageDataValues["thumbnail_traditional_chinese_image"] = "";
      // }
      if (thumbnailSimplifiedChineseFile) {
        let image = await uploadFile(thumbnailSimplifiedChineseFile);
        packageDataValues.thumbnail_simplified_chinese_image = image?.url;
      }
      // else {
      //   packageDataValues["thumbnail_simplified_chinese_image"] = "";
      // }
  
      // if (mainTraditionalChineseFile) {
      //   let image = await uploadFile(mainTraditionalChineseFile);
      //   packageDataValues.main_traditional_chinese_image = image?.url;
      // }
      // else {
      //   packageDataValues["main_traditional_chinese_image"] = "";
      // }
      // if (mainSimplifiedChineseFile) {
      //   let image = await uploadFile(mainSimplifiedChineseFile);
      //   packageDataValues.main_simplified_chinese_image = image?.url;
      // }
      // else {
      //   packageDataValues["main_simplified_chinese_image"] = "";
      // }
  
      console.log("packageDataValues===>", packageDataValues);
      let itinearyDataValues = itinearies.filter(iti=> {
        if(iti.port_name!=="High Seas"){
          iti.day && delete iti.day;
          return iti;
        }
      });
  
      console.log("itinearyDataValues====>", itinearyDataValues);
  
      let packageValid = checkFields(packageDataValues);
      let itinearyValid = checkFields(itinearyDataValues);
  
      if (packageValid || itinearyValid) {
        let valid =
          packageValid && itinearyValid
            ? packageValid + " " + itinearyValid
            : packageValid
            ? packageValid
            : itinearyValid;
        // dispatch({
        //   type: "SET_ERROR_MESSAGE",
        //   errMess: { message: valid, type: "error" },
        // });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: valid,
        });
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
        // setError(valid);
      } else {
        try {
          setError();
          const data = await updatePackageData(packages.package.itinerary_id, {
            ...packageDataValues,
            items: itinearyDataValues,
            noteItem: noteDataValues 
          });
  
          if (data?.error) {
            throw new Error(data.error);
          }
          if (packages?.id) {
            addlog(
              "Package Updated",
              "Package",
              {
                id: packages.id,
                title: packages.package_name,
              },
              userDetails.id
            );
          } else {
            addlog(
              "Package Created",
              "Package",
              {
                id: data.id,
                title: data.package_name,
              },
              userDetails.id
            );
          }
  
          navigate("/cms/packages");
  
          dispatch({
            type: "SET_ERROR_MESSAGE",
            errMess: { message: "Values updated", type: "success" },
          });
        } catch (error) {
          console.log(error);
          // dispatch({
          //   type: "SET_ERROR_MESSAGE",
          //   errMess: {
          //     message: error.message || "Unknown error occurred",
          //     type: "error",
          //   },
          // });
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message || "Unknown error occurred",
          });
        }
      }
    } catch (error) {
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: {
      //     message: error.message || "Unknown error occurred",
      //     type: "error",
      //   },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.message || "Unknown error occurred",
      }); 
    }
  };

  const handleItineary = (e, index, key) => {
    console.log("itinearies====>", itinearies)
    const newItinearies = [...itinearies];
    console.log(index)
    console.log(key);
    console.log(e.target.value);
    newItinearies[index][key] =
      key === "departure_day" ? parseInt(e.target.value) : e.target.value;
    newItinearies[index][key] = e.target.value;
    setItinearies([...newItinearies]);
  };
  const handleFileChange = (event, targetFile) => {
    if (targetFile === "itineary_map") {
      const files = Array.prototype.slice.call(event.target.files)[0];
      setSelectedFile(files);
    }

    if (targetFile === "thumbnail") {
      const file = Array.prototype.slice.call(event.target.files)[0];
      setThumbailSelectedFile(file);
    }

    if (targetFile === "main") {
      const file = Array.prototype.slice.call(event.target.files)[0];
      setMainSelectedFile(file);
    }
  };
  
  console.log("packages====>", packages);
  return (
    <div>
      <section className="content">
        {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )}

        <div className="container-fluid">
          <form className="input_form" onSubmit={updatePackage}>
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Package Information</h3>
              </div>
              <div className="card-body pb-2">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Itinerary Id</label>
                      <p className="f_data">#{packages.package?.itinerary_id}</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Itravel Itinerary Title</label>
                      <p className="f_data">{packages.package?.package_name}</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Cruise Id (Sailing Dates)</label>
                      {packages.package?.cruises &&
                        packages.package.cruises.length > 0 && (
                          <ul className="multiple_dates">
                            {packages.package.cruises.map((c) => (
                              <li>{c}</li>
                            ))}
                          </ul>
                        )}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Departure Port</label>
                      <p className="f_data">{packages.package?.departure_port}</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Vessel</label>
                      <p className="f_data">{packages.package?.vessel_name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Package Details</h3>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">English Display Title</label>
                      <input
                        // required
                        className="f_input"
                        onChange={(e) => setEnglishTitle(e.target.value)}
                        value={englishTitle}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Simplified Chinese Display Title
                      </label>
                      <input
                        // required
                        className="f_input"
                        onChange={(e) =>
                          setSimplifiedChineseTitle(e.target.value)
                        }
                        value={simplifiedChineseTitle}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Traditional Chinese Display Title
                      </label>
                      <input
                        // required
                        className="f_input"
                        onChange={(e) =>
                          setTraditionalChineseTitle(e.target.value)
                        }
                        value={traditionalChineseTitle}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                       Arabic Display Title
                      </label>
                      <input
                        // required
                        style={{direction:"rtl"}}
                        className="f_input"
                        onChange={(e) =>
                          setarabicTitle(e.target.value)
                        }
                        value={arabicTitle}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">English Display Subtitle</label>
                      <input
                        // required
                        className="f_input"
                        onChange={(e) => setEnglishSubTitle(e.target.value)}
                        value={englishSubTitle}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Simplified Chinese Display Subtitle
                      </label>
                      <input
                        // required
                        className="f_input"
                        onChange={(e) =>
                          setSimplifiedChineseSubTitle(e.target.value)
                        }
                        value={simplifiedChineseSubTitle}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Traditional Chinese Display Subtitle
                      </label>
                      <input
                        // required
                        className="f_input"
                        onChange={(e) =>
                          setTraditionalChineseSubTitle(e.target.value)
                        }
                        value={traditionalChineseSubTitle}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">
                        Arabic Display Subtitle
                      </label>
                      <input
                        // required
                        style={{direction:"rtl"}}
                        className="f_input"
                        onChange={(e) =>
                          setarabicSubTitle(e.target.value)
                        }
                        value={arabicSubTitle}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">English Description</label>
                      <ReactQuill
                        value={englishDescription}
                        onChange={(e) => setEnglishDescription(e)}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                        Simplified Chinese Description
                      </label>
                      <ReactQuill
                        value={simplifiedChineseDescription}
                        onChange={(e) => setSimplifiedChineseDescription(e)}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                        Traditional Chinese Description
                      </label>
                      <ReactQuill
                        value={traditionalChineseDescription}
                        onChange={(e) => setTraditionalChineseDescription(e)}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                        Arabic Description
                      </label>
                      <ReactQuill
                        value={arabicDescription}
                        onChange={(e) => setarabicDescription(e)}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {itinearies && itinearies.length > 0 && (
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Itinerary</h3>
                </div>
                <div className="card-body">
                  <div className="add_iti_table">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>Day</th>
                          <th>Port</th>
                          <th>Display Port</th>
                          <th>Arrival</th>
                          <th>Display Arrival</th>
                          <th>Departure</th>
                          <th>Display Departure</th>
                        </tr>
                      </thead>
                      <tbody>
                        {itinearies.map((i, index) => (
                          <tr>
                            <td>
                              <input
                                type="number"
                                onChange={(e) =>
                                  handleItineary(e, index, "departure_day")
                                }
                                value={i.day}
                                // readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                // readOnly
                                onChange={(e) =>
                                  handleItineary(e, index, "port_name")
                                }
                                value={i.port_name}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                readOnly={i.port_name === 'High Seas' ? true: false}
                                onChange={(e) =>
                                  handleItineary(e, index, "display_port_name")
                                }
                                value={i?.display_port_name}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                // readOnly
                                onChange={(e) =>
                                  handleItineary(e, index, "arrival_time")
                                }
                                value={i?.arrival_time }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                readOnly={i.port_name === 'High Seas' ? true: false}
                                onChange={(e) =>
                                  handleItineary(e, index, "display_arrival_time")
                                }
                                   value={i?.display_arrival_time }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                // readOnly
                                onChange={(e) =>
                                  handleItineary(e, index, "departur_time")
                                }
                                value={i?.departur_time }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                readOnly={i.port_name === 'High Seas' ? true: false}
                                onChange={(e) =>
                                  handleItineary(e, index, "display_departur_time")
                                }
                                value={i?.display_departur_time }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">English Note</label>
                        <inputo
                          // required
                          className="f_input"
                          onChange={(e) => setNOTE(e.target.value)}
                          value={NOTE}
                          type="text"
                        />
                      </div>
                    </div>
                  
                  </div> */}
                 
                  <div className="row">
               

                    <UploadFileImage
                      className="col-lg-3"
                      width={5058}
                      height={2192}
                      image={
                        packages?.package?.itineary_image
                          ? `${constants.MEDIAURL}/${packages?.package?.itineary_image}`
                          : packages?.package?.itineary_image
                      }
                      label={"English Map Image"}
                      placeholder={"Upload English Map Image "}
                      selectedFile={selectedFile}
                      setImageForPackages={setItinearyImage}
                      setSelectedFile={setSelectedFile}
                    />

                    <UploadFileImage
                      className="col-lg-3"
                      width={5058}
                      height={2192}
                      image={
                        packages?.package?.itineary_simplified_chinese_image
                          ? `${constants.MEDIAURL}/${packages?.package?.itineary_simplified_chinese_image}`
                          : packages?.package?.itineary_simplified_chinese_image
                      }
                      label={"Simplified Chinese Map Image"}
                      placeholder={"Upload Simplified Chinese Map Image "}
                      selectedFile={simplifiedChineseFile}
                      setImageForPackages={setItinearySimplifiedChineseImage}
                      setSelectedFile={setSimplifiedChineseFile}
                    />
                    <UploadFileImage
                      className="col-lg-3"
                      width={5058}
                      height={2192}
                      image={
                        packages?.package?.itineary_traditional_chinese_image
                          ? `${constants.MEDIAURL}/${packages?.package?.itineary_traditional_chinese_image}`
                          : packages?.package
                              ?.itineary_traditional_chinese_image
                      }
                      label={"Traditional Chinese Map Image "}
                      placeholder={"Upload Traditional Chinese Map Image "}
                      selectedFile={traditionalChineseFile}
                      setImageForPackages={setItinearyTraditionalChineseImage}
                      setSelectedFile={setTraditionalChineseFile}
                    />
                      <UploadFileImage
                      className="col-lg-3"
                      width={5058}
                      height={2192}
                      image={
                        packages?.package?.itineary_arabic_image
                          ? `${constants.MEDIAURL}/${packages?.package?.itineary_arabic_image}`
                          : packages?.package
                              ?.itineary_arabic_image
                      }
                      label={"Arabic Map Image "}
                      placeholder={"Upload Arabic Map Image "}
                      selectedFile={arabicFile}
                      setImageForPackages={setItinearyarabicImage}
                      setSelectedFile={setarabicFile}
                    />
                  
                    <UploadFileImage
                      className="col-lg-3"
                      width={778}
                      height={542}
                      image={
                        packages?.package?.thumbnail_image
                          ? `${constants.MEDIAURL}/${packages?.package?.thumbnail_image}`
                          : packages?.package
                              ?.thumbnail_image
                      }
                      label={"English Itinerary Package Image"}
                      placeholder={"Upload English Itinerary Package Image "}
                      selectedFile={thumbailSelectedFile}
                      setImageForPackages={setThumbnailImage}
                      setSelectedFile={setThumbailSelectedFile}
                    />

                    <UploadFileImage
                      className="col-lg-3"
                      width={778}
                      height={542}
                      image={
                        packages?.package?.thumbnail_simplified_chinese_image
                          ? `${constants.MEDIAURL}/${packages?.package?.thumbnail_simplified_chinese_image}`
                          : packages?.package
                              ?.thumbnail_simplified_chinese_image
                      }
                      label={"Simplified Chinese Itinerary Package Image"}
                      placeholder={"Upload Simplified Chinese Itinerary Package Image "}
                      selectedFile={thumbnailSimplifiedChineseFile}
                      setImageForPackages={setThumbnailSimplifiedChineseImage}
                      setSelectedFile={setThumbnailSimplifiedChineseFile}
                    />
                    <UploadFileImage
                      className="col-lg-3"
                      width={778}
                      height={542}
                      image={
                        packages?.package?.thumbnail_traditional_chinese_image
                          ? `${constants.MEDIAURL}/${packages?.package?.thumbnail_traditional_chinese_image}`
                          : packages?.package
                              ?.thumbnail_traditional_chinese_image
                      }
                      label={"Traditional Chinese Itinerary Package Image "}
                      placeholder={
                        "Upload Traditional Chinese Itinerary Package Image "
                      }
                      selectedFile={thumbnailTraditionalChineseFile}
                      setImageForPackages={setThumbnailTraditionalChineseImage}
                      setSelectedFile={setThumbnailTraditionalChineseFile}
                    />
                        <UploadFileImage
                      className="col-lg-3"
                      width={778}
                      height={542}
                      image={
                        packages?.package?.thumbnail_arabic_image
                          ? `${constants.MEDIAURL}/${packages?.package?.thumbnail_arabic_image}`
                          : packages?.package
                              ?.thumbnail_arabic_image
                      }
                      label={"Arabic Itinerary Package  Image "}
                      placeholder={
                        "Upload Arabic Itinerary Package Image "
                      }
                      selectedFile={thumbnailarabicFile}
                      setImageForPackages={setThumbnailarabicImage}
                      setSelectedFile={setThumbnailarabicFile}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">English Note</label>
                      <ReactQuill
                        value={englishNote}
                        onChange={(e) => setEnglishNote(e)}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">Simplified Chinese Note</label>
                      <ReactQuill
                        value={simplifiedChinesNote}
                        onChange={(e) => setSimplifiedChineseNote(e)}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">Traditional Chinese Note</label>
                      <ReactQuill
                        value={traditionalChineseNote}
                        onChange={(e) => setTraditionalChineseNote(e)}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">Arabic Note</label>
                      <ReactQuill
                        value={arabicNote}
                        onChange={(e) => setarabicNote(e)}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="iti_inner">
                      <div className="icheck-primary d-inline">
                        <input
                          type="checkbox"
                          id="customCheckbox4"
                          name="r10"
                          checked={isActive ? true : false}
                          onChange={(e) => setIsActive(e.target.checked)}
                        />
                        <label for="customCheckbox4">
                          {isActive ? (
                            <p>
                              <span style={{ fontWeight: 600 }}>Active</span>
                            </p>
                          ) : (
                            <p>
                              <span style={{ fontWeight: 200 }}>Active</span>
                            </p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-right">
                <button className="btn main_submit">Update Package</button>
              </div>
            </div>
          </form>
          <div className="pt-3"></div>
        </div>
      </section>
    </div>
  );
};

export default PackageInputs;
