import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import { useState } from "react";
import { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import LogItem from "./LogItem";
import { getlogData } from "../../utils/ApiCalls/Logs";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { Pagination } from "antd";
import SearchField from "../SearchField";
import { getAllLocations } from "../../utils/ApiCalls/Locations";

const Logs = ({ type, refreshlog, slug, hidetitle }) => {
  const [pageSize, setPageSize] = useState(10);
  const [, dispatch] = useDataLayerValue();
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [rows, setRows] = React.useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [locationOption, setLocationOption] = useState([]);
  React.useEffect(() => {
    getData(page);
  }, [page, refreshlog]);

  React.useEffect(() => {
    getLocation();
  }, []);

  const getLocation = async () => {
    let data = await getAllLocations();
    setLocationOption(data?.data || []);
  };
  const getData = async (value, sort, order) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let args = { type: type, page: value, limit: pageSize, search };
    if (order) {
      if (sort) {
        args = { ...args, sort };
      }
      args = { ...args, order };
    }

    let data = await getlogData(args);
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.data) {
      setRows(data.data);
      setCount(data.count);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const onChange = (pageNumber) => {
    setPage(pageNumber);
    setRows([]);
  };

  return (
    <div className="container-fluid">
      <div className="card">
        <div className="card-header two_item">
          <h5 className="card-title">Logs</h5>
        </div>
        <div className="card-body">
          <SearchField
            value={search}
            SearchValue={() => {
              setPage(1);
            }}
            setValue={(e) => {
              setSearch(e.target.value);
            }}
          />
          {rows?.length ? (
            <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.2,
                },
              }}
            >
              {rows?.map((item) => (
                <LogItem hidetitle={hidetitle} location={locationOption} item={item} slug={slug} />
              ))}
              <Pagination
                style={{ marginLeft: "auto", marginTop: "10px" }}
                defaultCurrent={page}
                current={page}
                onChange={onChange}
                total={count}
              />
            </Timeline>
          ) : (
            <p style={{ textAlign: "center" }}>No Logs found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Logs;
