import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { getancillary, getEmergencyData, getGuestData } from "../../utils/ApiCalls/Leads";
import { addlog } from "../../utils/ApiCalls/Logs";
import {
  createOtherPages,
  updateOtherPagesData,
} from "../../utils/ApiCalls/OtherPage";
import { checkFields } from "../../utils/Validation/checkFields";
import BookingLogs from "../Booking/BookingLogs";
import SwitchBar from "../Pages/SwitchBar";
import CustomizedTable from "../UI/CustomizedTable";
import Ancillary from "../Booking/Ancillary";

const LeadsInputs2 = ({ booking, pages }) => {
  const [datacontent, setdataContent] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [active, setActive] = useState("booking");
    const [searchParams] = useSearchParams();
  const [guets, setGuets] = useState([]);
  const [bookingno, setBookingNo] = useState("");
  const [url, setUrl] = useState("");
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [error, setError] = useState();
  const [currentActive, setCurrentActive] = useState(booking?.leadDetails?.[0]);
  const [emergency, setEmergency] = useState([]);
  const [activeGuestId, setActiveGuestId] = useState()
  const [ancillary, setAncillary] = useState([]);
  const [activeCabin, setActiveCabin] = useState(searchParams.get("cabin_no")||booking?.leadDetails?.[0]?.cabin_no);

  const columns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => "",
      width: 50,
      render: (item, record) => <div>{record.first_name}</div>,
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: (a, b) => "",
      width: 50,
      render: (item, record) => <div>{record.last_name}</div>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => "",
      width: 50,
      render: (email, record) => record.email,
    },
    {
      title: "Citizenship",
      dataIndex: "citizenship",
      key: "citizenship",
      sorter: (a, b) => "",
      width: 50,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      sorter: (a, b) => "",
      width: 50,
    },
    {
      title: "Mobile No",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) => "",
      width: 50,
    },
    {
      title: "Date of Birth",
      dataIndex: "dob",
      key: "dob",
      sorter: (a, b) => "",
      width: 50,
      render: (text) => <div>{moment(text).format("DD MMMM YYYY")}</div>,
    },{
      title: "Ancillary",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) => "",
      render: (text, record) => <Ancillary record={record}/>,
    },
  ];
  const columns2 = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) => "",
      width: 50,
      render: (item, record) => (
        <div>
          {record.first_name} {record.middle_name} {record.last_name}
        </div>
      ),
    },

    {
      title: "Mobile No",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) => "",
      width: 50,
    },
    {
      title: "Cabin no",
      dataIndex: "cabin_no",
      key: "cabin_no",
      sorter: (a, b) => "",
      width: 50,
    },
    
  ];
  const navigate = useNavigate();

  useEffect(() => {
    if (booking?.booking_no && activeCabin) {
      setBookingNo(booking?.booking_no);
      getGuests(booking?.booking_no, activeCabin);
      setdataContent(booking?.content);
      setUrl(booking?.url);
      setIsActive(booking?.is_active === 1 ? true : false);
    }
  }, [booking, currentActive]);


  useEffect(() => {
    if (activeCabin) {
      setCurrentActive(booking?.leadDetails?.filter(item => item.cabin_no == activeCabin)?.[0])
    }
  }, [activeCabin])

  const getGuests = async (booking_no, cabin_no) => {
    let data, res, ancillary;
    data = await getGuestData({ booking_no, cabin_no });
    res = await getEmergencyData({ booking_no, cabin_no });
    ancillary = await getancillary({ booking_no })

    console.log({ ancillary })
    setEmergency(res);
    setGuets(data || []);
    setAncillary(ancillary || [])
    setActiveGuestId(data?.[0].id)

  };

  const addPages = async (e) => {
    console.log("hi");
    e.preventDefault();
    let added = false;
    let dataValues = {
      bookingno,
      url,
      content: datacontent,
      is_active: isActive ? 1 : 0,
    };
    let valid = checkFields(dataValues);
    if (valid) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: valid, type: "error" },
      });
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
      setError(valid);
      console.log(valid);
    } else {
      setError();
      const values = {
        ...dataValues,
      };

      let data;
      if (pages?.id) {
        data = await updateOtherPagesData(booking?.id, values);
      } else {
        data = await createOtherPages(values);
      }
      if (data?.error) {
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: data.error, type: "error" },
        });

        added = false;
      } else {
        added = true;
      }
      if (pages?.id) {
        addlog(
          "Leads Updated",
          "Leads",
          {
            id: booking?.id,
            title: values.booking_no || booking?.booking_no,
          },
          userDetails.id
        );
      } else {
        addlog(
          "Leads Created",
          "Leads",
          {
            id: data.id,
            title: data.booking_no,
          },
          userDetails.id
        );
      }
      if (added && !pages?.id) {
        navigate("/cms/other-pages");
      } else {
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: "Values updated", type: "success" },
        });
        navigate("/cms/other-pages");
      }
    }
  };
  return (
    <div>
      <section className="content">
        {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )}

        <div className="container-fluid">
          <SwitchBar
            tabs={[
              { title: "Booking", value: "booking" },
              { value: "logs", title: "logs" },
            ]}
            activeTab={active}
            setActiveTab={setActive}
          />

          {active === "booking" && (
            <form className="input_form" onSubmit={addPages}>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Failed Booking Information</h3>
                </div>

                <div className="card-body pb-2">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">Booking No</label>
                        <p className="f_data">{booking?.booking_no ?? "--"}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">Package Id</label>
                        <p className="f_data">{booking?.package_id ?? "--"}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">Vessel</label>
                        <p className="f_data">{booking?.vessel_name ?? "--"}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">Booking Date</label>
                        <p className="f_data">
                          {moment(booking.created_at).format("MMMM DD,YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">Sailing Date</label>
                        <p className="f_data">
                          {moment(booking.sailing_date).format("MMMM DD,YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">Departure Port</label>
                        <p className="f_data">{booking?.port ?? "--"}</p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">Total Amount</label>
                        <p className="f_data">{booking?.leadDetails?.map(item => parseFloat(item.price)).reduce((a, b) => a + b, 0) ?? "--"}</p>
                      </div>
                    </div>



                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">Adults</label>
                        <p className="f_data">{booking?.leadDetails?.map(item => item.adult).reduce((a, b) => a + b, 0) ?? "--"}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">Children</label>
                        <p className="f_data">{booking?.leadDetails?.map(item => item.child).reduce((a, b) => a + b, 0) ?? "--"}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">Infants</label>
                        <p className="f_data">{booking?.leadDetails?.map(item => item.infant).reduce((a, b) => a + b, 0) ?? "--"}</p>
                      </div>
                    </div>


                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">Currency</label>
                        <p className="f_data">{booking?.currency ?? "--"}</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                      <div className="fieldrow">
                        <label className="f_label">Market</label>
                        <p className="f_data">{booking?.market_code ?? "--"}</p>
                      </div>
                    </div>


                  </div>
                </div>


              </div>
              <SwitchBar
                tabs={booking?.leadDetails?.map((item, index) => ({ title: `Cabin ${index + 1}`, value: item.cabin_no }))}
                activeTab={activeCabin}
                setActiveTab={setActiveCabin}
              />
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Cabin Booking Information</h3>
                </div>

                <div className="card-body pb-2 row">
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Cabin Catergory</label>
                      <p className="f_data">{currentActive?.cabin_category ?? "--"}</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Total Amount</label>
                      <p className="f_data">{currentActive?.price ?? "--"}</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Adults</label>
                      <p className="f_data">{currentActive?.adult ?? "--"}</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Children</label>
                      <p className="f_data">{currentActive?.child ?? "--"}</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Infants</label>
                      <p className="f_data">{currentActive?.infant ?? "--"}</p>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Cabin Varient</label>
                      <p className="f_data">{currentActive?.cabin_variant ?? "--"}</p>
                    </div>
                  </div>


                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Rate Plan</label>
                      <p className="f_data">{currentActive?.fare_code_name ?? "--"}</p>
                      <p className="f_data" style={{ color: "green" }}>({currentActive?.fare_code ?? "--"})</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Cabin Accessable</label>
                      <p className="f_data">
                        {currentActive?.cabin_category
                          ? currentActive.cabin_category.toUpperCase().endsWith("A")
                            ? "YES"
                            : "NO"
                          : "--"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Passanger Information</h3>
                </div>

                <div className="card-body pb-2">
                  {guets && (
                    <CustomizedTable
                      setPageSize={() => { }}
                      page={1}
                      count={0}
                      columns={columns}
                      rows={guets}
                      sortHandler={() => { }}
                    />
                  )}
                </div>
              </div>
           
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Emergency Contact</h3>
                </div>

                <div className="card-body pb-2">
                  {emergency && (
                    <CustomizedTable
                      setPageSize={() => { }}
                      page={1}
                      count={0}
                      columns={columns2}
                      rows={emergency}
                      sortHandler={() => { }}
                    />
                  )}
                </div>
              </div>
            </form>
          )}
          <div className="pt-3"></div>
          {active === "logs" && <BookingLogs booking_no={booking?.booking_no} />}
        </div>
      </section>
    </div>
  );
};

export default LeadsInputs2;
