import React from "react";
import "./app.css";
import { getImage } from "../../../../../utils/getImages";
const Card5Ship = ({ cardData, activetab }) => {
  return (
    <div>
      <div className="col-md-12 text-center py-5">
        <h1 className="title-color px-3 px-md-0 mt-10">
          {activetab === "english"
            ? cardData?.title
            : activetab === "simplified_chinese"
            ? cardData?.simplified_chinese_title
            : activetab === "arabic"
            ? cardData?.arabic_title
            : cardData?.traditional_chinese_title}
        </h1>
        <a href={activetab === "english"
                ? cardData?.link
                : activetab === "simplified_chinese"
                ? cardData?.simplified_chinese_link
                : activetab === "arabic"
                ? cardData?.arabic_link
                : cardData?.traditional_chinese_link}>
        <p 
              dangerouslySetInnerHTML={{__html:activetab === "english"
                ? cardData?.description
                : activetab === "simplified_chinese"
                ? cardData?.simplified_chinese_description
                : activetab === "arabic"
                ? cardData?.arabic_description
                : cardData?.traditional_chinese_description}}
              style={{ direction: activetab === "arabic" && "rtl",textDecoration:"underline" }}
            /></a>
        <div
          id="icon-lists"
          className="row align-items-center"
          style={{ marginTop: "20px" }}
        >
          {cardData?.dataArray?.map((item) => (
            <div className="col-6 col-md-3">
              <div className="text-center">
                <img
                  src={
                    getImage(item?.image)
                   }
                  alt=""
                />
                <h3 dangerouslySetInnerHTML={{__html:activetab === "english"
                    ? item?.title
                    : activetab === "simplified_chinese"
                    ? item?.simplified_chinese_title
                    : activetab === "arabic"
                    ? item?.arabic_title
                    : item?.traditional_chinese_title}} data-lang='{ "CN":"150,695 噸" }'/>
                
              
                  <p 
              dangerouslySetInnerHTML={{__html:activetab === "english"
                ? item?.description
                : activetab === "simplified_chinese"
                ? item?.simplified_chinese_description
                : activetab === "arabic"
                ? item?.arabic_description
                : item?.traditional_chinese_description}}
              style={{ direction: activetab === "arabic" && "rtl" }}
            >

            </p>
                  
               
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Card5Ship;
