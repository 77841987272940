import React, { useEffect, useState } from "react";
import "./app.css";
import { getImage } from "../../../../../utils/getImages";
import { getLang } from "../lang/getlang";
const OnboardPreview = ({ cardData, activetab }) => {
  const [active, setActive] = useState("genting_dream");
  const [notes, setNotes] = useState("");
  useEffect(() => {
    let item = cardData?.dataArray.filter((item) => item?.ship === active)[0];
    setNotes(
      activetab === "english"
        ? item?.note
        : activetab === "simplified_chinese"
        ? item?.simplified_chinese_note
        : activetab === "arabic"
        ? item?.arabic_note
        : item?.traditional_chinese_note
    );
  }, [active, activetab, cardData]);
  return (
    <div style={{ marginLeft: "10px" }}>
      <h1 className="title-color mb-4"> </h1>
      <div
        className="col-12 text-center mb-5"
        style={{ display: "flex", alignItems: "center", width: "100%" }}
      >
        <ul
          className="page-tab scroll-tab"
          style={{
            display: "flex",
            gap: "10px",

            listStyleType: "none",
            padding: 0,
            alignItems: "center",
            justifyContent: "center",
            margin: 0,
            width: "100%",
          }}
        >
          <li
            onClick={() => setActive("genting_dream")}
            data-scroll-tab-seq={1}
            className=""
          >
       {getLang("genting_dream",activetab)}
          </li>
          <li
            onClick={() => setActive("resort_world_one")}
            data-scroll-tab-seq={2}
            className=""
          >
                  {getLang("resorts_world_one",activetab)}
          </li>
          {/* {cardData?.dataArray?.map((item, index) => (
            <li data-scroll-tab-seq={index + 1 + ""} className="">
              {activetab === "english"
                ? item?.title
                : activetab === "simplified_chinese"
                ? item?.simplified_chinese_title
                : item?.traditional_chinese_title}
            </li>
          ))} */}
        </ul>
      </div>
      <div
        style={{
          display: "flex",
          // alignItems: "center",
          flexWrap: "wrap",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        <div className="row" style={{ width: "100%", padding: "10px" }}>
          <div style={{ width: "100%" }}>
            <div className="col-12 text-center scroll-content-1">
              {" "}
              <h2
                className="mb-5 title-grey"
                style={{ direction: activetab === "arabic" && "rtl" }}
              >
                {activetab === "english"
                  ? cardData?.title
                  : activetab === "simplified_chinese"
                  ? cardData?.simplified_chinese_title
                  : cardData?.traditional_chinese_title}
              </h2>
            </div>
          </div>
          <table className="beverage-package1">
            <thead>
              <tr>
                <th colspan="3" scope="col">
                  &nbsp;
                </th>
                {active === "resort_world_one" && <th scope="col">{getLang("one_nights",activetab)}</th>}
                <th scope="col">{getLang("two_nights",activetab)}</th>
                <th scope="col">{getLang("three_nights",activetab)}</th>
                <th scope="col">{getLang("four_nights",activetab)}</th>
                {active === "resort_world_one" && <th scope="col">{getLang("five_nights",activetab)}</th>}
                {active === "resort_world_one" && <th scope="col">{getLang("six_nights",activetab)}</th>}
              </tr>
            </thead>
            {cardData?.dataArray
              .filter((item) => item?.ship === active)
              ?.map((item) => (
                <tbody>
                  <tr>
                    <td rowspan={item?.dataArray.length + 1 + ""}>
                      {" "}
                      <div
                          dangerouslySetInnerHTML={{
                            __html:
                              activetab === "english"
                                ? item?.title
                                : activetab === "simplified_chinese"
                                ? item?.simplified_chinese_title
                                : activetab === "arabic"
                                ? item?.arabic_title
                                : item?.traditional_chinese_title,
                          }}
                        />
                    </td>
                  </tr>
                  {item?.dataArray?.map((item) => (
                    <tr>
                      <td className="img-cell">
                        <img
                          src={getImage(item?.image)}
                          width="100%"
                          style={{ minWidth: "100px" }}
                        />
                      </td>
                      <td>
                        <span className="title1">
                          {" "}
                          <div
                          dangerouslySetInnerHTML={{
                            __html:
                              activetab === "english"
                                ? item?.title
                                : activetab === "simplified_chinese"
                                ? item?.simplified_chinese_title
                                : activetab === "arabic"
                                ? item?.arabic_title
                                : item?.traditional_chinese_title,
                          }}
                        />
                        </span>
                        <br />
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              activetab === "english"
                                ? item?.description
                                : activetab === "simplified_chinese"
                                ? item?.simplified_chinese_description
                                : activetab === "arabic"
                                ? item?.arabic_description
                                : item?.traditional_chinese_description,
                          }}
                        />
                      </td>
                      {active === "resort_world_one" && (
                        <td className="center">
                          <span className="price">
                            {activetab === "english"
                              ? item?.one_nights
                              : activetab === "simplified_chinese"
                              ? item?.simplified_chinese_one_nights
                              : activetab === "arabic"
                              ? item?.arabic_one_nights
                              : item?.traditional_chinese_one_nights}
                          </span>
                        </td>
                      )}
                      <td className="center">
                        <span className="price">
                          {activetab === "english"
                            ? item?.two_nights
                            : activetab === "simplified_chinese"
                            ? item?.simplified_chinese_two_nights
                            : activetab === "arabic"
                            ? item?.arabic_two_nights
                            : item?.traditional_chinese_two_nights}
                        </span>
                      </td>
                      <td className="center">
                        <span className="price">
                          {activetab === "english"
                            ? item?.three_nights
                            : activetab === "simplified_chinese"
                            ? item?.simplified_chinese_three_nights
                            : activetab === "arabic"
                            ? item?.arabic_three_nights
                            : item?.traditional_chinese_three_nights}
                        </span>
                      </td>
                      <td className="center">
                        <span className="price">
                          {activetab === "english"
                            ? item?.four_nights
                            : activetab === "simplified_chinese"
                            ? item?.simplified_chinese_four_nights
                            : activetab === "arabic"
                            ? item?.arabic_four_nights
                            : item?.traditional_chinese_four_nights}
                        </span>
                      </td>
                      {active === "resort_world_one" && (
                        <td className="center">
                          <span className="price">
                            {activetab === "english"
                              ? item?.five_nights
                              : activetab === "simplified_chinese"
                              ? item?.simplified_chinese_five_nights
                              : activetab === "arabic"
                              ? item?.arabic_five_nights
                              : item?.traditional_chinese_five_nights}
                          </span>
                        </td>
                      )}
                      {active === "resort_world_one" && (
                        <td className="center">
                          <span className="price">
                            {activetab === "english"
                              ? item?.six_nights
                              : activetab === "simplified_chinese"
                              ? item?.simplified_chinese_six_nights
                              : activetab === "arabic"
                              ? item?.arabic_six_nights
                              : item?.traditional_chinese_six_nights}
                          </span>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              ))}
          </table>
       
        </div>
      </div>
      <div style={{display:"flex",flexDirection:"column"}}>
          <div style={{ marginTop: "30px",fontWeight:"bold"}}>   {getLang("note",activetab)}</div>
          <div
            style={{ marginLeft:"20px"}}
            dangerouslySetInnerHTML={{
              __html: notes,
            }}
          /></div>
    </div>
  );
};

export default OnboardPreview;
