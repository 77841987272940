import React, { useState } from "react";
import { constants } from "../../constants/constants";
import { getImage } from "../../utils/getImages";
import UploadFileImageBanner from "../Banner/uploadFileImageBanner";
import UploadFile from "../fileUpload/uploadFile";
import UploadFileImage from "../imageUpload/uploadFileImage";
import UploadFileImageBanner2 from "../Banner/uploadFileImageBanner copy";

const UploadFileForm = ({
  label,
  className,
  placeholder,
  image,
  setImage,
  required,
  type,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  if (type === "image")
    return (
      <UploadFileImage
      mediaType={"image"}
        className={"" + className}
        mandatory={required ? "*" : ""}
        label={label}
        placeholder={placeholder}
        selectedFile={selectedFile}
        image={getImage(image)}
        setSelectedFile={(e) => {
          setSelectedFile(e);
          setImage(e);
        }}
      />
    );
  if (type === "pdf")
    return (
      <UploadFile
        className={"" + className}
        mandatory={required ? "*" : ""}
        label={label}
        placeholder={placeholder}
        selectedFile={selectedFile}
        image={getImage(image)}
        setSelectedFile={(e) => {
          setSelectedFile(e);
          setImage(e);
        }}
        accept="application/pdf"
      />
    );
  else
    return (
      <UploadFileImageBanner
        className={"" + className}
        mandatory={required ? "*" : ""}
        label={label}
        placeholder={placeholder}
        selectedFile={selectedFile}
        image={getImage(image)}
        setSelectedFile={(e) => {
          if (typeof e === "string") {
            setSelectedFile(e.replace(constants.MEDIAURL + "/", ""));
            setImage(e.replace(constants.MEDIAURL + "/", ""));
          } else {
            setSelectedFile(e);
            setImage(e);
          }
        }}
        mediaType={"video"}
        accept="video/*"
      />
    );
};

export default UploadFileForm;
