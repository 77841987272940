import React, { useEffect, useState } from "react";
import YouTubeEmbed from "./YouTubeEmbed";

const UploadFileImageBanner2 = ({
  selectedFile,
  setSelectedFile,
  className,
  image,
  mandatory,
  width,height,
  mediaType,
  label,
  placeholder,
  trigger
}) => {

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  useEffect(() => {
    if(image)
    setSelectedFile(image)
  }, [image, mediaType]);
  const handleRemoveImage = () => {

    setSelectedFile();
  };
 
  return (
    <div className={className || "col-12"}>
      <div className="fieldrow">
        <label className="f_label">{label}{<span style={{ color: "red" }}> {mandatory}</span>}</label>
        {mediaType === "link" ? (
          <input
            required
            className="f_input"
            type="text"
            value={selectedFile}
            onChange={(e) => setSelectedFile(e.target.value)}
          />
        ) : (
          <div className="upload_box">
            <input
              type="file"
              onChange={handleFileChange}
              accept={mediaType === "image" ? "image/*" : "video/*"}
            />
            <i className="ri-upload-cloud-2-line"></i>
            <span>{placeholder}</span>
            <span>{`Width: ${width||1920},Height: ${height||826}`}</span>
          </div>
        )}
      </div>{" "}
      <div className="fieldrow uploaded_item">
        <ul>
          {selectedFile && (
            <li>
              {mediaType === "image" ? (
                <img src={typeof selectedFile === "string" ? selectedFile : URL.createObjectURL(selectedFile)} alt="" />
              ) : mediaType === "link" ? <YouTubeEmbed
                height={"100"}
                width={"200"}
                videoUrl={selectedFile}
              /> : (
                <video
                  controls
                  src={typeof selectedFile === "string" ? selectedFile : URL.createObjectURL(selectedFile)}
                  style={{ height: 100, width: 200 }}
                ></video>
              )}
              <button onClick={handleRemoveImage} type="button">
                <i className="ri-close-line"></i>
              </button>
            </li>
          )}

          {/* {image && !selectedFile && !remove && (
            <li style={{ width: mediaType === "link" && "100%" }}>
              {mediaType === "image" ? (
                <img src={image} alt="" />
              ) : mediaType === "link" ? (
                <YouTubeEmbed height={"100"} width={"200"} videoUrl={image} />
              ) : (
                <video
                  controls
                  src={image}
                  style={{ height: 100, width: 200 }}
                ></video>
              )}
              <button onClick={handleRemoveImage} type="button">
                <i className="ri-close-line"></i>
              </button>
            </li>
          )} */}
        </ul>
      </div>
    </div>
  );
};

export default UploadFileImageBanner2;
