import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill"; // Import React Quill
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../DataLayer/DataLayer";

import moment from "moment";
import { constants } from "../../constants/constants";
import LocationAndExpcept from "../../constants/LocationAndExpcept";
import { uploadFile } from "../../utils/ApiCalls/Document";
import {
  createExperience,
  getExperienceDataLocation,
  updateExperienceData,
} from "../../utils/ApiCalls/Experience";
import { getAllLocations } from "../../utils/ApiCalls/Locations";
import { addlog } from "../../utils/ApiCalls/Logs";
import { checkFields } from "../../utils/Validation/checkFields";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import UploadFileImageBanner2 from "../Banner/uploadFileImageBanner copy";
import { IMAGE_SUB_TYPE, IMAGE_TYPE } from "../../constants/ImageConstant";
import { uploadData } from "../../utils/ApiCalls/Upload";

const MySwal = withReactContent(Swal)

const ExperienceInputs = ({ experience }) => {
  const [englishTitle, setEnglishTitle] = useState(
    experience?.englishTitle || ""
  );
  
  const [description, setDescription] = useState(experience?.description || "");
  const [description2, setDescription2] = useState(
    experience?.description || ""
  );
  const [simplifiedChineseTitle, setSimplifiedChineseTitle] = useState(
    experience?.simplifiedChineseTitle || ""
  );
  const [traditionalChineseTitle, setTraditionalChineseTitle] = useState(
    experience?.traditionalChineseTitle || ""
  );
  const [arabicTitle, setarabicTitle] = useState(
    experience?.arabicTitle || ""
  );

  const [simplifiedChineseDescription, setSimplifiedChineseDescription] =
    useState("");

    const [arabicDescription, setarabicDescription] =
    useState("");
  const [traditionalChineseDescription, setTraditionalChineseDescription] =
    useState("");
  const [simplifiedChineseDescription2, setSimplifiedChineseDescription2] =
    useState("");
  const [traditionalChineseDescription2, setTraditionalChineseDescription2] =
    useState("");
    const [arabicDescription2, setarabicDescription2] =
    useState("");
    const [image, setImage] = useState( null);
  const [isActive, setIsActive] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [traditionalChineseFile, setTraditionalChineseFile] = useState(null);
  const [simplifiedChineseFile, setSimplifiedChineseFile] = useState(null);
  const [arabicFile, setarabicFile] = useState(null);
  const [title, setTitle] = useState(experience?.title || "");
  const [displayLocation, setDisplayLocation] = useState([]);
  const [url, setUrl] = useState(experience?.url || "");
  const [traditional_chinese_image, setTraditional_chinese_image] =
    useState(null);
  const [simplified_chinese_image, setSimplified_chinese_image] =
    useState(null);
    const [arabic_image, setarabic_image] =
    useState(null);
  const [valid_from, setValidFrom] = useState();
  const [valid_to, setValidTo] = useState();
  const [locationOption, setLocationOption] = useState([]);
  const [{ userDetails }, dispatch] = useDataLayerValue();
  const [error, setError] = useState();
  const navigate = useNavigate();
  const [currentLocation, setCurrentLocation] = useState([]);
  useEffect(() => {
    if (experience) {
      if (experience.image) {
        getLocationData();
      }
      setImage(experience?.image)
      setTraditional_chinese_image(experience?.traditional_chinese_image);
      setSimplified_chinese_image(experience?.simplified_chinese_image)
      setarabic_image(experience?.arabic_image)
      setarabicTitle(experience?.arabic_title)
      setTitle(experience?.title);
      setDescription(experience?.description);
      setDescription2(experience?.description2);
      setarabicDescription(experience?.arabic_description);
      setarabicDescription2(experience?.arabic_description2)
      setSimplifiedChineseTitle(experience?.simplified_chinese_title);
      setTraditionalChineseTitle(experience?.traditional_chinese_title);
      setEnglishTitle(experience?.english_title);
      setUrl(experience?.url);
      setSimplifiedChineseDescription(
        experience?.simplified_chinese_description
      );
      setTraditionalChineseDescription(
        experience?.traditional_chinese_description
      );
      setSimplifiedChineseDescription2(
        experience?.simplified_chinese_description2
      );
      setTraditionalChineseDescription2(
        experience?.traditional_chinese_description2
      );
      if (experience.location_id) setDisplayLocation([experience.location_id]);
      setIsActive(experience?.is_active === 1 ? true : false);

      setValidFrom(experience?.valid_from);
      setValidTo(experience?.valid_to);
    }
  }, [experience]);
  const getLocationData = async () => {
    let data = await getExperienceDataLocation({ image: experience.image });

    if (data.error) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: data.error, type: "error" },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.error,
      });
    } else {
      setCurrentLocation(data);
      setDisplayLocation(data?.map((item) => item.location_id) || []);
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
      [{ direction: "rtl" }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleEnglishTitleChange = (content) => {
    setEnglishTitle(content);
  };

  const handleSimplifiedChineseTitleChange = (content) => {
    setSimplifiedChineseTitle(content);
  };

  const handleTraditionalChineseTitleChange = (content) => {
    setTraditionalChineseTitle(content);
  };

  const handleCheckboxChange = () => {
    setIsActive(!isActive);
  };
  const handleFileChange = (event) => {
    // Handle file change logic if needed
    setSelectedFile(event.target.files[0]);
  };

  const handleRemoveImage = () => {
    // Handle removing image logic if needed
    setSelectedFile(null);
  };
  const handleDisplayLocationChange = (selectedOptions) => {
    setDisplayLocation(selectedOptions.target.value);
  };
  useEffect(() => {
    getOptionLocation();
  }, []);

  const getOptionLocation = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getAllLocations();
    console.log(data);
    if (data.error) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: data.error, type: "error" },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.error,
      });
    } else {
      setLocationOption(data?.data || []);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const addExperience = async (e) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    e.preventDefault();
    let added = false;
    let dataValues = {
      image,
      simplified_chinese_image,
      traditional_chinese_image,
      arabic_image,
      title,
      url,
      description,
      order_by: 1,
      description2,
      is_active: isActive ? 1 : 0,
      english_title: englishTitle,
      arabic_title: arabicTitle,
    arabic_description: arabicDescription,
  arabic_description2: arabicDescription2,
      valid_from: valid_from,
      valid_to: valid_to,
      simplified_chinese_title: simplifiedChineseTitle,
      traditional_chinese_title: traditionalChineseTitle,
      simplified_chinese_description: simplifiedChineseDescription,
      simplified_chinese_description2: simplifiedChineseDescription2,
      traditional_chinese_description: traditionalChineseDescription,
      traditional_chinese_description2: traditionalChineseDescription2,
    };
    if (selectedFile) {
      if (typeof selectedFile === "string") {
        dataValues.image = selectedFile.replace(constants.MEDIAURL + "/", "");
      }else{
      let imageType = {
        type: IMAGE_TYPE.DEAL_IMG,
        subType: IMAGE_SUB_TYPE.ENGLISH_IMG,
      };
      let image = await uploadFile(selectedFile);
      if (image?.error) throw new Error(image.error);

      dataValues.image = image?.url;}
    }else{
      dataValues.image=""
    }
    if (traditionalChineseFile) {
      if (typeof traditionalChineseFile === "string") {
        dataValues.traditional_chinese_image = traditionalChineseFile.replace(constants.MEDIAURL + "/", "");
      }else{
      let imageType = {
        type: IMAGE_TYPE.DEAL_IMG,
        subType: IMAGE_SUB_TYPE.TRADITIONAL_CHINESE_IMG,
      };
      let image = await uploadFile(traditionalChineseFile);
      if (image?.error) throw new Error(image.error);

      dataValues.traditional_chinese_image = image?.url;}
    }else{
      dataValues.traditional_chinese_image =""
    }
    if (simplifiedChineseFile) {
      if (typeof simplifiedChineseFile === "string") {
        dataValues.simplified_chinese_image = simplifiedChineseFile.replace(constants.MEDIAURL + "/", "");
      }else{
      let imageType = {
        type: IMAGE_TYPE.DEAL_IMG,
        subType: IMAGE_SUB_TYPE.TRADITIONAL_CHINESE_IMG,
      };
      let image = await uploadFile(simplifiedChineseFile);
      if (image?.error) throw new Error(image.error);

      dataValues.simplified_chinese_image = image?.url;}
    }
      else{
        dataValues.simplified_chinese_image =""
      }
    
    if (arabicFile) {
      if (typeof arabicFile === "string") {
        dataValues.arabic_image = arabicFile.replace(constants.MEDIAURL + "/", "");
      }else{
      let imageType = {
        type: IMAGE_TYPE.DEAL_IMG,
        subType: IMAGE_SUB_TYPE.TRADITIONAL_CHINESE_IMG,
      };
      let image = await uploadFile(arabicFile);
      if (image?.error) throw new Error(image.error);

      dataValues.arabic_image = image?.url;}
    }   else{
      dataValues.arabic_image =""
    }
    // let requiredFields=["english_image"];
    // const isChinaSelecetd = displayLocation.find(location=> locationOption.find(l=> location === l.id)?.location_code==="CN");
    // if(isChinaSelecetd){
    //     requiredFields.push("traditional_chinese_image", "simplified_chinese_image");
    // }
    let valid = checkFields(dataValues, "experiences","");
    if (valid) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: valid, type: "error" },
      // });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: valid,
      });
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
      // setError(valid);
    } else {
      try {
        await Promise.all(
          displayLocation.map(async (item, index) => {
            let values = {
              location_id: item,
              ...dataValues            
            };
            let data;
            if (experience?.id) {
              let locationValue = currentLocation.filter(
                (data) => data.location_id === item
              );
              if (locationValue.length > 0) {
                let { ...rest } = values;
                if (item === experience.location_id) {
                  data = await updateExperienceData(locationValue[0].id, values);
                } else {
                  data = await updateExperienceData(locationValue[0].id, rest);
                }
              } else {
                await createExperience({ ...values, recordIteration: index + 1 });
              }
            }else {
              values = {
                ...values,
                recordIteration: index + 1
              }
              data = await createExperience(values);
            }

            console.log(data);

            if (data?.error) {
              throw new Error(data.error);
            } else {
              if (experience?.id) {
                addlog(
                  "Experience Updated",
                  "Experience",
                  {
                    id: experience.id,
                    title: values.title || experience.title,
                    url: url || experience.url,
                    location_id: experience.location_id,
                  },
                  userDetails.id
                );
              } else {
                addlog(
                  "Experience Created",
                  "Experience",
                  {
                    id: data.id,
                    title: values.title,
                    url: values.url,
                    location_id: item,
                  },
                  userDetails.id
                );
              }
            }
          })
        );
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });

        if (!experience.id) {
          navigate("/cms/homepage/experience");
        } else {
          navigate("/cms/homepage/experience");
          dispatch({
            type: "SET_ERROR_MESSAGE",
            errMess: { message: "Values updated", type: "success" },
          });
        }
      } catch (error) {
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
        // dispatch({
        //   type: "SET_ERROR_MESSAGE",
        //   errMess: {
        //     message: error.message || "Unknown error occurred",
        //     type: "error",
        //   },
        // });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message || "Unknown error occurred",
        });
      }
    }
  };
  return (
    <div>
      <section className="content">
        {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )}

        <div className="container-fluid">
          <div className="card">
            <form className="input_form" onSubmit={addExperience}>
              <div className="card-body">
                
                <div className="row">
                  <div  className="col-lg-12 ">
                  <LocationAndExpcept
                       displayLocation={displayLocation}
                       banner={experience}
                       locationOption={locationOption}
                       nondisplay
                       options={locationOption}
                       setDisplayLocation={setDisplayLocation}
                       handleDisplayLocationChange={handleDisplayLocationChange}
                      />
               </div>
               <UploadFileImageBanner2
                      mediaType={"image"}
                   className="col-lg-3"
                 
                   width={778}
                   height={542}
                    image={
                      experience?.image
                        ? `${constants.MEDIAURL}/${experience?.image}`
                        : experience?.image
                    }
                    label={"English Experience Image"}
                    placeholder={"Upload experience Image"}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                  />
                              <UploadFileImageBanner2
                      mediaType={"image"}
                   className="col-lg-3"
            
                  // mandatory={displayLocation.find(location=>locationOption.find(l=> l.id===location)?.location_code === "CN") ? "*": ""}

                    width={778}
                    height={542}
                    image={
                      experience?.simplified_chinese_image
                        ? `${constants.MEDIAURL}/${experience?.simplified_chinese_image}`
                        : experience?.simplified_chinese_image
                    }
                    label={"Simplified Chinese Experience Image "}
                    placeholder={"Upload Simplified Chinese Experience Image"}
                    selectedFile={simplifiedChineseFile}
                    setSelectedFile={setSimplifiedChineseFile}
                  />
                      <UploadFileImageBanner2
                      mediaType={"image"}
                   className="col-lg-3"
                  // mandatory={displayLocation.find(location=>locationOption.find(l=> l.id===location)?.location_code === "CN") ? "*": ""}

                    width={778}
                    height={542}
                    image={
                      experience?.traditional_chinese_image
                        ? `${constants.MEDIAURL}/${experience?.traditional_chinese_image}`
                        : experience?.traditional_chinese_image
                    }
                    label={"Traditional Chinese Experience Image"}
                    placeholder={"Upload Traditional Chinese Experience Image"}
                    selectedFile={traditionalChineseFile}
                    setSelectedFile={setTraditionalChineseFile}
                  />
                            <UploadFileImageBanner2
                      mediaType={"image"}
                   className="col-lg-3"
                 
                  // mandatory={displayLocation.find(location=>locationOption.find(l=> l.id===location)?.location_code === "CN") ? "*": ""}

                    width={778}
                    height={542}
                    image={
                      experience?.arabic_image
                        ? `${constants.MEDIAURL}/${experience?.arabic_image}`
                        : experience?.arabic_image
                    }
                    label={"Arabic Experience Image"}
                    placeholder={"Upload Arabic Experience Image"}
                    selectedFile={arabicFile}
                    setSelectedFile={setarabicFile}
                  />
         

                  
         
         <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">English Title</label>
                      <ReactQuill
                        value={englishTitle}
                        onChange={handleEnglishTitleChange}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                        Simplified Chinese Title
                      </label>
                      <ReactQuill
                        value={simplifiedChineseTitle}
                        onChange={handleSimplifiedChineseTitleChange}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                        Traditional Chinese Title
                      </label>
                      <ReactQuill
                        value={traditionalChineseTitle}
                        onChange={handleTraditionalChineseTitleChange}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                        Arabic Title
                      </label>
                      <ReactQuill
                        value={arabicTitle}
                        onChange={(e)=>setarabicTitle(e)}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>

                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">English Description2</label>
                      <ReactQuill
                        value={description}
                        onChange={setDescription}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                        Simplified Chinese Description2
                      </label>
                      <ReactQuill
                        value={simplifiedChineseDescription}
                        onChange={setSimplifiedChineseDescription}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                        Traditional Chinese Description2
                      </label>
                      <ReactQuill
                        value={traditionalChineseDescription}
                        onChange={setTraditionalChineseDescription}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                       Arabic Description2
                      </label>
                      <ReactQuill
                        value={arabicDescription}
                        onChange={setarabicDescription}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">English Description1</label>
                      <ReactQuill
                        value={description2}
                        onChange={setDescription2}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
              
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                        Simplified Chinese Description1
                      </label>
                      <ReactQuill
                        value={simplifiedChineseDescription2}
                        onChange={setSimplifiedChineseDescription2}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
            

                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                        Traditional Chinese Description1
                      </label>
                      <ReactQuill
                        value={traditionalChineseDescription2}
                        onChange={setTraditionalChineseDescription2}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="fieldrow">
                      <label className="f_label">
                        Arabic Description1
                      </label>
                      <ReactQuill
                        value={arabicDescription2}
                        onChange={setarabicDescription2}
                        modules={modules}
                        formats={formats}
                      />
                    </div>
                  </div>
                
               
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Url</label>
                      <input
                        className="f_input"
                        // required
                        type="text"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6 col-6">
                    {/* <div className="sailing_date "> */}
                    {/* <h4 className="sub_title2">Valid Date</h4> */}
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="fieldrow">
                          <label className="f_label"> Valid From <span style={{color:"red"}}> *</span></label>
                          <input
                            required
                            value={valid_from}
                            onChange={(e) => {
                              setValidFrom(e.target.value)
                              setValidTo("");
                            }}
                            className="f_input"
                            type="date"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                        <div className="fieldrow">
                          <label className="f_label">Valid To <span style={{color:"red"}}> *</span></label>
                          <input
                            required
                            className="f_input"
                            value={valid_to}
                            onChange={(e) => {
                              setValidTo(e.target.value);
                            }}
                            min={moment(valid_from)
                              .format("YYYY-MM-DD")}
                            type="date"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="iti_inner">
                      <div className="icheck-primary d-inline">
                        <input
                          type="checkbox"
                          id="customCheckbox4"
                          name="r10"
                          checked={isActive}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="customCheckbox4">
                          {isActive ? (
                            <p>
                              <span style={{ fontWeight: 600 }}>Active</span>
                            </p>
                          ) : (
                            <p>
                              <span style={{ fontWeight: 200 }}>Active</span>
                            </p>
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-right">
                <button className="btn main_submit">
                  {experience.id ? "Update" : "Add"} experience
                </button>
              </div>
            </form>
          </div>
          <div className="pt-3"></div>
        </div>
      </section>
    </div>
  );
};

export default ExperienceInputs;
