import React, { useEffect, useState } from "react";
import Layout from "../../../Layout";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import HeaderBread from "../../../components/HeaderBread";
import { Link, useNavigate } from "react-router-dom";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import CustomizedTable from "../../../components/UI/CustomizedTable";
import Logs from "../../../components/Logs";
import { deleteWebpage, getAllWebpage } from "../../../utils/ApiCalls/WebPages";
import DeletePop from "../../../constants/ErrorPermissionModal";
import { addlog } from "../../../utils/ApiCalls/Logs";
import { message } from "antd";
import { checkPermission } from "../../../utils/Validation/checkPermission";
import { deleteMenuList } from "../../../utils/ApiCalls/MenuList";

export const Ships = () => {
  const navigate = useNavigate();
  const [rows, setRows] = useState();
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [active, setActive] = useState("listing");
  const [{ permission, userDetails }, dispatch] = useDataLayerValue();

  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Ships List",
      route: "/cms/webpages/our-ship",
    },
  ];

  const handleDelete = async (id, url) => {
    if (checkPermission(permission, "delete_per", "ships")) {
      deleteWebpage(id);
      deleteMenuList({ url })
      addlog("Webpage Deleted", "Webpage", { id }, userDetails.id);
      getData(page, sort, order);
    } else {
      message.error("Missing Permission for delete ");
    }
  };

  const columns = [
    {
      title: "No",
      width: 10,
      dataIndex: "id",
      key: "position",
      fixed: "left",
      render: (position, record, index) => index + 1,
    },
    {
      title: "Page Title",
      width: 10,
      dataIndex: "page_name",
      key: "page_name",
      fixed: "left",
    },
    {
      title: "Slug",
      width: 10,
      dataIndex: "slug",
      key: "slug",
      fixed: "left",
    },

    {
      title: "Action",
      key: "operation",
      width: 30,
      render: (text, record) => (
        <div className="table_action_btn">
          <div
            onClick={() => navigate("/webpages/ships/edit/" + record.slug)}
            className="btn"
          >
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <DeletePop
            onConfirm={() =>
              handleDelete(record.id, "our-ship/" + record.slug)
            }
          >
            <div>
              {" "}
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>
        </div>
      ),
    },
  ];
  const getData = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getAllWebpage({ page_type: "ships" });
    console.log(data);
    setRows(data.data);
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Layout type={"read_per"} module_data={"ships"}>
      <CustomHeaderTitle title={"Deals"} />
      <div>
        <div>
          <HeaderBread navigation={navigation} title={"Ships"}></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            {active === "listing" && (
              <div className="card">
                <div className="card-header two_item">
                  <h5 className="card-title">Ship List</h5>
                  <Link to="/webpages/ships/add" className="btn add_v_btn">
                    Add Ship
                  </Link>
                </div>

                <div className="card-body">
                  <div className="table_holder">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    ></div>
                    {rows && (
                      <CustomizedTable
                        setRows={setRows}
                        setPageSize={setPageSize}
                        setPage={setPage}
                        pageSize={pageSize}
                        page={page}
                        count={count}
                        columns={columns}
                        rows={rows}
                        title={"Cabin Category"}
                        sortHandler={() => { }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>{" "}
        {active === "logs" && (
          <Logs
            type={"Page"}
            slug={"/webpages/our-ship/edit/"}
            refreshlog={active}
          />
        )}
      </div>
    </Layout>
  );
};
