import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderBread from "../../../components/HeaderBread";
import { useDataLayerValue } from "../../../DataLayer/DataLayer";
import Layout from "../../../Layout";

import { message } from "antd";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import CustomizedTable from "../../../components/UI/CustomizedTable";
import { constants } from "../../../constants/constants";
import DeletePop from "../../../constants/ErrorPermissionModal";
import { getPermission } from "../../../utils/ApiCalls/Permission";
import { deleteWebpage, getAllWebpage, updateWebpage } from "../../../utils/ApiCalls/WebPages";
import { checkPermission } from "../../../utils/Validation/checkPermission";
import ValidDateUI from "../../../constants/validDateUI";
import Swal from "sweetalert2";
import { addlog } from "../../../utils/ApiCalls/Logs";

const Blogs = () => {
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Blog List",
      route: "/profile/user-management",
    },
  ];
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [{ permission, userDetails }, dispatch] = useDataLayerValue();
  const navigate = useNavigate()

  const columns = [
    {
      title: "No",
      width: 2,
      dataIndex: "id",
      key: "position",
      fixed: "left",
      render: (position, record, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Image",
      width: 30,
      dataIndex: "image",
      key: "image",
      render: (image, record) => {

        return (
          <img
            src={`${constants.MEDIAURL}/${image}`}
            style={{ width: "100px", height: "80px" }}
          />
        );

      },
    },
    {
      title: "Title",
      width: 400,
      dataIndex: "page_name",
      key: "page_name",
      fixed: "left",
    },

    {
      title: "Action",
      key: "operation",
      width: 30,
      render: (text, record) => (
        <div className="table_action_btn">
          <div
            onClick={() =>
              navigate("/webpages/blog/edit/" + record.slug)
            }
            className="btn"
          >
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <DeletePop
            onConfirm={() =>
              handleDelete(record.id, record.title, record.location_id)
            }
          >
            <div>
              {" "}
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>
        </div>
      ),
    },
  ];
  const handleUpdate = async (id, values, title, location_id) => {
    console.log({values})
    if (checkPermission(permission, "update_per", "Blog")) {
      let data = await updateWebpage(id, values);
      if (data.error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: data.error,
        });
      } else {
        addlog(
          "Webpage " + !values.valid_from
            ? values.is_active === 1
              ? "Actived"
              : "Deactivated"
            : "updated",
          "Webpage",
          { ...values, id, title, location_id },
          userDetails.id
        );
      }
      await getData(page);
    } else {
      message.error("Update permission missing");
    }
  };
  useEffect(() => {
    getData(page);
  }, [page, pageSize]);
  const handleDelete = async (id) => {
    if (checkPermission(permission, "delete_per", "Blog")) {
      await deleteWebpage(id);
      await getData(page);
    } else {
      message.error("Missing Permission for delete");
    }
  };

  const getData = async (value) => {
    let data = await getAllWebpage({ page_type: "blog_item", page: value, limit: pageSize });
    const per = await getPermission();
    setRoles(per?.data?.map((item) => item.name));
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.data) {
      setRows(data.data);
      setCount(data.count);
    }
  };
  return (
    <Layout type={"read_per"} module_data={"blog"}>
      <CustomHeaderTitle title={"Blog List"} />
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"Blog List"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <div className="card">
              <div className="card-header two_item">
                <h5 className="card-title">Blogs List</h5>
                <div className="two_item">
                  <Link
                    to="/webpages/blog/add"
                    className="btn export_v_btn mx-2"
                  >
                    Add Blog
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <div className="table_holder">
                  {roles?.length > 0 && (
                    <CustomizedTable
                      setRows={setRows}
                      setPageSize={setPageSize}
                      setPage={setPage}
                      pageSize={pageSize}
                      page={page}
                      count={count}
                      columns={columns}
                      rows={rows}
                      title={"NewsLetter"}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Blogs;
