import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React, { useEffect, useState } from "react";
import DyanmicFrom from "../../../DyanmicForm/DyanmicFrom";
import { message } from "antd";
import { checkFormFields } from "../../../../utils/Validation/checkFormFields";
const FormCard1 = ({ data, add, index, updateData, attributes, button, active }) => {
  const [error, setError] = useState([]);
  const [cardData, setCardData] = useState(
    data || {
      image: null,
      title: "",
      simplified_chinese_title: "",
      traditional_chinese_title: "",
      traditional_chinese_description: "",
      description: "",
      simplified_chinese_description: "",
    }
  );

  useEffect(() => {
    if (cardData?.dataArray?.length < data?.dataArray?.length) {
      setCardData(item => ({ ...item, dataArray: data.dataArray }))
    }
  }, [data])

  const addData = () => {
    if (index) {
      let error = checkFormFields(
        cardData,
        attributes.filter(item => item.required).map((item) => item.key)
      );
      if (error.length > 0) {
        setError(error)
        message.error("Some fields are missing");
      } else {
        setError([])
        updateData(index - 1, { ...cardData }, "new");
      }
    }
    else {
      updateData(0, { ...cardData, title: button || "Section" });
    }
  };
  return (
    <div style={{ padding: "10px" }}>
      <div className="card-body">
        {!add && (
          <div className="row">
            <DyanmicFrom
              error={error}
              active={active}
              attributes={attributes || []}
              data={cardData}
              setData={(e) => {
                setCardData(e);
              }}
            />
          </div>
        )}
        {!add ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              onClick={addData}
              className={!add ? "btn main_submit2" : "btn main_submit3"}
            >
              {add && <AddCircleOutlineIcon />} {index ? "Update" : "Add"}{" "}
              Section
            </button>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              onClick={addData}
              style={{ marginLeft: "auto", marginRight: "-30px" }}
              className={!add ? "btn main_submit2" : "btn main_submit3"}
            >
              {add && <AddCircleOutlineIcon />} {index ? "Update" : "Add"}{" "}
              {button ? button : "Section"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormCard1;
