import React from "react";
import { useParams } from "react-router-dom";
import { destinationpages } from "../../../components/template/CustomTemplate/Pages/Destination";
import CustomTemplates from "../Deals/CustomTemplate";

const EditDestinationPage = () => {
  const params = useParams();

  return (
    <CustomTemplates
      slug={params.id}
      attributes={destinationpages}
      page_type={"destinations"}
      url={"destinations"}
      webpage_slug={"destinations/china"}
    />
  );
};

export default EditDestinationPage;
