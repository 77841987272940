import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { alpha, styled } from "@mui/material/styles";
import { useEffect } from "react";

const SearchField = ({ value, setValue, SearchValue, placeholder }) => {
  useEffect(() => {
    let timeout = setTimeout(() => {
      SearchValue();
    }, 1000);

    return () => clearTimeout(timeout);
  }, [value]);
  return (
    <div
      style={{
        display: "flex",
        marginBottom: "10px",
        justifyContent: "space-between",
      }}
    >
      <div></div>

      <div
        id="voucher_list_filter"
        style={{ display: "flex" }}
        className="dataTables_filter"
      >
        {/* <label style={{ marginRight: "10px", fontWeight: "400" }}>
          Search:
        </label> */}
        <input
          type="search"
          value={value}
          onChange={setValue}
          inputProps={{ "aria-label": "search" }}
          className="form-control form-control-sm"
          placeholder="Search"
          style={{ maxWidth: "188px" }}
          aria-controls="voucher_list"
        />
      </div>
    </div>
  );
};

export default SearchField;
