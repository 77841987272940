import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Layout from "../../Layout";
import HeaderBread from "../../components/HeaderBread";
import CustomHeaderTitle from "../../components/constants/CustomHeaderTitle";
import SpecialPagesInputs from "../../components/Pages/SpecialPagesInputs";
import {
  getSpecialPagesData,
  getSpecialPagesPackageData,
  getMarketCodeByAgency,
} from "../../utils/ApiCalls/SpecialPage";

const EditSpecialPage = () => {
  const params = useParams();
  const [specialpages, setSpecialPages] = useState({});
  const [agencyMargetCodes, setAgencyMarketCodes] = useState([]);
  const [itinerary, setItinerary] = useState([]);
  const [{}, dispatch] = useDataLayerValue();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Special Pages",
      route: "/cms/special-pages",
    },
    {
      title: "Edit Special Pages",
      route: "/cms/special-pages/edit",
    },
  ];
  useEffect(() => {
    getData(params?.id);
  }, [params]);

  const getData = async (value) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getSpecialPagesData({ id: value });
    let res = await getSpecialPagesPackageData({ special_page_id: value });

    if (data.error || res.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error || res.error, type: "error" },
      });
    else if (data.count === 1) {
      setSpecialPages(data.data[0]);
      console.log("data=====>", data.data[0]);
      const marketCode = await getMarketCodeByAgency(
        data.data[0]?.agency?.agencyCode
      );
      console.log("marketCode====>", marketCode);
      setAgencyMarketCodes(marketCode);
      setItinerary(res.map((item) => item.fare_code));
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  return (
    <Layout type={"update_per"} module_data={"Special Pages"}>
      <CustomHeaderTitle title={"Edit Special Pages"} />
      <HeaderBread
        navigation={navigation}
        title={"Edit Special Pages"}
      ></HeaderBread>
      {specialpages && agencyMargetCodes.length > 0 && (
        <SpecialPagesInputs
          page={specialpages}
          iteneries={itinerary}
          agencyMargetCodes={agencyMargetCodes}
        />
      )}
    </Layout>
  );
};

export default EditSpecialPage;
