import ArticalCard from "../Previews/ArticaleCard/Preview";
import ShipBannerPreview from "../Previews/Ship/Banner/Preview";
export const brochures_and_guide = {
  form: [
    {
      title: "Page Name",
      key: "page_name",
      className: "col-lg-12",
      type: "input",
    },
    {
      title: "Meta Description",
      key: "meta_description",
      className: "col-lg-12",
      type: "input",
    },
  ],
  component: [
    {
      type: 1,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "Page Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Page Image",
          type: "file",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <ShipBannerPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 2,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
      ],
      level1: [
        {
          title: "English Thumbnail Image",
          key: "image",
          className: "col-lg-3",
          type: "file",
          placeholder: "Upload English Thumbnail Image",
          required: false,
        },
        {
          title: "Simplified Chinese Thumbnail Image",
          key: "simplified_chinese_image",
          className: "col-lg-3",
          placeholder: "Upload Simplified Chinese Thumbnail Image ",
          type: "file",
          required: false,
        },
        {
          title: "Traditional Chinese Thumbnail Image",
          key: "traditional_chinese_image",
          className: "col-lg-3",
          placeholder: "Upload Traditional Chinese Thumbnail Image ",
          type: "file",
          required: false,
        },
        {
          title: "Arabic Thumbnail Image",
          key: "arabic_image",
          className: "col-lg-3 arabic",
          type: "file",
          placeholder: "Upload Arabic Thumbnail Image ",
          required: false,
        },
        {
          title: "English File",
          key: "english_file",
          className: "col-lg-3",
          type: "file",
          placeholder: "Upload English File",
          filetype: "pdf",
          required: false,
        },
        {
          title: "Simplified Chinese File",
          key: "simplified_chinese_file",
          className: "col-lg-3",
          placeholder: "Upload Simplified Chinese File ",
          type: "file",
          filetype: "pdf",
          required: false,
        },
        {
          title: "Traditional Chinese File",
          key: "traditional_chinese_file",
          className: "col-lg-3",
          placeholder: "Upload Traditional Chinese File ",
          type: "file",
          filetype: "pdf",
          required: false,
        },
        {
          title: "Arabic File",
          key: "arabic_file",
          className: "col-lg-3 arabic",
          type: "file",
          placeholder: "Upload Arabic File ",
          filetype: "pdf",
          required: false,
        },
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <ArticalCard activetab={activetab} cardData={cardData} />
      ),
    },
  ],
};
