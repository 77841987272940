import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";

export const createCabin = async (data) => {
  console.log(data);
  try {
    let res = await Api.post("/cabin-master", data);
    return res?.data;
  } catch (e) {
    console.log(e);
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getCabin = async (query) => {
  try {
    let res = await Api.get("/cabin-master", {
      params: query,
    });
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};
export const getSingleCabin = async (id) => {
  try {
    let res = await Api.get("/cabin-master/"+id);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};


export const deleteCabin = async (id) => {
  try {
    let res = await Api.delete("/cabin-master/" + id);
  
    return res?.data || [];
  } catch (e) {
    return { error: "Failed to delete" };
  }
};

export const updateCabin = async (id, data) => {
  try {
    let res = await Api.patch("/cabin-master/" + id, data);
  
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getCabinTypes = async () => {
  try {
    let res = await Api.get("/cabin-types");
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getCabinCategory = async (vessel_id, cabin_types) => {
  try {
    let res = await Api.get("/cabin-category", {
      params: { vessel_id:vessel_id, cabin_type_id:cabin_types },
    });
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getCabinCategoryByVessel = async (vessel_id) => {
  try {
    let res = await Api.get("/cabin-category/"+vessel_id);
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getCabinVarient = async (cabin_categories_id) => {
  try {
    let res = await Api.get("/cabin-varients", {
      params: { cabin_categories_id},
    });
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getCabinVarientByVarientId = async (cabin_categories_id) => {
  try {
    let res = await Api.get("/cabin-varients/"+ cabin_categories_id);
    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};
