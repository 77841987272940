import { message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import Layout from "../../Layout";
import HeaderBread from "../../components/HeaderBread";
import Logs from "../../components/Logs";
import SwitchBar from "../../components/Pages/SwitchBar";
import SearchField from "../../components/SearchField";
import ActiveInactive from "../../components/UI/ActiveInactive";
import CustomizedDragNDrop from "../../components/UI/CustomizedDragNDrop";
import CustomHeaderTitle from "../../components/constants/CustomHeaderTitle";
import DeletePop from "../../constants/ErrorPermissionModal";
import ValidDateUI from "../../constants/validDateUI";
import { addlog } from "../../utils/ApiCalls/Logs";
import {
  deleteSpecialPagesData,
  getSpecialPagesData,
  updatePosition,
  updateSpecialPagesData,
} from "../../utils/ApiCalls/SpecialPage";
import { checkPermission } from "../../utils/Validation/checkPermission";

const MySwal = withReactContent(Swal);

const SpecialPages = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Special Pages",
      route: "/cms/special-pages",
    },
  ];
  const [active, setActive] = useState("listing");
  const [regionalActive, setRegionalActive] = useState("SG");
  const [rows, setRows] = useState([]);
  const [count, setCount] = useState(0);

  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [{ userDetails, permission }, dispatch] = useDataLayerValue();
  const sortHandler = async (options) => {
    if (Object.keys(options).length > 0) {
      let sort, order;
      sort = options.columnKey;
      order =
        options.order === "ascend"
          ? "asc"
          : options.order === "descend"
          ? "desc"
          : undefined;
      setSort(sort);
      setOrder(order);
      await getData(1, sort, order);
    }
  };
  const handleDelete = async (id, title) => {
    if (checkPermission(permission, "delete_per", "Special Pages")) {
      await deleteSpecialPagesData(id);
      addlog(
        "Special Pages Deleted",
        "Special_Pages",
        { id, title },
        userDetails.id
      );
      await getData(page, sort, order);
    } else {
      message.error("Missing Permission for delete ");
    }
  };
  const columns = [
    {
      title: "Sl",
      dataIndex: "id",
      width: 100,
      key: "id",
      render: (text, record, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Title",
      dataIndex: "english_display_title",
      key: "english_display_title",
      render: (item) => {
        let value = item.split("-");
        if (value && value.length > 1)
          return value.slice(0, value.length - 1).join("-");
        else return value.join("");
      },
      sorter: (a, b) => "",
    },
    {
      title: "Vessel",
      dataIndex: "vessel_name",
      key: "vessel",
      // sorter: (a, b) => "",
      render: (item) => item.name,
    },
    {
      title: "Market Code",
      dataIndex: "agency",
      key: "agency",
      // sorter: (a, b) => "",
      render: (item) => item?.marketCode,
    },

    {
      title: "Valid date",
      dataIndex: "valid_date",
      key: "valid_till",

      render: (text, record, index) => {
        return <ValidDateUI record={record} handleUpdate={handleUpdate} />;
      },
      // sorter: (a, b) => "",
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      sorter: (a, b) => "",

      render: (item, record, index) => {
        return (
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              checked={!(new Date(record.valid_to) <
                new Date(new Date()))&&item ? true : false}
              onChange={(value) => {
                //         console.log("valid_from====>", moment(record.valid_to).format("YYYY-MM-DD"), moment(new Date()).format("YYYY-MM-DD"))
                // console.log("tttt", moment(record.valid_to).format("YYYY-MM-DD")< moment(new Date()).format("YYYY-MM-DD"))
                try {
                  if (
                    new Date(record.valid_from) >
                    new Date(new Date())
                  ) {
                    throw new Error(`Special Page is not yet started`);
                  }

                  if (
                    // new Date(record.valid_to) <= new Date()
                    new Date(record.valid_to) <
                    new Date(new Date())
                  ) {
                    throw new Error(`Special Page is expired`);
                  }
                  handleUpdate(
                    record.id,
                    {
                      is_active: value.target.checked ? 1 : 0,
                    },
                    record.title
                  );
                } catch (error) {
                  dispatch({
                    type: "SET_LOADING",
                    loading: false,
                  });
                  // dispatch({
                  //   type: "SET_ERROR_MESSAGE",
                  //   errMess: {
                  //     message: error.message || "Unknown error occurred",
                  //     type: "error",
                  //   },
                  // });
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.message,
                  });
                }
              }}
              className="custom-control-input"
              id={"customSwitch1" + index}
            />
            <label
              className="custom-control-label"
              style={{}}
              for={"customSwitch1" + index}
            ></label>
          </div>
        );
      },
    },
    {
      title: "Show Home",
      dataIndex: "show_home",
      key: "show_home",
      sorter: (a, b) => "",

      render: (item, record, index) => {
        return (
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              checked={item ? true : false}
              onChange={(value) => {
                //         console.log("valid_from====>", moment(record.valid_to).format("YYYY-MM-DD"), moment(new Date()).format("YYYY-MM-DD"))
                // console.log("tttt", moment(record.valid_to).format("YYYY-MM-DD")< moment(new Date()).format("YYYY-MM-DD"))
                try {
                 
                  handleUpdate(
                    record.id,
                    {
                      show_home: value.target.checked ? 1 : 0,
                    },
                    record.title
                  );
                } catch (error) {
                  dispatch({
                    type: "SET_LOADING",
                    loading: false,
                  });
                  // dispatch({
                  //   type: "SET_ERROR_MESSAGE",
                  //   errMess: {
                  //     message: error.message || "Unknown error occurred",
                  //     type: "error",
                  //   },
                  // });
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.message,
                  });
                }
              }}
              className="custom-control-input"
              id={"customSwitch1-show" + index}
            />
            <label
              className="custom-control-label"
              style={{}}
              for={"customSwitch1-show" + index}
            ></label>
          </div>
        );
      },
    },

    {
      title: "Action",
      key: "operation",

      render: (text, record) => (
        <div className="table_action_btn">
          <div className="btn" onClick={() => handleEdit(record.id)}>
            <i className="ri-edit-2-line" data-toggle="tooltip" title="Edit"></i>
          </div>
          <DeletePop onConfirm={() => handleDelete(record.id, record.title)}>
            <div>
              {" "}
              <div className="btn delete_btn">
                <i
                  className="ri-delete-bin-line"
                  data-toggle="tooltip"
                  title="Delete"
                ></i>
              </div>
            </div>
          </DeletePop>

          <div
            onClick={async () => {
              try {
                navigator.clipboard
                  .writeText(`${record.url}`)
                  .then(() => {
                    alert("successfully copied");
                  })
                  .catch(() => {
                    alert("something went wrong");
                  });
              } catch (error) {
                console.error("Error copying to clipboard:", error);
              }
            }}
            className="btn copy_btn"
          >
            <span data-toggle="tooltip" title="Copy Url">
              Copy Url
            </span>
          </div>
        </div>
      ),
    },
  ];
  useEffect(() => {
    getData(page);
  }, [page, pageSize, search]);

  useEffect(() => {
    getData(page);
  }, [regionalActive]);

  const updateDealPosition = async (pages) => {
    if (checkPermission(permission, "update_per", "Special Pages")) {
      // e.preventDefault();
      try {
        let args = { search: search, page: page, limit: pageSize };
        if (regionalActive) {
          args = { ...args, location: regionalActive };
        }
        if (status !== "") {
          args = { ...args, is_active: status };
        }
        /*Later*/
        // }
        // if (order) {
        //   if (sort) {
        //     args = { ...args, sort };
        //   }
        //   args = { ...args, order };
        // }
        const data = await updatePosition(pages, args);
        // addlog(
        //   "Deal Position Updated",
        //   "Deal",
        //   { location_id:deals[0].location_id },
        //   userDetails.id
        // );
        if (data.error)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data.error,
          });
        else if (data.data) {
          console.log(data.data?.map(item=>item?.position))
          setRows(data.data);
          setCount(data.count);
          // setPositionalValue(data.data);
        }
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
      } catch (error) {
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
        // dispatch({
        //   type: "SET_ERROR_MESSAGE",
        //   errMess: {
        //     message: error.message || "Unknown error occurred",
        //     type: "error",
        //   },
        // });
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.message || "Unknown error occurred",
        });
      }
    } else {
      message.error("Update permission missing");
    }
  };

  const getData = async (value, sort, order) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let args = { search: search, page: value, limit: pageSize };
    if (regionalActive) {
      args = { ...args, location: regionalActive };
    }
    if (status !== "") {
      args = { ...args, is_active: status };
    }
    if (order) {
      if (sort) {
        args = { ...args, sort };
      }
      args = { ...args, order };
    }

    let data = await getSpecialPagesData(args);
    if (data.error) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: data.error, type: "error" },
      // });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.error,
      });
    } else if (data.data) {
      setRows(data.data);
      setCount(data.count);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };
  const handleEdit = async (id) => {
    if (checkPermission(permission, "update_per", "Special Pages")) {
      navigate("/cms/special-pages/edit/" + id);
    } else {
      message.error("Update permission missing");
    }
  };

  const handleUpdate = async (id, values, title) => {
    if (checkPermission(permission, "update_per", "Special Pages")) {
      let data = await updateSpecialPagesData(id, values);
      if (data.error) {
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: { message: data.error, type: "error" },
        });
      } else {
        addlog(
          "Special Pages " + !values.valid_from
            ? values.is_active === 1
              ? "Actived"
              : "Deactivated"
            : "updated",
          "Special_Pages",
          { ...values, id, title },
          userDetails.id
        );
      }
      getData(page);
    } else {
      message.error("Update permission missing");
    }
  };

  return (
    <Layout type={"read_per"} module_data={"Special Pages"}>
      <CustomHeaderTitle title={"Special Pages"} />
      <div>
        <div>
          <HeaderBread
            navigation={navigation}
            title={"Special Pages"}
          ></HeaderBread>
        </div>
        <section className="content" style={{ marginTop: "20px" }}>
          <div className="container-fluid">
            <SwitchBar
              tabs={[
                { title: "Special Pages List", value: "listing" },
                { value: "logs", title: " History Logs" },
              ]}
              activeTab={active}
              setActiveTab={setActive}
            />
            {active === "listing" && (
              <div className="card">
                <div className="card-header two_item">
                  <h5 className="card-title">Special Pages List</h5>
                  <div className="two_item" style={{ width: "350px" }}>
                    <ActiveInactive
                      getData={getData}
                      setStatus={setStatus}
                      status={status}
                      setPage={setPage}
                    />
                    <Link to="/cms/special-pages/add" className="btn add_v_btn">
                      Add Pages
                    </Link>
                  </div>
                </div>
                <div className="card-body">
                  <div className="table_holder">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div style={{ maxWidth: 200 }}>
                        <SearchField
                          value={search}
                          SearchValue={() => {
                            setPage(1);
                          }}
                          setValue={(e) => {
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <SwitchBar
                      tabs={[
                        { title: "Singapore", value: "SG" },
                        { value: "MY", title: "Malaysia" },
                        { value: "ID", title: "Indonesia" },
                        { value: "IN", title: "India" },
                        { value: "CN", title: "China" },
                        { value: "TW", title: "Taiwan" },
                        { value: "HK", title: "Hong Kong" },
                        { value: "PH", title: "Philippines" },
                        { value: "AE", title: "UAE" },
                      
                        { value: "OTHER", title: "All Others" },
                      ]}
                      module={"country-tab"}
                      setPageSize={setPageSize}
                      setPage={setPage}
                      setRows={setRows}
                      setCount={setCount}
                      activeTab={regionalActive}
                      setActiveTab={setRegionalActive}
                      setSearch={setSearch}
                    />
                    {rows && (
                      <CustomizedDragNDrop
                        setRows={setRows}
                        setPageSize={setPageSize}
                        setPage={setPage}
                        pageSize={pageSize}
                        page={page}
                        count={count}
                        columns={columns}
                        rows={rows}
                        sortHandler={sortHandler}
                        title={"Special Pages"}
                        updatePosition={updateDealPosition}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
        {active === "logs" && (
          <Logs
            type={"Special_Pages"}
            slug={"/cms/special-pages/edit/"}
            refreshlog={active}
          />
        )}
      </div>
    </Layout>
  );
};

export default SpecialPages;
