import React from "react";
import { getImage } from "../../../../../utils/getImages";
import "./app.css";
const JobPostCard = ({ cardData, activetab }) => {
 
  return (
    <div style={{ margin: "10px" }}>
      <h1 className="title-color mb-4" style={{textAlign:"center"}}>
        {" "}
        {activetab === "english"
          ? cardData?.title
          : activetab === "simplified_chinese"
          ? cardData?.simplified_chinese_title
          : activetab === "arabic"
          ? cardData?.arabic_title
          : cardData?.traditional_chinese_title}
      </h1>
      <div
      className="row"
        style={{
          display: "flex",
          // alignItems: "center",
          flexWrap: "wrap",
          flexDirection: "row",
          gap: "10px",
        }}
      >
        {cardData?.dataArray?.map((item) => (
          <div className="col-12 " >
        <div className="col-md-12 my-4">

<div className="careers list-group border rounded p-4">


  <a href="" data-pos="1" style={{padding:"10px"}} classname=" list-group-item-action flex-column align-items-start">
    <div classname="w-100">
      <h5 classname="mb-1">   {activetab === "english"
          ? item?.title
          : activetab === "simplified_chinese"
          ? item?.simplified_chinese_title
          : activetab === "arabic"
          ? item?.arabic_title
          : item?.traditional_chinese_title}</h5>
      <small className="d-block"> {activetab === "english"
          ? item?.date
          : activetab === "simplified_chinese"
          ? item?.simplified_chinese_date
          : activetab === "arabic"
          ? item?.arabic_date
          : item?.traditional_chinese_date}</small>
      <small className="d-block">Job Location:  {activetab === "english"
          ? item?.location
          : activetab === "simplified_chinese"
          ? item?.simplified_chinese_location
          : activetab === "arabic"
          ? item?.arabic_location
          : item?.traditional_chinese_location}</small>
    </div>

    <div className="resp resp-1" style={{display:"block"}}> 
      <h6>Job Highlights</h6>
      <p
                            style={{direction:activetab === "arabic"&&"rtl"}}
                              dangerouslySetInnerHTML={{
                                __html:
                                  activetab === "english"
                                    ? item?.highlights
                                    : activetab === "simplified_chinese"
                                    ? item?.simplified_chinese_highlights
                                    : activetab === "arabic"
                                    ? item?.arabic_highlights
                                    : item?.traditional_chinese_highlights,
                              }}
                            />
   
      <hr/>
      
      <h6>Responsibilities</h6>
      <p style={{direction:activetab === "arabic"&&"rtl"}}
                              dangerouslySetInnerHTML={{
                                __html:
                                  activetab === "english"
                                    ? item?.responsibilities
                                    : activetab === "simplified_chinese"
                                    ? item?.simplified_chinese_responsibilities
                                    : activetab === "arabic"
                                    ? item?.arabic_responsibilities
                                    : item?.traditional_chinese_responsibilities,
                              }}
                            />
      
      <hr/>

      <h6>Qualifications and Experience</h6>
      <p style={{direction:activetab === "arabic"&&"rtl"}}
                              dangerouslySetInnerHTML={{
                                __html:
                                  activetab === "english"
                                    ? item?.qualifications
                                    : activetab === "simplified_chinese"
                                    ? item?.simplified_chinese_qualifications
                                    : activetab === "arabic"
                                    ? item?.arabic_qualifications
                                    : item?.traditional_chinese_qualifications,
                              }}
                            />
      <br/>

      <p style={{direction:activetab === "arabic"&&"rtl"}}
                              dangerouslySetInnerHTML={{
                                __html:
                                  activetab === "english"
                                    ? item?.note
                                    : activetab === "simplified_chinese"
                                    ? item?.simplified_chinese_note
                                    : activetab === "arabic"
                                    ? item?.arabic_note
                                    : item?.traditional_chinese_note,
                              }}
                            />
    </div>
  </a>

</div>
</div>            <div className="icon-info border rounded p-5 text-center">
            <img   src={
                  getImage(item?.image)
                  } className="d-block mb-4 mx-auto"/>
            <h4 className="title mb-3" >{activetab === "english"
                  ? item?.title
                  : activetab === "simplified_chinese"
                  ? item?.simplified_chinese_title
                  : activetab === "arabic"
                  ? item?.arabic_title
                  : item?.traditional_chinese_title}</h4>
           <div
                          dangerouslySetInnerHTML={{
                            __html:
                              activetab === "english"
                                ? item?.description
                                : activetab === "simplified_chinese"
                                ? item?.simplified_chinese_description
                                : activetab === "arabic"
                                ? item?.arabic_description
                                : item?.traditional_chinese_description,
                          }}
                        /> </div>
          
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobPostCard;
