import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { useNavigate } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import { uploadFile } from "../../utils/ApiCalls/Document";
import { checkFields } from "../../utils/Validation/checkFields";
import UploadFileImage from "../imageUpload/uploadFileImage";
import {
  createDealDetails,
  updateDealDetailsData,
  getVessels
} from "../../utils/ApiCalls/DealDetails";
import { constants } from "../../constants/constants";


const DealDetailsInputs = ({ details }) => {
  const [isActive, setIsActive] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [traditionalChineseFile, setTraditionalChineseFile] = useState(null);
  const [simplifiedChineseFile, setSimplifiedChineseFile] = useState(null);
  const [traditional_chinese_image, setTraditional_chinese_image] =
  useState(null);
  const [simplified_chinese_image, setSimplified_chinese_image] =
  useState(null);
  const [simplifiedChineseTitle, setSimplifiedChineseTitle] = useState("");
  const [traditionalChineseTitle, setTraditionalChineseTitle] = useState("");
  const [vesselOptions, setVesselOptions] = useState([]);
  const [vessel, setVessel] = useState("");
  const [title, setTitle] = useState("");
  const [itinerary_id, setItinerary_id] = useState("");
  const [, dispatch] = useDataLayerValue();
  const [error, setError] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (details) {
      setTitle(details?.title);
      setSimplifiedChineseTitle(details?.simplifiedChineseTitle);
      setTraditionalChineseTitle(details?.traditionalChineseSubTitle)
      setSimplified_chinese_image(details?.simplified_chinese_image);
      setTraditional_chinese_image(details?.traditional_chinese_image);
      setItinerary_id(details?.itinerary_id);
      setIsActive(details?.is_active === 1 ? true : false);
      setVessel(details?.vessel?.id);
    }
  }, [details]);


  useEffect(() => {
    getLoadData();
  }, []);

  const handleCheckboxChange = () => {
    setIsActive(!isActive);
  };

  const getLoadData = async () => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    let data = await getVessels();
    if (data?.error) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    } else {
      setVesselOptions(data);
    }
    dispatch({
      type: "SET_LOADING",
      loading: false,
    });
  };

  const addDealDetails = async (e) => {
    dispatch({
      type: "SET_LOADING",
      loading: true,
    });
    e.preventDefault();
    let added = false;
    let dataValues = {
      title,
      simplified_chinese_title: simplifiedChineseTitle,
      traditional_chinese_title: traditionalChineseTitle,
      is_active: isActive ? 1 : 0,
      vessel_id: vessel,
    };
    if (selectedFile) {
      let image = await uploadFile(selectedFile);
      dataValues.image = image?.url;
    }
    if (traditionalChineseFile) {
      let image = await uploadFile(traditionalChineseFile);
      dataValues.traditional_chinese_image = image?.url;
    }
    if (simplifiedChineseFile) {
      let image = await uploadFile(simplifiedChineseFile);
      dataValues.simplified_chinese_image = image?.url;
    }
    let valid = checkFields(dataValues);
    if (valid) {
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: valid, type: "error" },
      });
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });   
setError(valid);
    } else {
      try {
        const values = {
          ...dataValues,
        };
        let data;
        if (details?.id) {
          data = await updateDealDetailsData(details.id, values);
        } else {
          data = await createDealDetails(values);
        }

        if (data?.error) {
          throw new Error(data.error);
        }

        dispatch({
          type: "SET_LOADING",
          loading: false,
        });

        if (!details.id) {
          navigate("/cms/homepage/deal-details");
        } else {
          navigate("/cms/homepage/deal-details");
          dispatch({
            type: "SET_ERROR_MESSAGE",
            errMess: { message: "Values updated", type: "success" },
          });
        }
      } catch (error) {
        dispatch({
          type: "SET_LOADING",
          loading: false,
        });
        dispatch({
          type: "SET_ERROR_MESSAGE",
          errMess: {
            message: error.message || "Unknown error occurred",
            type: "error",
          },
        });
      }
    }
  };
  return (
    <div>
      <section className="content">
        {error && (
          <div className="d-flex justify-content-center align-items-center text-danger">
            <p>{error}</p>
          </div>
        )}

        <div className="container-fluid">
          <div className="card">
            <form className="input_form" onSubmit={addDealDetails}>
              <div className="card-body">
                <div className="row">
                  <UploadFileImage
                    image={details?.image ? `${constants.MEDIAURL}/${details?.image}`: details?.image}
                    label={"English Deal Details Image"}
                    placeholder={"Upload Deal Details Image"}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                  />

                  <UploadFileImage
                    width={778}
                    height={542}
                    image={details?.traditional_chinese_image ? `${constants.MEDIAURL}/${details?.traditional_chinese_image}`: details?.traditional_chinese_image }
                    label={"Traditional Chinese Deal Details Image "}
                    placeholder={"Upload Traditional Chinese Deal Details Image "}
                    selectedFile={traditionalChineseFile}
                    setSelectedFile={setTraditionalChineseFile}
                  />
                  <UploadFileImage
                    width={778}
                    height={542}
                    image={details?.simplified_chinese_image ? `${constants.MEDIAURL}/${details?.simplified_chinese_image}`: details?.simplified_chinese_image}
                    label={"Simplified Chinese Deal Details Image"}
                    placeholder={"Upload Simplified Chinese Deal Details Image "}
                    selectedFile={simplifiedChineseFile}
                    setSelectedFile={setSimplifiedChineseFile}
                  />


                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">English Title</label>
                      <input
                        // required
                        className="f_input"
                        type="text"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Simplified Chinese Title</label>
                      <input
                        // required
                        className="f_input"
                        type="text"
                        value={simplifiedChineseTitle}
                        onChange={(e) => setSimplifiedChineseTitle(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Traditional Chinese Title</label>
                      <input
                        // required
                        className="f_input"
                        type="text"
                        value={traditionalChineseTitle}
                        onChange={(e) => setTraditionalChineseTitle(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                    <div className="fieldrow">
                      <label className="f_label">Vessels</label>
                      <Select
                          required
                          style={{ width: "100%", height: "45px" }}
                          value={vessel}
                          onChange={(e) => setVessel(e.target.value)}
                        >
                          {vesselOptions.map((item, index) => (
                            <MenuItem key={index} value={item?.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      {/* <input
                        className="f_input"
                        required
                        type="text"
                        value={itinerary_id}
                        onChange={(e) => setItinerary_id(e.target.value)}
                      /> */}
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="iti_inner">
                      <div className="icheck-primary d-inline">
                        <input
                          type="checkbox"
                          id="customCheckbox4"
                          name="r10"
                          checked={isActive}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="customCheckbox4">
                        {isActive? <p><span style={{fontWeight:600}}>Active</span></p> : <p><span style={{fontWeight:200}}>Active</span></p>}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-footer text-right">
                <button className="btn main_submit">
                  {details.id ? "Update" : "Add"} details
                </button>
              </div>
            </form>
          </div>
          <div className="pt-3"></div>
        </div>
      </section>
    </div>
  );
};

export default DealDetailsInputs;
