import moment from "moment";
import React, { useEffect, useState } from "react";
import LocationAndExpcept from "../../../../constants/LocationAndExpcept";
import { getAllLocations } from "../../../../utils/ApiCalls/Locations";
import DateAndTimePicker from "../../../DateAndTimePicker";

const PageSEO = ({ page_type, cardData, setCardData, webpage_slug }) => {
  const [title, setTitle] = useState("");
  const [active, setActive] = useState(false);
  const [locationOption, setLocationOption] = useState([]);
  const [displayLocation, setDisplayLocation] = useState([]);
  const [valid_from, setValidFrom] = useState("");
  const [valid_to, setValidTo] = useState("");
  const [currentLocation, setCurrentLocation] = useState([]);

  const handleDisplayLocationChange = (selectedOptions) => {

    setCardData((item) => ({
      ...item,
      location: selectedOptions.target.value,
    }))
    setDisplayLocation(selectedOptions.target.value);
  };
  useEffect(() => {
    getOptionLocation()
  }, [])
  const getOptionLocation = async () => {

    let data = await getAllLocations();

    if (data.error) {
      // dispatch({
      //   type: "SET_ERROR_MESSAGE",
      //   errMess: { message: data.error, type: "error" },
      // });

    } else {
      setLocationOption(data?.data || []);
    }

  };
  return (
    <div className="card">
      {!active && (
        <div className="card-body">
          <div className="row">
            {(page_type === "events_item" || page_type === "destinations") && <div className="col-lg-12 col-md-6 col-sm-6 col-6">
              <div className="sailing_date">
                <h4 className="sub_title2">Valid Date</h4>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="fieldrow">
                      <label className="f_label">
                        From <span style={{ color: "red" }}> *</span>
                      </label>

                      <DateAndTimePicker
                        value={cardData?.valid_from}
                        setValue={(e) => {
                          console.log(e)
                          setValidFrom(e);
                          setValidTo("");
                          setCardData((item) => ({
                            ...item,
                            valid_from: e
                          }))
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                    <div className="fieldrow">
                      <label className="f_label">
                        To <span style={{ color: "red" }}> *</span>
                      </label>

                      <DateAndTimePicker

                        value={cardData?.valid_to}

                        setValue={(e) => {
                          setValidTo(e);
                          setCardData((item) => ({
                            ...item,
                            valid_to: e
                          }))
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>}
            <div className="col-lg-12">
              <div className="fieldrow">
                <label className="f_label">Page Title</label>
                <input
                  className="f_input"
                  // required
                  type="text"
                  value={cardData?.title}
                  onChange={(e) =>
                    setCardData((item) => ({
                      ...item,
                      title: e.target.value,
                    }))
                  }
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="fieldrow">
                <label className="f_label">Meta Description</label>
                <textarea
                  rows="4"
                  cols="50"
                  className="f_input"
                  // required
                  type="text"
                  value={cardData?.description}
                  onChange={(e) =>
                    setCardData((item) => ({
                      ...item,
                      description: e.target.value,
                    }))
                  }
                />
              </div>{" "}
            </div>
            <div className="col-lg-12">
              <div className="fieldrow">
                <label className="f_label">Page Slug</label>
                <input
                  className="f_input"
                  // required
                  type="text"
                  value={cardData?.slug}
                  onChange={(e) =>
                    setCardData((item) => ({
                      ...item,
                      slug: e.target.value,
                    }))
                  }
                />
              </div>{" "}
            </div>

            {page_type === "blog_item" && <div className="col-lg-12">
              <div className="fieldrow">
                <label className="f_label">Page Date</label>
                <input
                  className="f_input"
                  type="date"
                  value={cardData?.date && moment(cardData?.date).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setCardData((item) => ({
                      ...item,
                      date: moment(e.target.value).format("YYYY-MM-DD"),
                    }))
                  }
                  }
                />
              </div>


            </div>}
            {page_type === "events_item" && <LocationAndExpcept
              displayLocation={cardData.location || []}
              locationOption={locationOption}
              nondisplay
              options={locationOption}
              setDisplayLocation={(value) => {
                console.log({ value })
                setCardData((item) => ({
                  ...item,
                  location: value,
                }))
              }}
              handleDisplayLocationChange={handleDisplayLocationChange}
            />}

            {!cardData?.id && webpage_slug && (
              <div className="row">
                <div className="col-lg-3">
                  <div className="fieldrow">
                    <label className="f_label">English Menu Title </label>
                    <input
                      className="f_input"
                      // required
                      type="text"
                      value={cardData?.menuName}
                      onChange={(e) =>
                        setCardData((item) => ({
                          ...item,
                          menuName: e.target.value,
                        }))
                      }
                    />
                  </div>{" "}
                </div>
                <div className="col-lg-3">
                  <div className="fieldrow">
                    <label className="f_label">
                      Simplified Menu Chinese Title{" "}
                    </label>
                    <input
                      className="f_input"
                      // required
                      type="text"
                      value={cardData?.simplifiedChineseMenuName}
                      onChange={(e) =>
                        setCardData((item) => ({
                          ...item,
                          simplifiedChineseMenuName: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="fieldrow">
                    <label className="f_label">
                      Traditional Chinese Page Title
                    </label>
                    <input
                      className="f_input"
                      // required
                      type="text"
                      value={cardData?.traditionalChineseMenuName}
                      onChange={(e) =>
                        setCardData((item) => ({
                          ...item,
                          traditionalChineseMenuName: e.target.value,
                        }))
                      }
                    />
                  </div>{" "}
                </div>
                <div className="col-lg-3">
                  <div className="fieldrow">
                    <label className="f_label">Arabic Menu Title</label>
                    <input
                      className="f_input"
                      // required
                      type="text"
                      value={cardData?.arabicMenuName}
                      onChange={(e) =>
                        setCardData((item) => ({
                          ...item,
                          arabicMenuName: e.target.value,
                        }))
                      }
                    />
                  </div>{" "}
                </div>

              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PageSEO;
