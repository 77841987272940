import React from "react";
import { eventDetails } from "../../../components/template/CustomTemplate/Pages/eventDetails";
import CustomTemplates from "../Deals/CustomTemplate";

const AddEventsPage = () => {

  return (
    <CustomTemplates page_type={"events_item"} attributes={eventDetails} />
  );
};

export default AddEventsPage;
