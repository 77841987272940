import Card3Ship from "../Previews/Card3Ship/Preview";
import Card4Ship from "../Previews/Card4Ship/Preview";
import Card5Ship from "../Previews/Card5Ship/Preview";
import FooterShip from "../Previews/FooterShip/Preview";
import ShipBannerPreview from "../Previews/Ship/Banner/Preview";
import ShipDealPreview from "../Previews/Ship/DealCard/Preview";

export const ships = {
  form: [
    {
      title: "Page Name",
      key: "page_name",
      className: "col-lg-12",
      type: "input",
      required: false,
    },
    {
      title: "Meta Description",
      key: "meta_description",
      className: "col-lg-12",
      type: "input",
    },
  ],
  component: [
    {
      type: 1,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "Page Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Page Image",
          type: "file",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <ShipBannerPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 1,
      fields: [
        {
          title: "Page Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Page Image",
          type: "file",
          required: false,
        },

        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Button 1 English Title",
          key: "title_button_1",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Button 1 Simplified Chinese Title",
          key: "simplified_chinese_title_button_1",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Button 1 Traditional Chinese Title",
          key: "traditional_chinese_title_button_1",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Button 1 Arabic Title",
          key: "arabic_title_button_1",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "English Button 1 file",
          key: "english_file_1",
          className: "col-lg-3",
          type: "file",

          placeholder: "Upload English Button 1 file",
          filetype: "pdf",
          required: false,
        },
        {
          title: "Simplified Chinese Button 1 file",
          key: "simplified_chinese_file_1",
          className: "col-lg-3",
          placeholder: "Upload Simplified Chinese Button 1 file ",
          type: "file",
          filetype: "pdf",
          required: false,
        },
        {
          title: "Traditional Chinese Button 1 file",
          key: "traditional_chinese_file_1",
          className: "col-lg-3",
          placeholder: "Upload Traditional Chinese Button 1 file ",
          type: "file",
          filetype: "pdf",
          required: false,
        },
        {
          title: "Arabic Button 1 file",
          key: "arabic_file_1",
          className: "col-lg-3 arabic",
          type: "file",
          placeholder: "Upload Arabic Button 1 file ",
          filetype: "pdf",
          required: false,
        },
        {
          title: "Button 2 English Title",
          key: "title_button_2",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Button 2 Simplified Chinese Title",
          key: "simplified_chinese_title_button_2",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Button 2 Traditional Chinese Title",
          key: "traditional_chinese_title_button_2",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Button 2 Arabic Title",
          key: "arabic_title_button_2",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "English Button 2 file",
          key: "english_file_2",
          className: "col-lg-3",
          type: "file",
          placeholder: "Upload English Button 2 file",
          filetype: "pdf",
          required: false,
        },
        {
          title: "Simplified Chinese Button 2 file",
          key: "simplified_chinese_file_2",
          className: "col-lg-3",
          placeholder: "Upload Simplified Chinese Button 2 file ",
          type: "file",
          filetype: "pdf",
          required: false,
        },
        {
          title: "Traditional Chinese Button 2 file",
          key: "traditional_chinese_file_2",
          className: "col-lg-3",
          placeholder: "Upload Traditional Chinese Button 2 file ",
          type: "file",
          filetype: "pdf",
          required: false,
        },
        {
          title: "Arabic Button 2 file",
          key: "arabic_file_2",
          className: "col-lg-3 arabic",
          type: "file",
          placeholder: "Upload Arabic Button 1 file ",
          filetype: "pdf",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <ShipDealPreview activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 2,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
      ],
      level1: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false,
        },

        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "English Description 2",
          key: "description2",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Simplified Chinese Description 2",
          key: "simplified_chinese_description2",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Traditional Chinese Description 2",
          key: "traditional_chinese_description2",
          className: "col-lg-3",
          type: "html",
        },
        {
          title: "Arabic Description 2",
          key: "arabic_description2",
          className: "col-lg-3",
          type: "html",
        },
      ],
      preview: (activetab, cardData) => (
        <Card3Ship activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 3,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
      ],
      level1: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false,
        },

        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },
        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
      ],
      level2: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <Card4Ship activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 2,
      fields: [
        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "input",
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "input",
        },
      ],
      level1: [
        {
          title: "Card Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Card Image",
          type: "file",
          required: false,
        },

        {
          title: "English Title",
          key: "title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Simplified Chinese Title",
          key: "simplified_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Traditional Chinese Title",
          key: "traditional_chinese_title",
          className: "col-lg-3",
          type: "input",
          required: false,
        },
        {
          title: "Arabic Title",
          key: "arabic_title",
          className: "col-lg-3 arabic",
          type: "input",
          required: false,
        },

        {
          title: "English Description",
          key: "description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Simplified Chinese Description",
          key: "simplified_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Traditional Chinese Description",
          key: "traditional_chinese_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
        {
          title: "Arabic Description",
          key: "arabic_description",
          className: "col-lg-3",
          type: "html",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <Card5Ship activetab={activetab} cardData={cardData} />
      ),
    },
    {
      type: 1,
      fields: [
        {
          title: "Footer Image",
          key: "image",
          className: "col-lg-12",
          placeholder: "Upload Footer Image",
          type: "file",
          required: false,
        },
      ],
      preview: (activetab, cardData) => (
        <FooterShip activetab={activetab} cardData={cardData} />
      ),
    },
  ],
};
