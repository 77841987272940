import React from "react";
import { getLang } from "../lang/getlang";
import "./app.css";
const TablePreview7 = ({ cardData, activetab }) => {
  
  return (
    <div style={{ padding: "10px" }}>
      <div className="row mt-4 py-3">
        <div className="col-12 text-center">
          {cardData.dataArray?.map((item,index) => {
            return (
              <div className="container table-wrapper" key={index}>
                <table className="wifi">
                  <thead>
                    <tr>
                      <th id="th1" colspan="7" className="text-center" scope="col">
                        {" "}
                        {activetab === "english"
                          ? item?.subtitle
                          : activetab === "simplified_chinese"
                          ? item?.simplified_chinese_subtitle
                          : activetab === "arabic"
                          ? item?.arabic_subtitle
                          : item?.traditional_chinese_title}
                      </th>
                    </tr>
                    <tr>
                      <th
                        id="th3"
                        scope="col"
                        data-lang='{ "CN":"配套" , "AR": "الباقات"}'
                      >
                         {getLang("packages",activetab)}
                   
                      </th>
                      <th
                        id="th2"
                        scope="col"
                        data-lang='{ "CN":"1晚" , "AR": "ليلة واحدة"}'
                      >
                   {getLang("port_name",activetab)}
                      </th>
                      <th
                        id="th2"
                        scope="col"
                        data-lang='{ "CN":"2晚" , "AR": "ليلتان"}'
                      >{getLang("address",activetab)}
                      </th>
                    
                     
                    </tr>
                  </thead>
                  <tbody>
                    {item?.dataArray?.map((item) => (
                      <tr>
                        <td data-lang='{ "CN":"標準（全程郵輪）- 1設備" , "AR": "عادية (كامل الرحلة)- جهاز واحد"}'>
                          {activetab === "english"
                            ? item?.venue_title
                            : activetab === "simplified_chinese"
                            ? item?.simplified_chinese_venue_title
                            : activetab === "arabic"
                            ? item?.arabic_venue_title
                            : item?.traditional_chinese_venue_title}
                        </td>
                        <td className="center">
                          <span className="price">
                            {" "}
                            {activetab === "english"
                              ? item?.port_name
                              : activetab === "simplified_chinese"
                                ? item?.simplified_chinese_port_name
                              : activetab === "arabic"
                              ? item?.arabic_port_name
                              : item?.traditional_chinese_port_name}
                          </span>
                        </td>
                        <td className="center">
                          <span className="price">
                            {" "}
                            {activetab === "english"
                              ? item?.address
                              : activetab === "simplified_chinese"
                              ? item?.simplified_chinese_address
                              : activetab === "arabic"
                              ? item?.arabic_address
                              : item?.traditional_chinese_address}
                          </span>
                        </td>
                  
                      </tr>
                    ))}
                  
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
      </div>
    
    </div>
  );
};

export default TablePreview7;
