import Api from "../../Api";
import { modifyError } from "../../Validation/ModifyErrros";


export const getPackageData = async (query) => {
  try {
    let res = await Api.get("/packages", {
      params: query,
    });

    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getPackageDataAll = async (query) => {
  try {
    let res = await Api.get("/packages/group", {
      params: query,
    });

    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getFare = async (query) => {
  try {
    let res = await Api.get("/packages/fares", {
      params: query,
    });

    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const getPackageDataById = async (id) => {
  try {
    let res = await Api.get("/packages/" + id)

    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};
export const getPackageGroupDataById = async (id) => {
  try {
    let res = await Api.get("/packages/group/" + id)

    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const getPackageDetails = async (id) => {
  try {
    let res = await Api.get("/packages/itineariesDetails/" + id)

    return res?.data || [];
  } catch (e) {

    return { error: "Something Wrong" };
  }
};

export const getNoteData = async (module) => {
  console.log(module);
  try {
    let res = await Api.get("/note/" + module)

    return res?.data || [];
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const updatePackageData = async (id, data) => {
  try {
    let res = await Api.patch("/packages/itineary/" + id, data);

    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const createCron = async (data) => {

  try {
    let res = await Api.post("/cron-logs", data);
    return res?.data;
  } catch (e) {
    return { error: "Something Wrong" };
  }
};
export const getCron = async () => {

  try {
    let res = await Api.get("/cron-logs",);
    return res?.data;
  } catch (e) {
    return { error: "Something Wrong" };
  }
};

export const updatePackageStatus = async (id, data) => {
  try {
    let res = await Api.patch("/packages/" + id, data);

    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};

export const deletePackageData = async (id) => {
  try {
    let res = await Api.delete("/packages/" + id);

    return res?.data || [];
  } catch (e) {
    let error = modifyError(e);
    return { error: error.message };
  }
};