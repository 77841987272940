import React from "react";
import { useParams } from "react-router-dom";
import { ships } from "../../../components/template/CustomTemplate/Pages/Ships";
import CustomTemplates from "../Deals/CustomTemplate";

const EditShips = () => {
  const params = useParams();

  return (
    <CustomTemplates
      slug={params.id}
      attributes={ships}
      page_type={"ships"}
      url={"our-ship"}
      webpage_slug={"our-ship/genting-dream"}
    />
  );
};

export default EditShips;
