import React, { useEffect, useState } from "react";
import { useDataLayerValue } from "../../DataLayer/DataLayer";
import CustomizedTable from "../../components/UI/CustomizedTable";
import { getDownloadsData } from "../../utils/ApiCalls/Downloads";
import moment from "moment-timezone";
import { constants } from "../../constants/constants";

// import DatePicker from 'react-date-picker';
// // import 'react-datepicker/dist/react-datepicker.css'

const Download = (componentData) => {
  const [downloadRows, setDownloadRows] = useState([]);
  const [downloadCount, setDownloadCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [positionVal, setPositionVal] = useState([]);
  const [{ userDetails }, dispatch] = useDataLayerValue();

  const sortHandler = async (options) => {
    if (Object.keys(options).length > 0) {
      let sort, order;
      sort = options.columnKey;
      order =
        options.order === "ascend"
          ? "asc"
          : options.order === "descend"
          ? "desc"
          : undefined;
      await getDownloads(1, sort, order);
    }
  };

  const setPositionalValue = (data) => {
    const mappedPositionVal = data.map((d) => ({
      id: d.id,
      pos: d.position,
    }));
    setPositionVal(mappedPositionVal);
  };
  const downloadColumns = [
    {
      title: "Sl",
      width: 30,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      render: (text, record, index) => (page - 1) * pageSize + index + 1,
    },
    {
      title: "Requested At",
      dataIndex: "created_at",
      key: "created_at",
      width: 80,
      // sorter: (a, b) => "",
      render: (created_at) => <p>{moment(created_at).tz("Asia/Kuala_Lumpur").format("DD/MM/YYYY hh:mm a")}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 80,
      // sorter: (a, b) => "",
      render: (status) => <p>{status==0?<i className="badge badge-danger">Pending</i>:<i className="badge badge-success">Completed</i>}</p>,
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (text, record) => (
        <>
          {record?.status==0 ? 
          <div className="table_action_btn">
            <div
              className="btn"
            >
              <i className="fa fa-spinner fa-spin" data-toggle="tooltip"></i>
            </div>
          </div> : 
          <div className="table_action_btn">
          <a href={constants.MEDIAURL+'/'+record?.s3_link} target="_blank">
            <div className="btn success_btn">
              <i className="fa fa-download" data-toggle="tooltip"></i>
            </div>
          </a>
        </div>}
        </>
      ),
    }
  ];
  useEffect(() => {    
    getDownloads(page,false,false,true);
    const interval = setInterval(async () => {  
      getDownloads(page,false,false,false);
    }, 10000);
  
    return () => clearInterval(interval);
  }, [page]);
  const getDownloads = async (value, sort, order, is_loading) => {
    if(is_loading){
      dispatch({
        type: "SET_LOADING",
        loading: true,
      });
    }   

    let args = {
      page: value,
      limit: pageSize,
      module: componentData.module,
      user_id: userDetails.id,
    };

    if (order) {
      if (sort) {
        args = { ...args, sort };
      }
      args = { ...args, order };
    }
    
    let data = await getDownloadsData(args);
    if (data.error)
      dispatch({
        type: "SET_ERROR_MESSAGE",
        errMess: { message: data.error, type: "error" },
      });
    else if (data.data) {
      setDownloadRows(data.data);
      setDownloadCount(data.count);
      setPositionalValue(data.data);
    }
    if(is_loading){
      dispatch({
        type: "SET_LOADING",
        loading: false,
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="card">
        <div className="card-header two_item">
          <h5 className="card-title">Downloads</h5>
        </div>
        <div className="card-body">
          <div className="table_holder">
            <CustomizedTable
              setRows={setDownloadRows}
              setPageSize={setPageSize}
              setPage={setPage}
              pageSize={pageSize}
              page={page}
              count={downloadCount}
              columns={
                downloadColumns
              }
              rows={downloadRows}
              sortHandler={sortHandler}
              title={"Cabin Bookings"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
