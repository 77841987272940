import React from "react";

import { LifeAtRwc } from "../../../../components/template/CustomTemplate/Pages/LifeAtRwc";
import CustomTemplates from "../../Deals/CustomTemplate";

const EditEntertainmentPage = () => {
  

  return (
    <CustomTemplates slug={"life-at-rwc"} attributes={LifeAtRwc} />
  );
};

export default EditEntertainmentPage;
