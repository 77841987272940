import React from "react";
import BannerInputs from "../../../components/Banner/BannerInputs";
import HeaderBread from "../../../components/HeaderBread";
import Layout from "../../../Layout";
import { useNavigate } from "react-router-dom";
import ExperienceInputs from "../../../components/Experience/ExperienceInputs";
import CustomHeaderTitle from "../../../components/constants/CustomHeaderTitle";
import NewsLetterInputs from "../../../components/NewsLetter/NewsLetterInput";
import AnnouncementInputs from "../../../components/Announcement/NewsLetterInput";

const CreateAnnouncement = () => {
  const navigate = useNavigate();
  const navigation = [
    {
      title: "Home",
      route: "/",
    },
    {
      title: "Announcement",
      route: "/cms/homepage/announcement",
    },
    {
      title: "Add Announcement",
      route: "/cms/homepage/announcement/add",
    },
  ];

  return (
    <Layout type={"create_per"} module_data={"Announcement"}>
      <CustomHeaderTitle title={"Create Announcement"} />
      <HeaderBread
        navigation={navigation}
        title={"Add Announcement"}
      ></HeaderBread>
      <AnnouncementInputs newsletter={{}} />
    </Layout>
  );
};

export default CreateAnnouncement;
